import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import useWindowSize from 'routes/SummerCampaign/hooks/useWindowSize';

function ScrollToTopHOC(Component) {
	return function WrappedComponent(props) {
		const size = useWindowSize();

		return <Component {...props} size={size} />;
	};
}

class ScrollToTop extends Component {
	componentDidMount() {}

	componentDidUpdate(prevProps) {
		const webView = this.props.webView;
		if (this.props.location !== prevProps.location && !webView) {
			window.scrollTo(0, 0);
			this.props.dismissNotice();

			//Toggle mobile menu with route changing
			const ToggleActive = (selector) => {
				const containActive = document.querySelector(selector)?.classList?.contains('active');
				containActive && document.querySelector(selector).classList.toggle('active');
			};

			const size = this.props.size;

			if (size < 876) {
				ToggleActive('.hamburger-button');
			}
			ToggleActive('.menu_nav');
		}
	}

	render() {
		return this.props.children;
	}
}

export default withRouter(ScrollToTopHOC(ScrollToTop));
