import styled from 'styled-components';
import { MouseIcon } from '../../../../prepr/assets';

export const AnchorButtonWrapper = styled.div`
  background: linear-gradient(315deg, #007dde 0%, #0047ba 100%);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0;

  cursor: pointer;

  transition: box-shadow 0.3s ease-in-out;

  a {
    display: inline-block;
    width: 65px;
    height: 65px;
    background: url(${MouseIcon}) no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    cursor: pointer;

    background: #007dde;
    box-shadow: 0px 4px 20px rgba(0, 174, 255, 0.24);

    transition: box-shadow 0.3s ease-in-out;
  }
`;
