import styled, { css } from 'styled-components';
import { FieldContainer } from './InputField/InputField.styles';
import { SelectWrapper } from './SelectField/SelectField.styles';
import { SelectorWrapper } from './InputSelector/InputSelector.styles';
import { ButtonWrapper } from '../Button/Button.styles';

export const FormWrapperBase = styled.form(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    align-content: center;
    position: relative;
    width: 100%;

    margin: 0 auto;
    padding: 0;
    
    ${mediaQueries.sm} {
      ${ButtonWrapper} {
        width: 100%;
      }

      ${FieldContainer}, ${SelectWrapper} {
        width: 100% !important;
      }
    }
  `;
});

export const FieldWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;

    width: 100%;
    position: relative;

    padding: 0;

    ${FieldContainer}, ${SelectWrapper}, ${SelectorWrapper} {
      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }
    ${mediaQueries.sm} {
      ${FieldContainer}, ${SelectWrapper} {
        padding-right: 0;
        padding-left: 0;
      }
    }
  `;
});