import axios from 'axios'
import { apiURL } from '../../config/config'


export const deletePointsTransfer = (accountId, transferId, token) => {
  return axios({
    url: apiURL + `/api/Points/${accountId}/pointsTransfer/${transferId}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}

export const updatePointsTransfer = (accountId, transferId, token, settings) => {
  return axios({
    url: apiURL + `/api/Points/${accountId}/pointsTransfer/${transferId}`,
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: settings
  })
}
export const getAllPointsTransfer = (accountId, token) => {
  return axios({
    url: apiURL + `/api/Points/${accountId}/getAllPointsTransfer`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}

export const getAllPointsHistory = (settings, token) => {
  return axios({
    url: apiURL + `/api/Points/${settings.accountId}/getAllPointsHistory`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    params: {
      "sortOrder": settings.sortOrder,
      "pageNumber": settings.pageNumber,
      "pageSize": settings.pageSize
    }
  })
}

export const getAllCards = (accountId, token) => {
  return axios({
    url: apiURL + `/api/Card/${accountId}/getAllCards`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}

export const getGiftCards = (token) => {
  return axios({
    url: apiURL + `/api/Customer/giftCards`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}

export const getPointTransactionTypes = token => {
  return axios({
    url: apiURL + `/api/Constant/getPointTransactionTypes`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}

export const getCustomerDetails = (accountId, token) => {
  return axios({
    url: apiURL + `/api/Customer/${accountId}/customerDetails`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}

export const setCustomerDetails = (settings, accountId, token) => {
  return axios({
    url: apiURL + `/api/Customer/${accountId}/customerDetails`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: settings
  })
}

export const transferPoints = (accountId, token, settings) => {
  return axios({
    url: apiURL + `/api/Points/${accountId}/pointsTransfer`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: settings
  })
}

export const unlinkCard = (cardId, accountId, token) => {
  return axios({
    url: apiURL + `/api/Card/${accountId}/cards/${cardId}/unlinkCard`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}

export const blockCard = (cardId, accountId, token) => {
  return axios({
    url: apiURL + `/api/Card/${accountId}/getCard/${cardId}/lostOrStolenCard`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}

export const linkLoyaltyCard = (accountId, token, settings) => {
  return axios({
    url: apiURL + `/api/Card/${accountId}/linkLoyaltyCard`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: settings
  })
}

export const linkPaymentCard = (accountId, token, settings) => {
  return axios({
    url: apiURL + `/api/Card/${accountId}/linkPaymentCard`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: settings
  })
}

export const linkPhoneNumber = (accountId, token, settings) => {
  return axios({
    url: apiURL + `/api/Customer/${accountId}/linkPhoneNumber`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: settings
  })
}

export const setPhysicalCardAsMasterCard = (accountId, token, settings) => {
  return axios({
    url: apiURL + `/api/Card/${accountId}/setPhysicalCardAsMasterCard`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: settings
  })
}

export const setDigitalCardAsMasterCard = (accountId, token, settings) => {
  return axios({
    url: apiURL + `/api/Card/${accountId}/setDigitalCardAsMasterCard`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: settings
  })
}

export const mergeAccounts = (masterID, minionID, masterAuthToken, settings) => {
  return axios({
    url: apiURL + `/api/Customer/${masterID}/accountMerge/${minionID}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": masterAuthToken
    },
    data: settings
  })
}
