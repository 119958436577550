import React, { IframeHTMLAttributes, ClipboardEvent, useState } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import SVG from 'react-inlinesvg';

import { FieldLabel } from '../UI';
import ErrorsList from '../ErrorsList';

import { FieldContainer, FieldInput, FieldError, ButtonEye} from './InputField.styles';
import { IconEye, IconEyeCrossed } from '../../../../assets';

interface InputFieldProps extends IframeHTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn;
  isRequired?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isError?: boolean;
  errorText: Array<string> | string | undefined;
  width?: number;
  last?: boolean;
  type?: string;
  name: string;
  label: string;
  isEyeIconActive?: boolean;
  defaultValue?: string | number;
  isErrorsListActive?: boolean;
  fieldScheme?: object;
  onCut?: (e: ClipboardEvent<HTMLInputElement>) => void;
  onCopy?: (e: ClipboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
}

const InputField = ({
  register,
  isRequired = false,
  placeholder = '',
  disabled = false,
  errorText,
  isError = false,
  width = 100,
  type = 'text',
  name,
  label,
  isEyeIconActive = false,
  defaultValue = '',
  isErrorsListActive = false,
  fieldScheme,
  onCut,
  onCopy,
  onPaste
}: InputFieldProps) => {
  const [isFieldValueVisible, setIsFieldValueVisible] = useState<boolean>(false);
  const handleSetIsFieldValueVisible = (e: any) => {
    e.preventDefault();
    setIsFieldValueVisible((prev) => !prev)
  }

  return (
    <FieldContainer width={width}>
      <FieldLabel name={name} label={label} isRequired={isRequired} />

      <FieldInput
        id={name}
        className={isError ? 'hasError' : ''}
        {...register}
        placeholder={placeholder}
        disabled={disabled}
        type={isFieldValueVisible ? 'text' : type}
        defaultValue={defaultValue}
        isEyeIconActive={isEyeIconActive}
        onCut={onCut}
        onCopy={onCopy}
        onPaste={onPaste}
      />

      {isEyeIconActive &&
        <ButtonEye onClick={handleSetIsFieldValueVisible}>
          <SVG src={isFieldValueVisible? IconEye : IconEyeCrossed} />
        </ButtonEye>
      }

      {isError && !isErrorsListActive && <FieldError>{errorText}</FieldError>}

      {
        isError && isErrorsListActive && fieldScheme &&
        <ErrorsList fieldScheme={fieldScheme}
                    errorText={errorText}/>
      }

    </FieldContainer>
  );
};

export default InputField;
