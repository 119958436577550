import styled, { css } from 'styled-components';

export const TopNavigationWrapper = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    background: ${color.cloudWhite};
  `;
});

export const TopNavigationBox = styled.div(({theme}) => {
  const { color } = theme;

  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    background: ${color.cloudWhite};
  `;
});

