import React, { useEffect, useRef, useState } from 'react';
import { Headline } from '../../../redesign/components/shared';
import ProductCard from '../../../redesign/components/shared/ProductCard';
import {
  getRewardsByCategory,
} from '../../../components/utils/_reward-helpers';

import { ProductCollectionProps } from './productCollection.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';

import { ProductRow, ProductRowCol } from './ProductCollection.styles';

const ProductCollection: React.FC<ProductCollectionProps> = ({
  params,
  product,
  onLogin
}) => {
  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const url = window.location.href;

  const lastURLSegment = url.split('/').pop();

  const renderCounter = useRef(0);

  useEffect(() => {
    renderCounter.current ++;
  })

  useEffect(() => {
    if(renderCounter.current <=1 ){
      if (lastURLSegment) {
        getRewardsByCategory(99, lastURLSegment).then((response) => {
          setData(response.data.payload.rewards);
        });
      }
    }
  }, [lastURLSegment]);

  useEffect(() => {
    if (data.length > 0 && params?.is_small_images) {
      const linksArray = data.map((i: any) => {
        return Object.assign(i, { resizedImage: true });
      });

      // @ts-ignore
      setFilteredData(linksArray);
    } else {
      setFilteredData(data);
    }
  }, [data, params]);

  return (
    <Wrapper>
      {filteredData.length > 0 && (
        <Container maxWidth={'1280px'}>
          <Headline
            title={params?.title}
            urlText={params?.cta_label}
            url={params?.cta_url}
          >
            <ProductRow>
              {filteredData?.map((item: typeof product) => {
                return (
                  <ProductRowCol
                    className="row-element"
                    key={`product-${item?.id}-${params?._id}`}
                  >
                    <ProductCard onLogin={onLogin} {...item} />
                  </ProductRowCol>
                );
              })}
            </ProductRow>
          </Headline>
        </Container>
      )}
    </Wrapper>
  );
};

export default ProductCollection;
