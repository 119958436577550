import React from 'react';
import { components } from 'react-select';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6745 2.23919L8.54331 9.45479C8.24312 9.75882 7.75814 9.75881 7.45718 9.45479L0.325967 2.23919C-0.10833 1.8003 -0.10833 1.08622 0.325967 0.646551C0.760265 0.20766 1.46512 0.20766 1.89942 0.646552L8.00063 6.8191L14.1003 0.646553C14.5354 0.207662 15.2402 0.207662 15.6745 0.646553C16.1088 1.08622 16.1088 1.8003 15.6745 2.23919Z"
          fill="#999999"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
