import styled, { css } from 'styled-components';
import sliderArrow from '../../../../prepr/assets/icons/i_arrow_line_grey_right.svg';

type ActiveTabProps = {
  isActive: boolean;
};

export const TabsContainer = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    margin-bottom: 40px;

    ${mediaQueries.sm} {
      margin-bottom: 20px;
    }

    .swiper-container {
      ${mediaQueries.sm} {
        overflow: visible;
      }
    }

    .swiper-slide {
      width: auto;
      cursor: pointer;
      padding-bottom: 1px;

      &:active,
      &:focus,
      &:focus-within,
      &:target,
      &:visited,
      &:focus-visible {
        border: 0;
        outline: none;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 20px;
      height: 30px;
      margin-top: -15px;
      box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 0.8);

      ${mediaQueries.sm} {
        display: none;
      }

      &:after {
        content: url(${sliderArrow});
        font-size: 10px;
      }

      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    .swiper-button-prev {
      left: 0;
      justify-content: flex-start;

      &:after {
        transform: rotate(180deg);
      }
    }

    .swiper-button-next {
      right: 0;
      justify-content: flex-end;
    }
  `;
});

export const TabBox = styled.div<ActiveTabProps>(({ theme, isActive }) => {
  const { color, mediaQueries } = theme;

  return css`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px 9px;

    //width: 145px;
    min-height: 64px;
    max-height: 64px;

    background: ${() => (isActive ? color.lightblue : color.white)};
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: ${() => (isActive ? color.lightblue : color['grey-200'])};
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;

    ${mediaQueries.minWidthSM} {
      min-height: 86px;
      max-height: 86px;
      padding: 16px;
      &:hover {
        border-color: ${color.lightblue};
        background: ${color.lightblue};
        transition: background-color 0.3s ease-in-out;
      }
    }

    &:active,
    &:target,
    &:visited {
      outline: none;
    }

    .swiper-button-prev &,
    .swiper-button-next &,
    &:focus,
    &:focus-within,
    &:focus-visible {
      border: 2px solid ${color.azure};
      outline: none;
    }
  `;
});

export const TabIcon = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 26px;
    height: 24px;

    margin-bottom: 5px;

    ${mediaQueries.minWidthSM} {
      margin-bottom: 14px;
    }

    svg {
      max-width: 100%;
      height: auto;
      max-height: 24px;
      vertical-align: bottom;
    }
  `;
});

export const TabTitle = styled.div<ActiveTabProps>(({ theme, isActive }) => {
  const { color, font, size } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px14};
    line-height: 1.3;
    color: ${() => (isActive ? color.blue : color.grey)};
  `;
});
