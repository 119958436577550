import React from 'react';
import { SparenProps } from './sparen.types';
import { Button } from '../../../redesign/components/shared';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import {
  SparenTitle,
  SparenContent,
  SparenItem,
  SparenItemTitle,
  SparenItemText,
  SparenItemImage,
  ButtonBox
} from './Sparen.styles';

const Sparen: React.FC<SparenProps> = ({ params }) => {
  const image = params?.image?.[0];

  return (
    <Wrapper id="sparen">
      <Container maxWidth="1250px">
        <SparenTitle>{params?.headline}</SparenTitle>
        <SparenContent>
          <SparenItem>
            <SparenItemTitle>{params?.headline_1}</SparenItemTitle>
            <SparenItemText>{params?.text_1}</SparenItemText>
            {params?.cta_label_1 && params?.cta_url_1 && (
              <Button
                buttontype="tertiary"
                nativeLink={!params?.is_cta_url_1_current_domain}
                text={params?.cta_label_1}
                url={params?.cta_url_1}
              />
            )}
          </SparenItem>

          <SparenItem>
            <SparenItemImage>
              <img
                loading='lazy'
                src={image?.cdn_files?.[0]?.url || image?.url}
                width={image?.width}
                height={image?.height}
                alt={image?.name}
              />
            </SparenItemImage>
          </SparenItem>

          <SparenItem textAlign="right">
            <SparenItemTitle>{params?.headline_2}</SparenItemTitle>
            <SparenItemText>{params?.text_2}</SparenItemText>
            {params?.cta_label_2 && params?.cta_url_2 && (
              <Button
                buttontype="tertiary"
                nativeLink={!params?.is_cta_url_2_current_domain}
                text={params?.cta_label_2}
                url={params?.cta_url_2}
              />
            )}
          </SparenItem>
        </SparenContent>
        {params?.cta_label && params?.cta_url && (
          <ButtonBox>
            <Button
              nativeLink={!params?.is_cta_url_current_domain}
              text={params?.cta_label}
              url={params?.cta_url}
            />
          </ButtonBox>
        )}
      </Container>
    </Wrapper>
  );
};

export default Sparen;
