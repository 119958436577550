import styled, { css } from 'styled-components';

import {
  AccordionItemButton,
  AccordionTitle,
  AccordionItemContent,
  AccordionItemWrapper
} from "../../../redesign/components/shared/Accordion/Accordion.styles";
import { HeadingSubtitle, HeadlineTitle } from "../../../redesign/components/shared/Headline/Headline.styles";
import {
  CustomLinkWrapper,
  CustomNativeLinkWrapper
} from "../../../redesign/components/shared/CustomLink/CustomLink.styles";

export const BoxStyled = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    position: relative;
    
    ${AccordionItemWrapper}{
      border: 1px solid transparent;
      background-color: ${color.blue};
    }
    
    ${AccordionItemContent}{
      h1,
      h2,
      h3,
      h4,
      h5,
      h6, 
      p,
      a, 
      span, li{
        color: rgba(255,255,255,0.5);
      }
    }
    
    ${AccordionItemButton}{
      background-color: ${color.blue};
      color: ${color.white};
      
      &:after{
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAKCAYAAAC9vt6cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABfSURBVHgBnYzLDYBACESHDizBDrWEtRJb3A6QOZDsQRCchBA+74mqHgB2q0tEJgoxZrNGbnLw3CiGvw5xGB3JCpP1ZUnyCi/HVJLCX5ISHElacCDpwYFk4E8MPFnZzwN4wuP2soQRZgAAAABJRU5ErkJggg==");
      }
    }
    
    ${AccordionTitle}{
      ${mediaQueries.minWidthSM} {
        &:hover {
          background-color: ${color.blue};
        }
      }
    }
    
    ${HeadlineTitle},
    ${HeadingSubtitle},
    ${CustomLinkWrapper},
    ${CustomNativeLinkWrapper} {
      color: ${color.white};
    }

    ${CustomLinkWrapper},
    ${CustomNativeLinkWrapper} {
      &:after{
        background-image: url('data: image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01Ljg3NzIxIDE1LjcxNjVDNS40NDgyNCAxNS4zMTY2IDUuNDIyIDE0LjY0MTggNS44MTg2IDE0LjIwOTJMMTAuNTgwNiA5LjAxNTQ1TDEuMDU3ODEgOS4wMTU0NUMwLjQ3MzU5OCA5LjAxNTQ1IDUuMTA3MzdlLTA4IDguNTM3ODggMCA3Ljk0ODc4Qy01LjEwNzM0ZS0wOCA3LjM1OTY4IDAuNDczNTk4IDYuODgyMTIgMS4wNTc4MSA2Ljg4MjEyTDEwLjQ3NjMgNi44ODIxMUw1LjgzMzA2IDEuNzg4NTNDNS40Mzc3IDEuMzU0ODMgNS40NjU4NiAwLjY4MDA0OSA1Ljg5NTk3IDAuMjgxMzc2QzYuMzI2MDggLTAuMTE3Mjk5IDYuOTk1MjYgLTAuMDg4ODk4IDcuMzkwNjIgMC4zNDQ4MDlMMTMuNzIxIDcuMjg5MDhDMTQuMDkzOCA3LjY5ODA3IDE0LjA5MjkgOC4zMjcxNCAxMy43MTg5IDguNzM1MDVMNy4zNzIwNCAxNS42NTc0QzYuOTc1NDQgMTYuMDkgNi4zMDYxOCAxNi4xMTY1IDUuODc3MjEgMTUuNzE2NVoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==');
      }
    }
  `;
});
