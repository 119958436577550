import React, { useEffect, useState } from 'react';
import { Headline } from '../../../redesign/components/shared';
import ProductCard from '../../../redesign/components/shared/ProductCard';
import { getRewardsByCategory } from '../../../components/utils/_reward-helpers';

import { ProductCollectionManualProps } from './productCollectionManual.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';

import { ProductRow, ProductRowCol } from './ProductCollectionManual.styles';

const ProductCollectionManual: React.FC<ProductCollectionManualProps> = ({
  params,
  product,
  onLogin
}) => {
  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const pageURL = params?.internal_link?.[0];

  useEffect(() => {
    if (params?.category_id) {
      getRewardsByCategory(
        params?.is_two_rows ? 8 : 4,
        params?.category_id
      ).then((response) => {
        setData(response.data.payload.rewards);
      });
    }
  }, [params]);

  useEffect(() => {
    if (data.length > 0 && params?.is_small_images) {
      const linksArray = data.map((i: any) => {
        return Object.assign(i, { resizedImage: true });
      });

      // @ts-ignore
      setFilteredData(linksArray);
    } else {
      setFilteredData(data);
    }
  }, [data, params]);

  return (
    <Wrapper>
      {filteredData.length > 0 && (
        <Container maxWidth={'1280px'}>
          <Headline
            title={params?.title}
            urlText={params?.cta_label}
            url={params?.cta_url ? params?.cta_url : pageURL?._slug}
          >
            <ProductRow>
              {filteredData?.map((item: typeof product) => {
                return (
                  <ProductRowCol
                    className="row-element"
                    key={`product-manual-${item?.id}-${params?._id}`}
                  >
                    <ProductCard onLogin={onLogin} {...item} />
                  </ProductRowCol>
                );
              })}
            </ProductRow>
          </Headline>
        </Container>
      )}
    </Wrapper>
  );
};

export default ProductCollectionManual;
