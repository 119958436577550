import styled, { css } from 'styled-components';
import { CustomTheme } from '../../../../theme/types';

interface InputSelectorParams {
  disabled?: boolean;
  width?: number;

  theme: CustomTheme;
}

export function SelectorFieldWrapper({ disabled, theme }: InputSelectorParams) {
  const { size, color, font } = theme;

  return css`
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    background-color: ${color.white};
    border: 1px solid ${color.border};

    color: ${color.grey};
    border-radius: ${size.px4};
    position: relative;
    letter-spacing: 0;

    line-height: ${size.px16};
    appearance: none;
    font-size: ${size.px16};
    font-family: ${font.family.sansSerif};

    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${size.px16} ${size.px12};

    outline: none;
    height: 86px;
    width: 100%;

    ${disabled &&
    css`
      background-color: ${color.cloudWhite};
      color: ${color.grey};
      border-color: ${color.chineseGray};

      cursor: not-allowed;
    `}

    &:focus-visible {
      background-color: ${color.lightBlue};
      border-color: ${color.steelBlue};
      border-width: 2px;
      caret-color: ${color.black};
      color: ${color.black};
      outline: none;
    }

    &.hasError {
      background-color: ${color.white};
      border-color: ${color.red};
      border-width: 1px;
    }

    @media (min-width: 767px) {
      &:hover {
        border-color: ${color.steelBlue};
        background: ${color.lightBlue};
        border-width: 2px;
        transition: background-color 0.3s, border-color 0.3s, color 0.3s;
      }
    }
  `;
}

export const SelectorWrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0px 6px 24px 6px;
  box-sizing: border-box;
`;

export const SelectorItem = styled.div`
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
`;

type FieldContainerAttributes = {
  width: number;
  height: number;
};

export const SelectorContainer = styled.div(
  ({ width, height }: FieldContainerAttributes) => css`
    margin-bottom: 1rem;
    padding: 0 1rem;
    position: relative;

    width: ${width}%;
    height: ${height}%;
    display: block;
  `
);

export const SelectorContent = styled.div(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    color: ${color.grey};
    margin: 0;

    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    pointer-events: none;
  `;
});

export const SelectorIcon = styled.div(() => {
  return css`
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 0 auto 5px;
    pointer-events: none;

    img {
      max-width: 100%;
      height: auto;
      max-height: 24px;
      vertical-align: bottom;

      &.active-icon {
        display: none;
      }
    }
  `;
});

export const SelectorField = styled.input(
  ({ disabled, theme }) => css`
    ${SelectorFieldWrapper({ disabled: disabled, theme: theme })}
    cursor: pointer;

    &:checked {
      background-color: ${theme.color.lightBlue};
      border-color: ${theme.color.steelBlue};
      border-width: 2px;
    }

    &:checked + ${SelectorContent} {
      color: ${theme.color.steelBlue};

      img {
        display: none;
      }

      .active-icon {
        display: block;
      }
    }
  `
);
