import styled, { css } from 'styled-components';

export const SwitchContainer = styled.div(() => {
  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 5px 0;
  `;
});

export const SwitchLabel = styled.span(({ theme }) => {
  const { size, color, font } = theme;

  return css`
    max-width: calc(100% - 60px);
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    font-size: ${size.px12};
    line-height: 1.2;
    color: ${color.grey};
  `;
});

export const SwitchTrack = styled.label(() => {
  return css`
    display: block;
    position: relative;
    width: 40px;
    height: 24px;
  `;
});

export const SwitchThumb = styled.span(({ theme }) => {
  const { color } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    background: ${color.cottonGrey};
    cursor: pointer;
    transition: 0.4s;

    &:hover{
      background: ${color.steelBlue};
    }

    &:before {
      content:'';
      display: block;
      position: absolute;
      left: 4px;
      bottom: 4px;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: ${color.white};
      transition: 0.4s;
    }
  `;
});

export const SwitchInput = styled.input(({ theme }) => {
  const { color } = theme;

  return css`
    opacity: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    
    &:disabled {
      & + ${SwitchThumb} {
        background: ${color.cloudWhite};
        cursor: not-allowed;
      }
    }
    
    &:checked {
      & + ${SwitchThumb} {
        background: ${color.blue};

        &:hover {
          background: ${color.olympic};
        }

        :before {
          transform: translateX(16px);
        }
      }

      &:disabled{
        & + ${SwitchThumb} {
          background: ${color.lightblue};
        }
      }
    }
  `;
});
