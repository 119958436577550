import React from 'react';
import { HeroBannerProps } from './heroBanner.types';
import { Button } from '../index';
import { clickGTM } from '../../../../components/utils/_gtm-helpers';
import {
  HeroBannerContent,
  HeroBannerHolder,
  HeroBannerImageStyles,
  HeroBannerSubtitle,
  HeroBannerText,
  HeroBannerTitle,
  OvalBlobStyles,
  HeroBannerContentWrapper
} from './HeroBanner.styles';

const HeroBanner: React.FC<HeroBannerProps> = (props) => {
  const { cta_label, cta_link, headline, superheadline, text, visual } = props;
  const img = visual?.[0];

  const handleClickGTM = () => {
    if(cta_link?.includes('e-bike')){
      clickGTM('button_click', 'Button Clicks','Meer weten - eBike')
    }
  }

  return (
    <>
      <HeroBannerHolder>
        <HeroBannerImageStyles
          loading='lazy'
          alt={headline}
          src={img?.cdn_files?.[0]?.url || img?.url}
        />
        <HeroBannerContentWrapper>
          <HeroBannerContent>
            {headline && <HeroBannerSubtitle>{headline}</HeroBannerSubtitle>}
            {superheadline && (
              <HeroBannerTitle>{superheadline}</HeroBannerTitle>
            )}
            {text && <HeroBannerText>{text}</HeroBannerText>}
            {cta_label && cta_link && (
              <Button
                onClick={handleClickGTM}
                text={cta_label}
                url={cta_link}
                nativeLink={!!cta_link} />
            )}
          </HeroBannerContent>
        </HeroBannerContentWrapper>
        <OvalBlobStyles />
      </HeroBannerHolder>
    </>
  );
};

export default HeroBanner;
