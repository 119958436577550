import styled, { css } from 'styled-components';

export const Wrapper = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    background-color: ${color.white};
    margin-top: -80px;
    padding-top: 80px;
  `;
});

export const Container = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    padding: 80px 25px;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    ${mediaQueries.sm} {
      padding: 40px 25px;
    }
  `;
});

export const ImageWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 45%;
    max-width: 600px;
    margin-right: 5%;

    ${mediaQueries.sm} {
      overflow: hidden;
      width: calc(100% + 60px);
      max-width: initial;
      margin: 0 -30px 40px;
      
      img{
        margin: -13px 0 -17px;
      }
    }
  `;
});

export const ContentWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 50%;
    max-width: 410px;
    
    ${mediaQueries.sm} {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      text-align: center;
    }
  `;
});

export const PreTitle = styled.h6(({ theme }) => {
  const { color, size, mediaQueries } = theme;

  return css`
    font-size: ${size.px18};
    color: ${color.black};
    line-height: 1.2;
    margin-bottom: 20px;
    
    ${mediaQueries.sm} {
      font-size: ${size.px16};
    }
  `;
});

export const Title = styled.h2(({ theme }) => {
  const { color, size, mediaQueries } = theme;

  return css`
    font-size: ${size.px32};
    color: ${color.black};
    line-height: 1.2;
    margin-bottom: 20px;

    ${mediaQueries.sm} {
      font-size: ${size.px28};
    }
  `;
});

export const Text = styled.p(({ theme }) => {
  const { color, size } = theme;

  return css`
    font-size: ${size.px14};
    color: ${color.darkCharcoal};
    line-height: 1.2;
    margin-bottom: 40px;
  `;
});

export const Remark = styled.p(({ theme }) => {
  const { font, color, size } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px14};
    color: ${color.grey};
    line-height: 1.2;
    margin-bottom: 15px;
  `;
});

export const ButtonWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;

    button, a{
      display: inline-flex;
    }

    ${mediaQueries.sm} {
      button, a{
        max-width: 335px;
      }
    }
  `;
});