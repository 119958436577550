import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const InfoWrapper = styled.div(() => {
  return css`
  `;
});

export const InfoText = styled.span(({ theme }) => {
  const { size, color } = theme;

  return css`
    font-size: ${size.px12};
    color: ${color.graniteGray};
  `;
});

export const InfoLink = styled(Link)(({ theme }) => {
  const { size, font } = theme;

  return css`
    font-size: ${size.px14};
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    text-decoration: underline;
  `;
});