import React from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectOption, SelectOptionListProps } from './selectField.types';

import { DropdownIndicator, DropdownOption } from './components';
import { FieldLabel } from '../UI';

import { colourStyles } from './config';
import { SelectHolder, SelectWrapper } from './SelectField.styles';

import { FieldError } from '../InputField/InputField.styles';

export type SelectProps = {
  name: string;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  width: number;
  isError?: boolean;
  errorText: string | undefined;
};

const SelectAutocomplete: React.FC<SelectOptionListProps & SelectProps> = ({
  options,
  name,
  placeholder,
  isRequired = false,
  label,
  width,
  errorText,
  isError = false
}) => {
  const { control } = useFormContext();

  const optionsList: {
    label: string;
    value: string | number;
    code?: string | number;
  }[] = options.map((option: SelectOption) => ({
    label: option.label,
    value: option.value,
    code: option.code
  }));

  return (
    <SelectWrapper
      style={{ width: `${width}%` }}
      className={isError ? 'hasError' : ''}
    >
      <FieldLabel name={name} label={label!} isRequired={isRequired} />
      <SelectHolder>
        <Controller
          name={name}
          control={control}
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              styles={colourStyles}
              classNamePrefix="CustomSelect SelectField"
              placeholder={placeholder}
              inputId={name}
              blurInputOnSelect={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null
              }}
              options={optionsList}
              formatOptionLabel={DropdownOption}
              isSearchable={false}
              hideSelectedOptions={false}
              noOptionsMessage={() => null}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
        {isError && <FieldError>{errorText}</FieldError>}
      </SelectHolder>
    </SelectWrapper>
  );
};

export default SelectAutocomplete;
