import React from 'react';
import { TopNavigation, BottomNavigation } from './UI';

function Footer() {
  return (
    <>
      <TopNavigation />
      <BottomNavigation />
    </>
  );
}
export default Footer;
