import styled from 'styled-components';
import { SelectorWrapper } from '../InputSelector/InputSelector.styles';

export const SelectorListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;

  width: 100%;
  position: relative;

  padding: 0;

  ${SelectorWrapper} {
    &:last-child {
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
  }
`;
