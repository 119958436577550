import React from 'react';
import { BrandBadgeLink } from './BrandBadge.styles';
import { IconLogoEssoExtrasWhite } from "../../../../../assets";

function BrandBadge() {
  return (
    <BrandBadgeLink href="/">
      <img src={IconLogoEssoExtrasWhite} alt="Extras" />
    </BrandBadgeLink>
  );
}
export default BrandBadge;
