import React from 'react';
import { brandList } from '../../data/brandList';
import { BrandListLink, BrandListWrapper } from './BrandList.styles';
import { shopLang } from "../../../../../../config/config";

const BrandList = () => {
  return (
    <BrandListWrapper>
      {brandList.map((item, index) => {
        const setUrl = () => shopLang === 'be_fr' ? item.url_be_fr
          : shopLang === 'be_nl' ? item.url_be_nl : item.url_nl;
        return (
          <BrandListLink
            key={item.image}
            href={setUrl()}
            rel={item?.rel}
            target={item?.target}
          >
            <img src={item.image} alt={item.title} />
          </BrandListLink>
        );
      })}
    </BrandListWrapper>
  );
};

export default BrandList;
