import styled, { css } from 'styled-components';
import {Link} from 'react-router-dom';
import { CategoryPlatform, IconArrowWhite, Bg1, Bg2, DescriptionShadow } from "../../../../assets";

export const CarouselWrapper = styled.div(({ theme }) => {
  const {  mediaQueries } = theme;

  return css`
    padding-bottom: 40px;

    ${mediaQueries.sm} {
      padding-bottom: 20px;
    }
    
    .swiper-container{
      overflow: visible;
      //min-height: 501px;

      ${mediaQueries.xl} {
        //min-height: 35vw;
      }
      
      ${mediaQueries.sm} {
        overflow: hidden;
      }
    }
    
    //.swiper-slide{
      //height: 100%;
    //}
    
    .swiper-wrapper{ 
      will-change: transform;
    }
  `;
});

export const SlideTitle = styled.div(({ theme }) => {
  const { size, font, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px32};
    line-height: 1.2;
    color: ${color.white};
    min-width: 350px;
    text-align: center;
    
    ${mediaQueries.sm} {
      font-size: ${size.px16};
      min-width: 200px;
    }
  `;
});

export const NavigationBox = styled.div(({ theme }) => {
  const {  mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-top: 10px;
    
    ${mediaQueries.xl} {
    }
    
    ${mediaQueries.retinaScreens}{
      margin-top: -20px;
    }
    
    ${mediaQueries.nonRetinaScreens}{
      margin-top: -20px;
    }
  `;
});

export const ButtonBase = styled.button(({ theme }) => {
  const {  mediaQueries } = theme;

  return css`
    background: transparent;
    margin: 0 10px;
    
    &:after{
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      background-image: url('${IconArrowWhite}');
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: center;

      ${mediaQueries.sm} {
        width: 20px;
        height: 20px;
      }
    }
  `;
});
export const PrevButton = styled(ButtonBase)(({ theme }) => {
  const {  mediaQueries } = theme;

  return css`
    ${mediaQueries.sm} {
      
    }
  `;
});

export const NextButton = styled(ButtonBase)(({ theme }) => {
  const {  mediaQueries } = theme;

  return css`
    &:after{
      rotate: 180deg;
    }
 
    ${mediaQueries.sm} {
      
    }
  `;
});

export const CarouselBox = styled.div(({ theme }) => {
  const {  mediaQueries } = theme;

  return css` 
    position: relative;
    padding-top: 250px;
    
    ${mediaQueries.xl} {
      padding-top: 20%;
    }

    ${mediaQueries.retinaScreens}{
      padding-top: 10%;
    }
    
    ${mediaQueries.nonRetinaScreens}{
      padding-top: 10%;
    }

    ${mediaQueries.md} {
      padding-top: 28%;
    }

    ${mediaQueries.xxs} {
      padding-top: 35%;
    }
    
    ${mediaQueries.s} {
      padding-top: 50%;
    }

    &:before,
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150%;
      background-repeat: no-repeat;
      background-position: top center;
    }
    
    &:before{
      background-image: url('${Bg1}');
      background-size: 100% 95%;

      ${mediaQueries.xl} {
      }

      ${mediaQueries.retinaScreens}{
        background-size: 100% 93%;
      }
      
      ${mediaQueries.nonRetinaScreens}{
        background-size: 100% 93.5%;
      }
    }

    &:after{
      background-image: url('${Bg2}');
      background-size: cover;
    }
    
    .swiper-slide{
      text-align: center;
      padding-bottom: 4%;
      opacity: 0;

      ${mediaQueries.xl} {
        padding-bottom: 4.5%;
      }

      ${mediaQueries.md} {
        padding-bottom: 5.5%;
      }

      ${mediaQueries.xxs} {
        padding-bottom: 6%;
      }

      ${mediaQueries.s} {
        padding-bottom: 7.5%;
      }
      
      &-active{
        opacity: 1;
        
        ${ImgBox}{
          &:after{
            opacity: 0; 
          }
        }
        
        ${DescriptionBox}{
          opacity: 1;
        }
      }
      
      &-prev,
      &-next,
      &-custom-styled{
        opacity: 1;

        ${ImgBox}{
          &:after{
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s ease-out, visibility 0s ease-out 0s;
          }
        }
      }

      img{
        position: relative;
        z-index: 4;
        width: 100%;
        height: auto;
        vertical-align: bottom;
        max-width: 60%;
      }
    }
  `;
});

export const ImgBox = styled.div(({ theme }) => {
  const {  mediaQueries } = theme;

  return css`
    position: relative;
    margin: 0 auto;
    
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 85%;
      left: 0;
      width: 100%;
      height: 45%;
      z-index: 0;
      background-image: url('${CategoryPlatform}');
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: top center;
      visibility: hidden;
      transition: opacity 0.3s ease-out, visibility 0s ease-out 0.6s;
    }
    
    ${mediaQueries.sm} {
    }
  `;
});

export const DescriptionBox = styled.div(({ theme }) => {
  const {  mediaQueries } = theme;

  return css`
    position: absolute;
    bottom: 20%;
    right: 80%;
    width: 60%;
    max-width: 330px;
    text-align: left;
    opacity: 0;
    
    ${mediaQueries.sm} {
      opacity: 1;
      position: relative;
      z-index: 1;
      bottom: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      padding: 30px 25px;
    }
    
    
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 580px;
      height: 440px;
      background-image: url('${DescriptionShadow}');
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center;
      transform: translate(-50%, -50%);

      ${mediaQueries.sm} {
        display: none;
      }
    }
  `;
});

export const DescriptionTitle = styled.h4(({ theme }) => {
  const {  font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    font-size: ${size.px26};
    color: ${color.white};
    line-height: 1.2;
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
    
    ${mediaQueries.sm} {
      font-family: ${font.family.EMPrintRegular};
      font-size: ${size.px32};
    }
  `;
});

export const DescriptionText = styled.p(({ theme }) => {
  const {  font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    font-size: ${size.px16};
    color: ${color.white};
    line-height: 1.2;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 2px solid ${color.white};
    position: relative;
    z-index: 1;
    
    ${mediaQueries.sm} {
      font-family: ${font.family.EMPrintRegular};
      padding-bottom: 0;
      border-bottom: none;
    }
  `;
});


const setStyles = (theme: any) => {
  const {  font, size, color, mediaQueries } = theme;

  return css`
    min-width: 165px;
    min-height: 35px;
    background: ${color.dodgerBlue};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    span{
      font-family: ${font.family.EMPrintSemi};
      font-weight: ${font.weight.regular};
      font-size: ${size.px16};
      color: ${color.white};
      line-height: 1.2;
    }

    ${mediaQueries.sm} {
      span{
        font-size: ${size.px14};
      }
    }
  `;
};

export const DescriptionButton = styled.button(({ theme }) => {

  return css`
    ${setStyles(theme)}
  `;
});

export const DescriptionLink = styled(Link)(({ theme }) => {

  return css`
    ${setStyles(theme)}
  `;
});