/*
 * action types
 */

export const ADD_AUTH_DATA_SC = 'ADD_AUTH_DATA_SC';
export const GET_CAMPAIGN_INFO_SC = 'GET_CAMPAIGN_INFO_SC';
export const ADD_USER_DATA_SC = 'ADD_USER_DATA_SC';
export const SET_TRANSACTIONS_SC = 'SET_TRANSACTIONS_SC';
export const SET_PARTICIPANT_DATA_SC = 'SET_PARTICIPANT_DATA_SC';
export const SET_CURRENT_PRIZE_DATA_SC = 'SET_CURRENT_PRIZE_DATA_SC';
/*
 * action creators
 */


export function addAuthData (data) {
  const { refreshToken, refreshExpiresAt } = data;

  refreshToken
    ? window.localStorage.setItem('refreshToken', refreshToken)
    : window.localStorage.removeItem('refreshToken');

  refreshExpiresAt
    ? window.localStorage.setItem('refreshExpiresAt', `${refreshExpiresAt}`)
    : window.localStorage.removeItem('refreshExpiresAt');

  return {
    type: ADD_AUTH_DATA_SC,
    data
  }
}

export function getCampaignInfo(data) {
  return {
    type: GET_CAMPAIGN_INFO_SC,
    data
  }
}

export function addUserData(data){
  return {
    type: ADD_USER_DATA_SC,
    data
  }
}

export function setTransactions(data){
  return {
    type: SET_TRANSACTIONS_SC,
    data
  }
}

export function setParticipantData(data){
  return {
    type: SET_PARTICIPANT_DATA_SC,
    data
  }
}

export function setCurrentPrizeData(data){
  return {
    type: SET_CURRENT_PRIZE_DATA_SC,
    data
  }
}
