import React, { useEffect } from 'react';

import { Button, StepsAccordion } from '../../../redesign/components/shared';
import { clickGTM } from '../../../components/utils/_gtm-helpers';
import { StepsVerticalProps } from './stepsVertical.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import {
  ButtonBox,
  StepsBox,
  StepsVertSubTitle,
  StepsVertTitle
} from './StepsVertical.styles';

const StepsVertical: React.FC<StepsVerticalProps> = ({ params }) => {

  useEffect(() => {
    let link = document.getElementsByClassName('accordion-item-container')[0]?.getElementsByTagName('a')[0];

    let isTargetLink = link?.href?.includes('/find-station');

    let handleClickGTM = () => {
      clickGTM('text_link', 'External Link', 'Bekijk waar je ons kunt vinden')
    }

    isTargetLink && link.addEventListener('click', handleClickGTM);

    return () => link?.removeEventListener('click', handleClickGTM);
  }, []);

  return (
    <Wrapper>
      <Container maxWidth={'1250px'}>
        <StepsBox>
          <StepsVertSubTitle>{params?.label} </StepsVertSubTitle>
          <StepsVertTitle>{params?.headline}</StepsVertTitle>
          <StepsAccordion items={params?.step_items} />
          {params?.cta_label && params?.cta_url && (
            <ButtonBox>
              <Button
                onClick={() => clickGTM('button_click', 'Button Clicks', 'Registreer je nu')}
                text={params?.cta_label}
                url={params?.cta_url}
                nativeLink={!!params?.cta_url}/>
            </ButtonBox>
          )}
        </StepsBox>
      </Container>
    </Wrapper>
  );
};

export default StepsVertical;
