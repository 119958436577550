import {
  Product_516,
  Product_517,
  Product_518,
  Product_519,
  Product_520,
  Product_521,
  Product_522,
  Product_523,
  Product_524,
  Product_525,
  Product_526,
  Product_527,
  Product_528,
  Product_529,
  Product_530,
  Product_531,
  Product_532,
  Product_533,
  Product_534,
  Product_535,
  Product_536,
  Product_538,
  Product_542,
  Product_543,
  Product_544,
  Product_545,
  Product_546,
  Product_547,
  Product_548,
  Product_549,
  Product_550,
  Product_551,
  Product_552,
  Product_553,
  Product_554,
  Product_555,
  Product_556,
  Product_557,
  Product_558,
  Product_559,
  Product_560,
  Product_561,
  Product_562,
  Product_565,
  Product_567,
  Product_568,
  Product_570,
  Product_571,
  Product_572,
  Product_573,
  Product_574,
  Product_575,
  Product_576,
  Product_577,
  Product_578,
  Product_579,
} from "../../../../assets/hardGiftsCampaign/products";

type ProductsImagesList = {
  id_stage?: number,
  id_prod: number,
  image: string,
}
export const ProductsImagesList: ProductsImagesList[] = [
  {
    id_stage: 730,
    id_prod: 516,
    image: Product_516,
  },
  {
    id_stage: 774,
    id_prod: 517,
    image: Product_517,
  },
  {
    id_stage: 743,
    id_prod: 518,
    image: Product_518,
  },
  {
    id_stage: 744,
    id_prod: 519,
    image: Product_519,
  },
  {
    id_stage: 745,
    id_prod: 520,
    image: Product_520,
  },
  {
    id_stage: 740,
    id_prod: 521,
    image: Product_521,
  },
  {
    id_stage: 741,
    id_prod: 522,
    image: Product_522,
  },
  {
    id_stage: 742,
    id_prod: 523,
    image: Product_523,
  },
  {
    id_stage: 747,
    id_prod: 524,
    image: Product_524,
  },
  {
    id_stage: 738,
    id_prod: 525,
    image: Product_525,
  },
  {
    id_stage: 792,
    id_prod: 526,
    image: Product_526,
  },
  {
    id_stage: 734,
    id_prod: 527,
    image: Product_527,
  },
  {
    id_stage: 737,
    id_prod: 528,
    image: Product_528,
  },
  {
    id_stage: 736,
    id_prod: 529,
    image: Product_529,
  },
  {
    id_stage: 733,
    id_prod: 530,
    image: Product_530,
  },
  {
    id_stage: 735,
    id_prod: 531,
    image: Product_531,
  },
  {
    id_stage: 591,
    id_prod: 532,
    image: Product_532,
  },
  {
    id_stage: 592,
    id_prod: 533,
    image: Product_533,
  },
  {
    id_stage: 590,
    id_prod: 534,
    image: Product_534,
  },
  {
    id_stage: 796,
    id_prod: 535,
    image: Product_535,
  },
  {
    id_stage: 593,
    id_prod: 536,
    image: Product_536,
  },
  {
    id_prod: 538,
    image: Product_538,
  },
  {
    id_stage: 766,
    id_prod: 542,
    image: Product_542,
  },
  {
    id_stage: 759,
    id_prod: 543,
    image: Product_543,
  },
  {
    id_stage: 760,
    id_prod: 544,
    image: Product_544,
  },
  {
    id_stage: 780,
    id_prod: 545,
    image: Product_545,
  },
  {
    id_stage: 725,
    id_prod: 546,
    image: Product_546,
  },
  {
    id_stage: 724,
    id_prod: 547,
    image: Product_547,
  },
  {
    id_stage: 783,
    id_prod: 548,
    image: Product_548,
  },
  {
    id_stage: 723,
    id_prod: 549,
    image: Product_549,
  },
  {
    id_prod: 550,
    image: Product_550,
  },
  {
    id_prod: 551,
    image: Product_551,
  },
  {
    id_prod: 552,
    image: Product_552,
  },
  {
    id_prod: 553,
    image: Product_553,
  },
  {
    id_prod: 554,
    image: Product_554,
  },
  {
    id_prod: 555,
    image: Product_555,
  },
  {
    id_stage: 772,
    id_prod: 556,
    image: Product_556,
  },
  {
    id_stage: 768,
    id_prod: 557,
    image: Product_557,
  },
  {
    id_stage: 781,
    id_prod: 558,
    image: Product_558,
  },
  {
    id_stage: 782,
    id_prod: 559,
    image: Product_559,
  },
  {
    id_stage: 752,
    id_prod: 560,
    image: Product_560,
  },
  {
    id_stage: 749,
    id_prod: 561,
    image: Product_561,
  },
  {
    id_stage: 750,
    id_prod: 562,
    image: Product_562,
  },
  {
    id_stage: 764,
    id_prod: 565,
    image: Product_565,
  },
  {
    id_stage: 755,
    id_prod: 567,
    image: Product_567,
  },
  {
    id_stage: 756,
    id_prod: 568,
    image: Product_568,
  },
  {
    id_stage: 748,
    id_prod: 570,
    image: Product_570,
  },
  {
    id_stage: 751,
    id_prod: 571,
    image: Product_571,
  },
  {
    id_stage: 762,
    id_prod: 572,
    image: Product_572,
  },
  {
    id_stage: 763,
    id_prod: 573,
    image: Product_573,
  },
  {
    id_stage: 788,
    id_prod: 574,
    image: Product_574,
  },
  {
    id_stage: 767,
    id_prod: 575,
    image: Product_575,
  },
  {
    id_stage: 753,
    id_prod: 576,
    image: Product_576,
  },
  {
    id_stage: 775,
    id_prod: 577,
    image: Product_577,
  },
  {
    id_stage: 770,
    id_prod: 578,
    image: Product_578,
  },
  {
    id_stage: 791,
    id_prod: 579,
    image: Product_579,
  }
];
