import styled, { css } from 'styled-components';

const setStyles = (theme: any) => {
  const { mediaQueries, color } = theme;

  return css`
    display: block;
    width: calc(25% - 20px);
    padding: 24px 22px;
    border-radius: 20px;
    color: ${color.white};
    background-color: #0B1126;
    margin: 0 10px 20px;

    ${mediaQueries.md} {
      padding: 15px;
      width: calc(33% - 20px);
    }

    ${mediaQueries.sm} {
      width: 100%;
      border-radius: 14px;
      margin: 0;
    }

    &:active,
    &:hover,
    &:focus{
      text-decoration: none;
    }

    // &:active {
    //   color: ${color.navy};
    //   transition: all 0.2s ease-in-out;
    // }

    // &:hover,
    // &:focus {
    //   color: ${color.navy};
    //   transition: all 0.3s ease-in-out;
    // }

    //&:focus,
    // &:focus-visible {
    //   outline: 2px solid ${color.azure};
    // }
  `;
};
export const CardLink = styled.a(({ theme }) => {
  return css`
    ${setStyles(theme)};
  `;
});

export const CardForm = styled.form(({ theme }) => {
  return css`
    ${setStyles(theme)};
  `;
});

export const CardImage= styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
    
    img{
      width: 280px;
      height: auto;
      margin: 0 auto;
      vertical-align: bottom;
      aspect-ratio: 2/1.48;

      ${mediaQueries.sm} {
      }
    }
    
    ${mediaQueries.sm} {
    }
  `;
});

export const CardTitle= styled.h4(({ theme }) => {
  const { size, font, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    font-size: ${size.px18};
    line-height: 1.2;
    color: ${color.white};
    margin-bottom: 15px;
    height: calc(${size.px18} * 1.2 * 2);

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    
    ${mediaQueries.sm} {
      font-size: ${size.px12};
      height: calc(${size.px12} * 1.2 * 2);
    }
  `;
});

export const CardButton = styled.button(({ theme }) => {
  const {  font, size, color, mediaQueries } = theme;

  return css`
    width: 100%;
    height: 48px;
    background: ${color.blue};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    span{
      font-family: ${font.family.EMPrintSemi};
      font-weight: ${font.weight.regular};
      font-size: ${size.px14};
      line-height: 1.2;
      color: ${color.white};
    }
    
    ${mediaQueries.sm} {
      border-radius: 3px;
      height: 33px;
    }
  `;
});