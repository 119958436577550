import styled, { css } from 'styled-components';
import { WrapperBase, OverlayBase } from "../ChestGame.styles";

export const Wrapper = styled(WrapperBase)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    background: radial-gradient(circle at center, rgba(0,125,222,1), rgba(0,71,186,1));

    ${mediaQueries.sm} {
    }
  `;
});

export const Overlay = styled(OverlayBase)(() => {
  return css`
    background: radial-gradient(circle at center, rgba(0,125,222,1), rgba(0,71,186,1));
  `;
});


export const LogoImg = styled.img(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 168px;
    margin-bottom: 50px;
    
    ${mediaQueries.sm} {
      width: 99px;
    }
  `;
});