import styled, { css } from 'styled-components';
import { setMobBtnStyles } from "../LanguageSelector.styles";

export const DropDownMenuWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    z-index: 1000;
    transition: height 0.2s ease;
    
    &.active{
      height: 96px;

      ${mediaQueries.md} {
        height: 160px;
      }
    }
    
    ${mediaQueries.md} {
      position: static;
    }
  `;
});

export const DropDownItem = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 10px 5px;
    background: ${color.cloudWhite};
    border-bottom: 1px solid ${color.cottonGrey};
    cursor: pointer;
    transition: background 0.2s ease;
    
    &:last-child{
      border-bottom: 1px solid transparent;
    }
    
    &:hover{
      background: ${color.chineseGray};
      
      ${mediaQueries.md} {
        background: ${color.cloudWhite};
      }
    }
    
    ${mediaQueries.md} {
      ${setMobBtnStyles(theme)};
      height: 80px;
      justify-content: flex-start;
    }
    
    &.language-link-vl,
    &.language-link-fr{
      ${mediaQueries.md} {
        &.active{
          span{
            color: ${color.black};
          }
        }
      }
    }
  `;
});

export const DropDownText = styled.span(({ theme }) => {
  const { size, color, font } = theme;

  return css`
    font-size: ${size.px14};
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    color: ${color.graniteGray};
  `;
});