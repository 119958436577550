import React from 'react';
import { NavLink } from 'react-router-dom';
import i18n from '../../../../../config/i18n';
import Modal from '../../../../../redesign/components/shared/modals/Modal';
import {shopLang} from '../../../../../config/config';
import { ModalTypes } from '../../../../../redesign/components/shared/modals/Modal/modal.types';
import {
  ButtonFullWidth,
  ModalContent,
  ModalText
} from '../../../../../redesign/components/shared/modals/Modal/Modal.styles';
import Button from '../../../../../redesign/components/shared/Button';

const EBikePopup: React.FC<ModalTypes> = ({...props}) => {
  const contactData = Object.freeze({
    phone: shopLang === 'nl_nl' ? '+31 (0) 485799000' : shopLang === 'be_nl' ? '+32 2 290 03 44' : '+32 2 290 03 44',
    phoneLink: shopLang === 'nl_nl' ? 'tel:+310485799000' : shopLang === 'be_nl' ? 'tel:+3222900344' : 'tel:+3222900344',
    email: shopLang === 'nl_nl' ? 'essoextras.nl@arvato-scs.com' : ''
  });

  return (
    <Modal {...props}>
      <ModalContent>
        <ModalText>
          <span dangerouslySetInnerHTML={{ __html: i18n.t('eBike_popup_text')}}/>
           &nbsp;
            {contactData.email &&
              <>
                <NavLink to={'/contact-form'}>{contactData.email}</NavLink> of&nbsp;
              </>
            }
          <a href={contactData.phoneLink}>{contactData.phone}</a>.
        </ModalText>
      </ModalContent>

      <ButtonFullWidth>
        <Button text={'Close'}
                buttontype={'primary'}
                onClick={props.onClose} />
      </ButtonFullWidth>
    </Modal>
  );
};

export default EBikePopup;
