import styled, { css } from "styled-components";
import { BannerContainerBase, BannerWrapperBase } from "../Banners.styles";
import { ShapeBannerNoImage, ShapeBannerNoImage2 } from '../../../assets';

type BannerContainerProps = {
  widthContent?: number;
  isButtonActive?: boolean;
};

export const BannerWrapper = styled(BannerWrapperBase)(({theme}) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.sm} {
    }
	`;
});

export const BannerContainer = styled(BannerContainerBase)<BannerContainerProps>(({theme, widthContent, isButtonActive}) => {
  const {mediaQueries} = theme;

  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 176px;
    
    ${widthContent && widthContent >= 60 && `
      padding: 70px 40px;
    `}
    
    ${mediaQueries.sm} {
      flex-wrap: wrap;
      min-height: 150px;
      padding: 15px;

      ${!isButtonActive && `
        padding: 80px 15px;
      `}
    }
    
    &:before,
    &:after{
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: top left;
    }
    
    &:before{
      top: 0;
      right: 0;
      width: 170px;
      height: 155px;
      background-image: url(${ShapeBannerNoImage2});

      ${mediaQueries.sm} {
        right: -75px;
      }
    }

    &:after{
      bottom: 0;
      left: 50%;
      width: 240px;
      height: 70px;
      background-image: url(${ShapeBannerNoImage});

      ${mediaQueries.sm} {
        left: 0;
        width: 170px;
        height: 85px;
        background-position: bottom left;
      }
    }
	`;
});

export const ButtonBox = styled.div(({theme}) => {
  const { mediaQueries} = theme;

  return css`
    padding-right: 100px;
    padding-left: 10px;
    max-width: 48%;
    z-index: 1;
    
    a {
      text-decoration: none;
    }
    
    ${mediaQueries.sm} {
      padding-right: 0;
      padding-left: 0;
      max-width: 100%;
      width: 100%;
      margin-top: 20px;
    }
	`;
});