import styled, {css} from 'styled-components';
import { InfoWrapper } from '../Info/InfoBlock.styles';

export const FormWrapper = styled(InfoWrapper)(({ theme }) => {
	const {mediaQueries } = theme;

	return css`
    ${mediaQueries.sm} {
    }
  `;
});
export const FormTitle = styled.h2(({theme}) => {
	const {size} = theme;

	return css`
		padding-bottom: 15px;
		font-size: ${size.px32};
		line-height: 1.2;
	`;
});

export const FormSubTitle = styled.h3(({theme}) => {
	const {size, font} = theme;

	return css`
		padding-bottom: 15px;
		font-size: ${size.px20};
		line-height: 1.2;
		font-family: ${font.family.EMPrintRegular};
	`;
})
export const Input = styled.input(({theme}) => {
	const {color, size, font, mediaQueries} = theme;

	return css`
		outline: none;
		border: none;
		border-bottom: 1px solid ${color.grey};
		color: ${color.darkCharcoal};
		width: 100%;
		background-color: transparent;
		padding: 7px 0;
		margin: 0;
		font-size: ${size.px16};
		font-family: ${font.family.EMPrintRegular};
		font-weight: ${font.weight.regular};
		caret-color: ${color.darkCharcoal};

		:-webkit-autofill,
		:-webkit-autofill:hover,
		:-webkit-autofill:focus {
			-webkit-box-shadow: 0 0 0 1000px #cad4e9 inset !important;
			-webkit-text-fill-color: ${color.darkCharcoal} !important;
		}
		
		::placeholder {
			color: ${color.darkCharcoal};
			font-size: ${size.px16};
		}

		&.error-field {
			border-bottom-color: ${color.lightRed};
			color: ${color.lightRed};

			:-webkit-autofill,
			:-webkit-autofill:hover,
			:-webkit-autofill:focus {
				-webkit-text-fill-color: ${color.lightRed} !important;
			}
			
			::placeholder {
				color: ${color.lightRed};
			}
		}

		${mediaQueries.sm} {
			:-webkit-autofill,
			:-webkit-autofill:hover,
			:-webkit-autofill:focus {
				-webkit-box-shadow: 0 0 0 1000px #b3c1e0 inset !important;
			}
		}
	`;
});

export const InputWrap = styled.div(({theme}) => {
	const {mediaQueries} = theme;

	return css`
		position: relative;
		padding-bottom: 20px;

		${mediaQueries.sm} {
			
		}
	`;
});

export const ErrorTxt = styled.span(({theme}) => {
	const {size, color} = theme;

	return css`
		position: absolute;
		bottom: 5px;
		left: 0;
		width: 100%;
		display: block;
		color: ${color.lightRed};
		font-size: ${size.px13};
		margin: 0;
		line-height: 1;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	`;
});

export const ButtonBox = styled.div(({theme}) => {
	const {color, mediaQueries} = theme;

	return css`
		padding-top: 15px;

		button {
			margin: 0;
			&:hover,
			&:disabled{
				background-color: ${color.olympic};
			}
		}
		
		${mediaQueries.sm} {
			button {
				width: auto;
			}
		}
	`;
})