import { useLocation } from 'react-router-dom';

const useHeaderLinkActive = () => {
  const location = useLocation();

  return (url: string) => {
    const { pathname } = location;
    return pathname === url
      || (url === '/' && (
        pathname === '/homepage-member-active'
        || pathname === '/homepage-member-inactive'
        || pathname === '/home-page'
        ))
      || (url === '/profile/overview' && (
        pathname === '/profile/transactions'
        || pathname === '/profile/rewards'
        || pathname === '/profile/cards'
        || pathname === '/profile/info'
      ))
  };
};

export default useHeaderLinkActive;