import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconArrowBlue } from '../../../../../assets/';
import {
  setMobArrowStyles,
  setMobBtnStyles
} from "../../../TopNavigation/parts/LanguageSelector/LanguageSelector.styles";

export const LinksBarWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    height: 100%;

     ${mediaQueries.md} {
       width: 100%;
       flex-wrap: wrap;
     }
  `;
});

export const LinkLabel = styled.span(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    line-height: 1;
    color: ${color.darkCharcoal};
    transition: all 0.3s;
  `;
});

const setLinkStyles = (theme: any) => {
  const { color, mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    text-align: center;
    transition: all 0.3s;

    &:hover{
      background-color: ${color.lightBlue};

      ${mediaQueries.md} {
        background-color: transparent;
      }
    }

    &.active {
      background-color: ${color.lightBlue};
      ${LinkLabel}{
        color: ${color.blue};
      }
    }

    ${mediaQueries.md} {
      ${setMobBtnStyles(theme)};
      background-color: ${color.white};
      position: relative;

      &:after{
        ${setMobArrowStyles()};
        content: '';
        width: 14px;
        height: 16px;
        margin-top: -8px;
        background-image: url('${IconArrowBlue}');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  `;
};

export const NavLinkCustom = styled(NavLink)(({ theme }) => {
  return css`
    ${setLinkStyles(theme)}
  `;
});

export const LinkCustom = styled.a(({ theme }) => {
  return css`
    ${setLinkStyles(theme)}
  `;
});

