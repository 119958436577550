import React from 'react';

import { Headline, Video } from '../../../redesign/components/shared';

import { VideoVimeoProps } from './videoVimeo.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { VideoRow } from './VideoVimeo.styles';

const VideoVimeo: React.FC<VideoVimeoProps> = ({ params }) => {
  return (
    <Wrapper>
      <Container maxWidth={'1200px'}>
        {params?.headline && <Headline title={params?.headline} />}
        <VideoRow>
          {params?.video_items?.map((video, index) => {
            return (
              <Video
                key={video._id || index.toString()}
                {...video}
              />
            );
          })}
        </VideoRow>
      </Container>
    </Wrapper>
  );
};

export default VideoVimeo;
