import React, { useState } from 'react';
import i18n from '../../../../../config/i18n';
import { Button } from '../../../../components/shared';
import APIService from '../../../../../prepr/components/EndYearCampaignCustom/apiEndYearCampaign/api';

import {
	ModalQuestionWrapper,
	ModalBlock,
	ButtonSendWrap,
	FormWrap,
	ErrorWrap,
	ModalSubtitle,
	ModalTitle,
	ModalLabel,
	ModalInputWrap,
	ModalCloseBtn
} from './ModalBE.style';

import {IconClose} from '../../assets';

function ModalBE() {
	const [error, setError] = useState<boolean | null>(null);
	const [isCheck, setIsCheck] = useState(true);
	const [localAnswerState, setLocalAnswerState] = useState(false);

	const onRadioChanged = (event: any) => {
		setIsCheck(false);

		if (event.target.value === 'true') {
			setError(false);
			setLocalAnswerState(true);
		} else {
			setLocalAnswerState(false);
		}
	};

	const hideModal = () => {
		setError(false);
		APIService.setIsShowModal(false);
	};

	const submitAnswer = () => {
		if (localAnswerState) {
			APIService.setIsModalAnswerCorrect(true);
			setError(false);
			hideModal();
		} else {
			APIService.setIsModalAnswerCorrect(false);
			setError(true);
		}
	};

	return (
		<ModalQuestionWrapper>
			<ModalBlock>
				<ModalCloseBtn onClick={() => hideModal()}>
					<img src={IconClose} alt='close modal' />
				</ModalCloseBtn>
				<ModalSubtitle>{i18n.t('eyc_2024_modal_intro')}</ModalSubtitle>
				<ModalTitle>{i18n.t('eyc_2024_modal_question')}</ModalTitle>
				<FormWrap>
					<ModalInputWrap>
						<input type='radio' name='answer' id='answer1' value={'true'} onChange={onRadioChanged} />
						<ModalLabel htmlFor="answer1">
							{i18n.t("eyc_2024_modal_1_answer")}
							<span className="checkmark" />
						</ModalLabel>
					</ModalInputWrap>

					<ModalInputWrap>
						<input type='radio' name='answer' id='answer2' value={'false'} onChange={onRadioChanged} />
						<ModalLabel htmlFor="answer2" className={error ? "error" : ""}>
							{i18n.t("eyc_2024_modal_2_answer")}
							<span className="checkmark" />
						</ModalLabel>
						{error && (
							<ErrorWrap>
								{i18n.t('eBike_validation_question_error')}
							</ErrorWrap>
						)}
					</ModalInputWrap>

					<ButtonSendWrap>
						<Button
							disabled={isCheck}
							text={i18n.t('eyc_2024_modal_button')}
							buttontype={'primary'}
							onClick={submitAnswer}
						/>
					</ButtonSendWrap>
				</FormWrap>
			</ModalBlock>
		</ModalQuestionWrapper>
	);
}

export default ModalBE;