import React, { Component } from "react";
import '../../../components/common/Dialogs.scss';

class DialogWindow extends Component {
    handleCloseModal = (event) => {
        if(this.props.isOpen) {
            const isClickInside = this.node.contains(event.target) || this.node.closest('.dialog-parent')?.querySelector('.input-link')?.contains(event.target) || (!!this.node.closest('.phone-input-wrapper') && this.node.closest('.phone-input-wrapper')?.querySelector('[name="phone-num"]')?.contains(event.target)) ||
              (document.getElementsByClassName('whyDialogBox')[0]?.contains(event.target)) ||
              (document.getElementsByClassName('contactServiceBox')[0]?.contains(event.target)) ||
              (document.getElementsByClassName('locationDialogBox')[0]?.contains(event.target)) ;
            if(!isClickInside) {
                this.props.handleCloseModal(event);
            }
        }
    }
    componentDidMount() {
        document.addEventListener('click', this.handleCloseModal, false)
    }
    componentWillUnmount () {
        document.removeEventListener('click', this.handleCloseModal, false)
    }
    render() {
        return (
        <div className={`dialog-window ${this.props.isOpen ? 'opened': ''}`} ref={node => { this.node = node; }}>
            {this.props.isOpen &&
                <div className="dialog-content">
                    <button onClick={this.props.handleCloseModal} className="close-modal-button"></button>
                    {this.props.children}
                </div>
            }
        </div>
        );
    }
}


export default DialogWindow;
