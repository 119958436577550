import React from 'react';
import { PageBanner } from '../../../redesign/components/shared';
import { removeBrackets } from '../../../redesign/utils';

import { useSelector } from 'react-redux';

interface PageHeaderHelpProps {
  params?: {
    headline_logged_out: string;
    headline_logged_in: string;
    background_image: object;
  };
  onLogin?: boolean;
}

const PageHeaderHelp: React.FC<PageHeaderHelpProps> = ({ params, onLogin }) => {
  const firstName = useSelector(
    (state: { currentUser: any }) => state!.currentUser.firstName
  );

  const userName = removeBrackets(params!.headline_logged_in, {
    firstName: firstName
  });
  return (
    <PageBanner
      image={params?.background_image}
      headline={onLogin ? userName : params?.headline_logged_out}
    />
  );
};

export default PageHeaderHelp;
