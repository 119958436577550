import React from 'react';
import { ProductCardManual } from '../../../redesign/components/shared';

import { ProductCollectionManualGridProps } from './productCollectionManualGrid.types';

import { Container, Wrapper } from '../../../redesign/theme/GlobalStyles';

import {
  ProductRow,
  ProductRowCol
} from './ProductCollectionManualGrid.styles';

const ProductCollectionManualGrid: React.FC<
  ProductCollectionManualGridProps
> = ({ params, onLogin }) => {
  const products = params?.products;

  return (
    <Wrapper>
      {products!.length > 0 && (
        <Container maxWidth={'1280px'} paddingBottom={15}>
          <ProductRow>
            {products?.map((item) => {
              return (
                <ProductRowCol
                  className="row-element manual-grid"
                  key={`product-${item!.id}-${params?._id}`}
                >
                  <ProductCardManual onLogin={onLogin} {...item} />
                </ProductRowCol>
              );
            })}
          </ProductRow>
        </Container>
      )}
    </Wrapper>
  );
};

export default ProductCollectionManualGrid;
