import axios from 'axios'
import i18n from '../../config/i18n'
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';

axios.interceptors.response.use(null, function(error) {
  if (error?.response?.data?.code === 9999) {
    connect.dispatch(actions.addNotice({text: i18n.t("08_wrong_email"), type: 'error'}));
  }
  return Promise.reject(error);
});
