// @ts-ignore
import { createPreprClient } from '@preprio/nodejs-sdk';

export const prepr = createPreprClient({
  token:
    window.location.hostname === 'localhost'
      ? '12848bd8e4af2c700d8202f91eb3423ab8a075e32f0aa7fc160feea1a510d5f5'
      : process.env.REACT_APP_PREPR_ACCESS_TOKEN, // You can find one in your Prepr environment
  baseUrl: 'https://graphql.prepr.io/graphql',
  userId: null // Optional, used for AB testing implementations
});
