import { prepr } from '../services/prepr';

import { getLocalePrepr } from '../../components/utils/_app-helpers';

let locale = getLocalePrepr();
const getArticles = prepr
  .graphqlQuery(
    `{
      Links(sort: title_ASC, limit: null, locale: "${locale}") {
        items {
            _id
            _publish_on
            cta_url
            description
            title
            internal_link { 
              __typename
              ... on Page {
                  _id
                  _slug
                }
          
            }
            image {
              cdn_files {
                url(width: ${900})
              }
              url
              width
              height
              name
            }
          }
          total
        }
      }`
  )
  .fetch()
  .then((res: any) => res.data.Links.items)
  .catch((error: string) => error);

export default getArticles;
