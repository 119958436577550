import styled, { css } from 'styled-components';

export const FormSubtitle = styled.h2(({ theme }) => {
  const { color, font, size } = theme;

  return css`
    font-size: ${size.px16};
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    color: ${color.graniteGray};
    line-height: 1;
    margin: 0 0 ${size.px24};
  `;
});
