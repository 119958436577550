import React, { useState } from 'react';

import StepVertical from './StepVertical';
import { clickGTM } from '../../../../components/utils/_gtm-helpers';
import { StepProps } from '../Step/step.types';

import { AccordionWrapper } from './StepsAccordion.styles';

function StepsAccordion({ items }: { items: Array<StepProps> | undefined }) {
  const [currentIdx, setCurrentIdx] = useState(0);

  const btnOnClick = (idx: number , headline: string) => {
    clickGTM('accordion_item_click', 'Open Accordion', `${headline}`);
    setCurrentIdx((currentValue) => (currentValue !== idx ? idx : currentValue));
  };

  return (
    <AccordionWrapper role='tabList' aria-label='List of Tabs'>
      {items?.map((item, idx) => (
        <StepVertical
          tabIndex={items.length - idx}
          key={idx}
          data={item}
          isOpen={idx === currentIdx}
          btnOnClick={() => btnOnClick(idx, item?.headline)}
          num={(idx+1) < 10 ? `0${idx+1}` : `${idx+1}`}
        />
      ))}
    </AccordionWrapper>
  );
}

export default StepsAccordion;
