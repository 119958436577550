import React from 'react';
import { shopLang } from '../../../../../../../config/config';
import i18n from '../../../../../../../config/i18n';
import { Button } from '../../../../../../../redesign/components/shared';
import {
  ButtonWrapper,
  Container,
  Content,
  HeaderImage,
  Text,
  Title,
  Wrapper
} from '../EYCHeader.styles';
import { HeaderBoxesFR2, HeaderBoxesNL2 } from '../../../assets/phase2';

const HeaderCompleted = () => {
  const headerImg = shopLang === 'be_fr' ? HeaderBoxesFR2 : HeaderBoxesNL2;

  return (
    <Wrapper phase={2}>
      <Container>
        <HeaderImage>
          <img src={headerImg} alt='boxes' />
        </HeaderImage>

        <Content>
          <div>
            <Title>{i18n.t('eyc_2024_header_completed_title')}</Title>
            <Text>{i18n.t('eyc_2024_header_completed_text')}</Text>
            <ButtonWrapper>
              <Button backgroundcolor={'#F37427'}
                      text={i18n.t('eyc_2024_rewards_btn')}
                      url={'/rewards/all'}
              />
            </ButtonWrapper>
          </div>
        </Content>
      </Container>
    </Wrapper>
  )
};

export default HeaderCompleted;