import styled, { css } from 'styled-components';
import { IconAppAndroid, IconAppIos } from '../../../../prepr/assets';

export const AppButtonListStyles = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    display: flex;

    ${mediaQueries.sm} {
      justify-content: center;
      margin-top: ${size.px32};
    }
  `;
});

export const AppButton = styled.a(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    display: block;
    height: ${size.px48};

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    text-decoration: none;
    opacity: 1;

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    ${mediaQueries.s} {
      height: ${size.px42};
    }
  `;
});

export const AppStoreButton = styled(AppButton)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 163px;
    background-image: url(${IconAppIos});

    ${mediaQueries.s} {
      width: 145px;
    }
  `;
});

export const PlayStoreButton = styled(AppButton)(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    width: 147px;
    background-image: url(${IconAppAndroid});
    margin-right: ${size.px8};

    ${mediaQueries.sm} {
      margin-right: ${size.px16};
    }

    ${mediaQueries.s} {
      width: 125px;
    }
  `;
});
