import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { clickGTM, pageViewGTM } from '../../../components/utils/_gtm-helpers';
import { Accordion, Tabs } from '../../../redesign/components/shared';
import { TabProps } from '../../../redesign/components/shared/Tabs/tabs.types';
import { AccordionData } from '../../../redesign/components/shared/Accordion/accordion.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { FAQTitle, NavigationWrap } from './NavigationCollection.styles';
import { ProductHighlightsProps } from '../../../redesign/components/shared/ProductHighlights/productHighlights.types';

interface FAQProps extends TabProps {
  faqs?: AccordionData[];
}

interface ProductCollectionHighlightsProps extends TabProps {
  products?: ProductHighlightsProps[];
}

interface CombineProps extends FAQProps, ProductCollectionHighlightsProps {}

interface NavigationCollectionProps {
  categories: CombineProps[];
}

const NavigationCollection: ({
  params,
  slug
}: {
  params: NavigationCollectionProps;
  slug: string;
}) => false | JSX.Element = ({ params, slug }) => {
  const [activeTab, setActiveTab] = useState<null | number>(null);
  const activeFaq = activeTab !== null && params?.categories?.[activeTab]?.faqs;

  const tabs =
    params?.categories.map(({ _id, _slug, title, icon, active_icon }) => {
      return {
        _id,
        _slug,
        title,
        icon,
        active_icon
      };
    }) || [];

  const isActiveRewards = slug?.split('/')[0] === 'rewards' ? true : undefined;
  const history = useHistory();

  const parsePageSlug = useCallback(
    (slug: string) => {
      const reward = slug?.split('/')[0];
      const rewardId = slug?.split('/')[1];

      if (reward === 'rewards' && Number.parseInt(rewardId)) {
        let idx = params?.categories.findIndex(
          (i) => Number(i?._slug) === Number(rewardId)
        );
        setActiveTab(idx);
      } else if (reward === 'rewards' && (rewardId === 'all' || !rewardId)) {
        pageViewGTM(`/${window.location.hash}`, 'Rewards - Esso Extras', `${window.location.href}`);
        let idx = params?.categories?.findIndex(
          (i) => i?._slug?.trim().toLowerCase() === 'all'
        );
        setActiveTab(idx);
      }
    },
    [params]
  );

  useEffect(() => {
    if (!isActiveRewards) {
      setActiveTab(0);
    }
  }, [isActiveRewards]);

  const handleSetActiveTabs = (idx: number, title: string) => {
    clickGTM('swiper_click', 'Swiper Interactions', `${title}`)
    setActiveTab(idx);
  };

  useEffect(() => {
    isActiveRewards && parsePageSlug(slug);
  }, [isActiveRewards, parsePageSlug, slug]);

  useEffect(() => {
    if (isActiveRewards && activeTab !== null) {
      let categorySlug = params?.categories?.[activeTab]?._slug
        ?.trim()
        .toLowerCase();

      categorySlug &&
        history.push({
          pathname: `/rewards/${categorySlug}`
        });
    }
  }, [isActiveRewards, activeTab, history, params]);

  return (
    <NavigationWrap>
      {activeTab != null && (
        <Wrapper>
          <Container
            maxWidth={isActiveRewards && '1250px'}
            paddingBottom={0}
            className="isCustomTabs"
          >
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              handleSetActiveTabs={handleSetActiveTabs}
            />
            {activeFaq && (
              <>
                <FAQTitle>{params?.categories?.[activeTab]?.title}</FAQTitle>
                <Accordion items={activeFaq} />
              </>
            )}
          </Container>
        </Wrapper>
      )}
    </NavigationWrap>
  );
};

export default NavigationCollection;
