import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from '../../../../../../../redesign/components/shared';
import APIService from '../../../../api_campaign/api';
import i18n from '../../../../../../../config/i18n';
import { apiLang, shopLang } from '../../../../../../../config/config';
import { formProps } from './form.types';
import { InputWrap, Input, ErrorTxt, FormWrapper, FormTitle, FormSubTitle, ButtonBox } from './Form.styles';

const Form: React.FC<formProps> = () => {
  const email = localStorage.getItem('userEmail');
  const firstName = useSelector((state: any) => state?.currentUser?.firstName);
  const lastName = useSelector((state: any) => state?.currentUser?.lastName);
  const participantId = useSelector((state: any) => state?.participantData?.id);
  const isFormBtnDisabled = useSelector((state: any) => state?.isFormBtnDisabled);
  const[phoneNumber, setPhoneNumber] = useState<string>('');
  const[mobileNumber, setMobileNumber] = useState<string>('');

  const checkSubmitting = useRef(false);

  type Inputs = {
    firstName: string,
    lastName: string,
    street: string,
    houseNumber: string,
    city: string,
    email: string,
    postcode:string,
    phone: string,
    mobile: string,
  }
  const schema = yup
    .object()
    .shape({
      firstName: yup
          .string()
          .required(i18n.t('eBike_form_error_firstName'))
          .matches(/^[A-zÀ-ÿ\s-']{1,30}$/i, i18n.t('eBike_form_error_firstName')),
      lastName: yup
        .string()
        .required(i18n.t('eBike_form_error_lastName'))
        .matches(/^[A-zÀ-ÿ\s-']{1,30}$/i, i18n.t('eBike_form_error_lastName')),
      street: yup.string()
        .required(`${i18n.t('eBike_form_error_street')}`)
        .matches(/^[A-zÀ-ÿ0-9\s-']{1,30}$/i, `${i18n.t('eBike_form_error_street')}`),
      houseNumber: yup
        .string()
        .required(`${i18n.t('eBike_form_error_houseNumber')}`)
        .matches(/^[A-zÀ-ÿ0-9\s-'/]{1,5}$/i, `${i18n.t('eBike_form_error_houseNumber')}`),
      postcode: yup
        .string()
        .required(`${i18n.t('eBike_form_error_postcode')}`)
        .matches(
          (apiLang === 'nl') ?
            /^[0-9]{4}[A-zÀ-ÿ]{2}/ :
            /^[0-9]{4}/,
          `${i18n.t('eBike_form_error_postcode')}`),
      city: yup
        .string()
        .required(`${i18n.t('eBike_form_error_city')}`)
        .matches(/^[A-zÀ-ÿ\s-']{1,30}$/i, `${i18n.t('eBike_form_error_city')}`),
      email: yup
        .string()
        .required(`${i18n.t('eBike_form_error_email')}`)
        .matches(/^\s*[A-zÀ-ÿ0-9._%+-]+@[A-zÀ-ÿ0-9.-]+\.[A-zÀ-ÿ]{2,}\s*$/i, `${i18n.t('eBike_form_error_email')}`),
      phone: yup
        .string()
        .required( `${i18n.t('eBike_form_error_phone')}`)
        .matches(/^[0-9]+$/,  `${i18n.t('eBike_phone_1_validation')}`)
        .min(9, `${i18n.t('eBike_phone_2_validation')}`)
        .max(16, `${i18n.t('eBike_phone_2_validation')}`),
      mobile: yup
        .string()
        .required( `${i18n.t('eBike_form_error_mobile')}`)
        .matches(/^[0-9]+$/,  `${i18n.t('eBike_phone_1_validation')}`)
        .min(9, `${i18n.t('eBike_phone_2_validation')}`)
        .max(16, `${i18n.t('eBike_phone_2_validation')}`),
    }).required()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } =
    useForm<Inputs>({
      resolver: yupResolver(schema),
      mode: "all"
  })

  useEffect(() => {
    return () => {
      checkSubmitting.current = false;
    };
  }, []);

  const handleSendData = (data: any) => {
    if (checkSubmitting.current) return null;

    checkSubmitting.current = true;
    APIService.setIsFormBtnDisabled(true);

    const winnerData = {
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      dob: new Date().toString(),
      address1: data.street || '',
      address2: data.houseNumber || '',
      zip: data.postcode || '',
      city: data.city || '',
      email: data.email.trim() || '',
      phone: data.phone.trim() || '',
      country: shopLang.slice(0, 2).toLowerCase(),
      notice: data.mobile.trim() || '',
    };

    APIService.sendForm({...winnerData}, participantId)
      .then(() => APIService.updateUser())
      .then(() => {
        APIService.setIsFormBtnDisabled(false);
        APIService.setIsFormShowed(false);
        return Promise.resolve();
      })
      .catch((error) => {
        console.error(error);
        APIService.setIsFormBtnDisabled(false);
      });
  };

  return (
      <FormWrapper className={'FormWrapperFormWrapperFormWrapperFormWrapper'}>
        <FormTitle>{i18n.t('eBike_form_title')}</FormTitle>
        <FormSubTitle>{i18n.t('eBike_form_subtitle')}</FormSubTitle>

        <form onSubmit={handleSubmit(handleSendData)}>
          <InputWrap>
              <Input
                defaultValue={firstName || ''}
                className={errors?.firstName ? 'error-field' : ''}
                placeholder={`${i18n.t(
                  'eBike_form_placeholder_firstName',
                )}`}
                {...register('firstName')}
              />
              {errors?.firstName && (
                <ErrorTxt>
                  {errors?.firstName?.message || i18n.t('eBike_form_error_firstName')}
                </ErrorTxt>
              )}
            </InputWrap>

          <InputWrap>
            <Input
              defaultValue={lastName || ''}
              className={errors.lastName ? 'error-field' : ''}
              placeholder={`${i18n.t(
                'eBike_form_placeholder_lastName',
              )}`}
              {...register('lastName')}
            />
            {errors.lastName && (
              <ErrorTxt>
                {errors?.lastName?.message || i18n.t('eBike_form_error_lastName')}
              </ErrorTxt>
            )}
          </InputWrap>

          <InputWrap>
            <Input
              className={errors.street ? 'error-field' : ''}
              placeholder={`${i18n.t(
                'eBike_form_placeholder_street',
              )}`}
              {...register('street')}
            />
            {errors.street && (
              <ErrorTxt>
                {errors?.street?.message || i18n.t('eBike_form_error_street')}
              </ErrorTxt>
            )}
          </InputWrap>

          <InputWrap>
            <Input
              className={errors.houseNumber ? 'error-field' : ''}
              placeholder={`${i18n.t(
                'eBike_form_placeholder_houseNumber',
              )}`}
              {...register('houseNumber')}
            />
            {errors.houseNumber && (
              <ErrorTxt>
                {errors?.houseNumber?.message || i18n.t('eBike_form_error_houseNumber')}
              </ErrorTxt>
            )}
          </InputWrap>

          <InputWrap>
            <Input
              className={errors.postcode ? 'error-field' : ''}
              placeholder={`${i18n.t(
                'eBike_form_placeholder_postcode',
              )}`}
              {...register('postcode')}
            />
            {errors.postcode && (
              <ErrorTxt>
                {errors?.postcode?.message || i18n.t('eBike_form_error_postcode')}
              </ErrorTxt>
            )}
          </InputWrap>

          <InputWrap>
            <Input
              className={errors.city ? 'error-field' : ''}
              placeholder={`${i18n.t(
                'eBike_form_placeholder_city',
              )}`}
              {...register('city')}
            />
            {errors.city && (
              <ErrorTxt>
                {errors?.city?.message || i18n.t('eBike_form_error_city')}
              </ErrorTxt>
            )}
          </InputWrap>

          <InputWrap>
            <Input
                defaultValue={email || ''}
                className={errors.email ? 'error-field' : ''}
                placeholder={`${i18n.t(
                  'eBike_form_placeholder_email',
                )}`}
                {...register('email')}
              />
              {errors.email && (
                <ErrorTxt>
                  {errors?.email?.message || i18n.t('eBike_form_error_email')}
                </ErrorTxt>
              )}
            </InputWrap>

          <InputWrap>

          <Controller
            name='phone'
            control={control}
            render={({ field: { onChange} }) => (
              <Input
                type={'tel'}
                className={errors.phone ? 'error-field' : ''}
                placeholder={`${i18n.t(
                  'eBike_form_placeholder_phone',
                )}`}
                {...register('phone')}
                value={phoneNumber}
                onChange={ (e) => {
                  onChange(e);
                  if(!isNaN(Number(e.target.value.trim()))){
                    setPhoneNumber(e.target.value.trim())
                  }else{
                    setPhoneNumber(phoneNumber)
                  }
                }}
              />
            )}
          />
          {errors.phone && (
            <ErrorTxt>
              {errors?.phone?.message || i18n.t('eBike_form_error_phone')}
            </ErrorTxt>
          )}
        </InputWrap>

          <InputWrap>
           <Controller
            name='mobile'
            control={control}
            render={({ field: { onChange} }) => (
              <Input
                type={'tel'}
                className={errors.mobile ? 'error-field' : ''}
                placeholder={`${i18n.t(
                  'eBike_form_placeholder_mobile',
                )}`}
                {...register('mobile')}
                value={mobileNumber}
                onChange={ (e) => {
                  onChange(e);
                  if(!isNaN(Number(e.target.value.trim()))){
                    setMobileNumber(e.target.value.trim())
                  }else{
                    setMobileNumber(mobileNumber)
                  }
                }}
              />
            )}
          />
          {errors.mobile && (
            <ErrorTxt>
              {errors?.mobile?.message || i18n.t('eBike_form_error_mobile')}
            </ErrorTxt>
          )}
        </InputWrap>

        <ButtonBox>
          <Button
            disabled={isFormBtnDisabled}
            text={`${i18n.t('eBike_btn_sendForm')}`}
            buttontype={'primary'}
            type={'submit'}/>
        </ButtonBox>
        </form>
      </FormWrapper>
  )
}
export default Form;
