import styled, { css } from 'styled-components';
import { ArticleBox } from "../../../redesign/components/shared/Article/Article.styles";

type ArticleContainerProps = {
  itemsPerRow?: string | number;
};

const setImageAlignment = (itemsPerRow: string | number, theme: any) => {
  if(+itemsPerRow >= 3){
    return css `
      img {
        ${theme.mediaQueries.minWidthSM} {
          max-width: initial;
          height: 100%;
        }
      }
  `
  }
}

export const ArticlesWrap = styled.div<ArticleContainerProps>(({ theme, itemsPerRow = 1}) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    flex-wrap: wrap;
    width: 101%;
    margin: 0 -0.5%;
    
    .swiper-wrapper,
    .swiper-slide {
      height: auto;
    }
    
    &.isActiveCarousel {
      img {
        ${mediaQueries.minWidthSM} {
          max-width: initial;
          height: 100%;
        }
      }
    }

    ${mediaQueries.sm} {
      margin: 0 0 20px;
    }

    ${setImageAlignment(itemsPerRow, theme)};
    
    ${ArticleBox}{
      ${mediaQueries.sm} {
        border-bottom: none;
      }
    }
  `;
});

export const ArticleContainer = styled.div<ArticleContainerProps>(
  ({ theme, itemsPerRow = '3' }) => {
    const { mediaQueries } = theme;

    return css`
      width: calc(100% / ${+itemsPerRow} - 1%);
      margin: 0 0.5% 20px;

      ${mediaQueries.sm} {
        width: calc(100%/2 - 1%);
      }

      ${setImageAlignment(itemsPerRow, theme)};
    `;
  }
);