import styled, { css } from 'styled-components';
import { ToastTypes } from './Toast';
import { IconInfo } from '../../assets';

export const ToastWrapper = styled.div<ToastTypes>(
  ({ theme, type }) => {
    const { mediaQueries } = theme;

    return css`
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 290px;
      text-align: left;
      z-index: 100;
      background: #cce0f2;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.32);
      border-radius: 8px;
      padding: 15px;
      box-sizing: border-box;
      opacity: 1;
      transition: opacity 200ms;

      ${type === 'blueChest' && `
        @media (min-width: 1250px){
          right: 250px;
        }
  
        bottom: 20%;
        right: 20vw;
        
        ${mediaQueries.md} {
          bottom: 10%;
          right: 15vw;
        }
  
        ${mediaQueries.sm} {
          bottom: 0;
          right: initial;
          left: 50%;
          transform: translateX(-145px);
        }
      `}

      ${type === "goldChest" && `
        background: rgba(255, 255, 255, 0.8);
        left: calc(50% + 80px);
        bottom: 50%;
 
        ${mediaQueries.sm} {
          left: 50%;
          bottom: 0;
          transform: translateX(-145px);
        }
      `}

      ${type === 'keys' && `  
        bottom: 100px;
        left: 25%;
        
        ${mediaQueries.md} {
          left: inherit;
          right: -100px;
        }
  
        ${mediaQueries.sm} {
          bottom: 70px;
          right: initial;
          left: 50%;
          transform: translateX(-145px);
        }
      `}
    `;
  }
);

export const ToastIcon = styled.div(() => {
  return css`
    width: 32px;
    height: 32px;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-image: url('${IconInfo}');
    margin-right: 10px;
  `;
});

export const ToastText = styled.p(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    line-height: 1.57;
    color: ${color.darkCharcoal};
    width: calc(100% - 32px - 10px);
  `;
});