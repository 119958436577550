import React from 'react';
import { Wrapper } from '../../../redesign/theme/GlobalStyles';
import { Editor, TermsContainer } from "./TermsContent.styles";

interface TermsContentProps {
  params?: {
    _id?: string;
    text?: string;
    font_color?: string;
  };
}

const TermsContent: React.FC<TermsContentProps> = ({ params }) => {
  return (
    <Wrapper>
      <TermsContainer maxWidth="1000px">
        <Editor fontColor={params?.font_color}
                dangerouslySetInnerHTML={{ __html: `${params?.text}` }}/>
      </TermsContainer>
    </Wrapper>
  );
};

export default TermsContent;
