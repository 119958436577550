import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import GameWrap from '../GameWrap';
import Confetti from '../../Confetti';
import APIService from '../../../../../api_campaign/api';
import { useWindowSize } from '../../../../../../../../redesign/hooks';
import { apiLang, shopLang } from '../../../../../../../../config/config';
import clearLocaleStorage from '../../../../../../../../redesign/utils/clearLocaleStorage';
import { GameLoggedInUserProps } from './gameLoggedInUser.types';

const GameLoggedInUser: React.FC<GameLoggedInUserProps> = () => {
  const windowSize = useWindowSize();
  const accountId = localStorage.getItem('accountId');
  const isPlayBtnDisabled = useSelector((state: any) => state?.isPlayBtnDisabledCampaign);
  const activeBalloon = useSelector((state: any) => state?.activeBalloon);
  const animatedBalloon = useSelector((state: any) => state?.animatedBalloon);
  const transactions = useSelector((state: any) => state?.transactions);
  const campaignId = useSelector((state: any) => state.campaignInfo?.campaignGames[0]?.id);
  const isShowModalCampaign = useSelector((state: any) => state?.isShowModalCampaign);
  const isConfettiShowed = useSelector((state: any) => state?.isConfettiShowed);
  const history = useHistory();
  const location = useLocation();

  const onSetActiveBalloon = (num: number) => {
    if(!isPlayBtnDisabled){
      if(activeBalloon === num && !isShowModalCampaign){
        APIService.setActiveBalloon(0)
      }else{
        APIService.setActiveBalloon(num);
      }
    }
  }
  const onSetUnActiveBalloon = () => {
    if(!isPlayBtnDisabled && !isShowModalCampaign){
      APIService.setActiveBalloon(0);
    }
  }
  const onSetActiveBalloonMob = (num: number)  => {
    return windowSize <= 767 && onSetActiveBalloon(num)
  }
  const onSetActiveBalloonDesktop = (num: number)  => {
    windowSize > 768 && onSetActiveBalloon(num)
  }
  const onSetUnActiveBalloonDesktop = () => {
    windowSize > 768 && onSetUnActiveBalloon()
  }

  const countGames = useCallback(() => {
    ReactGA.event({
      category: 'Game counter - E-bike 2023',
      action: apiLang.toUpperCase(),
      label: `accountId: ${accountId}`,
    });
  }, [accountId]);

  const defineWinnerBackend = useCallback(() => {
    countGames();

    return APIService.createParticipantWithLottery({campaignGameId: campaignId})
      .then((prize) => {
        return Promise.resolve(prize);
      })
      .catch(() => {
        console.info('Something is wrong - defineWinnerBackend');
        return Promise.resolve({error: true})
      })
  }, [campaignId, countGames])

  const handleControlAfterGame = useCallback(	(isUserWon: boolean) => {
    APIService.setIsUserWon(isUserWon);

    return  APIService.lotterySync()
      .then(() => APIService.updateUser())
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => console.info('Something is wrong - handleControlAfterGame'))

  }, [])

  const onPlayGame = useCallback((num: number) => {
    const dateNow = Date.now();
    const accessExpiresAt = localStorage.getItem('accessExpiresAt');
    const authorized = localStorage.getItem('authorized');

    if (authorized && accessExpiresAt && (dateNow >= +accessExpiresAt)) {
      clearLocaleStorage();
      history.push({
        pathname: '/auth/login',
        state: {
          prevPath: location.pathname
        }
      })
    }else{
      if(activeBalloon === num && !isPlayBtnDisabled){
        APIService.setIsPlayBtnDisabled(true);

        defineWinnerBackend()
            .then((response) => {
              APIService.setAnimatedBalloon(num);

              if(response?.lotteryPrize?.prize){
                setTimeout(() => {
                  APIService.setIsReplayGame(true);
                  APIService.setTransactions();
                  APIService.setIsConfettiShowed(true);
                  APIService.setIsFormShowed(true);
                }, 6000);

                return handleControlAfterGame(true)
                    .then(() => console.log('User is won'))
              }else{
                setTimeout(() => {
                  APIService.setIsReplayGame(true);
                  APIService.setTransactions();
                }, 6000);

                return handleControlAfterGame(false)
                    .then(() => {
                      console.log('User is lost');
                    })
              }
            })
      }
    }
  },[activeBalloon, isPlayBtnDisabled, defineWinnerBackend, handleControlAfterGame, history, location])

  const showModal = useCallback((num: number) => {
    const dateNow = Date.now();
    const accessExpiresAt = localStorage.getItem('accessExpiresAt');
    const authorized = localStorage.getItem('authorized');

    if (authorized && accessExpiresAt && (dateNow >= +accessExpiresAt)) {
      clearLocaleStorage();
      history.push({
        pathname: '/auth/login',
        state: {
          prevPath: location.pathname
        }
      })
    }else{
      if(!!transactions && !isPlayBtnDisabled){
        shopLang !== 'nl_nl' ? APIService.setIsShowModal(true) : onPlayGame(num);
      }
    }
  }, [onPlayGame, transactions, isPlayBtnDisabled, history, location])

  return (
    <>
      {isConfettiShowed && <Confetti />}
      <GameWrap onSetActiveBalloonMob={onSetActiveBalloonMob}
                onSetActiveBalloonDesktop={onSetActiveBalloonDesktop}
                onSetUnActiveBalloonDesktop={onSetUnActiveBalloonDesktop}
                onPlayGame={onPlayGame}
                showModal={showModal}
                activeBalloon={activeBalloon}
                animatedBalloon={animatedBalloon}
      />
    </>
  )
};

export default GameLoggedInUser;