import styled, { css } from 'styled-components';
import { HeadingSubtitle, HeadlineTitle } from "../../../redesign/components/shared/Headline/Headline.styles";
import {accordionWidth} from '../../../redesign/components/shared/StepsAccordion/StepsAccordion.styles';

export const StepsBox = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    position: relative;
    padding: 4vw 0;

    ${mediaQueries.xs} {
      padding: 0;
    }
  `;
});

export const StepsVertTitle = styled(HeadlineTitle)(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    max-width: 100%;
    width: ${accordionWidth};
    margin: 0 0 15px;

    ${mediaQueries.sm} {
      width: 100%;
      margin: 0 0 25px;
    }
  `;
});

export const StepsVertSubTitle = styled(HeadingSubtitle)(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    width: ${accordionWidth};
    margin: 0;

    ${mediaQueries.sm} {
      width: 100%;
    }
  `;
});

export const ButtonBox = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    display: inline-block;
    
    a {
      text-decoration: none;
    }
    
    ${mediaQueries.sm} {
      display: block;
    }
  `;
});
