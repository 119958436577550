import styled, { css } from 'styled-components';
import {
  Cloud,
  FlyingBike,
  BgHeader,
} from "../../../../assets";
import { headerWrapperHeight, headerWrapperHeightMob } from '../../EBikeCampaignCustom.styles';

export const EBikeHeaderWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    height: ${headerWrapperHeight};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    &:before{
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 300%;
      height: 100%;
      
      background-image: url('${BgHeader}');
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: cover;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      
    }
    
    ${mediaQueries.sm} {
      height: ${headerWrapperHeightMob};
    }
  `;
});

export const AnimatedBox = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 250px);
    

    ${mediaQueries.sm} {
      height: calc(100% - 215px);
    }
  `;
});

export const AnimatedCloud = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 85px;
    width: 1050px;
    height: 496px;
    right: 55vw;
    background-image: url('${Cloud}');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;

    animation-name: Cloud;
    animation-duration: 24s;
    animation-iteration-count: infinite;

    ${mediaQueries.sm} {
      right: 0;
      top: 50px;
    }

    @keyframes Cloud {
      0% {
        transform: scale(1, 1) translate(0, 0);
      }
      16%, 45% {
        transform: scale(1.3, 1.3) translate(10vw, 0);
      }
      64%, 100% {
        transform: scale(1, 1) translate(0, 0);
      }
    }
  `;
});

export const AnimatedFlyingBike = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    width: 530px; 
    height: 780px;
    left: 50%;
    margin-left: calc(-530px/2 - 42px);
    top: -5px;
    z-index: 1;
    background-image: url('${FlyingBike}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    animation-name: FlyingBike;
    animation-duration: 24s;
    animation-iteration-count: infinite;
    
    ${mediaQueries.sm} {
      width: 340px;
      height: 505px;
      margin-left: calc(-340px/2);
    }

    @keyframes FlyingBike {
      0% {
        transform: scale(1, 1) translate(0, 0);
      }
      8%, 16% { //top
        transform:  scale(0.95, 0.95) translate(0, -45px);
      }
      24%, 32% { //left
        transform:  scale(0.95, 0.95) translate(-12%, -15px);
      }
      40%, 48% {//base
        transform: scale(1, 1) translate(0, 0);
      }
      56%, 64% {//top
        transform: scale(0.95, 0.95) translate(0, -45px);
      }
      72%, 80% { //left
        transform:  scale(0.95, 0.95) translate(-12%, -15px);
      }
      88%, 100% { //base
        transform: scale(1, 1) translate(0, 0);
      }
    }
  `;
});

export const EBikeHeaderContent = styled.div(({ theme }) => {
  const {mediaQueries } = theme;

  return css`
    position: relative;
    z-index: 2;
    text-align: center;
    padding-bottom: 75px;
    max-width: 980px;
    width: 96%;
    margin: 0 auto;

    ${mediaQueries.sm} {
      padding-bottom: 60px;
      max-width: 90%;
      width: 100%;
    }
  `;
});

export const EBikeHeaderTitle = styled.h1(({ theme }) => {
  const {font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px60};
    line-height: 1.2;
    color: ${color.white};
    letter-spacing: 1px;
    margin-bottom: 10px;

    ${mediaQueries.sm} {
      font-size: ${size.px34};
    }
  `;
});

export const EBikeHeaderSubTitle = styled.h3(({ theme }) => {
  const {font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px24};
    line-height: 1.2;
    color: ${color.white};
    letter-spacing: 0.4px;

    ${mediaQueries.sm} {
      font-size: ${size.px18};
    }
  `;
});

export const ButtonBox = styled.div(({ theme }) => {
  const {mediaQueries } = theme;

  return css`
    margin-top: 25px;

    ${mediaQueries.sm} {
      margin-top: 10px;
    }
    
    button{
      ${mediaQueries.sm} {
        width: auto;
      }
    }
  `;
});

export const CloudBox = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: none;
    position: absolute;
    width: 900px;
    height: 425px;
    left: 67vw;
    bottom: -185px;
    background-image: url('${Cloud}');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    
    ${mediaQueries.sm} {
    }
  `;
});


export const RefWrapp = styled.div``;