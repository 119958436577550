import styled, { css } from 'styled-components';
import { ButtonWrapper } from '../Button/Button.styles';
import { FormWrapperBase } from '../Form/Form.styles';

export const FormWrapper = styled(FormWrapperBase)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    padding: 0 0 4rem;

    ${ButtonWrapper} {
      margin-right: 0;
    }

    ${mediaQueries.sm} {
      ${ButtonWrapper} {
        margin-right: auto;
      }
    }
  `;
});
