import axios from 'axios';
import { apiURL, shopLang } from '../../config/config'

export const cookiePopupCheck = () => {
  if (localStorage.getItem("cookiesSeen") === null) {
    return false;
  } else if (localStorage.getItem("cookiesSeen") === 'true') {
    return true;
  }
}

export const getHomeContent = () => {
  return axios({
    url: apiURL + `/api/Product/${shopLang}/getHomeContent`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  });
}

export const getCurrentUser = (token) => {
  return axios({
    url: apiURL + "/api/Customer/currentUser",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token,
    }
  });
}

export const getMyOrders = (userId, token) => {
  return axios({
    url: apiURL + `/api/MyRewards/${userId}/myrewards?pageSize=100`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}
