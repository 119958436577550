import React from 'react';
import parse from 'html-react-parser';

import { TabBox, TabIcon, TabTitle } from './Tabs.styles';

const TabItem = ({
  title,
  icon,
  isActive,
  activeIcon,
  tabIndex
}: {
  title: string;
  icon: string;
  activeIcon: string;
  isActive: boolean;
  tabIndex: number;
}) => {
  const isActiveIcon = activeIcon ? activeIcon : icon;

  return (
    <TabBox isActive={isActive} tabIndex={tabIndex}>
      <TabIcon>{icon && parse(isActive ? isActiveIcon : icon)}</TabIcon>

      <TabTitle isActive={isActive}>{title}</TabTitle>
    </TabBox>
  );
};

export default TabItem;
