import styled, { css } from 'styled-components';
import { BlueOval12 } from '../../../../assets/images';

export const CardBannerWrapper = styled.div`
  position: relative;
  z-index: 1;

  width: 100%;
`;

export const CardBannerContent = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    width: 100%;
    height: 100%;

    background: url(${BlueOval12}) center no-repeat;

    ${mediaQueries.minWidthSM} {
      background-size: cover;
    }
  `;
});
