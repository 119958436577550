import { prepr } from '../services/prepr';

import { getLocalePrepr } from '../../components/utils/_app-helpers';

let locale = getLocalePrepr();

const getLoginPageData = prepr
  .graphqlQuery(
    `{
      LoginPageData (locales: ["${locale}"]) {
        _id
        image_desktop {
          cdn_files {
            url(width: ${1400})
          }
          url
          width
          height
          name
        }
        image_mobile {
          cdn_files {
            url(width: ${1100})
          }
          url
          width
          height
          name
        }
      }
    }`
  )
  .fetch()
  .then((res: any) => res?.data?.LoginPageData)
  .catch((error: string) => error);

export default getLoginPageData;
