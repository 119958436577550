import React from 'react';
import { useSelector } from 'react-redux';

import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import {
  InputField,
  SelectAutocomplete,
} from '../Form';
import Button from '../Button';

import { checkLoggedIn } from '../../../../components/utils/_app-helpers';
import i18n from '../../../../config/i18n';
import { countriesList, errorsList } from '../Form/data';

import { FieldWrapper } from '../Form/Form.styles';
import { FormWrapper, LoaderForm } from './AccountDeletionForm.styles';

type Inputs = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  cardNumber: number;
};

const AccountDeletionForm = () => {
  const isLoggedIn = checkLoggedIn();
  const isCurrentUserDataLoaded = useSelector((state: any) => state?.isCurrentUserDataLoaded);
  const phoneNumebrLoaded = useSelector((state: any) => state?.phoneNumebrLoaded);
  const emailDefault = localStorage.getItem('userEmail');
  const firstNameDefault = useSelector((state: any) => state?.currentUser?.firstName);
  const lastNameDefault = useSelector((state: any) => state?.currentUser?.lastName);
  const cardNumberDefault = useSelector((state: any) => state?.currentUser?.masterCardNumber);
  const phoneNumberDefault = useSelector((state: any) => state?.currentUser?.linkedPhoneNumber);
  const phoneNumberDefault2 = useSelector((state: any) => state?.customerDetails?.phoneNumber);

  const methods = useForm<Inputs>({ mode: 'all' });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const mailtoHandler = (formData: Inputs | any) => {
    window.location.href = `mailto:${i18n.t('05_Contact_service_email')
    }?subject=account_deletion&body=${i18n.t('05_Login_email_field')}%3A%20${formData?.email
    }%0D%0A---%0D%0A${i18n.t('06-personal_data_first_name')}%3A%20${formData?.firstName
    }%0D%0A---%0D%0A${i18n.t('06-personal_data_last_name')}%3A%20${formData?.lastName
    }%0D%0A---%0D%0A${i18n.t('13_Contact-form_phonenumber')}%3A%20+${formData?.phoneNumber
    }%0D%0A---%0D%0A${i18n.t('13_Contact-form_card-number')}%3A%20${formData?.cardNumber
    }%0D%0A`;
  };

  const onSubmit: SubmitHandler<Inputs> = (formData) => {
    mailtoHandler(formData);
  };

  return (
    ((isLoggedIn && isCurrentUserDataLoaded && phoneNumebrLoaded) || !isLoggedIn) ?
      <FormProvider {...methods}>
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <FieldWrapper>
            <InputField
              register={{
                ...register('email', {
                  required: errorsList.email.required,
                  pattern: {
                    value:
                      /^([a-zA-Z0-9])(([a-zA-Z0-9])*([._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9-])+(\.))+([a-zA-Z]{2,4})+$/,
                    message: errorsList.email.required
                  }
                })
              }}
              type='email'
              placeholder={i18n.t('05_Login_email_field')}
              errorText={errors.email?.message}
              name='email'
              label={i18n.t('05_Login_email_field')}
              width={50}
              defaultValue={isLoggedIn ? (emailDefault || '') : ''}
              isError={!!errors.email}
              isRequired
            />
          </FieldWrapper>

          <FieldWrapper>
            <InputField
              register={{
                ...register('firstName', {
                  required: errorsList.firstName.required,
                  pattern: {
                    value:
                      /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
                    message: errorsList.firstName.required,
                  }
                })
              }}
              type='text'
              placeholder={i18n.t('06-personal_data_first_name')}
              errorText={errors.firstName?.message}
              name='firstName'
              label={i18n.t('06-personal_data_first_name')}
              width={50}
              defaultValue={isLoggedIn ? (firstNameDefault || '') : ''}
              isError={!!errors.firstName}
              isRequired
            />
          </FieldWrapper>

          <FieldWrapper>
            <InputField
              register={{
                ...register('lastName', {
                  required: errorsList.lastName.required,
                  pattern: {
                    value:
                      /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
                    message: errorsList.lastName.required,
                  }
                })
              }}
              type='text'
              placeholder={i18n.t('06-personal_data_last_name')}
              errorText={errors.lastName?.message}
              name='lastName'
              label={i18n.t('06-personal_data_last_name')}
              width={50}
              defaultValue={isLoggedIn ? (lastNameDefault || '') : ''}
              isError={!!errors.lastName}
              isRequired
            />
          </FieldWrapper>

          <FieldWrapper>
            <SelectAutocomplete
              width={50}
              label={`${i18n.t('13_Contact-form_phonenumber')}*`}
              name='phoneNumber'
              hasDefaultValue
              options={countriesList}
              errorText={errorsList.phoneNumber.required}
              defaultValue={isLoggedIn && phoneNumberDefault2 ? `+${phoneNumberDefault2.replace('+', '')}` :
                isLoggedIn && phoneNumberDefault ? `+${phoneNumberDefault.replace('+', '')}` : ''}
              isError={!!errors.phoneNumber}
              isRequired
            />
          </FieldWrapper>

          <FieldWrapper>
            <InputField
              register={{
                ...register('cardNumber', {
                  required: errorsList.cardNumber.required,
                  pattern: {
                    value: /^[0-9]*/,
                    message: errorsList.cardNumber.required
                  }
                })
              }}
              type='number'
              placeholder='7036'
              errorText={errors.cardNumber?.message}
              name='cardNumber'
              label={`${i18n.t('13_Contact-form_card-number')}*`}
              width={50}
              defaultValue={isLoggedIn ? (cardNumberDefault || '') : ''}
              isError={!!errors.cardNumber}
              isRequired
            />
          </FieldWrapper>

          <Button backgroundcolor={'#FE000C'}
                  disabled={!!(errors?.email || errors?.firstName|| errors?.lastName || errors?.phoneNumber || errors?.cardNumber)}
                  text={i18n.t('btn_delete_account')}
                  type='submit' />
        </FormWrapper>
      </FormProvider> :
      <LoaderForm />
  );
};

export default AccountDeletionForm;
