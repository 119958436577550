import {
  IconBEFlag,
  IconDEFlag,
  IconFRFlag,
  IconGBFlag,
  IconNLFlag,
  IconSPFlag
} from '../../../../../prepr/assets';

import { SelectOption } from '../SelectField/selectField.types';

export const countriesList: SelectOption[] = [
  {
    label: 'Nederland',
    code: 'NL',
    value: '+31(0)',
    image: `${IconNLFlag}`
  },
  {
    label: 'België',
    code: 'BE',
    value: '+32(0)',
    image: `${IconBEFlag}`
  },
  {
    label: 'Frankrijk',
    code: 'FR',
    value: '+33(0)',
    image: `${IconFRFlag}`
  },
  {
    label: 'Spanje',
    code: 'SP',
    value: '+34(0)',
    image: `${IconSPFlag}`
  },
  {
    label: 'Verenigd Koninkrijk',
    code: 'NL',
    value: '+44(0)',
    image: `${IconGBFlag}`
  },
  {
    label: 'Duitsland',
    code: 'DE',
    value: '+49(0)',
    image: `${IconDEFlag}`
  }
];
