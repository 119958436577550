import { BrandListItemProps } from '../UI/BrandList/brandList.types';
import {
  MobilBrandLogo,
  ExxonMobilBrandLogo,
  RoundBrandLogoGrey
} from '../../../../../assets/images';

export const brandList: BrandListItemProps[] = [
  {
    image: `${ExxonMobilBrandLogo}`,
    title: 'ExxonMobil',
    url_nl: 'https://corporate.exxonmobil.com/',
    url_be_nl: 'https://corporate.exxonmobil.com/',
    url_be_fr: 'https://corporate.exxonmobil.com/',
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    image: `${MobilBrandLogo}`,
    title: 'Mobil',
    url_nl: 'https://www.mobil.be/nl-be/',
    url_be_nl: 'https://www.mobil.be/nl-be/',
    url_be_fr: 'https://www.mobil.be/fr-be/',
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    image: `${RoundBrandLogoGrey}`,
    title: 'Esso',
    url_nl: 'https://www.esso.nl/nl-nl',
    url_be_nl: 'https://www.esso.be/nl-be/',
    url_be_fr: 'https://www.esso.be/fr-be/',
    target: '_blank',
    rel: 'noopener noreferrer'
  }
];
