import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import i18n from '../../../../config/i18n';
// @ts-ignore
import { Interpolate } from 'react-i18next';
import { reSendEmail } from '../../../../components/utils/_auth-helpers';
import { Button } from '../../shared';

import {
  WrapperShapes,
  ContainerShapes,
  Title,
  Text
} from '../Auth.styles';
import { EmailSentImg } from '../../../assets';

function EmailSent() {
  const customerDetailsEmail = useSelector((state: any) => state?.customerDetails?.email);
  const email = localStorage.getItem('userEmail') || customerDetailsEmail;

  const handleResend = (event: any) => {
    event.preventDefault();
    let email = localStorage.getItem('userEmail');
    if (!email) return;
    reSendEmail(email)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('userEmail');
    };
  }, []);

  return (
    <WrapperShapes>
      <ContainerShapes maxWidth={'435px'}>
        <SVG src={EmailSentImg} />
        <Title>{i18n.t('05_Confirm_email_page_title')}</Title>
        <Text>
          <Interpolate i18nKey='sent_email_text'
                       useDangerouslySetInnerHTML={true}
                       email={email} />
        </Text>
        <Button className='sentEmailBtn'
                onClick={handleResend}
                text={i18n.t('sent_email_button')} />
      </ContainerShapes>
    </WrapperShapes>
  );
}
export default EmailSent;