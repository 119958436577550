import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { shopLang } from '../../../../../../../config/config';
import { Button } from '../../../../../../../redesign/components/shared';
import i18n from '../../../../../../../config/i18n';
import {
  ButtonWrapper,
  Container,
  Content,
  HeaderImage,
  Text,
  Title,
  Wrapper
} from '../EYCHeader.styles';
import { HeaderBoxesNl1, HeaderBoxesFR1 } from '../../../assets/phase1';
import { HeaderBoxesNL2, HeaderBoxesFR2 } from '../../../assets/phase2';

const HeaderLoggedOut = () => {
  const location = useLocation();
  const locale = shopLang.slice(0, 2).toLowerCase();
  const phase = useSelector((state: any) => state?.infoEYC?.name);
  const headerImg = phase === `ey-2024-${locale}-blue` ? (
    shopLang === 'be_fr' ? HeaderBoxesFR1 : HeaderBoxesNl1
  ) : (
    shopLang === 'be_fr' ? HeaderBoxesFR2 : HeaderBoxesNL2
  );

  return (
    <Wrapper phase={phase === `ey-2024-${locale}-blue` ? 1 : 2}>
      <Container>
        <HeaderImage>
          <img src={headerImg} alt='boxes' />
        </HeaderImage>

        <Content>
          <div>
          { phase === `ey-2024-${locale}-blue` ?
                <>
                  <Title>{i18n.t('eyc_2024_1_header_title')}</Title>
                  <Text>{i18n.t('eyc_2024_1_header_text')}</Text>
                </> :
                <>
                  <Title>{i18n.t('eyc_2024_2_header_title')}</Title>
                  <Text>{i18n.t('eyc_2024_2_header_text')}</Text>
                </>
            }

            <ButtonWrapper>
              <Button backgroundcolor={'#F37427'}
                      text={i18n.t('eyc_2024_loggedOut_btn')}
                      url={{ pathname: '/auth/login', state: { prevPath: location.pathname } }}
              />
            </ButtonWrapper>
          </div>
        </Content>
      </Container>
    </Wrapper>
  )
};

export default HeaderLoggedOut;