import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Trans, translate } from 'react-i18next';
import { Safety } from '../../../assets/images/icons';
import { Button } from "../Forms";

import "../../../components/Buttons/BtnPrimary/btn-primary.scss";
import '../../../components/common/Modal.scss';

const WeakPassword = ({ email, isModalOpen, onCloseModal, onSendEmail, isButtonDisabled }) => {
  useEffect(() => {
    Modal.setAppElement(document.querySelector('main'));
  }, [])
  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Weak Password"
      shouldCloseOnEsc={true}
      onRequestClose={onCloseModal}
      className="info-modal weak-password-modal"
    >
      <button onClick={onCloseModal} className="close-modal-button"></button>
      <Safety className="modal-icon" />
      <h2><Trans i18nKey="05_Login_weak-password_title" /></h2>
      <p><Trans i18nKey="05_Login_weak-password_text1" /></p>
      <p><Trans i18nKey="05_Login_weak-password_text2" /></p>
      <div className="info-field">
        <strong><Trans i18nKey="05_Login_weak-password_email" /></strong>
        <div className="marked-value">{email}</div>
      </div>
      <Button
        type="button"
        disabled={isButtonDisabled}
        onClick={onSendEmail}
        className="btn-primary"
        text={<Trans i18nKey="05_Login_weak-password_button" />}
      />
    </Modal>
  )
}

export default translate('common')(WeakPassword);