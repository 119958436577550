import React from 'react';

import { Container, Wrapper } from 'redesign/theme/GlobalStyles';
import { Accordion, Headline } from '../../../redesign/components/shared';
import { AccordionData } from '../../../redesign/components/shared/Accordion/accordion.types';
import { URLProps } from '../../interfaces/Page';
import { BoxStyled } from "./FAQCollection.styles";

interface FAQCollectionProps {
  params?: {
    title: string;
    cta_label: string;
    url: string;
    custom_styles?: boolean;
    link: URLProps[];
    questions: AccordionData[];
    max_width?: string;
    background_color?: string;
  };
}

const FAQCollection: React.FC<FAQCollectionProps> = ({ params }) => {
  const baseUrl = window.location.origin;

  const pageURL = params?.link?.[0];

  const FAQCollectionContent = (
    <>
      <Headline
        title={params?.title}
        urlText={params?.cta_label}
        url={params?.url ? params?.url : `${baseUrl}/#${pageURL?._slug}`}
        nativeLink={!!pageURL?._slug || !!params?.url}
      >
        <Accordion items={params!.questions} />
      </Headline>
    </>
  )

  return (
    <Wrapper backgroundColor={params?.background_color || 'transparent'}>
      <Container
        maxWidth={
          params?.max_width === 'narrow'
            ? '850px'
            : params?.max_width === 'wide'
              ? '1250px' :
              params?.max_width?.replaceAll(' ', '') === 'full_screen' ?
                '100%' :
                '850px'
        }>
        {params?.custom_styles ?
          <BoxStyled>
            {FAQCollectionContent}
          </BoxStyled> :
          FAQCollectionContent
        }
      </Container>
    </Wrapper>
  );
};

export default FAQCollection;
