import React from 'react'

import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import call from '../../../img/modals/call.png'
import call2x from '../../../img/modals/call@2x.png'

import mail from '../../../img/modals/mail-icon.png'
import mail2x from '../../../img/modals/mail-icon@2x.png'

import DialogWindow from './_template';


export const ContactService = props => {
  return (
    <DialogWindow 
                isOpen={props.isOpen}
                handleCloseModal={props.handleCloseModal}
                callLink={props.callLink}
    >
        <h2><Trans i18nKey="05_Contact_service_modal_title"></Trans></h2>
        <p>
          <Trans i18nKey="05_Customer_service_modal_text"></Trans>
        </p>
        <div className="call-link">
            <img src={call} srcSet={`${call} 1x, ${call2x} 2x`} alt="" />
            <span><Trans i18nKey="05_Contact_service_modal_call_text"></Trans></span>
        </div>
        <hr />
        <div className="call-link">
            <Link to="/contact-form" target="_blank" className="send-email">
                <img src={mail} srcSet={`${mail} 1x, ${mail2x} 2x`} alt="" />
                <span><Trans i18nKey="05_Contact_service_modal_email_text"></Trans></span>
            </Link>
        </div>
    </DialogWindow>
  )
}
