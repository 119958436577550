import styled, { css } from 'styled-components';
import { CustomTheme } from '../../../../theme/types';

interface InputFieldParams {
  disabled?: boolean;
  theme: CustomTheme;
  isEyeIconActive?: boolean;
}

export function InputField({ disabled, isEyeIconActive, theme }: InputFieldParams) {
  const { size, color, font } = theme;

  return css`
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    background-color: ${color.white};
    border: 1px solid ${color.border};

    color: ${color.black};
    border-radius: ${size.px4};
    position: relative;
    letter-spacing: 0;

    line-height: ${size.px16};
    appearance: none;
    font-size: ${size.px16};
    font-family: ${font.family.sansSerif};

    display: block;
    padding: ${() => isEyeIconActive ? 
      `${size.px16} ${size.px48} ${size.px16} ${size.px12}` :
      `${size.px16} ${size.px12}`};

    outline: none;
    height: 48px;
    width: 100%;

    ${disabled &&
    css`
      background-color: ${color.cloudWhite};
      color: ${color.grey};
      border-color: ${color.chineseGray};

      cursor: not-allowed;
    `}

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &:focus-visible {
      background-color: ${color.lightBlue};
      box-shadow: 0 0 0px 1000px ${color.lightBlue} inset;
      border-color: ${color.steelBlue};
      border-width: 2px;
      caret-color: ${color.black};
      color: ${color.black};
      outline: none;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px ${color.white} inset;
      background-color: ${color.white};
      border: 1px solid ${color.border};
    }

    &.hasError {
      background-color: ${color.white};
      border-color: ${color.red};
      border-width: 1px;
    }

    @media (min-width: 767px) {
      &:hover {
        border-color: ${color.steelBlue};
        border-width: 2px;
        transition: background-color 0.3s, border-color 0.3s, color 0.3s;
      }
    }
  `;
}

export const InputPlaceholder = ({ theme }: InputFieldParams) => {
  const { size, color } = theme;

  return css`
    color: ${color.grey};
    line-height: ${size.px16};
    font-size: ${size.px16};
  `;
};

type FieldContainerAttributes = {
  width: number;
};

export const FieldContainer = styled.div(
  ({ width }: FieldContainerAttributes) => css`
    margin-bottom: 1rem;
    padding: 0 0.75rem;
    position: relative;

    width: ${width}%;
    display: block;
  `
);

export const FieldInput = styled.input<InputFieldParams>(
  ({ disabled, isEyeIconActive, theme }) => css`
    ${InputField({
      disabled: disabled,
      isEyeIconActive: isEyeIconActive,
      theme: theme
    })}

    ::placeholder {
      ${InputPlaceholder({ theme: theme })}
    }
  `
);

export const FieldError = styled.span(({ theme }) => {
  const { color, size, font } = theme;
  return css`
    font-size: ${size.px12};
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};

    margin-top: 2px;

    color: ${color.red};
    display: block;
    width: 100%;
  `;
});

export const ButtonEye = styled.button(() => {
  return css`
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 33px;
    right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
});