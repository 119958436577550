import React from 'react';
import { ModalTypes } from '../Modal/modal.types';

import i18n from '../../../../../config/i18n';
import Button from '../../Button';
import Modal from '../Modal/Modal';

import {
  ModalContent,
  ModalText
} from '../Modal/Modal.styles';
import { ButtonsGroupNewsLetter } from './NewsLetterModal.styles';

interface NewsLetterModalTypes extends ModalTypes {
  onSubscribe?: () => void;
}

const NewsLetterModal: React.FC<NewsLetterModalTypes> = ({...props }) => {
  return (
    <Modal {...props}>
      <ModalContent>
        <ModalText>{i18n.t('registration_news_letter_popup_text')}</ModalText>
      </ModalContent>
      <ButtonsGroupNewsLetter>
        <Button text={i18n.t('registration_news_letter_popup_btn_no')}
                buttontype={'secondary'}
                onClick={props.onClose} />
        <Button text={i18n.t('registration_news_letter_popup_btn_yes')}
                onClick={props.onSubscribe} />
      </ButtonsGroupNewsLetter>
    </Modal>
  );
};

export default NewsLetterModal;