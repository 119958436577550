import React, { useCallback, useEffect, useRef, useState } from "react";

import { getRefValue, useWindowSize } from '../../../hooks';
import { StepProps } from "../Step/step.types";

import {
  AccordionStepWrapper,
  AccordionStepTitle,
  AccordionStepNum,
  AccordionStepButton,
  AccordionItemContainer,
  AccordionItemContent, AccordionItemImg, AccordionItemText, AccordionItemContentInner
} from "./StepsAccordion.styles";

function StepVertical({
  data,
  isOpen,
  btnOnClick,
  tabIndex,
  num,
}: {
  data: StepProps;
  isOpen: boolean;
  btnOnClick: () => void;
  tabIndex: number;
  num: string;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const windowSize = useWindowSize();
  const img = data?.image?.[0];

  const handleSetHeight = useCallback(() => {
    if (isOpen) {
      const contentEl = getRefValue(contentRef);
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen])

  useEffect(() => {
    handleSetHeight()
  }, [handleSetHeight, windowSize]);

  return (
    <AccordionStepWrapper role="presentation"
                          tabIndex={tabIndex}
                          className={`${isOpen ? 'active' : ''}`}>
      <AccordionStepTitle onClick={btnOnClick}>
        <AccordionStepNum>{num}</AccordionStepNum>
        <AccordionStepButton>{data.headline}</AccordionStepButton>
      </AccordionStepTitle>
      <AccordionItemContainer className="accordion-item-container"
                              style={{ height }}>

        <AccordionItemContent ref={contentRef}>
          <AccordionItemContentInner>
            <AccordionItemText  dangerouslySetInnerHTML={{ __html: `${data.description}` }}/>
          </AccordionItemContentInner>
          <AccordionItemImg>
            <img src={img?.cdn_files?.[0]?.url || img?.url}
                 alt={img?.name}
                 width={img?.width}
                 height={img?.height}
                 loading='lazy'/>
          </AccordionItemImg>
        </AccordionItemContent>
      </AccordionItemContainer>
    </AccordionStepWrapper>
  );
}

export default StepVertical;
