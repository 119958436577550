import { CarouselTypes } from "./carousel.types";
import {
  Lamps,
  Headphones,
  Iron,
  CookingSandwich,
  ToolRobot,
  BeautyDevice,
  ToyRobot,
  SportRobot
} from "../../../../assets";
import i18n from "../../../../../config/i18n";

export const CategoryList: CarouselTypes[] = [
  {
    id_stage: 21,
    id_prod: 33,
    image: Lamps,
    title: i18n.t('hgc_title_1_category'),
    subTitle: i18n.t('hgc_subtitle_1_category'),
    cta: i18n.t('hgc_cta'),
  },
  {
    id_stage: 6,
    id_prod: 31,
    image: CookingSandwich,
    title: i18n.t('hgc_title_2_category'),
    subTitle: i18n.t('hgc_subtitle_2_category'),
    cta: i18n.t('hgc_cta'),
  },
  {
    id_stage: 15,
    id_prod: 29,
    image: SportRobot,
    title: i18n.t('hgc_title_3_category'),
    subTitle: i18n.t('hgc_subtitle_3_category'),
    cta: i18n.t('hgc_cta'),
  },
  {
    id_stage: 14,
    id_prod: 28,
    image: ToyRobot,
    title: i18n.t('hgc_title_4_category'),
    subTitle: i18n.t('hgc_subtitle_4_category'),
    cta: i18n.t('hgc_cta'),
  },
  {
    id_stage: 13,
    id_prod: 26,
    image: `${BeautyDevice}`,
    title: i18n.t('hgc_title_5_category'),
    subTitle: i18n.t('hgc_subtitle_5_category'),
    cta: i18n.t('hgc_cta'),
  },
  {
    id_stage: 10,
    id_prod: 30,
    image: ToolRobot,
    title: i18n.t('hgc_title_6_category'),
    subTitle: i18n.t('hgc_subtitle_6_category'),
    cta: i18n.t('hgc_cta'),
  },
  {
    id_stage: 17,
    id_prod: 27,
    image: Iron,
    title: i18n.t('hgc_title_7_category'),
    subTitle: i18n.t('hgc_subtitle_7_category'),
    cta: i18n.t('hgc_cta'),
  },
  {
    id_stage: 18,
    id_prod: 32,
    image: Headphones,
    title: i18n.t('hgc_title_8_category'),
    subTitle: i18n.t('hgc_subtitle_8_category'),
    cta: i18n.t('hgc_cta'),
  },
];
