import React from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { onOpenModal } from './_modals-helper';

import termsImg from '../../../img/modals/register-terms.svg'

Modal.setAppElement('#root');

export const TermsAlert = props => {
  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel="Terms Alert modal"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={props.handleCloseModal}
      onAfterOpen={onOpenModal}
      className="WhyModal"
    >
      <section className="terms-modal-content">
        <button onClick={(e) => props.handleCloseModal(e)} className="close-modal-button"></button>
        <img src={termsImg} alt="" />
        <h2><Trans i18nKey="05_privacy_popup_text_1"></Trans></h2>
        <p><Trans i18nKey="05_privacy_popup_text_2"></Trans></p>
        <p className="small-caps">
          <Trans i18nKey="05_privacy_popup_text_3"></Trans>
          <br /><Link to="/terms" target="_blank"><Trans i18nKey="05_privacy_popup_text_4"></Trans></Link>
        </p>
        <button onClick={(e) => props.handleCloseModal(e)} className="modal-ok-button"><Trans i18nKey="05_privacy_popup_text_button"></Trans></button>
      </section>
    </Modal>

  )
}
