import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18n from '../../../../../../config/i18n';
import { checkLoggedIn } from '../../../../../../components/utils/_app-helpers';
import { useHeaderLinkActive } from '../../../../../hooks';
import {
  ProfileWrapper,
  ProfileIcon,
  ProfileDetails,
  ProfileText,
  ProfileSubText,
} from './Profile.styles';

function Profile({to: url}: {to: string}){
  const location = useLocation();
  const checkIsUrlActive = useHeaderLinkActive();
  const isLoggedIn = checkLoggedIn();
  const winPoints = useSelector((state: any) => state?.winPointsEYC); //end Year Campaign
  const accountBalance = useSelector(
    (state: { currentUser: any }) => state?.currentUser?.accountBalance
  );
  const setSeparatorToBalance= () => {
    return parseInt(accountBalance + winPoints).toLocaleString().replace(',', '.');
  }

  return (
    <ProfileWrapper to={url}
                    isActive={() => checkIsUrlActive(url)}>
      <ProfileIcon isDeleteStatus={location?.pathname === '/delete-account'} />
      <ProfileDetails>
        {
          isLoggedIn ?
            <>
              <ProfileText>{setSeparatorToBalance()}</ProfileText>
              <ProfileSubText>{i18n.t('points')}</ProfileSubText>
            </> :
            <ProfileText>{i18n.t('05_Authorization_login_tab')}</ProfileText>
        }
      </ProfileDetails>
    </ProfileWrapper>
  )
}

export default Profile;
