import React, { useEffect, useRef, useState } from 'react';

import { getRefValue } from '../../../hooks';
import { AccordionData } from './accordion.types';
import {
  AccordionItemButton,
  AccordionItemContainer,
  AccordionItemContent,
  AccordionItemWrapper,
  AccordionTitle
} from './Accordion.styles';

function AccordionItem({
  data,
  isOpen,
  btnOnClick,
  tabIndex
}: {
  data: AccordionData;
  isOpen: boolean;
  btnOnClick: () => void;
  tabIndex: number;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const contentEl = getRefValue(contentRef);

      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (
      event.code === 'Enter' ||
      event.code === 'NumpadEnter' ||
      event.code === 'Space'
    ) {
      btnOnClick();
      event.preventDefault();
    }
  };

  return (
    <AccordionItemWrapper
      role="presentation"
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
    >
      <AccordionTitle>
        <AccordionItemButton
          className={`${isOpen ? 'active' : ''}`}
          onClick={btnOnClick}
        >
          {data.question}
        </AccordionItemButton>
      </AccordionTitle>
      <AccordionItemContainer
        className="accordion-item-container"
        style={{ height }}
      >
        <AccordionItemContent
          ref={contentRef}
          dangerouslySetInnerHTML={{ __html: `${data.answer}` }}
        />
      </AccordionItemContainer>
    </AccordionItemWrapper>
  );
}

export default AccordionItem;
