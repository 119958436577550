export const CHESTS =  Object.freeze([
  {
    id: 1,
    className: 'ChestBox1'
  },
  {
    id: 2,
    className: 'ChestBox2'
  },
  {
    id: 3,
    className: 'ChestBox3'
  }
])