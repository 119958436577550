import React from 'react';
import { Selector } from './inputSelector.types';

import {
  SelectorField,
  SelectorItem,
  SelectorWrapper,
  SelectorContent,
  SelectorIcon
} from './InputSelector.styles';

const InputSelector: React.FC<Selector> = ({
  register,
  disabled = false,
  isError = false,
  width = 100,
  type = 'radio',
  name,
  value,
  label,
  icon,
  activeIcon
}) => {
  return (
    <SelectorWrapper style={{ width: `${width}%` }}>
      <SelectorItem>
        <SelectorField
          id={name}
          className={isError ? 'hasError' : ''}
          {...register}
          disabled={disabled}
          type={type}
          value={value}
        />

        <SelectorContent>
          <SelectorIcon>
            <img src={icon} alt={label} />
            <img className="active-icon" src={activeIcon} alt={label} />
          </SelectorIcon>
          {label}
        </SelectorContent>
      </SelectorItem>
    </SelectorWrapper>
  );
};

export default InputSelector;
