import styled, { css } from 'styled-components';

export const ProductRow = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .col {
      width: 25%;
    }

    .swiper-container {
      padding-bottom: ${size.px40};

      ${mediaQueries.sm} {
        padding-bottom: 0;
      }
    }
  `;
});

export const ProductRowCol = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: calc(100% / 4 - 1%);
    margin: 0 0.5% 20px;

    ${mediaQueries.md} {
      width: 33%;
      margin: 0 0 20px;
    }

    ${mediaQueries.sm} {
      width: 50%;
      margin: 0 0 20px;
    }

    ${mediaQueries.se} {
      width: 100%;
    }
  `;
});
