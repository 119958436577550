import styled, { css } from 'styled-components';
import { ArticleBox } from "../../../redesign/components/shared/Article/Article.styles";

export const ArticleOverviewGrid = styled.div`
  margin: 0 -10px;
`;

export const ArticleOverviewRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const ArticleOverviewRowCol = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    width: calc(100% / 4 - 1%);
    margin: 0 0.5% 20px;

    ${mediaQueries.md} {
      width: calc(100% / 3 - 1%);
      margin: 0 0.5% 20px;
    }

    ${mediaQueries.sm} {
      width: 100%;
      border-bottom: 1px solid ${color['grey-200']};
    }
    
    ${ArticleBox}{
      background: transparent;
    }
  `;
});
