import styled, { css } from 'styled-components';

export const BrandListWrapper = styled.nav(({ theme }) => {
  const { size } = theme;

  return css`
    position: relative;
    width: 100%;

    display: flex;
    justify-content: start;
    align-content: center;
    gap: ${size.px32};
  `;
});

export const BrandListLink = styled.a`
  display: inline-block;
  padding: 0;
  text-decoration: none;
  transition: opacity 0.2s ease-in;
  cursor: pointer;

  &:hover {
    transition: opacity 0.2s ease-in;

    opacity: 0.75;
  }
`;
