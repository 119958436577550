import React from 'react';

import { bottomNavLinksList } from '../../data/bottomNavLinksList';
import { FooterRow } from '../../Footer.styles';

import {
  BottomNavWrapper,
  BottomNavContainer,
  Col,
  Copy
} from './BottomNavigation.styles';
import i18n from '../../../../../../config/i18n';
import { BrandList, NavLinks } from '../index';
import { shopLang } from "../../../../../../config/config";

function BottomNavigation() {
  const date = new Date();
  const year = date.getFullYear();

  let address: {[key: string] : string} = {
    nl_nl: `&copy; Copyright 2003-${year} ExxonMobil Corporation. Alle rechten voorbehouden. <br /> Esso Nederland BV, Postbus 5120, 3197 ZG Rotterdam`,
    be_nl:`&copy; Copyright Exxon Mobil Corporation. alle rechten voorbehouden. V.U.: Ann Veyfeyken. ExxonMobil Petroleum & Chemical BV. Polderdijkweg, 2030 Antwerpen.`,
    be_fr: `&copy; Copyright Exxon Mobil Corporation. Tous droits réservés. E.R.: Ann Veyfeyken. ExxonMobil Petroleum & Chemical BV, Polderdijkweg Anvers.`,
  }

  return (
    <BottomNavWrapper>
      <BottomNavContainer>
        <FooterRow>
          <BrandList />
          <Col>
            <NavLinks alignment="flex-end" links={bottomNavLinksList}>
              <button className="optanon-toggle-display footer-link">
                <span className='ot-sdk-show-settings'>{i18n.t('00_footer_cookies_settings')}</span>
              </button>
            </NavLinks>
            <Copy dangerouslySetInnerHTML={{__html: address[shopLang]}}/>
          </Col>
        </FooterRow>
      </BottomNavContainer>
    </BottomNavWrapper>
  );
}
export default BottomNavigation;
