import {checkLanguageDomain} from '../components/utils/_app-helpers';

const getApiUrl = () => {
	if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev') {
		// return 'http://localhost:9090/https://esso-backendapi-dev-acceptance.azurewebsites.net';
		return 'https://connect.dvex578765944512.nl';
	} else if (process.env.REACT_APP_ENV === 'stage') {
		// return 'https://esso-backendapi-dev-acceptance.azurewebsites.net';
		return 'https://connect.dvex578765944512.nl';
	} else if (process.env.REACT_APP_ENV === 'prod') {
		return 'https://esso-backend-api-prod.m-point.eu';
	}
};

export const envSettings = {
	handoverPath: () => {
		if (process.env.REACT_APP_ENV === 'dev') {
			return '/handover/?target_path=';
		} else {
			return '/shop/handover/?target_path=';
		}
	},
	baseDomain: () => {
		if (process.env.REACT_APP_ENV === 'stage') {
			return 'https://www.test-esso-extra.nl/';
		} else if (process.env.REACT_APP_ENV === 'prod') {
			return 'https://www.essoextras.nl/';
		}
	},
};

export const apiURL = getApiUrl();
checkLanguageDomain();

export const apiLang = localStorage.getItem('apiLang') || 'nl';
let i18lang = localStorage.getItem('i18nextLng') || 'nl';

let shopLangPreformatted;
let authLangPreformatted;
let loginCountryPreformatted;
switch (i18lang) {
	case 'vl':
		shopLangPreformatted = 'be_nl';
		authLangPreformatted = 'nl';
		loginCountryPreformatted = 'be';
		break;
	case 'fr':
		shopLangPreformatted = 'be_fr';
		authLangPreformatted = 'fr';
		loginCountryPreformatted = 'be';
		break;
	default:
		shopLangPreformatted = 'nl_nl';
		authLangPreformatted = 'nl';
		loginCountryPreformatted = 'nl';
}

export const shopLang = shopLangPreformatted;
export const authLang = authLangPreformatted;
export const loginCountry = loginCountryPreformatted;
