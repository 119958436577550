import { isWebView } from '../../../../components/utils/_app-helpers';

// const getAndroidVersion = () => {
//   const ua = window.navigator.userAgent.toLowerCase();
//   const match = ua.match(/android\s([0-9.]*)/i);
//   return match ? match[1] : undefined;
// }

const webView = isWebView();

const isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);

const isAndroid = /android/.test(window.navigator.userAgent.toLowerCase())


const checkAgent = () => {
  return (webView || isAndroid || isIOS);
  // return !!(webView || (androidVersion && +androidVersion >= 12));
}

export default checkAgent;