import React, { useRef, useState } from 'react';
import {
  VideoWrapper,
  VideoContainer,
  VideoBox
} from './testAnimation.styles';

import chestPoster from './assets/chestPoster.png';
// import transparentPoster from './assets/transparentFirstFrame.png'
import chest_mp4 from './assets/chest.mp4';
import chest_webm from './assets/chest.webm';
import points_1000_mp4 from './assets/points_1000.mp4';
import points_1000_webm from './assets/points_1000.webm';
import goldKey_mp4 from './assets/goldKey.mp4';
import goldKey_webm from './assets/goldKey.webm';

function TestAnimation() {
  const chestVideoRef = useRef<HTMLVideoElement>(null);
  const points_1000_VideoRef = useRef<HTMLVideoElement>(null);
  const goldKeyVideoRef = useRef<HTMLVideoElement>(null);
  const [activeVideo, setActiveVideo] = useState<number>(0);
  // const [isChestImageActive, setIsChestImageActive] = useState<boolean>(true);

  const handleClick = () => {
    setActiveVideo(1);
    chestVideoRef?.current?.play();

    // setTimeout(() => {
    //   setIsChestImageActive(false)
    // }, 150) // to hide the 'play' button
  }

  const onEndedVideo1 = () => {
    chestVideoRef?.current?.pause();
    setActiveVideo(2);
    points_1000_VideoRef.current?.play();
  }

  const onEndedVideo2 = () => {
    setActiveVideo(3);
    goldKeyVideoRef.current?.play();
  }

  const onEndedVideo3 = () => {
    setActiveVideo(2);
    points_1000_VideoRef.current?.play();
  }

  return (
    <VideoWrapper>
      <VideoContainer>
        <h1>Click on the chest to open</h1>

        <VideoBox>
          <img src={chestPoster} alt='chest'
               onClick={handleClick}
               style={activeVideo === 0 ? { display: 'block' } : { display: 'none' }} />
               {/*// style={isChestImageActive ? { display: 'block' } : { display: 'none' }} />*/}

          {/*First Video*/}
          <video ref={chestVideoRef}
                 // poster={chestPoster} if poster is added in IOS app the video is opened in separate screen
                 muted
                 playsInline
                 style={activeVideo !== 0 ? { display: 'block' } : { display: 'none' }}
                 onEnded={onEndedVideo1}>
            <source src={chest_mp4} type='video/mp4' />
            <source src={chest_webm} type='video/webm' />
            Your browser does not support the video tag.
          </video>

          {/*Second Video*/}
          <video ref={points_1000_VideoRef}
                 // poster={transparentPoster}
                 muted
                 playsInline
                 style={activeVideo === 2 ? { display: 'block' } : { display: 'none' }}
                 onEnded={onEndedVideo2}>
            <source src={points_1000_mp4} type='video/mp4' />
            <source src={points_1000_webm} type='video/webm' />
            Your browser does not support the video tag.
          </video>

          {/*Third Video*/}
          <video ref={goldKeyVideoRef}
                 // poster={transparentPoster}
                 muted
                 playsInline
                 style={activeVideo === 3 ? { display: 'block' } : { display: 'none' }}
                 onEnded={onEndedVideo3}>
            <source src={goldKey_mp4} type='video/mp4' />
            <source src={goldKey_webm} type='video/webm' />
            Your browser does not support the video tag.
          </video>
        </VideoBox>
      </VideoContainer>
    </VideoWrapper>
  );
}

export default TestAnimation;