import styled, { css } from 'styled-components';
import {OverlayBase, TextBase, TitleBase, WrapperBase, Container} from "../ChestGame.styles";

export const Wrapper = styled(WrapperBase)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    //background: radial-gradient(circle at center, rgba(218,211,190,1), rgba(207,193,152,1));
    background: radial-gradient(circle at center, rgba(207,198,172,254), rgba(175,163,129,254));
    
    ${mediaQueries.sm} {
    }
  `;
});

export const Container2 = styled(Container)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.sm} {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  `;
});

export const Overlay = styled(OverlayBase)(() => {
  return css`
    background: radial-gradient(circle at center, rgba(207,198,172,254), rgba(175,163,129,254));
  `;
});

export const Description = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    max-width: 540px;
    margin: 0 auto;

    ${mediaQueries.md} {
      max-width: 400px;
    }

    ${mediaQueries.sm} {
    }
  `;
});

export const Title = styled(TitleBase)(({ theme }) => {
  const { size, color, mediaQueries } = theme;

  return css`
    color: ${color.navy};
    font-size: ${size.px48};

    ${mediaQueries.md} {
      font-size: ${size.px32};
    }

    ${mediaQueries.sm} {
      font-size: ${size.px28};
    }
  `;
});

export const Text = styled(TextBase)(({ theme }) => {
  const { color } = theme;

  return css`
    color: ${color.navy};
  `;
});