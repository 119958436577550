import { createSelector } from 'reselect';
import { shopLang } from "../../../../config/config";

export const getAccessToken = createSelector(
  [(state) => state.authDataEYC],
  (authDataEYC) => authDataEYC.accessToken
);

export const getRefreshToken = createSelector(
  [(state) => state.authDataEYC],
  (authDataEYC) => authDataEYC.refreshToken
);

export const getTransactions = createSelector(
  [(state) => state.userDataEYC],
  (userDataEYC) => userDataEYC?.campaignGameStats[0]?.lotteryTicketCount
);

// for blue and gold keys
export const getTokens = createSelector(
  [(state) => state.userDataEYC],
  (userDataEYC) => {
    const locale = shopLang.slice(0, 2).toLowerCase();
    let tokens = {};

    userDataEYC && userDataEYC.campaignGameStats?.length && userDataEYC.campaignGameStats.forEach((game) => {
      if(game?.campaignGameSlug === `ey-2024-${locale}-blue`){
        tokens.blue = game?.lotteryTicketCount || 0;
      }
      if(game?.campaignGameSlug === `ey-2024-${locale}-gold`){
        tokens.gold = game?.lotteryTicketCount || 0;
      }
    })

    return tokens;
  }
);