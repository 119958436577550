import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { FieldWrapper, FormWrapperBase } from '../../shared/Form/Form.styles';
import { FieldContainer } from '../../shared/Form/InputField/InputField.styles';

export const LoginWrapper = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    background-color: ${color.white};
    min-height: 100vh;
    display: flex;

    ${mediaQueries.md} {
      flex-direction: column;
    }
  `;
});

export const LoginContentWrapper = styled.div(({theme}) => {
  const { mediaQueries } = theme;

  return css`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaQueries.md} {
      width: 100%;
      order: 2;
    }
  `;
});

export const LoginContentContainer = styled.div(() => {
  return css`
    width: 100%;
    max-width: calc(485px + 2rem);
    padding: 2rem 1rem;
    
    .btnLogin{
      width: 100%;
      margin: 20px 0;
    }
  `;
});

export const LoginTitle = styled.h2(({theme}) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px32};
    line-height: 1.2;
    font-weight: ${font.weight.semiBold};
    color: ${color.darkCharcoal};
    margin-bottom: 30px;

    ${mediaQueries.md} {
      font-size: ${size.px24};
    }
  `;
});

export const LoginInfoWrapper = styled.div(() => {
  return css`
  `;
});
export const LoginInfo = styled.span(({theme}) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px16};
    line-height: 1.2;
    font-weight: ${font.weight.regular};
    color: ${color.darkCharcoal};
  `;
});

const setCustomStyles = (theme: any) => {
  return css `
    font-family: ${theme.font.family.EMPrintRegular};
    font-size: ${theme.size.px14};
    line-height: 1.2;
    color: ${theme.color.blue};
  `
}

const setPositionStyles = () => {
  return css `
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
  `
}

export const LoginLink = styled(Link)(({theme}) => {
  return css`
    ${setCustomStyles(theme)};
    font-weight: ${theme.font.weight.semiBold};
  `;
});

export const FieldLink = styled(LoginLink)(({theme}) => {
  const { font } = theme;

  return css`
    ${setPositionStyles()};
    font-weight: ${font.weight.regular};
  `;
});

export const FieldButton = styled.button(({theme}) => {
  const { font } = theme;

  return css`
    ${setCustomStyles(theme)};
    ${setPositionStyles()};
    font-weight: ${font.weight.regular};
    background: transparent;
  `;
});

export const FormWrapper = styled(FormWrapperBase)(() => {
  return css`
  `;
});

export const FieldWrapperLogin = styled(FieldWrapper)(() => {
  return css`
    ${FieldContainer}{
      padding: 0;
    }
  `;
});

export const LoginImageWrapper = styled.div(({theme}) => {
  const { mediaQueries } = theme;

  return css`
    width: 50%;
    
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    ${mediaQueries.md} {
      width: 100%;
      order: 1;
    }
  `;
});