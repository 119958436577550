import i18n from '../config/i18n';

import {
  ADD_USER_DATA,
  ADD_CMS_DATA,
  ADD_POINTS_HISTORY,
  ADD_CARDS_DATA,
  ADD_ORDER_HISTORY,
  ADD_TRANSACTION_TYPES,
  ADD_ORDER_CAT,
  ADD_CUSTOMER_DETAILS,
  ADD_WEBVIEW,
  ADD_NOTICE,
  SET_POINTS_TRANSFER,
  HISTORY_LOADED,
  PHONE_NUMBER_LOADED,
  IS_CURRENT_USER_DATA_LOADED,
  IS_CURRENT_USER_ACTIVE,
} from './actions';

import {
  ADD_AUTH_DATA_SC,
  GET_CAMPAIGN_INFO_SC,
  ADD_USER_DATA_SC,
  SET_TRANSACTIONS_SC,
  SET_PARTICIPANT_DATA_SC,
  SET_CURRENT_PRIZE_DATA_SC
} from './actions_sc';

import {
  GET_INFO_CAMPAIGN,
  ADD_AUTH_DATA_CAMPAIGN,
  ADD_USER_DATA_CAMPAIGN,
  SET_TRANSACTIONS_CAMPAIGN,
  SET_PARTICIPANT_CAMPAIGN,
  SET_CURRENT_PRIZE_CAMPAIGN,
  SET_IS_SHOW_MODAL_CAMPAIGN,
  SET_IS_MODAL_ANSWER_CORRECT_CAMPAIGN,
  SET_IS_GAME_LOADER_ACTIVE_CAMPAIGN,
  SET_ACTIVE_GAME_STATE_CAMPAIGN,
  SET_DEFAULT_ANIMATION_CAMPAIGN,
  SET_IS_PLAY_BTN_DISABLED_CAMPAIGN,
  SET_IS_USER_WON_CAMPAIGN,
  SET_IS_CAMPAIGN_COMPLETED,
  SET_IS_DEFINE_WINNER_CAMPAIGN,
  SET_IS_REPLAY_GAME_CAMPAIGN,
  SET_RESULT_GAME_FOR_ANIMATION,
  SET_IS_LABEL_CLOSED,
  SET_IS_CONFETTI_SHOWED,
  SET_IS_FORM_SHOWED,
  SET_ACTIVE_BALLOON,
  SET_ANIMATED_BALLOON,
  SET_IS_FORM_BTN_DISABLED,
} from "./actions_campaign";

import {
  GET_INFO_EYC,
  ADD_AUTH_DATA_EYC,
  ADD_USER_DATA_EYC,
  SET_TRANSACTIONS_EYC,
  SET_PARTICIPANT_EYC,
  SET_CURRENT_PRIZE_EYC,
  SET_IS_SHOW_MODAL_EYC,
  SET_IS_MODAL_ANSWER_CORRECT_EYC,
  SET_IS_USER_WON_EYC,
  SET_IS_REPLAY_GAME_EYC,
  SET_ACTIVE_ELEMENT_EYC,
  SET_IS_ELEMENT_ANIMATED_EYC,
  SET_IS_GAME_RESULT_ANIMATED_EYC,
  SET_IS_DEFINE_WINNER_COMPLETED_EYC,
  SET_WIN_POINTS_EYC,
} from "./actions_eyc";

const initialState = {
  webView: false,
  dataLoaded: false,
  CMSData: null,
  phoneNumebrLoaded: false,
  currentUser: {
    accountID: 0,
    accountBalance: 0,
    firstName: '...',
    lastName: '...',
    masterCardNumber: '000000000000000',
    linkedPhoneNumber: '',
    deleteAccount: false
  },
  isCurrentUserActive: false,
  pointsTransfers: [],
  pointsHistory: {
    default: true,
    count: 0,
    totalPages: 1,
    pageNumber: 1,
    pageSize: 8,
    records: [
      {
        id: 0,
        trxNumber: 0,
        timeStampy: i18n.t('06-empty_page_Point_history_text_1'),
        cardNumber: i18n.t('06-empty_page_Point_history_text_2'),
        cardName: i18n.t('06-empty_page_Point_history_text_2'),
        pointsIssued: '+0',
        primaryType: i18n.t('06-transactions_list_text_5'),
        description: i18n.t('06-empty_page_Point_history_text_2')
      }
    ]
  },
  cardsData: {
    default: true,
    cards: [
      {
        Id: 0,
        CardNumber: '0000000000000000000',
        CardName: 'Loading...',
        IsRedeemable: true,
        IsMasterCard: true,
        IsPhoneNumber: false,
        IsFaulty: false,
        IsStolen: false,
        IsRemoved: false
      }
    ]
  },
  ordersHistory: {
    default: true,
    myOrders: [
      {
        orderId: 1,
        itemId: 1,
        name: i18n.t('06-empty_page_My_order_text_2'),
        imageUrl: 'string',
        purchaseDate: 0,
        points: 0,
        description: 'string',
        primaryType: '',
        categoryId: 'Categorie',
        validUntil: 'string'
      }
    ]
  },
  transactionTypes: {
    default: true,
    types: [
      {
        id: 0,
        description: 'Unknown'
      }
    ]
  },
  orderCategories: {
    default: true,
    categories: []
  },
  customerDetails: {
    default: true,
    payload: {
      gender: 0,
      address1: 'string',
      address2: 'string',
      postCode: 'string',
      country: 'string',
      phoneNumber: 'string',
      birthday: 0,
      thirdPartyUse: true,
      securityQuestion: 'string',
      language: 'string',
      email: 'string',
      emailConfirmed: true,
      smsMarketing: true,
      analyticsUse: true,
      communicationsUse: true,
      usePhonenumber: true,
      prefix: 'string',
      firstName: 'string',
      lastName: 'string'
    }
  },
  noticeBody: {
    text: '',
    type: 'notice'
  },
  isCurrentUserDataLoaded: false,

	// SUMMER CAMPAIGN + END YEAR CAMPAIGN 2022
	campaignInfo: null,
	authData:{
		accessExpiresAt: null,
		accessToken: null,
		refreshExpiresAt: null,
		refreshToken: null
	},
	userData: null,
	transactions: null, //!!!!!!!!!!!!! E-Bike
	participantData: null, //!!!!!!!!!!!!! E-Bike
	currentPrizeData: null, //!!!!!!!!!!!!! E-Bike
	// END SUMMER CAMPAIGN + END YEAR CAMPAIGN 2022

	// END YEAR CAMPAIGN 2022 + END YEAR CAMPAIGN 2022
	isUserWonCampaign: false, //!!!!!!!!!!!!! E-Bike
	isPlayBtnDisabledCampaign: false, //!!!!!!!!!!!!! E-Bike
	defaultAnimationCampaign: null,
	activeGameStateCampaign: null, //  E-Bike - animated balloon
	isGameLoaderActiveCampaign: false,
	isCampaignCompleted: null,
	isShowModalCampaign: false,  //eBike
	isModalAnswerCorrectCampaign: false,  //eBike
	isDefineWinnerActive: false,
	isReplayGame: false, //!!!!!!!!!!!!! E-Bike
	isLabelClosed: false,
	resultGameForAnimation: 'lose', //'lose' || 'win'
	isConfettiShowed: false,  //eBike
	// END YEAR CAMPAIGN 2022

  //eBike
  isFormShowed: false,
  isFormBtnDisabled: false,
  activeBalloon: 0,
  animatedBalloon: 0,
  // end eBike

  // end year campaign 2023
  infoEYC: null, //campaignInfo
  authDataEYC:{
    accessExpiresAt: null,
    accessToken: null,
    refreshExpiresAt: null,
    refreshToken: null
  },
  userDataEYC: null,
  transactionsEYC: null,
  participantDataEYC: null,
  currentPrizeDataEYC: null,
  isUserWonEYC: false,
  isShowModalEYC: false,
  isModalAnswerCorrectEYC: false,
  isReplayGameEYC: false,

  activeElementEYC: 0,
  isElementAnimatedEYC: false,
  isGameResultAnimatedEYC: false,
  isDefineWinnerCompletedEYC: false,
  winPointsEYC: 0,
}

function appReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER_DATA:
      return Object.assign({}, state, {
        currentUser: action.data
      });

    case IS_CURRENT_USER_ACTIVE:
      return Object.assign({}, state, {
        isCurrentUserActive: action.data
      });

    case ADD_CMS_DATA:
      return Object.assign({}, state, {
        CMSData: action.data
      });
    case ADD_POINTS_HISTORY:
      return Object.assign({}, state, {
        pointsHistory: action.data
      });
    case ADD_CARDS_DATA:
      return Object.assign({}, state, {
        cardsData: action.data
      });
    case ADD_ORDER_HISTORY:
      return Object.assign({}, state, {
        ordersHistory: action.data
      });
    case ADD_TRANSACTION_TYPES:
      return Object.assign({}, state, {
        transactionTypes: action.data
      });
    case HISTORY_LOADED:
      return Object.assign({}, state, {
        dataLoaded: action.data
      });
    case PHONE_NUMBER_LOADED:
      return Object.assign({}, state, {
        phoneNumebrLoaded: action.data
      });
    case ADD_ORDER_CAT: {
      return Object.assign({}, state, {
        orderCategories: action.data
      });
    }
    case ADD_CUSTOMER_DETAILS: {
      return Object.assign({}, state, {
        customerDetails: action.data
      });
    }
    case ADD_WEBVIEW: {
      return Object.assign({}, state, {
        webView: action.data
      });
    }
    case ADD_NOTICE: {
      return Object.assign({
        noticeBody: action.data
      });
    }
    case SET_POINTS_TRANSFER: {
      return Object.assign({}, state, {
        pointsTransfers: action.data
      });
    }
    case IS_CURRENT_USER_DATA_LOADED: {
      return Object.assign({}, state, {
        isCurrentUserDataLoaded: action.data
      });
    }

// SUMMER CAMPAIGN + END YEAR CAMPAIGN 2022
		case ADD_AUTH_DATA_CAMPAIGN:
		case ADD_AUTH_DATA_SC: {
			return Object.assign({}, state, {
				authData: action.data
			});
		}
		case GET_INFO_CAMPAIGN:
		case GET_CAMPAIGN_INFO_SC: {
			return Object.assign({}, state, {
				campaignInfo: action.data
			});
		}
		case ADD_USER_DATA_CAMPAIGN:
		case ADD_USER_DATA_SC: {
			return Object.assign({}, state, {
				userData: action.data
			});
		}
		case SET_TRANSACTIONS_CAMPAIGN:
		case SET_TRANSACTIONS_SC: {
			return Object.assign({}, state, {
				transactions: action.data
			});
		}
		case SET_PARTICIPANT_CAMPAIGN:
		case SET_PARTICIPANT_DATA_SC: {
			return Object.assign({}, state, {
				participantData: action.data
			});
		}
		case SET_CURRENT_PRIZE_CAMPAIGN:
		case SET_CURRENT_PRIZE_DATA_SC: {
			return Object.assign({}, state, {
				currentPrizeData: action.data
			});
		}
// END SUMMER CAMPAIGN + END YEAR CAMPAIGN 2022

// END YEAR CAMPAIGN 2022
		case SET_IS_USER_WON_CAMPAIGN: {
			return Object.assign({}, state, {
				isUserWonCampaign: action.data
			});
		}
		case SET_IS_PLAY_BTN_DISABLED_CAMPAIGN: {
			return Object.assign({}, state, {
				isPlayBtnDisabledCampaign: action.data
			});
		}
		case SET_DEFAULT_ANIMATION_CAMPAIGN: {
			return Object.assign({}, state, {
				defaultAnimationCampaign: action.data
			});
		}
		case SET_ACTIVE_GAME_STATE_CAMPAIGN: {
			return Object.assign({}, state, {
				activeGameStateCampaign: action.data
			});
		}
		case SET_IS_GAME_LOADER_ACTIVE_CAMPAIGN: {
			return Object.assign({}, state, {
				isGameLoaderActiveCampaign: action.data
			});
		}
		case SET_IS_CAMPAIGN_COMPLETED: {
			return Object.assign({}, state, {
				isCampaignCompleted: action.data
			});
		}
		case SET_IS_SHOW_MODAL_CAMPAIGN: {
			return Object.assign({}, state, {
				isShowModalCampaign: action.data
			});
		}
		case SET_IS_MODAL_ANSWER_CORRECT_CAMPAIGN: {
			return Object.assign({}, state, {
				isModalAnswerCorrectCampaign: action.data
			});
		}
		case SET_IS_DEFINE_WINNER_CAMPAIGN: {
			return Object.assign({}, state, {
				isDefineWinnerActive: action.data
			});
		}
		case SET_IS_REPLAY_GAME_CAMPAIGN: {
			return Object.assign({}, state, {
				isReplayGame: action.data
			});
		}
		case SET_IS_LABEL_CLOSED: {
			return Object.assign({}, state, {
				isLabelClosed: action.data
			});
		}
		case SET_RESULT_GAME_FOR_ANIMATION: {
			return Object.assign({}, state, {
				resultGameForAnimation: action.data
			});
		}
		case SET_IS_CONFETTI_SHOWED: {
			return Object.assign({}, state, {
				isConfettiShowed: action.data
			});
		}
// END YEAR CAMPAIGN 2022

// E-bike CAMPAIGN 2023
    case SET_IS_FORM_SHOWED: {
      return Object.assign({}, state, {
        isFormShowed: action.data
      });
    }

    case SET_IS_FORM_BTN_DISABLED: {
      return Object.assign({}, state, {
        isFormBtnDisabled: action.data
      });
    }

    case SET_ACTIVE_BALLOON: {
      return Object.assign({}, state, {
        activeBalloon: action.data
      });
    }

    case SET_ANIMATED_BALLOON: {
      return Object.assign({}, state, {
        animatedBalloon: action.data
      });
    }

    /// End Year Campaign
    case GET_INFO_EYC: {
      return Object.assign({}, state, {
        infoEYC: action.data
      });
    }
    case ADD_AUTH_DATA_EYC: {
      return Object.assign({}, state, {
        authDataEYC: action.data
      });
    }
    case ADD_USER_DATA_EYC: {
      return Object.assign({}, state, {
        userDataEYC: action.data
      });
    }
    case SET_TRANSACTIONS_EYC: {
      return Object.assign({}, state, {
        transactionsEYC: action.data
      });
    }
    case SET_PARTICIPANT_EYC: {
      return Object.assign({}, state, {
        participantDataEYC: action.data
      });
    }
    case SET_CURRENT_PRIZE_EYC: {
      return Object.assign({}, state, {
        currentPrizeDataEYC: action.data
      });
    }

    case SET_IS_USER_WON_EYC: {
      return Object.assign({}, state, {
        isUserWonEYC: action.data
      });
    }

    case SET_IS_SHOW_MODAL_EYC: {
      return Object.assign({}, state, {
        isShowModalEYC: action.data
      });
    }
    case SET_IS_MODAL_ANSWER_CORRECT_EYC: {
      return Object.assign({}, state, {
        isModalAnswerCorrectEYC: action.data
      });
    }
    case SET_IS_REPLAY_GAME_EYC: {
      return Object.assign({}, state, {
        isReplayGameEYC: action.data
      });
    }
    case SET_ACTIVE_ELEMENT_EYC: {
      return Object.assign({}, state, {
        activeElementEYC: action.data
      });
    }
    case SET_IS_ELEMENT_ANIMATED_EYC: {
      return Object.assign({}, state, {
        isElementAnimatedEYC: action.data
      });
    }
    case SET_IS_GAME_RESULT_ANIMATED_EYC: {
      return Object.assign({}, state, {
        isGameResultAnimatedEYC: action.data
      });
    }
    case SET_IS_DEFINE_WINNER_COMPLETED_EYC: {
      return Object.assign({}, state, {
        isDefineWinnerCompletedEYC: action.data
      });
    }
    case SET_WIN_POINTS_EYC: {
      return Object.assign({}, state, {
        winPointsEYC: action.data
      });
    }

    default:
			return state;
	}
}

export default appReducer;
