import axios from "axios";
import clearLocaleStorage from "./clearLocaleStorage";

function checkAccessToken() {
  const dateNow = Date.now();
  const accessExpiresAt = localStorage.getItem("accessExpiresAt");
  const authorized = localStorage.getItem("authorized");
  if (authorized && accessExpiresAt && (dateNow >= +accessExpiresAt)) {
    clearLocaleStorage();
    window.location.reload();
  }
}

axios.interceptors.request.use(
  async (config) => {
    checkAccessToken();
    return config;
  });
