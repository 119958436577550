export const colourStyles = {
  control: (styles: any, { isFocused }: any) => ({
    ...styles,
    backgroundColor: isFocused ? '#F2F5FA' : '#fff',
    borderWidth: isFocused ? '2px' : '1px',
    borderColor: isFocused ? '#B2C1E0' : '#CCCCCC',
    borderRadius: ' 4px',
    boxShadow: 'none',
    height: '48px',
    opacity: 1,
    '&:hover': {
      borderWidth: '2px',
      borderColor: '#B2C1E0'
    },

    '.SelectField__placeholder': {
      opacity: 1
    },

    'input:-internal-autofill-selected, input:-webkit-autofill': {
      backgroundColor: 'transparent',
      boxShadow: `0 0 0px 1000px #fff  inset`
    },

    'input:-webkit-autofill:focus': {
      backgroundColor: 'transparent',
      boxShadow: `0 0 0px 1000px #F2F5FA  inset`
    },

    '&.CustomSelect__control--menu-is-open': {
      '.CustomSelect__dropdown-indicator': {
        transform: 'rotate(180deg)',
        paddingRight: '0',
        paddingLeft: '1rem',
        svg: {
          fill: '#003399',
          path: {
            fill: '#003399'
          }
        }
      }
    }
  }),
  input: (styles: any) => ({
    ...styles,
    color: '#000',
    opacity: 1,
    fontSize: '1rem',
    zIndex: 2,
    input: {
      opacity: '1 !important'
    }
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: '0 1rem',
    cursor: 'pointer'
  }),
  placeholder: (styles: any) => ({
    ...styles,
    opacity: 0
  }),
  menu: (styles: any) => ({
    ...styles,
    boxShadow: 'none',
    zIndex: 3
  }),
  menuList: (styles: any) => ({
    ...styles,
    padding: '0px',
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    zIndex: 3
  }),
  option: (styles: any, { isDisabled, isFocused }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#F2F5FA' : '#fff',
      color: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'default',
      transition: 'all .2s ease-out',
      padding: '.75rem 1rem',

      '&:active': {
        backgroundColor: '#002164',
        color: '#FFF',
        transition: 'all .2s ease-out',

        span: {
          color: '#FFF'
        }
      }
    };
  },
  dropdownIndicator: (styles: any) => ({
    ...styles,
    paddingRight: '1rem',
    svg: {
      width: '16px',
      fill: '#999999'
    }
  })
};
