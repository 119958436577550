import React from 'react';
import { ErrorsWrapper, ErrorsText } from './ErrorsList.styles';

interface ErrorsListProps {
  errorText: Array<string> | string | undefined;
  fieldScheme?: object;
}

const ErrorsList = ({
                      errorText,
                      fieldScheme
                    }: ErrorsListProps) => {
  return (
    <ErrorsWrapper>
      {
        fieldScheme && Object.values(fieldScheme).map((item, id) => {
          let matchedError = Array.isArray(errorText) &&
            errorText?.find((error) => item.errorText === error);

          let isChecked = (matchedError && Array.isArray(errorText)) || !Array.isArray(errorText);
          return (
            <ErrorsText
              key={id}
              className={isChecked ? '' : 'isChecked'}
            >{item.errorText}</ErrorsText>
          );
        })
      }
    </ErrorsWrapper>
  );
};

export default ErrorsList;
