import styled, { css } from 'styled-components';

export const AssetsWrapper = styled.div(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: ${size.px12};
    
    ${mediaQueries.sm} {
    }
  `;
});


export const AssetBox = styled.div(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    width: 49%;
    max-width: 588px;
    margin-bottom: ${size.px24};
    border-radius: ${size.px4};
    
    video, img{
      border-radius: ${size.px4};
      width: 100%;
      vertical-align: bottom;
    }

    ${mediaQueries.sm} {
      width: 100%;
      max-width: 100%;
    }
  `;
});

export const VideoBox = styled.div(() => {
  return css`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;

    video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  `;
});

export const VideoTitle = styled.h4(({ theme }) => {
  const { mediaQueries, size, font, color } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px20};
    font-weight: ${font.bold};
    line-height: 25px;
    letter-spacing: 0;
    color: ${color.darkCharcoal};
    padding: 27px 31px;

    ${mediaQueries.md} {
      padding: 20px;
    }

    ${mediaQueries.sm} {
      padding: 37px 23px;
    }
  `;
});

