import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';

import {
  CardBox,
  CardContent,
  CardImg,
  CardTitle,
  CardCost,
  CardContainerForm,
  StickerBox,
  StickerText
} from '../ProductCard.styles';
import parse from 'html-react-parser';

import { handleHandoverForm } from "../../../../utils";
import { handoverConstructor, linkConstructor } from '../../../../../components/utils/_app-helpers';

import { ProductCardManualProps } from '../productCard.types';

interface ArticleTitleProps {
  fontSize: number;
  lines: number;
  lineHeight: number;
}

const ProductCardManual: React.FC<ProductCardManualProps> = (props) => {
  const {
    detail_url,
    cost_label,
    image,
    name,
    points_cost,
    discount_percentage,
    onLogin
  } = props;
  let parsedCostString = cost_label.replace(/\\/g, '');

  const rewardLink = detail_url.replace(
    /^(http:\/\/|https:\/\/)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?/,
    ''
  );

  const replacePriceValue = (argument: any) => {
    return discount_percentage.replace(
      /(\d+)\d+%/,
      `<span>${argument?.[0]}</span>`
    );
  };

  let discountValue = discount_percentage.match(/(\d+)\d+%/);

  const wrappedDiscountValue = replacePriceValue(discountValue);

  let pointsValue = points_cost.match(/(\d+)\d+%/);

  const wrappedPointsValue = replacePriceValue(pointsValue);

  const authToken = localStorage.getItem('authToken');
  const authTokenParse = authToken && JSON.parse(authToken);
  const accessToken = authTokenParse && authTokenParse.accessToken;
  const formRef = useRef<HTMLFormElement>(null);

  const img = image?.[0];

  const [textLines, setTextLines] = useState<number>(0);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  let windowSize = useWindowSize();
  let articleTitleStyles: ArticleTitleProps = useMemo(() => {
    return {
      fontSize: 18,
      lines: 2,
      lineHeight: 1.3
    };
  }, []);

  let countLines = useCallback(() => {
    if (titleRef && titleRef.current) {
      let height = titleRef.current.clientHeight;
      height &&
      height >
        articleTitleStyles?.fontSize *
          articleTitleStyles?.lines *
          articleTitleStyles?.lineHeight
        ? setTextLines(1)
        : setTextLines(2);
    }
  }, [articleTitleStyles]);

  const productCardContent = (
    <>
      <CardImg minheight={208}>
        <img src={img?.cdn_files?.[0]?.url || img?.url} alt={name} />
        {discount_percentage !== null && discount_percentage !== undefined && (
          <StickerBox isDiscount={discount_percentage?.length > 0}>
            <StickerText>{parse(wrappedDiscountValue)}</StickerText>
          </StickerBox>
        )}

        {points_cost !== null && points_cost !== undefined && (
          <StickerBox isDiscount={discount_percentage?.length > 0}>
            <StickerText>{parse(wrappedPointsValue)}</StickerText>
          </StickerBox>
        )}
      </CardImg>
      <CardContent>
        <CardTitle ref={titleRef} typography={articleTitleStyles}>
          {name}
        </CardTitle>
        <CardCost lines={textLines}>
          {cost_label ? parse(parsedCostString) : cost_label}
        </CardCost>
      </CardContent>
    </>
  );

  useEffect(() => {
    countLines();
  }, [countLines, windowSize]);

  return onLogin ? (
    <CardContainerForm
      method="POST"
      action={handoverConstructor(rewardLink)}
      ref={formRef}
      onClick={() => handleHandoverForm(formRef, detail_url, false)}
    >
      <input type="text" name="auth_key" value={accessToken} hidden readOnly />
      {productCardContent}
    </CardContainerForm>
  ) : (
    <CardBox href={linkConstructor(detail_url)}>{productCardContent}</CardBox>
  );
};

export default ProductCardManual;
