import styled, { css } from 'styled-components';

export const CampaignCompletedWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgb(11,17,38, 0.9);
    
    ${mediaQueries.sm} {
    }
  `;
});

export const CampaignCompletedContent = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    ${mediaQueries.sm} {
    }
  `;
});

export const CampaignCompletedTitle = styled.h2(({ theme }) => {
  const {font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.regular};
    font-size: ${size.px26};
    line-height: 1.2;
    color: ${color.white};
    margin-bottom: 15px;

    ${mediaQueries.sm} {
    }
  `;
});

export const CampaignCompletedText = styled.p(({ theme }) => {
  const {font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    font-size: ${size.px16};
    line-height: 1.4;
    color: ${color.white};
    margin-bottom: 35px;

    ${mediaQueries.sm} {
    }
  `;
});