import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import i18n from '../../../../../../../config/i18n';
import { apiLang } from "../../../../../../../config/config";
import { useWindowSize } from "../../../../../../hooks";

import { DropDownItem, DropDownMenuWrapper, DropDownText } from "../DropDownMenu/DropDownMenu.styles";
import { BelgLangsWrapper, LangItem, LangSeparator, Separator } from "./BelgLangs.styles";
import { IconArrow, LangButton, LangButtonText, IconFlag } from "../LanguageSelector.styles";
import { IconArrowDownGrey, IconFlagBE } from "../../../../../../assets";

const BelgLangs = () => {
	const windowSize = useWindowSize();
	const [isBelgLangsActive, setIsBelgLangsActive] = useState(false);
	const [isBelgLangsMenuActive, setIsBelgLangsMenuActive] = useState(false);
	const currentSummerCampaignPath = i18n.t('21_EYCampaign_route_path');

	const toggleActive = () => {
		setIsBelgLangsMenuActive((prev) => !prev);
	};
	const clearActiveLangs= () => {
		document?.querySelector('.language-link-vl')?.classList.remove('active');
		document?.querySelector('.language-link-fr')?.classList.remove('active');
	}

	const setLanguageSelector = () => {
		clearActiveLangs();
		let currentLang = localStorage.getItem('i18nextLng');
		let selector = document.querySelector('.language-link-' + currentLang);
		selector?.classList.add('active');
	};

	const updateBelgLangs = () => {
		return apiLang === 'be' ?
			setIsBelgLangsActive(true) :
			setIsBelgLangsActive(false)
	}

	const pathLocalizationSummerCampaign = (currentSummerCampaignPath: string) => {
		let {href} = window.location;
		if (href.indexOf(currentSummerCampaignPath) > -1) {
			let localizedHash = href.replace(currentSummerCampaignPath, i18n.t('21_EYCampaign_route_path'));
			window.location.replace(localizedHash);
		}
	};

	const changeLanguage = (lang: string) => {
		// if (lang === 'cimode') {
		// 	return;
		// }

		clearActiveLangs();
		let selector = document.querySelector('.language-link-' + lang);
		selector?.classList.add('active');

		i18n.changeLanguage(lang, () => {
			/** localization for path for SummerCampaignPath **/
			pathLocalizationSummerCampaign(currentSummerCampaignPath);
		});

		// i18n.changeLanguage(lang);
		window.location.reload();
	};

	useEffect(() => {
		updateBelgLangs();
		setLanguageSelector();
	});

	return (
		<BelgLangsWrapper className={isBelgLangsActive ? 'active' : ''}>
			{windowSize <= 1024 ?
				<>
					<LangButton onClick={toggleActive}
											className={isBelgLangsMenuActive ? 'active' : ''}>
						<LangButtonText>
							<IconFlag>
								<img src={IconFlagBE} alt="Icon Flag BE" />
							</IconFlag>
							{i18n.t('locale_selector_button')}
						</LangButtonText>
						<IconArrow>
							<SVG src={IconArrowDownGrey} />
						</IconArrow>
					</LangButton>

					<DropDownMenuWrapper className={isBelgLangsMenuActive ? 'active' : ''}>
						<DropDownItem className='language-link-vl active'
													onClick={() => changeLanguage('vl')}>
							<DropDownText>
								NL
							</DropDownText>
						</DropDownItem>
						<DropDownItem className='language-link-fr'
													onClick={() => changeLanguage('fr')}>
							<DropDownText>
								FR
							</DropDownText>
						</DropDownItem>
					</DropDownMenuWrapper>
				</> :
				<>
					<Separator />
					<LangItem className='language-link-vl active'
										onClick={() => changeLanguage('vl')}>NL</LangItem>
					<LangSeparator>/</LangSeparator>
					<LangItem className='language-link-fr' onClick={() => changeLanguage('fr')}>FR</LangItem>
				</>
			}
		</BelgLangsWrapper>
	);
};

export default BelgLangs;
