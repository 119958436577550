import styled, { css } from 'styled-components';

export const VideoRow = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    display: flex;
    margin-bottom: ${size.px10};
    padding-top: ${size.px12};
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + ${size.px12}*2);
    margin: 0 -${size.px12};

    ${mediaQueries.md} {
      margin-bottom: ${size.px30};
    }

    ${mediaQueries.sm} {
      flex-direction: column;
      width: 100%;
      margin: 0;
    }

    > div {
      margin-bottom: ${size.px24};
      margin-left: ${size.px12};
      margin-right: ${size.px12};

      ${mediaQueries.sm} {
        margin-left: 0;
        margin-right: 0;
        
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  `;
});
