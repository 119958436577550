import styled, { css } from 'styled-components';

type NavListProps = {
  alignment?: string;
};

export const NavListWrapper = styled.ul<NavListProps>(
  ({ theme, alignment = 'left' }) => {
    const { mediaQueries, color, size } = theme;

    return css`
      color: ${color.chineseGray};

      position: relative;
      width: 100%;
      max-width: 316px;
      margin-left: 160px;

      display: flex;
      flex-wrap: wrap;
      justify-content: ${alignment};

      align-items: center;

      gap: ${size.px8};
      padding: 0 ${size.px8};

      ${mediaQueries.md} {
        margin-left: 0;
      }

      ${mediaQueries.sm} {
        justify-content: left;
        text-align: left;
        max-width: 100%;

        margin-top: ${size.px32};
        gap: ${size.px4} ${size.px8};
        padding: 0;
      }
    `;
  }
);

export const NavListItem = styled.li(({ theme }) => {
  const { mediaQueries, color, size, font } = theme;

  return css`
    color: ${color.chineseGray};

    position: relative;

    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-right: ${size.px12};
    line-height: 1;

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      top: ${size.px4};
      width: ${size.px4};
      height: ${size.px4};
      vertical-align: top;
      border-radius: 50%;
      margin: 0;
      background: ${color.chineseGray};
    }
    
    &:last-child{
      &:after{
        display: none;
      }
    }

    ${mediaQueries.sm} {
      text-align: left;
    }

    transition: opacity 0.2s ease-in;

    &:hover {
      transition: opacity 0.2s ease-in;

      opacity: 0.75;
    }

    button {
      color: ${color.chineseGray};
      font-family: ${font.family.sansSerif};
      font-size: ${size.px12};
      font-weight: ${font.weight.regular};
      background: none;
    }
  `;
});

export const NavListItemLink = styled.a(({ theme }) => {
  const { color, size, font } = theme;

  return css`
    display: block;

    color: ${color.chineseGray};
    font-family: ${font.family.sansSerif};
    font-size: ${size.px12};
    font-weight: ${font.weight.regular};

    cursor: pointer;
  `;
});
