import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { checkLoggedIn } from "../../../../components/utils/_app-helpers";
import { getCyclooData } from "../../../../components/utils/_campaign-helpers";

interface PageCyclooProps {
  showNotice: (text: string, type: string) => void;
}

function PageCycloo({showNotice} : PageCyclooProps) {
  const history = useHistory();
  const [url, setUrl] = useState<null | string>(null);
  const isLoggedIn = checkLoggedIn();
  const authToken = localStorage.getItem('authToken');
  const getToken = authToken && JSON.parse(authToken);
  const accessToken = getToken && getToken?.accessToken;
  const searchParams = history?.location?.search;

  const fetchCycloo = useCallback(async () => {
    if (isLoggedIn && accessToken && searchParams){
      try {
        localStorage.removeItem('campaignPopup');
        await getCyclooData(searchParams, accessToken);
        localStorage.setItem('campaignPopup', 'showed');
      } catch (err: any) {
        let error = err?.response?.data?.message || err?.message || 'Error in the Cycloo response';
        showNotice(error, 'error');
      }
      setUrl('cycloo-logged-in');
    }else{
      setUrl('cycloo-logged-out');
    }
  }, [isLoggedIn, searchParams, accessToken, showNotice]);

  useEffect( () => {
    fetchCycloo();
  }, [fetchCycloo]);

  useEffect(() => {
    if(url){
      // history.push(`/${url}${history?.location?.search}`);
      history.replace(`/${url}`);
    }
  }, [url, history]);
}

export default PageCycloo;