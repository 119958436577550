import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { TokensBar } from '../../index';
import Form from "../../Form";
import { Button } from '../../../../../../../../redesign/components/shared';
import i18n from '../../../../../../../../config/i18n';
import APIService from '../../../../../api_campaign/api';
import { getLocaleLink } from '../../../../../../../../components/utils/_app-helpers';
import { InfoLoggedInUserProps } from './infoLoggedInUser.types';
import { ButtonBox, Description, InfoWrapper, Title } from '../InfoBlock.styles';
import { Subtitle } from '../../../EBikeGame.styles';

const InfoLoggedInUser: React.FC<InfoLoggedInUserProps> = () => {
  const locale = useMemo(() => getLocaleLink(), []);
  const stationsLink = `https://www.esso.${locale}/stations`;
  const transactions = useSelector((state: any) => state?.transactions);
  const isReplayGame = useSelector((state: any) => state?.isReplayGame);
  const isUserWon = useSelector((state: any) => state?.isUserWonCampaign);
  const isFormShowed = useSelector((state: any) => state?.isFormShowed);

  const clearStatesToInitial = () => {
    APIService.setIsPlayBtnDisabled(false);
    APIService.setIsFormBtnDisabled(false);
    APIService.setIsUserWon(false);
    APIService.setIsReplayGame(false);
    APIService.setIsFormShowed(false);
    APIService.setIsConfettiShowed(false);
    APIService.setActiveBalloon(0);
    APIService.setAnimatedBalloon(0);
    APIService.setIsShowModal(false);
    APIService.setIsModalAnswerCorrect(false);
    APIService.setTransactions();

    console.log('clearStatesToInitial');
  }

  const btnToStations = (
    <Button
      text={i18n.t('eBike_btn_to_stations')}
      buttontype={'primary'}
      nativeLink
      url={stationsLink}
    />
  );

  const btnToClearStates = (
    <Button
      text={i18n.t('eBike_btn_play_again')}
      buttontype={'primary'}
      onClick={clearStatesToInitial}
    />
  )

  return (
    // loader ||
    <>
      {/*has Tokens + lost + not first play*/}
      <CSSTransition
        in={!!transactions && !isUserWon && isReplayGame}
        timeout={{
          appear: 2000,
          enter: 2000,
          exit: 0
        }}
        classNames='anim'
        unmountOnExit
      >
        <InfoWrapper>
          <Title>{i18n.t('eBike_title_hasTokens_lost_loggedIn')}</Title>
          <Subtitle>{i18n.t('eBike_subtitle_hasTokens_lost_loggedIn')}</Subtitle>
          <Description>{i18n.t('eBike_text_hasTokens_lost_loggedIn')}</Description>
          <ButtonBox>{btnToClearStates}</ButtonBox>
        </InfoWrapper>
      </CSSTransition>


      {/*no Tokens + lost + not first play*/}
      <CSSTransition
        in={!transactions && !isUserWon && isReplayGame}
        timeout={{
          appear: 2000,
          enter: 2000,
          exit: 0
        }}
        classNames='anim'
        unmountOnExit
      >
        <InfoWrapper>
          <Title>{i18n.t('eBike_title_noTokens_lost_loggedIn')}</Title>
          <Subtitle>{i18n.t('eBike_subtitle_noTokens_lost_loggedIn')}</Subtitle>
          <Description>{i18n.t('eBike_text_noTokens_lost_loggedIn')}</Description>
          <ButtonBox>{btnToStations}</ButtonBox>
        </InfoWrapper>
      </CSSTransition>

      {/*has Tokens + won + not first play + show form*/}
      <CSSTransition
        in={isUserWon && isReplayGame && isFormShowed}
        timeout={{
          appear: 2000,
          enter: 2000,
          exit: 0
        }}
        classNames='anim'
        unmountOnExit
      >
        <Form />
      </CSSTransition>


      {/*has Tokens + won + not first play + hide form */}
      <CSSTransition
        in={!!transactions && isUserWon && isReplayGame && !isFormShowed}
        timeout={{
          appear: 2000,
          enter: 2000,
          exit: 0,
        }}
        classNames='anim'
        unmountOnExit
      >
        <InfoWrapper>
          <Title>{i18n.t('eBike_title_hasTokens_won_loggedIn')}</Title>
          <Subtitle>{i18n.t('eBike_subtitle_hasTokens_won_loggedIn')}</Subtitle>
          <Description>{i18n.t('eBike_text_hasTokens_won_loggedIn')}</Description>
          <ButtonBox>{btnToClearStates}</ButtonBox>
        </InfoWrapper>
      </CSSTransition>

      {/*no Tokens + won + not first play + hide form */}
      <CSSTransition
        in={!transactions && isUserWon && isReplayGame && !isFormShowed}
        timeout={{
          appear: 2000,
          enter: 2000,
          exit: 0
        }}
        classNames='anim'
        unmountOnExit
      >
        <InfoWrapper>
          <Title>{i18n.t('eBike_title_noTokens_won_loggedIn')}</Title>
          <Subtitle>{i18n.t('eBike_subtitle_noTokens_won_loggedIn')}</Subtitle>
          <Description>{i18n.t('eBike_text_noTokens_won_loggedIn')}</Description>
          <ButtonBox>{btnToStations}</ButtonBox>
        </InfoWrapper>
      </CSSTransition>

      {/*has Tokens + First Play*/}
      <CSSTransition
        in={!!transactions && !isReplayGame}
        timeout={{
          appear: 2000,
          enter: 2000,
          exit: 0,
        }}
        classNames='anim'
        unmountOnExit
      >
        <InfoWrapper>
          <Title>{i18n.t('eBike_title_hasTokens_loggedIn')}</Title>
          <TokensBar />
          {transactions === 1 ?
            <Subtitle>{transactions} {i18n.t('eBike_subtitle_hasOneToken_loggedIn')}</Subtitle> :
            <Subtitle>{transactions} {i18n.t('eBike_subtitle_hasTokens_loggedIn')}</Subtitle>
          }
          <Description>{i18n.t('eBike_text_hasTokens_loggedIn')}</Description>
        </InfoWrapper>
      </CSSTransition>

      {/*no Tokens + First Play*/}
      <CSSTransition
        in={!transactions && !isReplayGame}
        timeout={{
          appear: 2000,
          enter: 2000,
          exit: 0,
        }}
        classNames='anim'
        unmountOnExit
      >
        <InfoWrapper>
          <Title>{i18n.t('eBike_title_noTokens_loggedIn')}</Title>
          <Subtitle>{i18n.t('eBike_subtitle_noTokens_loggedIn')}</Subtitle>
          <Description>{i18n.t('eBike_text_noTokens_loggedIn')}</Description>
          <ButtonBox>{btnToStations}</ButtonBox>
        </InfoWrapper>
      </CSSTransition>
    </>
  )
};

export default InfoLoggedInUser;