import styled, { css } from 'styled-components';
import { IconAlert, IconCheckGreen } from '../../../../../prepr/assets';

export const ErrorsWrapper = styled.ul(() => {
  return css`
    width: 100%;
    display: block;
    background: rgba(0, 51, 153, 0.05);
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0;
  `;
});

export const ErrorsText = styled.li(({ theme }) => {
  const { size } = theme;
  return css`
    position: relative;
    padding-left: 23px;
    font-size: ${size.px13};
    margin: 0;
    color: rgba(0, 51, 153, 0.3);
    
    &:before{
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 4px;
      top: 3px;
      background: url('${IconAlert}') no-repeat;
      background-size: contain;
    }
    
    &.isChecked{
      color: #90D222;
      
      &:before{
        background: url('${IconCheckGreen}') no-repeat;
      }
    }
  `;
});