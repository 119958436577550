import styled, { css } from 'styled-components';

export const FieldLabelWrapper = styled.label(({ theme }) => {
  const { color, size, font } = theme;
  return css`
    font-size: ${size.px14};
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.regular};

    color: ${color.black};

    margin-bottom: ${size.px10};
    position: relative;

    display: block;
    width: 100%;
    line-height: 1;
  `;
});

export const Note = styled.span(({ theme }) => {
  const { color } = theme;
  return css`
    color: ${color.grey};
    margin-bottom: 0;
    line-height: 1;
  `;
});
