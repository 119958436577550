import React, { useRef, useState } from "react";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import Lottie from 'react-lottie';

import { checkLoggedIn } from "../../../components/utils/_app-helpers";
import { LikeDislikeTypes } from './likeDislike.types';
import { Container } from '../../../redesign/theme/GlobalStyles';
import {
  WrapperLikeDislike,
  Headline,
  ButtonsWrapper,
  ButtonEmoji
} from "./LikeDislike.styles";
import dislike from '../../assets/animations/dislike.json';
import like from '../../assets/animations/like.json';

const LikeDislike: React.FC<LikeDislikeTypes> = ({ pageId, params }) => {
  const isLoggedIn = checkLoggedIn();
  const accountId = localStorage.getItem('accountId');
  const [isLikeStopped, setIsLikeStopped] = useState(true);
  const [isDislikeStopped, setIsDislikeStopped] = useState(true);

  const likeLottieRef = useRef();
  const dislikeLottieRef = useRef();

  const setLottieOptions = (animation: any) => {
    return{
      loop: false,
      autoplay: false,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
  }

  const setReaction = (label: 'Like' | 'Dislike') => {
    if(label === 'Like'){
      setIsLikeStopped(false)
    }

    if(label === 'Dislike'){
      setIsDislikeStopped(false)
    }

    const customerID = isLoggedIn && accountId ? accountId : `Guest - ${uuidv4()}`;

    const data = {
      'label': label,
      'content_item': {
        'id': pageId,
      },
      'customer':{
        'id': customerID,
      }
    }

    const token =  window.location.hostname === 'localhost'
      ? '91354c20981b35bd1bbe1d2b48894bf38dbaec5a7f4667824316ea5e9a87c178'
      : process.env.REACT_APP_PREPR_REST_API_ACCESS_TOKEN;

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    };

    return axios.post('https://tracking.prepr.io/events', data, config)
      .then(function (response) {
        console.log(`Emoji is ${response?.data?.message}`);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <WrapperLikeDislike>
      <Container maxWidth={'1200px'}>
        <Headline>
          {params?.headline}
        </Headline>
        <ButtonsWrapper>
          <ButtonEmoji
            disabled={!isDislikeStopped || !isLikeStopped}
            onClick={() => setReaction('Like')}>
            <Lottie
              ref={likeLottieRef}
              options={setLottieOptions(like)}
              isStopped={isLikeStopped}
              isClickToPauseDisabled={true}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => {
                    setIsLikeStopped(true);
                  },
                },
              ]}
            />
          </ButtonEmoji>
          <ButtonEmoji
            disabled={!isDislikeStopped || !isLikeStopped}
            onClick={() => setReaction('Dislike')}>
            <Lottie
              ref={dislikeLottieRef}
              options={setLottieOptions(dislike)}
              isStopped={isDislikeStopped}
              isClickToPauseDisabled={true}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => {
                    setIsDislikeStopped(true);
                  },
                },
              ]}
            />
          </ButtonEmoji>
        </ButtonsWrapper>
      </Container>
    </WrapperLikeDislike>
  )
};

export default LikeDislike;