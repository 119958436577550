import React from 'react';
import { useSelector } from 'react-redux';

import { InfoLoggedInUser } from '../parts';
import { GameLoggedInUser } from '../parts/Game';
import { loggedInUserProps } from './loggedInUser.types';
import { useLoader } from '../../../../../../routes/common/hooks';
import { EBikeGameBox, EBikeGameContainer } from '../EBikeGame.styles';

const LoggedInUser: React.FC<loggedInUserProps> = () => {
  const transactions = useSelector((state: any) => state?.transactions);
  const loader = useLoader(typeof transactions !== 'number');
  return (
      <EBikeGameContainer>
        { loader ||
        <EBikeGameBox>
          <InfoLoggedInUser/>
          <GameLoggedInUser/>
        </EBikeGameBox> }
      </EBikeGameContainer>
  )
};

export default LoggedInUser;