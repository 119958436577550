import styled, { css } from 'styled-components';
import { Container } from "../../theme/GlobalStyles";
import {ShapeError404Top, ShapeError404Bottom} from "../../../prepr/assets";

export const ErrorWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    width: 100%;
    
    &:before,
    &:after{
      content:'';
      display: block;
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:before{
      top: 0;
      right: 0;
      width: 27vw;
      height: 21vw;
      background-image: url(${ShapeError404Top});
      background-position: bottom left;

      ${mediaQueries.sm} {
        width: 45vw;
        height: 40vw;
      }
    }
    
    &:after{
      bottom: 0;
      left: 0;
      width: 27vw;
      height: 21vw;
      background-image: url(${ShapeError404Bottom});
      background-position: top right;

      ${mediaQueries.sm} {
        width: 50vw;
        height: 30vw;
      }
    }
  `;
});

export const ErrorContainer = styled(Container)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;


    ${mediaQueries.sm} {
      padding: 30vw 25px;
    }
  `;
});

export const ErrorContent = styled.div(() => {

  return css`
    z-index: 1;
    width: 100%;
    max-width: 385px;
    text-align: center;
  `;
});

export const ErrorImg = styled.img(({ theme }) => {
  const { size } = theme;

  return css`
    width: 100%;
    max-width: 144px;
    margin-bottom: ${size.px10};
  `;
});

export const ErrorTitle = styled.h2(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    font-size: ${size.px32};
    margin-bottom: ${size.px10};
    
    ${mediaQueries.sm} {
      font-size: ${size.px24};
    }
  `;
});

export const ErrorText = styled.p(({ theme }) => {
  const { size, color } = theme;

  return css`
    color: ${color.graniteGray};
    margin-bottom: ${size.px30};
  `;
});

export const ButtonBox = styled.div(({ theme }) => {
  const { size } = theme;

  return css`
    > * {
      margin-bottom: ${size.px16};

      &:last-child {
        margin-bottom: 0;
      }
    }
  `;
});