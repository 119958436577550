import styled, { css } from 'styled-components';
import {
  IconArrowBlue,
  IconArrowWhite,
  IconArrowNavy,
  IconArrowOlympic,
  IconArrowSteelBlue
} from '../../../../prepr/assets';
import { imageWidth, imageWidthTab } from "../../../../prepr/components/PageHeader/PageHeader.styles";

type BreadcrumbsTextProps = {
  primary: string;
};

type AlignmentBreadcrumbsProps = {
  alignmentBreadcrumbs?: string;
};

export const BreadcrumbsBox = styled.div<AlignmentBreadcrumbsProps>(
  ({ theme, alignmentBreadcrumbs= 'left' }) => {

    return css`
      text-align: ${() => alignmentBreadcrumbs === 'right' ? 'right' : 'left'};
      margin: ${() => (alignmentBreadcrumbs === 'right' ? '0 0 0 auto' : alignmentBreadcrumbs === 'center' ? '0 auto' : '0')};

      a{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        ${theme.mediaQueries.sm} {
          overflow: auto;
          white-space: normal;
          text-overflow: initial;
        }
      }
      
      max-width: 548px;
      width: calc(100% - ${imageWidth} + 15px);
      
      ${theme.mediaQueries.xl} {
        width: calc(100% - ${imageWidth} + 15px - 20px);
      }

      ${theme.mediaQueries.lg} {
        width: calc(100% - ${imageWidthTab} - 25px - 20px);
      }

      ${theme.mediaQueries.sm} {
        width: 100%;
        max-width: 100%;
        text-align: left;
      }
    `;
  }
);

export const BreadcrumbsText = styled.div<BreadcrumbsTextProps>(
  ({ theme, primary }) => {
    const { color, font, size } = theme;

    return css`
      cursor: pointer;
      display: inline-block;
      font-family: ${font.family.EMPrintSemi};
      font-size: ${size.px14};

      margin: 0;
      line-height: 1.3;
      color: ${() => (primary === 'primary' ? color.blue : color.white)};
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &:before {
        content: '';
        display: inline-block;
        margin: 0 10px -1px 0;
        width: 7px;
        height: 12px;
        background-image: ${() =>
          primary === 'primary'
            ? `url(${IconArrowBlue})`
            : `url(${IconArrowWhite})`};
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &:hover,
      &:focus {
        color: ${() =>
          primary === 'primary' ? color.olympic : color.steelBlue};

        transition: all 0.3s ease-in-out;

        &:before {
          background-image: ${() =>
            primary === 'primary'
              ? `url(${IconArrowOlympic})`
              : `url(${IconArrowSteelBlue})`};
        }
      }

      &:active {
        color: ${color.navy};
        transition: all 0.2s ease-in-out;

        &:before {
          background-image: url(${IconArrowNavy});
        }
      }

      &:focus,
      &:focus-visible {
        outline: 2px solid ${color.azure};
      }
    `;
  }
);
