import axios from 'axios';
import { apiURL } from '../../config/config';

export const getCyclooData = (searchParams, accessToken) => {
  return axios({
    url: apiURL + `/api/Cycloo/badge${searchParams}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": accessToken
    },
  })
}