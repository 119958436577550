import { createSelector } from 'reselect';

export const getAccessToken = createSelector(
  [(state) => state.authData],
  (authData) => authData.accessToken
);

export const getRefreshToken = createSelector(
  [(state) => state.authData],
  (authData) => authData.refreshToken
);

export const getTransactions = createSelector(
  [(state) => state.userData],
  (userData) => userData?.campaignGameStats[0]?.lotteryTicketCount
);