import styled, { css } from 'styled-components';
import { IconShopBlue } from '../../../../../assets';
import { NavigationIcon } from "../../MainNavigation.styles";

export const ShopForm = styled.form(() => {
  return css`
    display: inline-flex;
    height: 100%;
  `;
});
export const ShopButton = styled.button(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    border: none;
    cursor: pointer;
    background-color: transparent;
    padding: 15px;
    height: 100%;

    ${mediaQueries.md} {
      padding: 0 8px;
    }
  `;
});
export const ShopIcon = styled(NavigationIcon)(() => {
  return css`
    background-image: url('${IconShopBlue}');
  `;
});



