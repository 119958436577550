import styled, { css } from 'styled-components';
import { Container } from "../../../redesign/theme/GlobalStyles";

type JustTextWrapperTypes = {
  fontColor?: string;
}
export const JustTextWrapper = styled(Container)<JustTextWrapperTypes>(({ theme, fontColor='#333'}) => {
  const { size, font, mediaQueries } = theme;

  return css`
    position: relative;
    z-index: 2;
    padding-top: 0;
    padding-bottom: 0;
    
    h1, h2, h3, h4, h5, h6, p, a, span{
      color: ${fontColor};
    }
    
    a {
      font-weight: ${font.weight.semiBold};
      text-decoration: underline;
    }
    
    //customize for Hard Gifts campaign
    .banner-carousel{
      max-width: 1440px;
      padding: 0 40px;
      width: 100%;

      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);

      ${mediaQueries.xl} {
        top: 6vw;
      }
      
      ${mediaQueries.retinaScreens}{
        top: 2vw;
      }
      
      ${mediaQueries.nonRetinaScreens}{
        top: 2vw;
      }

      ${mediaQueries.sm} {
        padding: 0 20px;
      }
      
      h1{
        font-size: ${size.px36};

        ${mediaQueries.sm} {
          font-size: ${size.px28};
        }
      }

      h2, h3, h4, h5, h6{
        font-size: ${size.px32};

        ${mediaQueries.sm} {
          font-size: ${size.px18};
        }
      }

      p{
        font-size: ${size.px20};
        font-family: ${font.family.EMPrintRegular};
        padding-top: 10px;

        ${mediaQueries.sm} {
          font-size: ${size.px16};
          padding-top: 3px;
        }
      }
    }
    
    .terms {
      padding-bottom: 40px;
      
      p, a {
        font-size: ${size.px14};
      }
    }
    //end customize for Hard Gifts campaign
  `;
});
