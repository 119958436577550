import React from 'react';
import { useSelector } from 'react-redux';
import { tokensBarProps } from './tokensBar.types';
import { Token, TokensList } from './TokensBar.styles';
import { IconToken } from '../../../../../../assets';

const TokensBar: React.FC<tokensBarProps> = () => {
  const transactions = useSelector((state: any) => state?.transactions);
  const transactionsArr:Array<number> = [];

  for (let i = 1; i <= transactions; i++) {
    transactionsArr.push(i);
  }

  return (
    !!transactionsArr.length ?
      <>
        <TokensList>
          {transactionsArr.map((i) => (
            <Token key={i}>
              <img src={IconToken} alt='token icon' />
            </Token>
          ))}
        </TokensList>
      </> : <></>
  );
};

export default TokensBar;