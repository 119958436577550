import styled, { css } from 'styled-components';

export const headerWrapperHeight = '750px';
export const headerWrapperHeightMob = '615px';
export const customWrapperMarginBottom = '250px';
export const customWrapperMarginBottomMob = '0px';
export const gameContainerHeight = '980px';
export const gameContainerHeightMob = '700px';

export const EBikeCustomWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    overflow: hidden;
    margin-bottom: -${customWrapperMarginBottom};
    
    ${mediaQueries.sm} {
      margin-bottom: ${customWrapperMarginBottomMob};
    }
  `;
});