import { prepr } from '../services/prepr';

import { getLocalePrepr } from '../../components/utils/_app-helpers';

let locale = getLocalePrepr();

const getPage = (slug: string) =>
  prepr
    .graphqlQuery(
      `{
            Page(slug: "${slug}", locale: "${locale}") {
              _id
              _slug
              _locale
              type
              background_color
              
              breadcrumb {
                __typename
                ... on Page {
                  _id
                  internal_name
                  _slug
                }
              }
              type_of_breadcrumbs
              alignment_of_breadcrumbs
              components {
                __typename

                ... on PageHeaderHelp {
                  _id
                  headline_logged_out
                  headline_logged_in
                  background_image {
                    cover
                    cdn_files {
                      url(width: ${1920})
                    }
                    name
                    url
                    width
                    height
                  }
                }        
                            
                ... on ArticleOverview {
                    _id
                    title
                  }
                           
                ... on PageHeader {
                  _id
                  label
                  headline
                  text
                  image {
                    cdn_files {
                      url(width: ${800})
                    }
                    name
                    url
                    width
                    height
                  }
                  image_position
                  cta_label
                  cta_url
                  internal_link { 
                    __typename
                    ... on Page {
                        _id
                        _slug
                      }
                
                }
                  image_alignment
                }  
                                     
                ... on ArticleCollectionBig {
                  _id
                  __typename
                  title
                  subtitle
                  cta_label
                  url
                  max_width
                  items_per_row
                  pages {
                    ... on Page {
                      _id
                      _slug
                      internal_name
                    }
                  }
                }
                
                ... on LinkCollection {
                  _id
                  __typename
                  title
                  description
                  cta_label
                  cta_url
                  max_width
                  items_per_row
                  internal_link { 
                    __typename
                    ... on Page {
                        _id
                        _slug
                      }
                    }
                  links {
                    ... on Link {
                      _id
                      _publish_on
                      title
                      description
                      image { 
                      _id
                      url
                      cdn_files {
                         url(width: ${768})
                        }
                      }
                      cta_label
                      cta_url
                      internal_link { 
                      __typename
                      ... on Page {
                          _id
                          _slug
                        }
                      }
                      button_type
                    }
                  }
                }
    

                ... on FAQCollection {
                  _id
                  title
                  cta_label
                  url
                  custom_styles
                  max_width
                  background_color
                  link {
                    ... on Page {
                      _id
                      _slug
                      internal_name
                    }
                  }
                  questions {
                    _id
                    question
                    answer
                  }
                }

               ... on ContactComponent {
                  _id
                  title
                  subtitle
                  features {
                    internal_title
                    pre_headline
                    headline
                    text
                    image {
                      cdn_files {
                        url(width: ${144})
                      }
                      width
                      height
                      url
                      name
                    }
                    label
                    url
                  }
                }

                ... on NavigationCollection {
                  _id
                  __typename
                  categories {
                    ... on FAQCategory {
                      _id
                      title
                      icon
                      active_icon
                      faqs {
                        _id
                        question
                        answer
                      }
                    }
                               
                    ... on RewardCategory {
                      _id
                      _slug
                      title
                      icon
                      active_icon
                    
                      products {
                       ... on Product {
                          id
                          name
                          image {
                            cdn_files {
                                url
                            }
                            width
                            height
                            url
                            name
                          }   
                          subtitle          
                          description
                          detail_url
                          sort_order
                          points_cost
                          discount_percentage  
                        }
                      }
                    }
                                        

                    
                  }
                }
                
                ... on Form {
                      form
                      _id
                      title
                      label
                    }
                    
                ... on ContentBlock {
                    _id
                    label
                    headline
                    text
                    cta_label
                    cta_url
                    internal_link {
                      __typename
                      _slug
                    }
                    button_type
                    image {
                      _id
                      url
                      name
                      cdn_files {
                        url(width: ${900})
                      }
                    }
                    image_position
                  }
                  
                  
                 ... on PageHeaderProductCategory {
                  _id
                  title
                  text
                  image {
                   cdn_files {
                     url(width: ${700})
                   }
                   width
                   height
                   url
                   name
                 }
                  image_mobile {
                   cdn_files {
                     url(width: ${900})
                   }
                   width
                   height
                   url
                   name
                 }
                }
                
                ... on ProductCollection {
                    _id
                    headline
                    subtitle
                    is_small_images
                    products_placeholder
                    cta_label
                    url
                }
                
                    ... on ProductCollectionManual {
                      _id
                      title
                      cta_label
                      cta_url
                      category_id
                      is_two_rows,
                      is_small_images
                      internal_link { 
                        __typename
                        ... on Page {
                            _id
                            _slug
                          }
                    
                    }
                }
    
               ... on ProductCollectionHighlights {
               _id
                products {
                  id
                  name
                  image {
                   cdn_files {
                      url(width: ${900})
                   }
                   width
                   height
                   url
                   name
                 }   
                  subtitle          
                  description
                  detail_url
                  sort_order
                  points_cost
                  discount_percentage  
                }
               }
                
                
               ... on ProductCollectionAutomatic {
                  _id
                  title
                  cta_label
                  cta_url
                  type
                  category_id,
                  is_small_images,
                  internal_link { 
                        __typename
                        ... on Page {
                            _id
                            _slug
                          }
                    
                    }
                }      
                
               ... on ProductCollectionManualGrid {
                  _id
                  title
                  cta_label
                  cta_url,
                  internal_link { 
                    __typename
                    ... on Page {
                        _id
                        _slug
                      }
                
                }
                  products {
                    _id
                    _slug
                    id
                    name
                    subtitle
                    description
                    detail_url
                    sort_order
                    points_cost
                    discount_percentage
                    cost_label
                    image {
                       cdn_files {
                         url(width: ${500})
                       }
                       width
                       height
                       url
                       name
                    }                    
                  }
                }
                
               ... on StepsHorizontal {
                  _id
                  headline
                  label
                  step_items {
                    description
                    entry_title
                    headline
                    pre_headline
                    button_type
                    cta_url
                    cta_label
                    internal_link { 
                     __typename
                     ... on Page {
                         _id
                         _slug
                       }
                   }
                    image {
                      cdn_files {
                        url(width: ${768})
                      }
                      url
                    }
                  }
                }
                
               ... on StepsPopup {
                  _id
                  headline
                  label
                  step_items {
                    description
                    entry_title
                    headline
                    image {
                      cdn_files {
                        url(width: ${768})
                      }
                      url
                    }
                  }
                } 
                
             ... on StepsVertical {
                  _id
                  headline
                  label
                  cta_label
                  cta_url
                  step_items {
                    _id
                    description
                    entry_title
                    headline
                    image {
                      cdn_files {
                        url(width: ${600})
                      }
                      url
                      width
                      height
                      name
                    }
                  }
                }
                
             ... on Sparen {
                  _id
                  cta_label
                  cta_label_1
                  cta_label_2
                  cta_url
                  cta_url_1
                  cta_url_2
                  is_cta_url_current_domain
                  is_cta_url_1_current_domain
                  is_cta_url_2_current_domain
                  headline
                  headline_1
                  headline_2
                  text_1
                  text_2
                  image {
                    cdn_files {
                      url(width: ${600})
                    }
                    url
                    width
                    height
                    name
                  }
                }
                                                        
                ... on PageHeaderHome {
                  _id
                  header {
                    ... on HomepageHeader {
                      _id
                      internal_title
                      superheadline
                      headline
                      text
                      cta_label
                      cta_link
                      visual {
                        cdn_files {
                          url(width: ${1920})
                        }
                        url
                      }
                    }
                  }
                }
                
               ... on ModalsDemo {
                  _id
                }
               
               ... on Banner {
                  _id
                  headline
                  subtitle
                  appstore_buttons
                  image {
                   cdn_files {
                     url(width: ${500})
                   }
                   width
                   height
                   url
                   name
                  }
                  image_vertical_alignment
                  internal_link { 
                     __typename
                     ... on Page {
                         _id
                         _slug
                       }
                   }
                  cta_label
                  cta_url
                  cta_background_color
                  cta_color
                  dark_mode
                  play_store_url
                  app_store_url
                }
                
               ... on BannerNoImage {
                 _id
                cta_text
                cta_url
                dark_mode
                subtitle
                headline
                width_content
               }
               
               
               ... on VideoVimeo {
                    _id
                    headline
                    video_items {
                      title
                      url
                      description
                      is_video_vertical
                      description_position
                    }
                  }
                
               ... on JustText {
                _id
                text
                fontcolor
                max_width
               }
               
              ... on Custom {
                _id
               campaign_completed
               }
               
              ... on TermsContent {
                _id
                text
                font_color
              }
              
                       
              ... on AssetComponent {
                _id
                headline
                assets {
                    _type
                    cover(width: ${900})
                    name
                    url
                    cdn_files {
                        url
                    }
                 }
               }
               
             ... on Quotes {
                _id
                text
                font_color
                background_color
               }
               
                      
            ... on LikeDislike {
                _id
                headline
               }
               
           ... on EBikeCampaignCustom {
                _id
                is_campaign_completed
               }
               
           ... on EndYearCampaignCustom {
                _id
               }
               
           ... on LoyaltyProgramCollection {
                _id
                title
                max_width
                list {
                  _id
                  title
                  description
                  image_desktop {
                    cdn_files {
                      url(width: ${800})
                    }
                    name
                    url
                    width
                    height
                  }
                  image_mobile {
                    cdn_files {
                      url(width: ${800})
                    }
                    name
                    url
                    width
                    height
                  }
                  cta_label
                  cta_url
                  button_type
                  internal_link {
                     __typename
                     ... on Page {
                           _id
                           _slug
                     }
                  }
                 }
                }


              }
            }
          }`
    )
    .fetch();

export default getPage;