import React from 'react';
import { PageHeaderHomeProps } from './pageHeaderHome.types';
import { HeroBanner, AnchorButton } from '../../../redesign/components/shared';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay } from 'swiper';

import { PageHeaderHomeWrapper } from './PageHeaderHome.styles';

SwiperCore.use([Autoplay, EffectFade]);

const PageHeaderHome: React.FC<PageHeaderHomeProps> = ({ params }) => {
  return (
    <PageHeaderHomeWrapper>
      <Swiper
        slidesPerView={1}
        speed={700}
        loop={params?.header.length > 1}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false
        }}
      >
        {params?.header.map(
          (
            { text, visual, cta_label, cta_link, headline, superheadline }: any,
            index: { toString: () => React.Key | null | undefined }
          ) => {
            const tab = {
              text: text,
              visual: visual,
              cta_label: cta_label,
              cta_link: cta_link,
              headline: headline,
              superheadline: superheadline
            };
            return (
              <SwiperSlide key={index.toString()}>
                <HeroBanner {...tab} />
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
      <AnchorButton className="anchor-cta" anchorID="sparen" />
    </PageHeaderHomeWrapper>
  );
};

export default PageHeaderHome;
