import { NavLinksItemProps } from '../UI/NavLinks/navLinks.types';
import i18n from '../../../../../config/i18n';

const baseUrl = window.location.origin;

const urlInfoContact = `${baseUrl}/#/info-contact`;
const urlTerms = `${baseUrl}/#/terms`;
const urlPrivacy = `${baseUrl}/#/privacy`;

export const topNavLinksList: NavLinksItemProps[] = [
  {
    title: `${i18n.t('00_footer-1')}`,
    url_nl: urlInfoContact,
    url_be_nl: urlInfoContact,
    url_be_fr: urlInfoContact,
  },
  {
    title: `${i18n.t('00_footer_terms_link')}`,
    url_nl: urlTerms,
    url_be_nl: urlTerms,
    url_be_fr: urlTerms,
  },
  {
    title: `${i18n.t('00_cookies-4')}`,
    url_nl: 'https://www.exxonmobil.be/nl-BE/legal-pages/Cookie-statement',
    url_be_nl: 'https://www.exxonmobil.be/nl-BE/legal-pages/Cookie-statement',
    url_be_fr: 'https://www.exxonmobil.be/fr-be/legal-pages/cookie-statement',
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    title: `${i18n.t('00_footer-privacy')}`,
    url_nl: urlPrivacy,
    url_be_nl: urlPrivacy,
    url_be_fr: urlPrivacy,
  }
];
