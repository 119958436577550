import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import esso_fr from './lang/esso-web-site-fr-BE.json';
import esso_vl from './lang/esso-web-site-nl-BE.json';
import esso_nl from './lang/esso-web-site-nl-NL.json';

const languageDetector = new LanguageDetector(null, {lookupQuerystring: 'lng'});

i18n
  .use(languageDetector)
  .init({
    debug: true,
    fallbackLng: 'vl',
    ns: ['common'],
    defaultNS: ['common'],
    interpolation: {
      escapeValue: false, // not needed for react
    },

    resources: {
      nl: { common: esso_nl },
      vl: { common: esso_vl },
      fr: { common: esso_fr }
    },

    // react i18next special options (optional)
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
  });

export default i18n;
