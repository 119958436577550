import React from 'react';
import { InfoCompletedCampaign } from "../parts";
import GameWrap from "../parts/Game/GameWrap";
import { EBikeGameBox, EBikeGameContainer } from "../EBikeGame.styles";

const CompletedCampaign = () => {
  return (
      <EBikeGameContainer>
        <EBikeGameBox>
          <InfoCompletedCampaign/>
          <GameWrap/>
        </EBikeGameBox>
      </EBikeGameContainer>
  )
};

export default CompletedCampaign;