import { prepr } from '../services/prepr';

import { getLocalePrepr } from '../../components/utils/_app-helpers';

let locale = getLocalePrepr();
const getHeaderLinks = prepr
  .graphqlQuery(
    `{
      HeaderLinks(limit: null, locale: "${locale}") {
        items {
            cta_url
            cta_label
            order
            internal_link { 
              __typename
              ... on Page {
                  _id
                  _slug
                }
              }
          }
        }
      }`
  )
  .fetch()
  .then((res: any) => {
    return res.data.HeaderLinks.items})
  .catch((error: string) => error);

export default getHeaderLinks;
