import React from 'react';
import { SelectorListTypes } from './selectorList.types';

import { InputSelector } from '../index';
import { useFormContext } from 'react-hook-form';
import { SelectorListContainer } from './SelectorList.styles';
import { FieldLabel } from '../UI';

const SelectorList: React.FC<SelectorListTypes> = ({
  items,
  listLabel,
  isRequiredList = false
}) => {
  const { register } = useFormContext();

  return (
    <>
      <FieldLabel
        name={listLabel}
        label={listLabel}
        isRequired={isRequiredList}
      />
      {items && (
        <SelectorListContainer>
          {items.map(
            (
              { name, value, isRequired, label, icon, activeIcon, width },
              idx
            ) => {
              const item = {
                name: name,
                value: value,
                label: label,
                icon: icon,
                activeIcon: activeIcon,
                isRequired: isRequired,
                width: width
              };
              return (
                <InputSelector
                  key={idx}
                  register={register(name, {
                    required: isRequired
                  })}
                  {...item}
                />
              );
            }
          )}
        </SelectorListContainer>
      )}
    </>
  );
};

export default SelectorList;
