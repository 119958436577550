import React from 'react';
import { FieldLabelWrapper, Note } from './FieldLabel.styles';
import i18n from '../../../../../../config/i18n';

type FieldLabelType = {
  isRequired: boolean;
  name: string;
  label: string;
};

function FieldLabel({
  isRequired = false,
  name,
  label
}: FieldLabelType): JSX.Element {
  return (
    <FieldLabelWrapper htmlFor={name}>
      {label}{' '}
      {!isRequired && <Note>{i18n.t('13-Contact-form-option-field-msg')}</Note>}
    </FieldLabelWrapper>
  );
}

export default FieldLabel;
