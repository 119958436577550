import React from 'react';

import ProductHighlights from '../../../redesign/components/shared/ProductHighlights';
import { ProductCollectionHighlightsProps } from './productCollectionHighlights.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { ProductsWrap } from './ProductCollectionHighlights.styles';

const ProductCollectionHighlights: React.FC<
  ProductCollectionHighlightsProps
> = ({ params, onLogin }) => {
  const products = params?.products;

  return (
    <Wrapper>
      <Container maxWidth="1250px">
        <ProductsWrap>
          {products?.length &&
            products?.map((product, index) => {
              return (
                <ProductHighlights
                  key={product.id || index.toString()}
                  onLogin={onLogin}
                  {...product}
                />
              );
            })}
        </ProductsWrap>
      </Container>
    </Wrapper>
  );
};

export default ProductCollectionHighlights;
