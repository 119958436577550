import styled, { css } from 'styled-components';
import { ButtonWrapper } from '../Button/Button.styles';
import { FormWrapperBase } from '../Form/Form.styles';

export const FormWrapper = styled(FormWrapperBase)(() => {
  return css`
    ${ButtonWrapper} {
      margin: 2rem 0 0;
    }
  `;
});

export const LoaderForm = styled.div(() => {
  return css`
    min-height: 520px;
  `;
});