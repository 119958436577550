import React from 'react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import EndYearCampaign from './EYC';
// import { reCAPTCHA } from './api/const';

const EYC = ()=> {
  return (
      // reCAPTCHA ?
      //     <GoogleReCaptchaProvider reCaptchaKey={`${reCAPTCHA}`} useEnterprise>
      //         <EndYearCampaign/>
      //     </GoogleReCaptchaProvider> :
          <EndYearCampaign/>
  )
};
export default EYC;