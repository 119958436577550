import { useState } from 'react';
const useModal = () => {
  const [isShowed, setIsShowed] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  const close = () => {
    setIsAnimated(true);
    setTimeout(() => {
      setIsShowed(false);
      setIsAnimated(false);
    }, 300);
  };

  const toggle = () => {
    if (isShowed) {
      close();
    } else {
      setIsAnimated(false);
      setIsShowed(true);
    }
  };

  return {isShowed, toggle, close, isAnimated} as const;
};
export default useModal;