import React from 'react';

import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import {
  InputField,
  SelectorList,
  SelectField,
  SelectAutocomplete,
  TextAreaField
} from '../Form';
import Button from '../Button';

import i18n from '../../../../config/i18n';
import { countriesList, errorsList } from '../Form/data';
import { questionsList, selectorList } from './data';

import { FieldWrapper } from '../Form/Form.styles';
import { FormWrapper } from './ContactForm.styles';

type Inputs = {
  question: string;
  name?: string;
  cardNumber?: number;
  email: string;
  textArea: string;
  phoneNumber?: string;
  device?: string;
};

const ContactForm = () => {
  const methods = useForm<Inputs>({ mode: 'onChange' });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const mailtoHandler = (formData: Inputs | any) => {
    window.location.href = `mailto:${i18n.t(
      '05_Contact_service_email'
    )}?subject=${formData?.question.value}&body=${i18n.t(
      '13_Contact-form_devices-label'
    )}%3A%20${formData?.device ? formData?.device : '-'}%0D%0A---%0D%0A${i18n.t(
      '13_Contact-form_card-number'
    )}%3A%20${
      formData?.cardNumber ? formData?.cardNumber : '-'
    }%0D%0A---%0D%0A${i18n.t('13_Contact-form_fullname')}%3A%20${
      formData?.name ? formData?.name : '-'
    }%0D%0A---%0D%0A${i18n.t('13_Contact-form_phonenumber')}%3A%20+${
      formData?.phoneNumber ? formData?.phoneNumber : '-'
    }%0D%0A---%0D%0A${i18n.t('05_Login_email_field')}%3A%20${
      formData?.email
    }%0D%0A---%0D%0A${i18n.t('13_Contact-form_your-question')}%3A%20${
      formData?.textArea
    }%0D%0A`;
  };

  const onSubmit: SubmitHandler<Inputs> = (formData) => {

    mailtoHandler(formData);
  };

  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper>
          <SelectField
            width={50}
            name="question"
            placeholder={i18n.t('13_Contact-form_choose-subject')}
            isRequired
            label={i18n.t('13_Contact-form_question-about')}
            options={questionsList}
            isError={!!errors.question}
            errorText={errorsList.question.required}
          />
          <InputField
            register={{
              ...register('cardNumber', {
                pattern: {
                  value: /^[0-9]*/,
                  message: errorsList.cardNumber.required
                }
              })
            }}
            type="number"
            placeholder="7036"
            errorText={errors.cardNumber?.message}
            name="cardNumber"
            label={i18n.t('13_Contact-form_card-number')}
            width={50}
            isError={!!errors.cardNumber}
          />
        </FieldWrapper>
        <FieldWrapper>
          <InputField
            register={{
              ...register('name', {
                pattern: {
                  value:
                    /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
                  message: errorsList.firstName.required
                }
              })
            }}
            type="text"
            placeholder={i18n.t('13_Contact-form_fullname')}
            errorText={errors.name?.message}
            name="name"
            label={i18n.t('13_Contact-form_fullname')}
            width={50}
            isError={!!errors.name}
          />
          <SelectAutocomplete
            width={50}
            label={i18n.t('13_Contact-form_phonenumber')}
            name="phoneNumber"
            hasDefaultValue
            options={countriesList}
          />
        </FieldWrapper>
        <FieldWrapper>
          <InputField
            register={{
              ...register('email', {
                required: errorsList.email.required,
                pattern: {
                  value:
                    /^([a-zA-Z0-9])(([a-zA-Z0-9])*([._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9-])+(\.))+([a-zA-Z]{2,4})+$/,
                  message: errorsList.email.required
                }
              })
            }}
            type="email"
            placeholder={i18n.t('05_Login_email_field')}
            errorText={errors.email?.message}
            name="email"
            label={i18n.t('05_Login_email_field')}
            width={100}
            isError={!!errors.email}
            isRequired
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectorList
            items={selectorList}
            listLabel={i18n.t('13_Contact-form_devices-label')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextAreaField
            register={{
              ...register('textArea', {
                required: errorsList.question.required
              })
            }}
            name="textArea"
            label={i18n.t('13_Contact-form_your-question')}
            placeholder={i18n.t('13_Contact-form_describe-question')}
            isError={!!errors.textArea}
            errorText={errors.textArea?.message}
            rows={8}
            isRequired
          />
        </FieldWrapper>
        <Button text={i18n.t('13_Contact-form_send-email')} type="submit" />
      </FormWrapper>
    </FormProvider>
  );
};

export default ContactForm;
