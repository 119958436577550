import React from "react";
import useWindowSize from '../../../hooks/useWindowSize';
import {ServiceFeatureProps} from './serviceFeature.types';

import {
  FeatureBox, FeatureIcon, FeatureContent, FeatureInfo, FeatureTitle, FeatureText, FeatureLinkText, FeatureLink
} from "./ServiceFeature.styles";

const ServiceFeature: React.FC<ServiceFeatureProps> = (props) => {
  const {headline, text, image, label, url, internal_title} = props;

  const img = image?.[0];
  const typeService = internal_title.toLowerCase();

  let windowSize = useWindowSize();
  return (
    <FeatureBox>
      <FeatureContent>
        <FeatureIcon>
          <img src={img?.cdn_files?.[0]?.url || img?.url}
               width={img?.width}
               height={img?.height}
               alt={img?.name}/>
        </FeatureIcon>
        <FeatureInfo>
          <FeatureTitle>{headline}</FeatureTitle>
          <FeatureText>{text}</FeatureText>
        </FeatureInfo>
      </FeatureContent>
      {(
          (typeService === 'phone' && windowSize < 768) ||
          (typeService !== 'phone')) &&
        <FeatureLink href={url}>
          <FeatureLinkText>{label}</FeatureLinkText>
        </FeatureLink>
      }
    </FeatureBox>
  )
}

export default ServiceFeature;