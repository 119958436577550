import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowSize } from '../../../hooks';
import { Button } from '../index';
import { LoyaltyProgramProps, TitleProps } from './loyaltyProgram.types';
import { ButtonWrap, Container, ContentWrap, ImgWrap, Text, Title } from './LoyaltyProgram.styles';

const LoyaltyProgram: React.FC<LoyaltyProgramProps> = (props) => {
  const {
    title,
    description,
    image_desktop,
    image_mobile,
    cta_label,
    cta_url,
    button_type,
    internal_link
  } = props;

  const windowSize = useWindowSize();
  const image = image_desktop;
  const imageUrl = image?.cdn_files?.[0]?.url || image?.url;
  const imageMob = image_mobile;
  const imageUrlMob = imageMob?.cdn_files?.[0]?.url || imageMob?.url;

  const pageURL = internal_link?.[0];
  const hasLink = pageURL || cta_url !== null;
  const btnLink = pageURL ? pageURL._slug : cta_url;

  const [textLines, setTextLines] = useState<number>(0);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  let titleStyles: TitleProps = useMemo(() => {
    return {
      fontSize: 18,
      lines: 3,
      lineHeight: 1.3
    };
  }, []);

  let countLines = useCallback(() => {
    if(windowSize >= 768){
      if (titleRef && titleRef.current) {
        let height = titleRef.current.clientHeight;

        height &&
        height >=
        Math.floor(titleStyles?.fontSize *
          titleStyles?.lines *
          titleStyles?.lineHeight)
          ? setTextLines(2)
          : setTextLines(3);
      }
    }
  }, [titleStyles, windowSize]);

  useEffect(() => {
    countLines();
  }, [countLines, windowSize]);

  return (
    <Container>
      <ImgWrap>
        {windowSize > 1024 && imageUrl && <img src={imageUrl} alt={title} />}
        {windowSize <= 1024 && imageUrlMob && <img src={imageUrlMob} alt={title} />}
      </ImgWrap>
      <ContentWrap>
        <Title ref={titleRef} typography={titleStyles}>{title}</Title>
        <Text lines={textLines}>{description}</Text>

        {cta_label && hasLink && (
          <ButtonWrap buttonType={button_type || 'primary'}>
            <Button
              nativeLink={pageURL ? false : !!cta_url}
              buttontype={button_type || 'primary'}
              text={cta_label}
              url={btnLink}
            />
          </ButtonWrap>
        )}
      </ContentWrap>
    </Container>
  );
};

export default LoyaltyProgram;
