import React, { useRef } from "react";
import { handleHandoverForm } from "../../../../../utils";
import { handoverConstructor } from "../../../../../../components/utils/_app-helpers";
import { ShopButton, ShopIcon, ShopForm } from "./Shop.styles";

function Shop(){
  const authToken = localStorage.getItem('authToken');
  const authTokenParse = authToken && JSON.parse(authToken);
  const accessToken = authTokenParse && authTokenParse.accessToken;
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <ShopForm
      method='POST'
      ref={formRef}
      action={handoverConstructor('/checkout/cart/')}
      onClick={() => handleHandoverForm(formRef, '#/auth/login', true)}
    >
      <input type='text' name='auth_key' value={accessToken} hidden readOnly />
      <ShopButton>
        <ShopIcon />
      </ShopButton>
    </ShopForm>
  )
}
export default Shop;
