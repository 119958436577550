import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import DialogWindow from './_template';

export const LocationAgreeDialog = props => {
  return (
    <DialogWindow isOpen={props.isOpen} handleCloseModal={props.handleCloseModal}>
      <div className='more-info-text'>
        <p className="text-styled">
          <Trans i18nKey="05_Reg_location_block_text_part1"></Trans>
        </p>
        <p className="text-styled">
          <Trans i18nKey="05_Reg_location_block_text_part2">
            Indien u geen locatie gebonden aanbiedingen meer wilt ontvangen kunt u
            dit aanpassen via
            <Link to="/profile/info" target="_blank">
              essoextras.nl/profiel/mijndata
            </Link>
          </Trans>
        </p>
        <p className="text-styled">
          <Trans i18nKey="05_Reg_location_block_text_part3">
            Voor verwerking van uw persoonsgegevens en uw rechten verwijzen wij u
            naar
            <Link to="/privacy" target="_blank">
              www.essoextras.nl/privacy
            </Link>
            .
          </Trans>
        </p>
      </div>
    </DialogWindow>
  )
}