import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Headline } from '../../../redesign/components/shared';
import ProductCard from '../../../redesign/components/shared/ProductCard';
import { getRewardsByCategory } from '../../../components/utils/_reward-helpers';
import { clickGTM } from '../../../components/utils/_gtm-helpers';

import { ProductCollectionAutomaticProps } from './productCollectionAutomatic.types';

import { Container, Wrapper } from '../../../redesign/theme/GlobalStyles';

import { ProductAutomaticRow } from './ProductCollectionAutomatic.styles';

SwiperCore.use([Navigation, Pagination]);
const ProductCollectionAutomatic: React.FC<ProductCollectionAutomaticProps> = ({
  params,
  product,
  onLogin
}) => {
  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const baseUrl = window.location.origin;

  const pageURL = params?.internal_link?.[0];

  const url = window.location.href;

  const lastURLSegment = url.split('/').pop();

  const renderCounter = useRef(0);

  useEffect(() => {
    renderCounter.current ++;
  })

  useEffect(() => {
    if(renderCounter.current <=1 ){
      if (params?.category_id) {
        getRewardsByCategory(12, params?.category_id).then((response) => {
          response && setData(response.data?.payload.rewards);
        });
      } else if (lastURLSegment === 'all') {
        getRewardsByCategory(12).then((response) => {
          response && setData(response.data?.payload.rewards);
        });
      } else {
        getRewardsByCategory(12, lastURLSegment).then((response) => {
          response && setData(response.data?.payload.rewards);
        });
      }
    }
  }, [lastURLSegment, params]);

  useEffect(() => {
    if (data.length > 0 && params?.is_small_images) {
      const linksArray = data.map((i: any) => {
        return Object.assign(i, { resizedImage: true });
      });

      // @ts-ignore
      setFilteredData(linksArray);
    } else {
      setFilteredData(data);
    }
  }, [data, params]);

  return (
    <Wrapper>
      {filteredData.length > 0 && (
        <Container maxWidth={'1280px'}>
          <Headline
            title={params?.title}
            urlText={params?.cta_label}
            url={
              params?.cta_url
                ? params?.cta_url
                : `${baseUrl}/#${pageURL?._slug}`
            }
            nativeLink={!!params?.cta_url || !!pageURL?._slug}
            onClick={() => clickGTM('text_link', 'Internal Link', `${params?.cta_label}`)}
          >
            <ProductAutomaticRow className='isCustomPagination'>
              <Swiper
                slidesPerView={1.5}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  850: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                  },
                  1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                  }
                }}
                spaceBetween={12}
                navigation={filteredData.length > 4}
                pagination={{
                  type: 'bullets'
                }}
              >
                {filteredData?.map((item: typeof product, index) => {
                  return (
                    <SwiperSlide
                      key={`product-automatic-${item?.id}-${params?._id}`}
                    >
                      <ProductCard onLogin={onLogin} {...item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ProductAutomaticRow>
          </Headline>
        </Container>
      )}
    </Wrapper>
  );
};

export default ProductCollectionAutomatic;
