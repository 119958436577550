import React from 'react';
import { JustTextTypes } from "./justText.types";
import { JustTextWrapper } from "./JustText.styles";

const JustText: React.FC<JustTextTypes> = ({ params }) => {
  return (
    <JustTextWrapper maxWidth={
      params?.max_width === 'narrow' ? '850px' :
        params?.max_width === 'wide' ? '1250px' : '1440px'}
                     fontColor={params?.fontcolor}
                     dangerouslySetInnerHTML={{ __html: `${params?.text}` }}/>
  )
};

export default JustText;