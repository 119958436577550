import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import { ProductCardProps } from './productCard.types';

import {
  CardBox,
  CardContent,
  CardImg,
  CardTitle,
  CardCost,
  CardContainerForm
} from './ProductCard.styles';
import parse from 'html-react-parser';
import { handoverConstructor, linkConstructor } from "../../../../components/utils/_app-helpers";
import { handleHandoverForm } from "../../../utils";
import { clickGTM } from "../../../../components/utils/_gtm-helpers";

interface ArticleTitleProps {
  fontSize: number;
  lines: number;
  lineHeight: number;
}

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const {
    website,
    promoText,
    promoTextHtml,
    imageUrl,
    name,
    resizedImage,
    onLogin
  } = props;

  const rewardLink = website.replace(
    /^(http:\/\/|https:\/\/)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?/,
    ''
  );
  const authToken = localStorage.getItem('authToken');
  const authTokenParse = authToken && JSON.parse(authToken);
  const accessToken = authTokenParse && authTokenParse.accessToken;
  const formRef = useRef<HTMLFormElement>(null);

  const [textLines, setTextLines] = useState<number>(0);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  let windowSize = useWindowSize();
  let articleTitleStyles: ArticleTitleProps = useMemo(() => {
    return {
      fontSize: 18,
      lines: 2,
      lineHeight: 1.3
    };
  }, []);

  let countLines = useCallback(() => {
    if (titleRef && titleRef.current) {
      let height = titleRef.current.clientHeight;
      height &&
      height >
        articleTitleStyles?.fontSize *
          articleTitleStyles?.lines *
          articleTitleStyles?.lineHeight
        ? setTextLines(1)
        : setTextLines(2);
    }
  }, [articleTitleStyles]);

  const handleClickGTM = (website: string) => {
    let arrayWebsite = website?.split('/shop');
    let nameWebsite = arrayWebsite?.length > 1 && arrayWebsite[arrayWebsite.length - 1];

    nameWebsite && clickGTM('swiper_click', 'Swiper Interactions', `/shop${nameWebsite}`)
  }

  const productCardContent = (
    <>
      <CardImg className={resizedImage ? 'resizedImage' : 'default-image'}>
        <img src={imageUrl} alt={name} />
      </CardImg>
      <CardContent>
        <CardTitle ref={titleRef} typography={articleTitleStyles}>
          {name}
        </CardTitle>
        <CardCost lines={textLines}>
          {promoTextHtml ? parse(promoTextHtml) : promoText}
        </CardCost>
      </CardContent>
    </>
  );

  useEffect(() => {
    countLines();
  }, [countLines, windowSize]);

  return onLogin ? (
    <CardContainerForm
      method="POST"
      action={handoverConstructor(rewardLink)}
      ref={formRef}
      onClick={() => {
        handleClickGTM(website);
        handleHandoverForm(formRef, website, false);
      }}
    >
      <input type="text" name="auth_key" value={accessToken} hidden readOnly />
      {productCardContent}
    </CardContainerForm>
  ) : (
    <CardBox
      onClick={() => handleClickGTM(website)}
      href={linkConstructor(website)}>{productCardContent}</CardBox>
  );
};

export default ProductCard;
