import styled, { css } from 'styled-components';

export const FooterRow = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-content: center;

    align-items: center;

    ${mediaQueries.md} {
      align-items: flex-start;
    }

    ${mediaQueries.sm} {
      flex-direction: column;
      align-items: baseline;
    }
  `;
});
