import React from 'react';

import {
  CustomLinkWrapper,
  CustomNativeLinkWrapper
} from './CustomLink.styles';

export type CustomLinkProps = {
  text?: string;
  url?: string;
  nativeLink?: boolean;
  onClick?: () => void;
};

function CustomLink({ text, url, nativeLink, onClick }: CustomLinkProps) {
  return nativeLink ? (
    <CustomNativeLinkWrapper onClick={onClick} href={url} target="_self">
      {text}
    </CustomNativeLinkWrapper>
  ) : (
    <CustomLinkWrapper onClick={onClick} to={url!}>{text}</CustomLinkWrapper>
  );
}

export default CustomLink;
