import React from 'react';
import { PageHeaderProductCategoryProps } from './pageHeaderProductCategory.types';

import {
  BannerWrapper,
  BannerContainer,
  BannerContent,
  BannerTitle,
  BannerText
} from "./PageHeaderProductCategory.styles";

const PageHeaderProductCategory: React.FC<PageHeaderProductCategoryProps> = ({ params }) => {
  const image = params?.image?.[0];
  const imageUrl = image?.cdn_files?.[0]?.url || image?.url;
  const imageMob = params?.image_mobile?.[0];
  const imageUrlMob = imageMob?.cdn_files?.[0]?.url || imageMob?.url;

  return (
    <BannerWrapper image={{
      imageUrl,
      imageUrlMob
    }}>
      <BannerContainer maxWidth='1250px'>
        <BannerContent>
          <BannerTitle>{params?.title}</BannerTitle>
          <BannerText>{params?.text}</BannerText>
        </BannerContent>
      </BannerContainer>
    </BannerWrapper>
  );
};

export default PageHeaderProductCategory;
