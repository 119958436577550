import React from "react";
import i18n from "../../../../../../config/i18n";
import { BrandLink, BrandLinkBox, BrandLinksWrapper } from "./BrandLinks.styles";


function BrandLinks(){
  return (
    <BrandLinksWrapper>
      <BrandLinkBox>
        <BrandLink href={i18n.t("00_top_nav_menu-1_link")}
                   target={'_blank'}>
          {i18n.t("00_top_nav_menu-1")}
        </BrandLink>
      </BrandLinkBox>
      <BrandLinkBox>
        <BrandLink href={i18n.t("00_top_nav_menu-2_link")}
                   target={'_blank'}>
          {i18n.t("00_top_nav-menu-2")}
        </BrandLink>
      </BrandLinkBox>
    </BrandLinksWrapper>
  )
}
export default BrandLinks;
