import styled, { css } from 'styled-components';

export const setMobBtnStyles = (theme:any) => {
  const {color, size} = theme;

  return css`
    width: 100%;
    min-width: auto;
    height: auto;
    min-height: 80px;
    padding: 26px 50px 26px 40px;
    text-align: left;
    border-bottom: 1px solid ${color['grey-100']};
    
    span{
      line-height: 1.2;
      font-size: ${size.px22};
      color: ${color.graniteGray};
    }
	`;
};

export const setMobArrowStyles = () => {
  return css`
    display: block;
    position: absolute;
    top: 50%;
    right: 30px;
	`;
};

export const LangSelectorWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    
    ${mediaQueries.md} {
      flex-wrap: wrap;
    }
  `;
});

export const LangSelectorBox = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;

    ${mediaQueries.md} {
      width: 100%;
    }
  `;
});

export const LangButton = styled.button(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    position: relative;
    background: transparent;
    padding: 10px 5px;
    min-width: 180px; //148px;
    height: 48px;
    transition: all 0.2s ease;

    ${mediaQueries.md} {
      ${setMobBtnStyles(theme)};
      display: block;
    }
    
    svg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      vertical-align: bottom;

      path, ellipse{
        transition: all 0.2s ease;
      }
    }
    
    &:hover{
      background: ${color.chineseGray};

      ${mediaQueries.md} {
        background: transparent;
      }
    }
    
    &.active{
      background: ${color.chineseGray};

      ${mediaQueries.md} {
        background: ${color['grey-200']};
      }
      
      ${IconWorld}{
        svg {
          path{
            fill: ${color.black};
          }
          ellipse{
            fill: transparent;
          }
        }
      }
      
      ${LangButtonText}{
        color: ${color.black};
      }

      ${IconArrow}{
        ${mediaQueries.md} {
          transform: rotate(180deg);
          
          svg {
            path{
              fill: ${color.black};
            }
          }
        }
      }
    
    }
  `;
});

export const LangButtonText = styled.span(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    position: relative;
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    line-height: 1;
    color: ${color.graniteGray};
    padding-left: 28px;
    transition: all 0.2s ease;

    ${mediaQueries.md} {
      padding-left: 48px;
    }
  `;
});

export const IconArrow = styled.i(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: none;
    
    ${mediaQueries.md} {
      ${setMobArrowStyles()};
      width: 16px;
      height: 10px;
      margin-top: -5px;
    }
  `;
});

const IconBase = styled.i(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    margin-top: -8px;

    ${mediaQueries.md} {
      width: 24px;
      height: 24px;
      margin-top: -12px;
    }
  `;
});

export const IconWorld = styled(IconBase)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.md} {
    }
  `;
});

export const IconFlag = styled(IconBase)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.md} {
    }
  `;
});