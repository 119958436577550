import styled, { css } from 'styled-components';

export const NavigationWrap = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    min-height: 170px;

    ${mediaQueries.sm} {
      min-height: 105px;
    }
  `;
});

export const FAQTitle = styled.h2(({ theme }) => {
  const { color, font, size, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px32};
    line-height: 1.25;
    color: ${color.darkCharcoal};
    margin-bottom: 20px;

    ${mediaQueries.sm} {
      font-size: ${size.px24};
    }
  `;
});
