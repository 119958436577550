import styled, { css } from 'styled-components';
import { IconCheckMark } from '../../../../assets';

export const CheckboxLabel = styled.label(() => {
  return css`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;
  `;
});

export const CheckboxCircle = styled.span(({theme}) => {
  const { color } = theme;

  return css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${color.chineseGray};
    
    &:before{
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      left: -1px;
      top: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-width: 0;
      transition: border-width 0.2s ease-in-out;
    }
  `;
});
export const CheckboxSquare = styled.span(({theme}) => {
  const { color } = theme;

  return css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid ${color.chineseGray};
    
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${IconCheckMark});
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: center;
      transform: scale(0);
      transition: transform 0.3s ease-in-out;
    }
  `;
});

export const CheckboxInput = styled.input(({theme}) => {
  const { color } = theme;
  return css`
    display: none;
    
    &:checked + ${CheckboxCircle} {
      &:before {
        border: 8px solid ${color.admiral};
      }
    }

    &:checked + ${CheckboxSquare} {
      &:before {
        transform: scale(1);
      }
    }
  `;
});

export const CheckboxText = styled.p(({theme}) => {
  const { size, color, font, mediaQueries } = theme;

  return css`
    margin-left: 35px;
    display: inline-block;
    font-size: ${size.px16};
    color: ${color.black};

    a{
      font-size: ${size.px14};
      font-family: ${font.family.EMPrintRegular};
      line-height: 1.2;
      font-weight: ${font.weight.semiBold};
    }

    ${mediaQueries.sm} {
      font-size: ${size.px14};
    }
  `;
});