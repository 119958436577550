import styled, { css } from 'styled-components';

export const AccordionWrapper = styled.ul(({ theme }) => {
  const { size } = theme;

  return css`
    position: relative;
    list-style: none;
    margin: 0 0 ${size.px26};
    padding: 0;
  `;
});

export const AccordionItemWrapper = styled.li(({ theme }) => {
  const { size, color, font } = theme;

  return css`
    font-size: ${size.px16};
    color: ${color.darkCharcoal};

    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.regular};

    border: 1px solid ${color.border};
    border-radius: 4px;
    margin: ${size.px16} 0 0;

    &:first-child {
      margin-top: 0;
    }

    &:focus,
    &:focus-visible {
      outline: 2px solid ${color.azure};
    }
  `;
});

export const AccordionTitle = styled.h2(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    width: 100%;
    margin: 0;
    padding: 4px;
    text-align: left;
    transition: background-color 0.3s ease-in-out;

    ${mediaQueries.minWidthSM} {
      &:hover {
        background-color: ${color.lightblue};
        transition: background-color 0.3s ease-in-out;
      }
    }
  `;
});

export const AccordionItemButton = styled.button(({ theme }) => {
  const { size, color, font, mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    margin: -4px;
    width: calc(100% + 8px);

    background-color: ${color.white};
    border: 0;
    border-radius: 4px;
    padding: ${size.px23} ${size.px24} ${size.px23} ${size.px12};

    font-size: ${size.px16};
    color: ${color.darkCharcoal};
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.regular};
    text-align: left;

    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:after {
      content: '';
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin-left: auto;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAKCAYAAAC9vt6cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC9SURBVHgBjY+9DcIwEIWfYyRKqKGBDRDxAPRIzEBKqmQDzARQgUgTVkCIngEomALa9IEY2wREZCfxNff3vqc7Aj8OgXyIV5vjFqRwiVHShfcMQUTaAsQGIADNOnIVOBl42VrCc12C5KvPVA78OGmEx1JTwIqluJ8v6E/lCWSijkNvNsDjdHSBcV1wqhsXEwusSvoT1JlUwLo1zmQ7DuEti/UBQiU7bDcwTL5KEy6/8B/ldyrh5mDbCGwf1UneZnJUk4D4Ow0AAAAASUVORK5CYII=');
      background-repeat: no-repeat;
      background-size: 100%;
      transition: transform 0s ease-in-out;
      transform: translateY(3px);
    }

    &:focus {
      outline: none;
    }

    &.active {
      &:after {
        transform: rotate(-180deg) translateY(3px);
      }
    }

    ${mediaQueries.sm} {
      padding: ${size.px22} ${size.px16};
    }

    ${mediaQueries.minWidthSM} {
      &:hover {
        background-color: ${color.lightblue};
        transition: background-color 0.3s ease-in-out;
      }
    }
  `;
});

export const AccordionItemContainer = styled.div`
  transition: height 0s ease-in-out;
  overflow: hidden;
`;

export const AccordionItemContent = styled.div(({ theme }) => {
  const { size, font, mediaQueries, color } = theme;

  return css`
    font-size: ${size.px16};
    padding: ${size.px16} ${size.px12};
    font-family: ${font.family.sansSerif};

    ul,
    p {
      margin: 0 0 ${size.px18};

      &:last-child {
        margin-bottom: 0;
      }
    }

    li {
      position: relative;
      padding-left: ${size.px32};
      margin: 0 0 ${size.px10};

      color: ${color.paragraph};

      &:before {
        content: '';
        display: inline-block;

        position: absolute;
        left: ${size.px8};
        top: 4px;

        width: ${size.px8};
        height: ${size.px8};
        vertical-align: top;

        border-radius: 50%;
        margin: 0;
        background: #587abc;
      }

      &:last-child {
        margin-bottom: 0;
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${color.text};
      }
    }

    ${mediaQueries.sm} {
      font-size: ${size.px14};
      padding: 0 ${size.px16} ${size.px16};
    }
  `;
});
