import styled, { css } from 'styled-components';

export const FeaturesWrap = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    ${theme.mediaQueries.sm}{
      flex-wrap: wrap-reverse;
    }
  `;
});
