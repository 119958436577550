import React from "react";

import { DescriptionLink } from "../Carousel/Carousel.styles";
import {
  CampaignCompletedContent,
  CampaignCompletedTitle,
  CampaignCompletedText,
  CampaignCompletedWrapper
} from "./CampaignCompleted.styles";
import i18n from "../../../../../config/i18n";

const CampaignCompleted = () => {

  return (
    <CampaignCompletedWrapper>
      <CampaignCompletedContent>
        <CampaignCompletedTitle>
          {i18n.t('hgc_completed_title')}
        </CampaignCompletedTitle>
        <CampaignCompletedText>
          {i18n.t('hgc_completed_text')}
        </CampaignCompletedText>
        <DescriptionLink to={'/'}><span>{i18n.t('hgc_completed_button')}</span></DescriptionLink>
      </CampaignCompletedContent>
    </CampaignCompletedWrapper>
  );
};

export default CampaignCompleted;
