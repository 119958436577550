import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useImages, useLoader } from '../../../hooks';
import { shopLang } from '../../../../config/config';
import { checkAgent } from '../helpers';
import ModalBE from '../parts/ModalBE';
import Intro from './parts/Intro';
import Chests from './parts/Chests';
import Keys from '../parts/Keys';
import { Container } from '../ChestGame.styles';
import { Wrapper, LogoImg } from './Phase1.styles';
import * as images from '../assets';
import * as sprites from './assets/sprites';
import * as imagesPhase1 from './assets/images';

const Phase1 = () => {
  const checkDevice = useMemo(() => checkAgent(), []);
  const assets = useMemo(() =>
      checkDevice
        ? {...sprites, ...images, ...imagesPhase1}
        : {...images, ...imagesPhase1},
    [checkDevice]);
  const isAssetsLoaded = useImages(assets);
  const winPoints = useSelector((state: any) => state?.winPointsEYC);
  const accountBalance = useSelector(
    (state: { currentUser: any }) => state?.currentUser?.accountBalance
  );
  const isShowModal = useSelector((state: any) => state?.isShowModalEYC);
  const setSeparatorToBalance = () => {
    return parseInt(accountBalance + winPoints).toLocaleString().replace(',', '.');
  };
  const isCurrentUserDataLoaded = useSelector((state: any) => state?.isCurrentUserDataLoaded);
  const keysBlue = useSelector((state: any) => state?.transactionsEYC?.blue);
  const keysGold = useSelector((state: any) => state?.transactionsEYC?.gold);
  const loader = useLoader(!isAssetsLoaded || !isCurrentUserDataLoaded || typeof keysBlue !== 'number');

  return (
    loader ||
    <Wrapper>
      <Container>
        <LogoImg src={shopLang === 'be_fr' ? imagesPhase1.LogoFR : imagesPhase1.LogoNL} alt='logo' />

        <Intro />

        <Chests />

        <Keys phase={1}
              keysBlue={keysBlue}
              keysGold={keysGold}
              accountBalance={setSeparatorToBalance()} />

        {shopLang !== 'nl_nl' && isShowModal && <ModalBE />}
      </Container>
    </Wrapper>
  );
};

export default Phase1;