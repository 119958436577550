import styled, { css } from 'styled-components';
import { ButtonsGroup } from '../Modal/Modal.styles';

export const ButtonsGroupNewsLetter = styled(ButtonsGroup)(({theme}) => {
  const { mediaQueries } = theme;

  return css`
    
    button{
      ${mediaQueries.sm} {
        min-width: 125px;
        width: auto;
        padding: 1rem;
      }

      ${mediaQueries.s} {
        min-width: auto;
      }
    }
  `;
})