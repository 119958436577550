import styled, { css } from 'styled-components';

export const SelectWrapper = styled.div(({ theme }) => {
  const { size, color } = theme;

  return css`
    margin: 0 0 ${size.px16};
    padding: 0 ${size.px12};

    &.hasError {
      .CustomSelect__control {
        background-color: ${color.white};
        border-color: ${color.red};
        border-width: 1px;
      }
    }
  `;
});

export const SelectHolder = styled.div(({ theme }) => {
  const { size } = theme;

  return css`
    margin: 0;
    padding: 0;

    position: relative;

    .CustomSelectIcon {
      + div {
        input {
          margin-left: ${size.px20} !important;
        }
      }
    }
  `;
});

export const Icon = styled.img(({ theme }) => {
  const { size } = theme;

  return css`
    padding: 0 ${size.px8} 0 0;
    vertical-align: middle;

    height: ${size.px12};
  `;
});

export const InputIcon = styled.img(({ theme }) => {
  const { size } = theme;

  return css`
    padding: 0 ${size.px8} 0 ${size.px16};
    vertical-align: middle;

    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;

    height: ${size.px12};
  `;
});

export const DropdownOptionWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  align-content: center;
  align-items: center;
`;

export const SelectPlaceholder = styled.div(({ theme }) => {
  const { size, color } = theme;

  return css`
    display: flex;
    flex-wrap: nowrap;

    align-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    left: ${size.px16};
    transform: translateY(-50%);
    z-index: 2;

    color: ${color.chineseGray};
    pointer-events: none;
  `;
});

export const PlaceholderText = styled.span(({ theme }) => {
  const { size, color } = theme;

  return css`
    font-size: ${size.px16};

    color: ${color.chineseGray};
  `;
});

export const FieldLabel = styled.label(({ theme }) => {
  const { color, size, font } = theme;
  return css`
    font-size: ${size.px14};
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.regular};

    color: ${color.black};

    margin-bottom: ${size.px4};
    position: relative;

    display: block;
    width: 100%;
  `;
});

export const Note = styled.span(({ theme }) => {
  const { color } = theme;
  return css`
    color: ${color.grey};
  `;
});
