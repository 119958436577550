import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { clickGTM } from '../../../../components/utils/_gtm-helpers';
import useWindowSize from '../../../hooks/useWindowSize';
import { ArticleProps } from './article.types';

import {
  ArticleBox,
  ArticleContent,
  ArticleImg,
  ArticleTitle,
  ArticleText,
  ArticleAction,
  ArticleDate
} from './Article.styles';
import { Button } from '../index';

interface ArticleTitleProps {
  fontSize: number;
  lines: number;
  lineHeight: number;
}

const Article: React.FC<ArticleProps> = (props) => {
  const {
    internal_link,
    _publish_on,
    title,
    description,
    image,
    button_type,
    cta_url,
    cta_label
  } = props;

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const publishOn = new Date(_publish_on);
  const date = publishOn?.getDate();
  const month = publishOn?.getMonth();
  const year = publishOn?.getFullYear();

  const baseUrl = window.location.origin;

  const img = image?.[0];
  const pageURL = internal_link?.[0];

  const isALink = pageURL?._slug.length > 1;

  const handleClickGTM = () => {
    let pageSlug = pageURL?._slug;
    let pageUrl = cta_url;

    pageSlug && clickGTM('article_click', 'Article Interactions', `/#/${pageSlug}`);
    pageUrl && clickGTM('article_click', 'Article Interactions', `${pageUrl}`);
  }

  const linkAttributes =
    isALink || cta_url !== null
      ? {
          onClick: handleClickGTM,
          href: cta_url ? `${cta_url}` : `${baseUrl}/#${pageURL?._slug}`
        }
      : {};

  const [textLines, setTextLines] = useState<number>(0);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  let windowSize = useWindowSize();
  let articleTitleStyles: ArticleTitleProps = useMemo(() => {
    return {
      fontSize: 18,
      lines: 2,
      lineHeight: 1.3
    };
  }, []);

  let countLines = useCallback(() => {
    if (titleRef && titleRef.current) {
      let height = titleRef.current.clientHeight;
      height &&
      height >
        articleTitleStyles?.fontSize *
          articleTitleStyles?.lines *
          articleTitleStyles?.lineHeight
        ? setTextLines(1)
        : setTextLines(2);
    }
  }, [articleTitleStyles]);

  useEffect(() => {
    countLines();
  }, [countLines, windowSize]);

  return (
    <ArticleBox
      as={isALink || cta_url !== null ? 'a' : 'div'}
      {...linkAttributes}
      className={cta_label ? 'link-box' : 'article-box'}
    >
      <ArticleImg>
        <img
          loading='lazy'
          src={img?.cdn_files?.[0]?.url || img?.url}
          width={img?.width}
          height={img?.height}
          alt={img?.name}
        />
      </ArticleImg>
      <ArticleContent
        className={
          button_type !== 'none' && cta_label
            ? 'content-with-buttons'
            : 'content-no-buttons'
        }
      >
        <ArticleTitle ref={titleRef} typography={articleTitleStyles}>
          {title}
        </ArticleTitle>
        <ArticleText lines={textLines}>{description}</ArticleText>
        { (month !== null && month !== undefined && date && year) ?
          <ArticleDate>
            {monthNames[month]} {date}, {year}
          </ArticleDate> : <></>
        }
        {button_type !== 'none' && cta_label && (
          <ArticleAction>
            <Button
              onClick={handleClickGTM}
              buttontype={button_type} text={cta_label} />
          </ArticleAction>
        )}
      </ArticleContent>
    </ArticleBox>
  );
};

export default Article;
