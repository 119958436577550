/*
 * action types
 */
export const GET_INFO_CAMPAIGN = 'GET_INFO_CAMPAIGN';
export const ADD_AUTH_DATA_CAMPAIGN = 'ADD_AUTH_DATA_CAMPAIGN';
export const ADD_USER_DATA_CAMPAIGN = 'ADD_USER_DATA_CAMPAIGN';
export const SET_TRANSACTIONS_CAMPAIGN = 'SET_TRANSACTIONS_CAMPAIGN';
export const SET_PARTICIPANT_CAMPAIGN = 'SET_PARTICIPANT_CAMPAIGN';
export const SET_CURRENT_PRIZE_CAMPAIGN = 'SET_CURRENT_PRIZE_CAMPAIGN';
export const SET_IS_CAMPAIGN_COMPLETED = 'SET_IS_CAMPAIGN_COMPLETED';
export const SET_IS_USER_WON_CAMPAIGN = 'SET_IS_USER_WON_CAMPAIGN';
export const SET_IS_PLAY_BTN_DISABLED_CAMPAIGN = 'SET_IS_PLAY_BTN_DISABLED_CAMPAIGN';
export const SET_DEFAULT_ANIMATION_CAMPAIGN = 'SET_DEFAULT_ANIMATION_CAMPAIGN';
export const SET_ACTIVE_GAME_STATE_CAMPAIGN = 'SET_ACTIVE_GAME_STATE_CAMPAIGN';
export const SET_IS_GAME_LOADER_ACTIVE_CAMPAIGN = 'SET_IS_GAME_LOADER_ACTIVE_CAMPAIGN';
export const SET_IS_SHOW_MODAL_CAMPAIGN = 'SET_IS_SHOW_MODAL_CAMPAIGN';
export const SET_IS_MODAL_ANSWER_CORRECT_CAMPAIGN = 'SET_IS_MODAL_ANSWER_CORRECT_CAMPAIGN';
export const SET_IS_DEFINE_WINNER_CAMPAIGN = 'SET_IS_DEFINE_WINNER_CAMPAIGN';
export const SET_IS_REPLAY_GAME_CAMPAIGN = 'SET_IS_REPLAY_GAME_CAMPAIGN';
export const SET_RESULT_GAME_FOR_ANIMATION = 'SET_RESULT_GAME_FOR_ANIMATION';
export const SET_IS_LABEL_CLOSED = 'SET_IS_LABEL_CLOSED';
export const SET_IS_CONFETTI_SHOWED = 'SET_IS_CONFETTI_SHOWED';
export const SET_IS_FORM_SHOWED = 'SET_IS_FORM_SHOWED';
export const SET_IS_FORM_BTN_DISABLED = 'SET_IS_FORM_BTN_DISABLED';
export const SET_ACTIVE_BALLOON = 'SET_ACTIVE_BALLOON';
export const SET_ANIMATED_BALLOON = 'SET_ANIMATED_BALLOON';

/*
 * action creators
 */

export function getCampaignInfo(data) {
  return {
    type: GET_INFO_CAMPAIGN,
    data
  }
}

export function addAuthData (data) {
  const { refreshToken, refreshExpiresAt } = data;

  refreshToken
    ? window.localStorage.setItem('refreshToken', refreshToken)
    : window.localStorage.removeItem('refreshToken');

  refreshExpiresAt
    ? window.localStorage.setItem('refreshExpiresAt', `${refreshExpiresAt}`)
    : window.localStorage.removeItem('refreshExpiresAt');

  return {
    type: ADD_AUTH_DATA_CAMPAIGN,
    data
  }
}

export function addUserData(data){
  return {
    type: ADD_USER_DATA_CAMPAIGN,
    data
  }
}

export function setTransactions(data){
  return {
    type: SET_TRANSACTIONS_CAMPAIGN,
    data
  }
}

export function setParticipant(data){
  return {
    type: SET_PARTICIPANT_CAMPAIGN,
    data
  }
}

export function setCurrentPrize(data){
  return {
    type: SET_CURRENT_PRIZE_CAMPAIGN,
    data
  }
}

export const setIsCampaignCompleted = (data) => ({
  type: SET_IS_CAMPAIGN_COMPLETED,
  data
});

export const setIsUserWon = (data) => ({
  type: SET_IS_USER_WON_CAMPAIGN,
  data
});

export const setIsPlayBtnDisabled = (data) => ({
  type: SET_IS_PLAY_BTN_DISABLED_CAMPAIGN,
  data
});

export const setDefaultAnimation = (data) => ({
  type: SET_DEFAULT_ANIMATION_CAMPAIGN,
  data
})

export const setActiveGameState = (data) => ({
  type: SET_ACTIVE_GAME_STATE_CAMPAIGN,
  data
})

export const setIsGameLoaderActive = (data) => ({
  type: SET_IS_GAME_LOADER_ACTIVE_CAMPAIGN,
  data
})

export const setIsShowModal = (data) => ({
  type: SET_IS_SHOW_MODAL_CAMPAIGN,
  data
})

export const setIsModalAnswerCorrect = (data) => ({
  type: SET_IS_MODAL_ANSWER_CORRECT_CAMPAIGN,
  data
})

export const setIsDefineWinnerActive = (data) => ({
  type: SET_IS_DEFINE_WINNER_CAMPAIGN,
  data
})

export const setIsReplayGame = (data) => ({
  type: SET_IS_REPLAY_GAME_CAMPAIGN,
  data
})

export const setResultGameForAnimation = (data) => ({
  type: SET_RESULT_GAME_FOR_ANIMATION,
  data
})

export const setIsLabelClosed = (data) => ({
  type: SET_IS_LABEL_CLOSED,
  data
})

export const setIsConfettiShowed = (data) => ({
  type: SET_IS_CONFETTI_SHOWED,
  data
})

export const setIsFormShowed = (data) => ({
  type: SET_IS_FORM_SHOWED,
  data
})

export const setIsFormBtnDisabled = (data) => ({
  type: SET_IS_FORM_BTN_DISABLED,
  data
})

export const setAnimatedBalloon = (data) => ({
  type: SET_ANIMATED_BALLOON,
  data
})

export const setActiveBalloon = (data) => ({
  type: SET_ACTIVE_BALLOON,
  data
})