import React from 'react';
import {LoaderInner, LoaderLayout} from "./Loader.styles";

function Loader(){
  return (
    <LoaderLayout>
      <LoaderInner />
    </LoaderLayout>
  );
}

export default Loader;
