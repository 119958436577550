import React from 'react';

import { Button } from '../../../../redesign/components/shared';
import { BannerNoImageProps } from './bannerNoImage.types';

import { Wrapper, Container } from '../../../../redesign/theme/GlobalStyles';

import {BannerContentBase, BannerTitle, BannerText} from '../Banners.styles';

import {
  BannerWrapper,
  BannerContainer,
  ButtonBox
} from './BannerNoImage.styles';

const BannerNoImage: React.FC<BannerNoImageProps> = ({ params }) => {
  const isButtonActive = params?.cta_text && params?.cta_url;

  return (
    <Wrapper>
      <Container maxWidth={'1250px'}>
        <BannerWrapper darkMode={params?.dark_mode}>
          <BannerContainer isButtonActive={!!isButtonActive}
                           widthContent={params?.width_content}>
            <BannerContentBase widthContent={params?.width_content}>
              <BannerTitle>{params?.headline}</BannerTitle>
              <BannerText>{params?.subtitle}</BannerText>
            </BannerContentBase>

            {isButtonActive && (
              <ButtonBox>
                <Button
                  url={params?.cta_url}
                  text={params?.cta_text}
                  buttontype={params?.dark_mode ? 'secondary' : 'primary'}
                />
              </ButtonBox>
            )}
          </BannerContainer>
        </BannerWrapper>
      </Container>
    </Wrapper>
  );
};

export default BannerNoImage;
