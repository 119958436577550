import React from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import { HeadlineProps } from './headline.types';

import { CustomLink } from '../index';

import {
  HeadlineTop,
  HeadlineTitle,
  HeadingSubtitle,
  HeadlineBottom
} from './Headline.styles';

const Headline: React.FC<HeadlineProps> = (props) => {
  const { title, subtitle, url, urlText, children, nativeLink, onClick} = props;
  let windowSize = useWindowSize();

  const isUrlEmpty = url === undefined || url.includes('undefined');

  return (
    <>
      <HeadlineTop>
        <HeadlineTitle>{title}</HeadlineTitle>
        {!isUrlEmpty && urlText && windowSize >= 768 && (
          <CustomLink url={url} text={urlText} nativeLink={nativeLink} onClick={onClick}/>
        )}
      </HeadlineTop>
      {subtitle && <HeadingSubtitle>{subtitle}</HeadingSubtitle>}

      {children}

      {!isUrlEmpty && urlText && windowSize < 768 && (
        <HeadlineBottom>
          <CustomLink url={url} text={urlText} nativeLink={nativeLink} onClick={onClick}/>
        </HeadlineBottom>
      )}
    </>
  );
};

export default Headline;
