import { NavLinksItemProps } from "../UI/NavLinks/navLinks.types";
import i18n from '../../../../../config/i18n';

const baseUrl = window.location.origin;

const urlPrivacy = `${baseUrl}/#/privacy`;

export const bottomNavLinksList: NavLinksItemProps[] = [
  {
    title: `${i18n.t('00_footer_terms_link')}`,
    url_nl: 'https://www.exxonmobil.be/nl-be/legal-pages/terms-and-conditions',
    url_be_nl: 'https://www.exxonmobil.be/nl-be/legal-pages/terms-and-conditions',
    url_be_fr: 'https://www.exxonmobil.be/fr-be/legal-pages/terms-and-conditions',
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    title: `${i18n.t('00_cookies-4')}`,
    url_nl: 'https://www.exxonmobil.be/nl-BE/legal-pages/Cookie-statement',
    url_be_nl: 'https://www.exxonmobil.be/nl-BE/legal-pages/Cookie-statement',
    url_be_fr: 'https://www.exxonmobil.be/fr-be/legal-pages/cookie-statement',
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    title: `${i18n.t('00_footer-privacy')}`,
    url_nl: urlPrivacy,
    url_be_nl: urlPrivacy,
    url_be_fr: urlPrivacy,
  }
];
