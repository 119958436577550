import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { checkLoggedIn } from '../../../../../../components/utils/_app-helpers';
import { clickGTM } from '../../../../../../components/utils/_gtm-helpers';
import { useHeaderLinkActive } from '../../../../../hooks';
import getHeaderLinks from '../../../../../../prepr/queries/getHeaderLinks';
import { LinkBarProps } from './linksBar.types';
import { LinksBarWrapper, LinkLabel, NavLinkCustom, LinkCustom } from './LinksBar.styles';

function LinksBar(){
  const checkIsUrlActive = useHeaderLinkActive();
  const isLoggedIn = checkLoggedIn();
  const [headerLinks, setHeaderLinks] = useState<LinkBarProps[]>();
  const isCurrentUserActive = useSelector((state: any) => state?.isCurrentUserActive);
  const [errors, setErrors] = useState<string[]>();

  const fetchHeaderLinks = useCallback(async () => {
    try {
      const data = await getHeaderLinks;

      if (data.length > 0) {
        const filteredHeaderLinks = data.filter(
          (item: { cta_label: any; cta_url: any, internal_link: any }) =>
            (item.cta_url !== null && item.cta_label !== null) || (item.internal_link !== null && item.cta_label !== null)
        ).sort((a: any , b: any ) => a?.order - b?.order);

        setHeaderLinks(filteredHeaderLinks);
      }
    } catch (e) {
      setErrors(['There is an error']);
    }

  }, []);

  const trackButtonClick = function(linkText: string) {
    clickGTM('navigation_click', 'Nav Buttons', linkText);
  };

  useEffect(() => {
    fetchHeaderLinks();
  }, [fetchHeaderLinks]);

  return (
    headerLinks?.length! > 0 ?
    <LinksBarWrapper>
      {errors?.map((i) => i)}

      {headerLinks?.map(({cta_url, cta_label, internal_link}) => {
        const pageURL = internal_link?.[0];
        const isHomePage = pageURL && pageURL?._slug?.includes('home');
        const homePageUrl = isHomePage && (isLoggedIn && isCurrentUserActive ? 'homepage-member-active' :
            isLoggedIn && !isCurrentUserActive ? 'homepage-member-inactive': 'home-page') ;
        const btnLink = homePageUrl || (pageURL ? pageURL._slug : cta_url);

        return (
          btnLink && cta_label && (
            !!pageURL ? (
                <NavLinkCustom key={uuidv4()}
                               to={`/${btnLink}`}
                               onClick={() => trackButtonClick(cta_label)}
                               isActive={() => checkIsUrlActive(`/${btnLink}`)}>
                  <LinkLabel>{cta_label}</LinkLabel>
                </NavLinkCustom>
            ) :
              <LinkCustom key={uuidv4()}
                          href={btnLink}
                          target='_blank'>
                <LinkLabel>{cta_label}</LinkLabel>
              </LinkCustom>
          )
        )
      })}
    </LinksBarWrapper> :
    <></>
  )
}
export default LinksBar;
