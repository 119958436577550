import styled, { css } from 'styled-components';
import { StepBox, StepImg } from '../../../redesign/components/shared/Step/Step.styles';

export const StepsWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    width: 100%;
    z-index: 30;

    ${mediaQueries.minWidthSM} {
      margin-left: -12px;
      margin-right: -12px;
      width: calc(100% + 24px);
      overflow: hidden;
    }
  `;
});

interface StepsFlexRowProps{
  itemsPerRow: number;
}

export const StepsFlexRow = styled.div<StepsFlexRowProps>(({ theme, itemsPerRow = 3 }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;

    position: relative;
    width: 100%;

    ${StepBox} {
      width: 100%;
    }

    ${mediaQueries.minWidthSM} {
      ${StepBox} {
        width: ${() => itemsPerRow <=3 ? `calc(100% / ${+itemsPerRow} - 1%)` : `calc(100% / 3 - 1%)`};
      }
      ${StepImg}{
        max-height: ${() => itemsPerRow === 1 && 'initial'};
        
        img {
          width: ${() => itemsPerRow === 1 && '100%'};
        }
      }
    }
  `;
});
