import axios from 'axios'
import { apiURL } from '../../config/config'

export const logout = (token, country) => {
  return axios({
    url: apiURL + `/api/Auth/logout`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    params: {
      "country": country
    }
  })
}

export const updateEmail = (token, userId, email) => {
  return axios({
    url: apiURL + `/api/Customer/${userId}/username`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    },
    data: {
      "email": email
    }
  })
}

//Change password
export const changePassword = (currentPass, newPass, email) => {
  return axios({
    url: apiURL + `/api/Access/changePassword`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      "currentPassword": currentPass,
      "newPassword": newPass,
      "emailOrCardNumber": email
    }
  })
}

//Find user details and id's
export const customerDetails = (accountId, token) => {
  return axios({
    url: apiURL + `/api/Customer/${accountId}/customerDetails`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token,
    },
    params: {
      "accountId": accountId,

    }
  })
}

// Re-send confirm email
export const reSendEmail = (email) => {
  return axios({
    url: apiURL + "/api/Register/resendConfirmationEmail",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      "emailOrCardNumber": email
    }
  });
};

// Check card number for forgot email
export const checkCardNum = (cardNum) => {
  return axios({
    url: apiURL + "/api/Auth/checkCardStatus",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      cardNumber: cardNum
    }
  })
}

// Check card number for forgot email
export const getSecurityQuestion = (cardNum) => {
  return axios({
    url: apiURL + "/api/Access/getSecurityQuestion",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      emailOrCardNumber: cardNum
    }
  })
}

//Send security answer
export const sendSecAnswer = (settings) => {
  return axios({
    url: apiURL + "/api/Access/forgottenPassword",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      "answer": settings.answer,
      "emailOrCardNumber": settings.emailOrCardNumber
    }
  })
}

// Login request to obtain a token
export const authLogin = (settings) => {
  return axios({
    url: apiURL + "/api/Auth/login",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      "email": settings.email,
      "cardNumber": settings.cardNumber,
      "password": settings.password,
      "country": settings.country,
      "token": settings.token
    }
  });
};

// Registration with card
export const regWithCard = (settings) => {
  return axios({
    url: apiURL + "/api/Register/withCard",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      "cardNumber": settings.cardNumber,
      "activationCode": settings.activationCode,
      "email": settings.email,
      "password": settings.password,
      "firstName": settings.firstName,
      "lastName": settings.lastName,
      "country": settings.country,
      "language": settings.language,
      "securityQuestion": settings.securityQuestion,
      "securityAnswer": settings.securityAnswer,
      "prefix": settings.prefix,
      "gender": settings.gender,
      "phoneNumber": settings.phoneNumber,
      "usePhoneNumber": settings.usePhoneNumber,
      "preferences": settings.preferences
    }
  });
}

// Registration without a card
export const regNoCard = (settings) => {
  return axios({
    url: apiURL + "/api/Register/withoutCard",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      "email": settings.email,
      "password": settings.password,
      "firstName": settings.firstName,
      "lastName": settings.lastName,
      "country": settings.country,
      "language": settings.language,
      "communicationsUse": settings.communicationsUse,
      "securityQuestion": settings.securityQuestion,
      "securityAnswer": settings.securityAnswer,
      "prefix": settings.prefix,
      "gender": settings.gender,
      "phoneNumber": settings.phoneNumber,
      "preferences": settings.preferences,
      "usePhoneNumber": settings.usePhoneNumber,
    }
  });
}

export const sendAccessCodeEmail = (identifier, token) => {
  return axios({
    url: apiURL + "/api/Auth/sendAccessCodeEmail",
    method: "PUT",
    headers: {
      "Content-Type": "application/json-patch+json"
    },
    data: {
      identifier,
      token
    }
  })
}

export const updatePasswordViaCode = (settings) => {
  const { identifier, accessCode, newPassword } = settings;
  return axios({
    url: apiURL + "/api/Auth/updatePasswordViaCode",
    method: "POST",
    headers: {
      "Content-Type": "application/json-patch+json"
    },
    data: {
      identifier,
      accessCode,
      newPassword
    }
  })
}

//Parce url to find GET param
export const parseUrl = (getParam) => {
  const url = window.location.href;
  const get = url.split("?")[1];
  if (get) {
    return get.split("&").map((param) => {
      return param.split("=")[0] === getParam ? true : false;
    })
  }
}
