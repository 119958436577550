import React from 'react';
import { QuotesTypes } from "./quotes.types";
import { QuotesContent, QuotesContainer } from "./Quotes.styles";
import { Container, Wrapper } from "../../../redesign/theme/GlobalStyles";

const Quotes: React.FC<QuotesTypes> = ({ params }) => {

  return (
    <Wrapper>
      <Container maxWidth={'1200px'}>
        <QuotesContainer backgroundColor={params?.background_color}>
          <QuotesContent dangerouslySetInnerHTML={{ __html: `${params?.text}` }}
                         fontColor={params?.font_color}/>
        </QuotesContainer>
      </Container>
    </Wrapper>
  )
};

export default Quotes;
