import styled, { css } from 'styled-components';
import { Shape2, Shape3 } from '../../assets';
import { Container } from '../../theme/GlobalStyles';
import { AppButtonListStyles } from '../shared/AppButtonList/AppButtonList.styles';

export const WrapperShapes = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    position: relative;
    background-color: ${color.white};
    min-height: 100vh;
    
    &:before,
    &:after{
      content: '';
      display: block;
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:before{
      top: 0;
      right: 0;
      background-image: url('${Shape3}');
      background-position: bottom left;
      width: 35vw;
      height: 35vh;

      ${mediaQueries.md} {
        width: 65vw;
        height: 25vh;
      }
    }

    &:after{
      bottom: 0;
      left: 0;
      background-image: url('${Shape2}');
      background-position: top right;
      width: 30vw;
      height: 30vh;

      ${mediaQueries.md} {
        width: 55vw;
        height: 18vh;
      }
    }
    
    .sentEmailBtn{
      width: 100%;
    }
    
    ${AppButtonListStyles}{
      margin-top: 0;
      margin-bottom: 30px;
    }
  `;
});

export const ContainerShapes = styled(Container)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 1;

    ${mediaQueries.sm} {
      padding-bottom: 22vh;
      padding-top: 22vh;
    }
  `;
});

export const Title = styled.h1(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    font-size: ${size.px32};
    margin-bottom: 10px;
    
    ${mediaQueries.md} {
      font-size: ${size.px24};
    }
  `;
});

export const Text = styled.p(({ theme }) => {
  const { color } = theme;

  return css`
    color: ${color.graniteGray};
    margin-bottom: 30px;
    
    p, span{
      color: ${color.graniteGray};
    }
  `;
});