import styled, {css} from 'styled-components';

export const ModalQuestionWrapper = styled.div(() => {
	return css`
		position: fixed;
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.7);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	`;
});

export const ModalCloseBtn = styled.button(() => {
	return css`
		border: none;
		padding: 0;
		background: transparent;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	`;
});

export const ModalBlock = styled.div(({theme}) => {
	const {color} = theme;
	return css`
		max-width: 467px;
		width: 92%;
		box-shadow: 0 4px 60px rgba(0, 51, 153, 0.4);
		border-radius: 8px;
		background-color: ${color.white};
		padding: 40px 25px 25px;
		position: relative;
	`;
});

export const ModalSubtitle = styled.p(({theme}) => {
	const {color, size, font} = theme;
	return css`
		font-size: ${size.px14};
		line-height: 1.2;
		color: ${color.navy};
		margin: 0 0 15px;
		font-family: ${font.family.EMPrintRegular};
	`;
});

export const ModalTitle = styled.p(({theme}) => {
	const {color, size, font} = theme;
	return css`
		font-size: ${size.px18};
		line-height: 1.2;
		color: ${color.navy};
		margin: 0 0 20px;
		font-family: ${font.family.EMPrintSemi};
		font-weight: ${font.weight.semiBold};
	`;
});

export const FormWrap = styled.div(() => {
	return css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	`;
});

export const ModalInputWrap = styled.div(({theme}) => {
	const {color} = theme;

	return css`
		position: relative;
		width: 100%;
		margin: 0 0 20px;

		&.lastInputWrap{
			margin: 0 0 40px;
		}
		
		input{
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked{
				~.checkmark {
					:after {
						opacity: 1;
					}
				}
			}
		}

		.checkmark {
			position: absolute;
			top: 50%;
			margin-top: -8px;
			left: 20px;
			height: 16px;
			width: 16px;
			border: 1px solid ${color.navy};
			border-radius: 50%;

			&:after{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: rgb(0,71,186);
				background: linear-gradient(90deg, rgba(0,71,186,1) 0%, rgba(0,125,222,1) 100%);
				transform: translate(-50%, -50%);
				transition: opacity 0.2s;
				opacity: 0;
			}
		}
	`;
});

export const ModalLabel = styled.label(({theme}) => {
	const {color, size, font} = theme;

	return css`
		display: block;
		font-family: ${font.family.EMPrintRegular};
		font-size: ${size.px16};
		line-height: 1;
		color: ${color.navy};
		cursor: pointer;
		user-select: none;
		border: 1px solid ${color.navy};
		border-radius: 2px;
		align-self: stretch;
		padding: 20px 20px 20px 56px;
		margin: 0;
		transition: background-color 0.2s;

		&.error {
			border-color: ${color.lightRed};
		}
	`;
});

export const ErrorWrap = styled.p(({theme}) => {
	const {color, size, font} = theme;
	return css`
		position: absolute;
		bottom: -40px;
		left: 0;
		font-size: ${size.px12};
		line-height: 1.2;
		font-family: ${font.family.EMPrintRegular};
		color: ${color.error};
		padding-left: 20px;
		margin: 0;
		
		&:before {
			content: '';
			width: 3px;
			height: 3px;
			background-color: ${color.lightRed};
			position: absolute;
			left: 7px;
			top: 50%;
			transform: translateY(-50%);
		}
	`;
});

export const ButtonSendWrap = styled.div(({theme}) => {
	const {color, size, font, mediaQueries} = theme;
	return css`
		margin-top: 20px;

		${mediaQueries.md} {
			width: 100%;
		}

		button {
			font-family: ${font.family.EMPrintSemi};
			font-weight: ${font.weight.semiBold};
			font-size: ${size.px14};
			line-height: 1.2;
			cursor: pointer;
			background: rgb(0,71,186);
			background: linear-gradient(90deg, rgba(0,71,186,1) 0%, rgba(0,125,222,1) 100%);
			border-color: ${color.navy};
			border-radius: 2px;
			color: ${color.white};
			min-width: 115px;
			
			&:disabled{
				opacity: 0.7;
			}

			${mediaQueries.md} {
				min-height: 50px;
				text-decoration: none;
			}
		}
	`;
});
