import { apiLang, shopLang } from '../../config/config'

export default function SetOneTrustCookies() {

  const langAttribute = () => {
    switch(shopLang) {
      case 'nl_nl': 
        return 'nl-NL'
      case 'be_nl': 
        return 'nl-BE'
      case 'be_fr':
        return 'fr-BE'
      default: 
      return 'nl-NL'
    }
  }

  const nlProdDomainScript = 'b4ac0f74-ea1a-499d-ad6e-2249ea1b1bbd',
        nlStageDomainScript = 'b4ac0f74-ea1a-499d-ad6e-2249ea1b1bbd-test';

  const beProdDomainScript = '5c36e6f3-68b0-4d38-9bf8-f7f4216510cb',
        beStageDomainScript = '5c36e6f3-68b0-4d38-9bf8-f7f4216510cb-test';

  const envCookiesDomainScript = process.env.REACT_APP_ENV === "prod" ?  
                              (apiLang === 'nl' ? nlProdDomainScript : beProdDomainScript)
                              :
                              (apiLang === 'nl' ? nlStageDomainScript : beStageDomainScript);
  const insertTagBeforeElement = document.querySelector('title');

  function insertScriptBefore(script, referenceNode) {
    referenceNode.parentNode.insertBefore(script, referenceNode.previousSibling);
  }

  function setCookiesScript() {
    const cookiesScriptTag = document.createElement('script');
    cookiesScriptTag.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    cookiesScriptTag.dataset.domainScript = envCookiesDomainScript;
    cookiesScriptTag.dataset.documentLanguage = 'true';
    cookiesScriptTag.type = 'text/javascript';
    cookiesScriptTag.charset = 'UTF-8';
    insertScriptBefore(cookiesScriptTag, insertTagBeforeElement)
  }

  function setOptanonWrapperFunction() {
    const optanonWrapperScript = document.createElement('script'); 
    optanonWrapperScript.type = 'text/javascript';
    optanonWrapperScript.text = 'function OptanonWrapper() { }'
    insertScriptBefore(optanonWrapperScript, insertTagBeforeElement)
  }

  document.querySelector('html').setAttribute('lang', langAttribute())
  setCookiesScript();
  setOptanonWrapperFunction();
}