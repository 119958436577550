import scrollIntoView from 'scroll-into-view-if-needed';
import i18n from '../../config/i18n';
import { isBridge } from '../common/MobileBridge';
import { logout } from './_auth-helpers';
import { envSettings, shopLang } from '../../config/config';
import { clearLocaleStorage } from "../../redesign/utils";

/* global EssoWebDeviceBridge */

export const setMetaDesription = () => {
  const metaDescriptrion = document.querySelector('meta[name="description"]');
  const descriptionString = i18n.t('01_meta_description');
  metaDescriptrion.setAttribute('content', descriptionString);
};

export const handoverConstructor = (link) => {
  switch (shopLang) {
    case 'be_nl':
      return envSettings.handoverPath() + link + '&___store=be_nl';
    case 'be_fr':
      return envSettings.handoverPath() + link + '&___store=be_fr';
    default:
      return envSettings.handoverPath() + link;
  }
};

export const linkConstructor = (link) => {
  switch (shopLang) {
    case 'be_nl':
      return link + '?___store=be_nl';
    case 'be_fr':
      return link + '?___store=be_fr';
    default:
      return link;
  }
};

export const handleUnauthorize = (error, token) => {
  const calliOSApp = () => {
    try {
      window.webkit.messageHandlers.callbackHandler.postMessage('refreshToken');
    } catch (err) {
      console.log('The native context does not exist yet');
    }
  };
  if (error.response.status === 401) {
    calliOSApp();
    if (isBridge()) {
      EssoWebDeviceBridge.refreshToken();
    } else {
      logout(token, 'nl')
        .then((response) => {
          clearLocaleStorage();
          window.location.href = '#/auth/login';
          window.location.reload();
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  }
};

export const checkLoggedIn = () => {
  let isLoggedIn = localStorage.getItem('authorized');
  if (isLoggedIn) {
    return true;
  } else {
    return false;
  }
};

export const handleScroll = (e, id) => {
  e && e.preventDefault();
  let node = document.getElementById(id);
  if (!node) return;
  scrollIntoView(node, {
    scrollMode: 'if-needed',
    block: 'start',
    inline: 'nearest',
    behavior: 'smooth'
  });
};

export const matchCategories = (categories, catID) => {
  if (!categories.default) {
    let category = i18n.t('06-empty_page_My_order_text_1');
    let matchedType = categories.types.find((type) => type.id === catID);
    return typeof matchedType !== 'undefined'
      ? matchedType.description
      : category;
  }
};

export const matchOrderCat = (categories, catID) => {
  if (!categories.default) {
    let category = i18n.t('06-empty_page_My_order_text_1');
    let matchedType = categories.categories.find((type) => type.id === catID);

    return typeof matchedType !== 'undefined' ? matchedType.name : category;
  }
};

export const checkLanguageDomain = () => {
  const currentLang = localStorage.getItem('i18nextLng');
  const domain = window.location.host;
  const isCimode = window.location.href.indexOf('cimode') > 0;
  const isNL = domain.indexOf('.nl') > 0;
  const isBE = domain.indexOf('.be') > 0;

  if (isCimode) {
    return;
  } else if (isNL) {
    i18n.changeLanguage('nl');
    localStorage.setItem('apiLang', 'nl');
  } else if (isBE) {
    currentLang !== 'fr' && i18n.changeLanguage('vl');
    localStorage.setItem('apiLang', 'be');
  }
};

export const isWebView = () => {
  const webViewMatch = '/ESSO-WebView';
  return navigator.userAgent.includes(webViewMatch);
};

export const importAllImages = (path) => {
  let images = {};
  path.keys().map((item) => (images[item.replace('./', '')] = path(item)));

  return images;
};

export const getLocale = () => {
  switch (shopLang) {
    case 'be_nl':
      return 'be';
    case 'be_fr':
      return 'be/fr';

    case 'nl_nl':
    default:
      return 'nl';
  }
};

export const getLocaleLink = () => {
  switch (shopLang) {
    case 'be_nl':
      return 'be/nl-be';
    case 'be_fr':
      return 'be/fr-be';
    default:
      return 'nl/nl-nl';
  }
};

export const getLocalePrepr = () => {
  switch (shopLang) {
    case 'be_nl':
      return 'nl-BE';
    case 'be_fr':
      return 'fr-BE';

    case 'nl_nl':
    default:
      return 'nl-NL';
  }
};

export const getShopLocalePrepr = () => {
  switch (shopLang) {
    case 'be_nl':
      return 'be_nl';
    case 'be_fr':
      return 'be_fr';

    case 'nl_nl':
    default:
      return 'nl_nl';
  }
};

export const isIE11 = () => {
  const { userAgent } = window.navigator;
  return userAgent.indexOf('Trident/7.0') > -1;
};

export const checkRecaptcha = (racaptchaKey, callback, funcNotice) => (e)=> {
  e.preventDefault();

  if (!window.grecaptcha) {
    if (racaptchaKey){
      funcNotice('ReCaptcha not initialized!', 'error');
    }else{
      funcNotice('ReCaptcha key is missed!', 'error');
    }
    return;
  }

  window.grecaptcha.ready(() => {
    let getToken = new Promise(resolve => {
      let token = window.grecaptcha.execute(racaptchaKey, { action: 'submit' })
      resolve(token);
    })
    getToken
      .then(token => callback(token))
      .catch((e) => {
        funcNotice('ReCaptcha key is not valid!', 'error');
      });
  });
}