import React from "react";
import { v4 as uuidv4 } from 'uuid';

import { Headline } from "../../../redesign/components/shared";
import { AssetComponentProps } from "./assetComponent.types";
import { Wrapper, Container } from "../../../redesign/theme/GlobalStyles";
import { AssetsWrapper, AssetBox, VideoTitle, VideoBox } from "./AssetComponent.styles";

const AssetComponent: React.FC<AssetComponentProps> = ({ params }) => {
  const assets = params?.assets && params?.assets?.length > 0;

  return (
    assets ?
      <Wrapper>
        <Container maxWidth="1200px">
          {params?.headline && <Headline title={params?.headline} />}
          <AssetsWrapper>
            {params?.assets?.map((i) => {
              let video = i?.cdn_files && i?.cdn_files.length > 0 &&
                i?.cdn_files.find((v) => {
                  let parseUrl = v?.url?.split(".");
                  let videoFormat = parseUrl && parseUrl.length > 0 && parseUrl[parseUrl.length - 1];
                  return videoFormat && (videoFormat?.toLowerCase() === "mp4" || videoFormat?.toLowerCase() === "webm");
                });

              return (
                video ?
                  <AssetBox key={uuidv4()}>
                    <VideoBox>
                      <video controls poster={i?.cover}>
                        <source src={video?.url} />
                      </video>
                    </VideoBox>
                    <VideoTitle>{i?.name}</VideoTitle>
                  </AssetBox>
                  : <></>
              );
            })}
          </AssetsWrapper>
        </Container>
      </Wrapper> :
      <></>
  );
};

export default AssetComponent;
