import styled, { css } from 'styled-components';

export const BrandLinksWrapper = styled.nav(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    display: inline-flex;
    
    ${BrandLinkBox}{
      &:after{
        content: '';
        display: block;
        position: absolute;
        right: -10px;
        top: 50%;
        margin-top: -2px;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: ${color.chineseGray};

        ${mediaQueries.md} {
          right: -4px;
        }
      }
      
      &:last-child{
        margin-right: 0;
        
        &:after{
          display: none;
        }
      }
    }
    
    ${mediaQueries.md} {
      height: 48px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background: ${color.cloudWhite};
      padding: 0 16px;
    }
  `;
});

export const BrandLinkBox = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    margin-right: 16px;
    
    ${mediaQueries.md} {
      margin-right: 4px;
    }
  `;
});

export const BrandLink = styled.a(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    color: ${color.graniteGray};
    line-height: 1;
    margin: 0 16px;
    
    ${mediaQueries.md} {
      
    }
  `;
});
