import React from 'react';

import { Button } from '../../../../redesign/components/shared';
import { BannerProps } from './banner.types';

import { Wrapper, Container } from '../../../../redesign/theme/GlobalStyles';

import { BannerTitle, BannerText } from '../Banners.styles';

import {
  BannerWrapper,
  BannerContainer,
  BannerContent,
  AppButtonsWrap,
  AppStoreButton,
  PlayStoreButton,
  ButtonBox,
  BannerImageWrapper
} from './Banner.styles';

const Banner: React.FC<BannerProps> = ({ params }) => {
  const image = params?.image?.[0];
  const imageUrl = image?.cdn_files?.[0]?.url || image?.url;

  const pageURL = params?.internal_link?.[0];
  const hasLink = pageURL || params?.cta_url !== null;
  const btnLink = pageURL ? pageURL._slug : params?.cta_url;

  return (
    <Wrapper>
      <Container maxWidth={'1250px'}>
        <BannerWrapper darkMode={params?.dark_mode}>
          <BannerContainer>
            <BannerContent>
              <BannerTitle>{params?.headline}</BannerTitle>
              <BannerText>{params?.subtitle}</BannerText>

              {params?.appstore_buttons ? (
                <AppButtonsWrap>
                  {params?.play_store_url && (
                    <PlayStoreButton
                      href={params?.play_store_url}
                      target={'_blank'}
                    />
                  )}
                  {params?.app_store_url && (
                    <AppStoreButton
                      href={params?.app_store_url}
                      target={'_blank'}
                    />
                  )}
                </AppButtonsWrap>
              ) : (
                <>
                  {params?.cta_label && hasLink && (
                    <ButtonBox>
                      <Button
                        nativeLink={pageURL ? false : !!params?.cta_url}
                        url={btnLink}
                        text={params?.cta_label}
                        buttontype={params?.dark_mode ? 'secondary' : 'primary'}
                        backgroundcolor={params?.cta_background_color}
                        textcolor={params?.cta_color}
                      />
                    </ButtonBox>
                  )}
                </>
              )}
            </BannerContent>
            <BannerImageWrapper alignment={params?.image_vertical_alignment}>
              <img
                src={imageUrl}
                width={image?.width}
                height={image?.height}
                alt={image?.name}
              />
            </BannerImageWrapper>
          </BannerContainer>
        </BannerWrapper>
      </Container>
    </Wrapper>
  );
};

export default Banner;