import React from 'react';
import i18n from '../../../../../../../../config/i18n';
import { Description, InfoWrapper, Title } from '../InfoBlock.styles';
import { Subtitle } from '../../../EBikeGame.styles';

const InfoCompletedCampaign = () => {
  return (
      <InfoWrapper>
        <Title>{i18n.t('eBike_completed_title')}</Title>
        <Subtitle>{i18n.t('eBike_completed_subtitle')}</Subtitle>
        <Description>{i18n.t('eBike_completed_text')}</Description>
      </InfoWrapper>
  )
};

export default InfoCompletedCampaign;