import React from 'react';
import i18n from "../../../config/i18n";

import { Button } from "../shared";
import { IconTire } from "../../assets";
import {
  ErrorContainer,
  ErrorContent,
  ErrorImg,
  ErrorText,
  ErrorTitle,
  ErrorWrapper,
  ButtonBox,
} from "./Error404.styles";

function Error404() {
  return (
    <ErrorWrapper>
      <ErrorContainer maxWidth="1250px">
        <ErrorContent>
          <ErrorImg src={IconTire} alt='error 404'/>
          <ErrorTitle>{i18n.t('error404_title')}</ErrorTitle>
          <ErrorText>{i18n.t('error404_text')}</ErrorText>
          <ButtonBox>
            <Button
              url={'/info-contact'}
              text={i18n.t('error404_btn1')}
              buttontype={'primary'}
            />
            <Button
              url={'/'}
              text={i18n.t('error404_btn2')}
              buttontype={'secondary'}
            />
          </ButtonBox>
        </ErrorContent>
      </ErrorContainer>
    </ErrorWrapper>
  );
}

export default Error404;
