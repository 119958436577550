import React from 'react';
import { StepProps } from './step.types';
import { Button } from '../index';

import {
  StepBox,
  StepContent,
  StepImg,
  StepTitle,
  StepText, StepPreTitle, ButtonWrapper
} from './Step.styles';

const Step: React.FC<StepProps> = (props) => {
  const {
    headline,
    description,
    image,
    pre_headline,
    cta_label,
    cta_url,
    button_type,
    internal_link,
  } = props;
  const img = image?.[0];

  const pageURL = internal_link?.[0];
  const hasLink = pageURL || cta_url !== null;
  const btnLink = pageURL ? pageURL._slug : cta_url;

  return (
    <StepBox>
      <StepImg>
        <img
          loading='lazy'
          src={img?.cdn_files?.[0]?.url || img?.url}
          width={img?.width}
          height={img?.height}
          alt={img?.name}
        />
      </StepImg>
      <StepContent>
        {pre_headline && <StepPreTitle>{pre_headline}</StepPreTitle>}
        <StepTitle>{headline}</StepTitle>
        <StepText dangerouslySetInnerHTML={{ __html: `${description}` }} />

        {cta_label && hasLink && (
          <ButtonWrapper>
            <Button
              nativeLink={pageURL ? false : !!cta_url}
              buttontype={button_type || 'primary'}
              text={cta_label}
              url={btnLink}
            />
          </ButtonWrapper>
        )}
      </StepContent>
    </StepBox>
  );
};

export default Step;
