import styled, { css } from 'styled-components';
import { PageBannerProps } from './pageBanner.types';

export const PageBannerWrapper = styled.div<PageBannerProps>(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    position: relative;
    width: 100%;
    height: 100%;
    min-height: 265px;

    ${mediaQueries.xxs} {
      min-height: 200px;
    }
  `;
});

export const PageBannerOverlay = styled.img<PageBannerProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
  min-height: 100%;
`;

export const PageBannerContent = styled.div`
  position: relative;
  z-index: 1;

  width: 100%;
  padding: 0 1rem;
`;

export const PageBannerTitle = styled.h1(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    color: ${color.white};

    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px40};

    text-align: center;
    line-height: 1.3;
    margin: 0 0 5px;

    ${mediaQueries.sm} {
      font-size: ${size.px26};
    }
  `;
});
