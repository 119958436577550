import React, { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import i18n from '../../../../config/i18n';
import { AppButtonList } from '../../shared';
import { apiURL, shopLang } from '../../../../config/config';
import { useLoader } from '../../../hooks';
import { InfoLink, InfoText, InfoWrapper } from './EmailConfirm.styles';
import {
  WrapperShapes,
  ContainerShapes,
  Title,
  Text
} from '../Auth.styles';
import { Phones_BE_FR, Phones_BE_NL, Phones_NL_NL } from '../../../assets';


type EmailConfirmType = {
  showNotice: (text: string, type: string) => void;
};
const EmailConfirm = ({...props} : EmailConfirmType) => {
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const loader = useLoader(!emailConfirmed);

  const prepareString = (string: string) => {
    return string.split('?').pop();
  }

  const confirmEmail = (string: string) => {
    const parsed = qs.parse(string);
    let hash = parsed.v;
    let number = parsed.n;
    let hashedCardNumber = parsed.h;
    let details = parsed.c;

    let data;
    if(hashedCardNumber){
      data = {
        'hashCode': hash,
        'hashedCardNumber': hashedCardNumber,
        'customerDetailsId': details
      }
    }else{
      data = {
        'hashCode': hash,
        'cardNumber': number,
        'customerDetailsId': details
      }
    }

    // @ts-ignore
    if (typeof hash === 'undefined' || (hash && hash.length < 1)) {
      return;
    }

    axios({
      url: apiURL + '/api/Register/confirmEmail',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }).then( (response) => {
      setEmailConfirmed(true);
    }).catch( (error) => {
      console.log(error.response);
    })
  }

  useEffect(() => {
    let string = prepareString(window.location.href) || '';
    confirmEmail(string);
  }, []);

  return (
    loader ||
    <WrapperShapes>
      <ContainerShapes maxWidth={'435px'}>
        <img src={
          shopLang === 'be_fr' ?
            Phones_BE_FR :
            shopLang === 'be_nl' ?
              Phones_BE_NL :
              Phones_NL_NL
        } alt='phones'/>
        <Title>{i18n.t('confirm_email_title')}</Title>
        <Text>{i18n.t('confirm_email_text')}</Text>

        <AppButtonList/>

        <InfoWrapper>
          <InfoText>{i18n.t('confirm_email_additional_info')}</InfoText>{' '}
          <InfoLink to={'/rewards/all'}>{i18n.t('confirm_email_link')}</InfoLink>
        </InfoWrapper>
      </ContainerShapes>
    </WrapperShapes>
  );
}
export default EmailConfirm;