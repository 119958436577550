import styled, { css } from 'styled-components';
import {
  ProductContainerProps,
  StickerBoxProps,
  ImageBoxProps
} from './productHighlights.types';

const contentPadding = '1.7vw'; //25px
const contentPaddingMob = '15px';

const setStyles = (theme: any, itemsPerRow: string) => {
  return css`
    position: relative;
    display: block;
    width: calc(100% / ${+itemsPerRow} - 2%);
    height: 282px;
    margin: 0 1% 20px;
    overflow: hidden;
    cursor: pointer;

    transition: filter 0.2s ease-in-out;

    &:hover {
      filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.2));
      transition: filter 0.2s ease-in-out;
    }

    ${theme.mediaQueries.sm} {
      width: 100%;
      height: 222px;
      margin: 0 0 20px;
    }
  `;
};

export const ProductContainerLink = styled.a<ProductContainerProps>(
  ({ theme, itemsPerRow = '2' }) => {
    return css`
      ${setStyles(theme, itemsPerRow)}
    `;
  }
);

export const ProductContainerForm = styled.form<ProductContainerProps>(
  ({ theme, itemsPerRow = '2' }) => {
    return css`
      ${setStyles(theme, itemsPerRow)}
    `;
  }
);

export const ImageBox = styled.div<ImageBoxProps>(({ theme, imageUrl }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('${imageUrl}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 16px;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
        1.03deg,
        rgba(0, 0, 0, 0.6) 0.64%,
        rgba(0, 0, 0, 0) 98.89%
      );
    }

    ${mediaQueries.sm} {
      border-radius: 8px;
    }
  `;
});

export const ContentBox = styled.div(({ theme }) => {
  const { mediaQueries } = theme;
  return css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: ${contentPadding};
    z-index: 2;

    ${mediaQueries.sm} {
      padding: ${contentPaddingMob};
    }
  `;
});

export const ContentText = styled.p(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px24};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    color: ${color.white};
    margin: 0;

    max-height: calc(${size.px24} * 1.2 * 5);
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    ${mediaQueries.sm} {
      font-size: ${size.px20};
      max-height: calc(${size.px20} * 1.2 * 5);
    }
  `;
});

export const StickerBox = styled.div<StickerBoxProps>(
  ({ theme, isVisible = true }) => {
    const { color, mediaQueries } = theme;

    return css`
      display: ${() => (isVisible ? 'flex' : 'none')};
      position: absolute;
      top: ${contentPadding};
      right: ${contentPadding};
      width: 88px;
      height: 88px;
      border-radius: 50%;
      background: ${color.azure};
      box-shadow: 0 4px 20px rgba(0, 174, 255, 0.24);
      padding: 0 10px;
      text-align: center;

      flex-direction: column;
      align-items: center;
      justify-content: center;

      z-index: 2;

      ${mediaQueries.sm} {
        top: ${contentPaddingMob};
        right: ${contentPaddingMob};
        width: 56px;
        height: 56px;
      }
    `;
  }
);

export const StickerText = styled.p(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px14};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    color: ${color.white};
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    ${mediaQueries.sm} {
      font-family: ${font.family.sansSerif};
      font-size: ${size.px10};
      font-weight: ${font.weight.bold};
    }
  `;
});

export const StickerPercent = styled.p(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px20};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    color: ${color.white};
    margin: 0;

    ${mediaQueries.sm} {
      font-size: ${size.px14};
    }
  `;
});
