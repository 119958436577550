import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import i18n from '../../../../../../../config/i18n';
import APIService from '../../../../api_campaign/api';
import { Button } from '../../../../../../../redesign/components/shared';
import {
	ModalQuestionWrapper,
	ModalBlock,
	ButtonSendWrap,
	FormWrap,
	ErrorWrap,
	ModalSubtitle,
	ModalTitle,
	ModalLabel,
	ModalInputWrap,
	ModalCloseBtn
} from './ModalQuestions.style';
import { IconCloseBtn } from '../../../../../../assets';

function ModalQuestions() {
	const isShowModal = useSelector((state: any) => state?.isShowModalCampaign)
	const [isCheck, setIsCheck] = useState<boolean>(true);
	const [localAnswerState, setLocalAnswerState] = useState<boolean>(false);
	const [error, setError] = useState<boolean | null>(null);

	const onRadioChanged = (event: any) => {
		setIsCheck(false);

		if (event.target.value === 'true') {
			setError(false);
			setLocalAnswerState(true);
		} else {
			setLocalAnswerState(false);
		}
	};

	const hideModal = () => {
		setError(false);
		APIService.setIsShowModal(false);
	};

	const submitAnswer = () => {
		if (localAnswerState) {
			APIService.setIsModalAnswerCorrect(true);
			setError(false);
			hideModal();
		} else {
			APIService.setIsModalAnswerCorrect(false);
			setError(true);
		}
	};

	return (
		isShowModal ?
		<ModalQuestionWrapper>
			<ModalBlock>
				<ModalCloseBtn onClick={() => hideModal()}>
					<img src={IconCloseBtn} alt='close modal' />
				</ModalCloseBtn>
				<ModalSubtitle>{i18n.t('eBike_validation_question_subtitle')}</ModalSubtitle>
				<ModalTitle>{i18n.t('eBike_validation_question_title')}</ModalTitle>
				<FormWrap>
					<ModalInputWrap>
						<input type='radio' name='answer' id='answer1' value={'true'} onChange={onRadioChanged} />
						<ModalLabel htmlFor='answer1'>{i18n.t('eBike_validation_question_1_answer')}</ModalLabel>
						<span className='checkmark'/>
					</ModalInputWrap>

					<ModalInputWrap className='lastInputWrap'>
						<input type='radio' name='answer' id='answer2' value={'false'} onChange={onRadioChanged} />
						<ModalLabel htmlFor='answer2' className={error ? 'error' : ''}>
							{i18n.t('eBike_validation_question_2_answer')}
						</ModalLabel>
						<span className='checkmark'/>
						{error && (
							<ErrorWrap>
								{i18n.t('eBike_validation_question_error')}
							</ErrorWrap>
						)}
					</ModalInputWrap>

					<ButtonSendWrap>
						<Button
							disabled={isCheck}
							text={i18n.t('eBike_validation_question_btn')}
							buttontype={'primary'}
							onClick={submitAnswer}
						/>
					</ButtonSendWrap>
				</FormWrap>
			</ModalBlock>
		</ModalQuestionWrapper> : <></>
	);
}

export default ModalQuestions;
