import styled, { css } from 'styled-components';
import { Container } from 'redesign/theme/GlobalStyles';

export const HGWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.sm} {
      
    }
  `;
});

export const HGContainer = styled(Container)(({ theme }) => {
  const {mediaQueries } = theme;

  return css`
    padding: 0;

    ${mediaQueries.sm} {
    }
  `;
});

export const RefWrapp = styled.div``;