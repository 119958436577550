import styled, { css } from 'styled-components';
import { IconAppAndroid, IconAppIos, ShapeBlue, ShapeBannerImage,
  ShapeBannerImage2,
  BlueOval15,
  ShapeBannerImage2Mob
} from '../../../assets';
import { BannerContainerBase, BannerContentBase, BannerWrapperBase } from '../Banners.styles';


type BannerImageProps = {
  alignment?: string;
};

export const BannerWrapper = styled(BannerWrapperBase)(() => {
  return css`
	`;
});

export const BannerContainer = styled(BannerContainerBase)(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    display: flex;
    
    ${mediaQueries.sm} {
      flex-wrap: wrap-reverse;
    }
	`;
});

export const BannerContent = styled(BannerContentBase)(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    ${mediaQueries.sm} {
    }
	`;
});

export const BannerImageWrapper = styled.div<BannerImageProps>(({ theme, alignment }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    display: flex;
    align-items: ${() => alignment === 'top' ? 'flex-start' : alignment === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 50%;
    background-image: url(${ShapeBlue});
    background-repeat: no-repeat;
    background-size: 180%;
    background-position: 40px 50%;
    margin: -40px -40px -40px 40px;

    ${mediaQueries.md} {
      background-size: 210%;
    }

    ${mediaQueries.sm} {
      width: calc(100% + 30px);
      margin: -15px -15px 15px -15px;
      background-image: url(${BlueOval15});
      background-position: 62% 98.5%;
      background-size: 180%;
    }
    
    &:before,
    &:after{
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
    }
    
    &:before{
      top: 0;
      right: 0;
      width: 160px;
      height: 90px;
      background-position: top right;
      background-image: url(${ShapeBannerImage});

      ${mediaQueries.sm} {
        width: 33%;
        height: 23%;
      }
    }
    
    &:after{
      bottom: 0;
      left: -40px;
      width: 250px;
      height: 60px;
      background-position: 0 100%;
      background-image: url(${ShapeBannerImage2});

      ${mediaQueries.sm} {
        left: 0;
        width: 15%;
        height: 75%;
        background-image: url(${ShapeBannerImage2Mob});
      }
    }
    
    img{
      width: 85%;
      height: auto;
      vertical-align: bottom;
      z-index: 1;

      ${mediaQueries.md} {
        width: 94%;
      }

      ${mediaQueries.sm} {
        max-width: 500px;
        width: calc(100% - 30px);
        padding-top: ${() => alignment !== 'top' ? '15px' : '0px'};
      }
    }
  `;
});

export const AppButtonsWrap = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    display: flex;
    margin-top: 40px;
    
    ${mediaQueries.sm} {
      justify-content: space-between;
      margin-top: 20px;
    }
	`;
});

const AppButton = styled.a(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    display: block;
    height: 48px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    text-decoration: none;
    opacity: 1;
    transition: opacity 0.3s;
    
    ${mediaQueries.sm} {
      height: 40px;
    }
    
    &:hover {
      opacity: 0.7;
    }
	`;
});

export const AppStoreButton = styled(AppButton)(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    width: 163px;
    background-image: url(${IconAppIos});
    
    ${mediaQueries.sm} {
      width: 136px;
    }
	`;
});

export const PlayStoreButton = styled(AppButton)(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    width: 147px;
    background-image: url(${IconAppAndroid});
    margin-right: 15px;
    
    ${mediaQueries.sm} {
      width: 123px;
    }
	`;
});

export const ButtonBox = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    display: inline-flex;
    margin-top: 40px;
    
    a {
      text-decoration: none;
    }
    
    ${mediaQueries.sm} {
      display: block;
      margin-top: 20px;
    }
	`;
});