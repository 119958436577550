import ReactGA from 'react-ga4';
import { shopLang } from '../../config/config';

let counter = 0;
let isAdded = false;

export const addGTM = (sendTo, options, gtmID='DC-3743070') => {
	const defaultParameters = {
		allow_custom_scripts: true,
		send_to: `${gtmID}/${sendTo}`,
		event_callback: function () {
			console.log('Google Analytics is ready');
		},
	};

	if (window.gtag && !isAdded) {
		window.gtag('event', 'conversion', {
			...defaultParameters,
			...options,
		});
	} else if (counter <= 3) {
		counter++;
		setTimeout(() => addGTM(sendTo, options, gtmID='DC-3743070'), 2000);
	}
};

export const addGTMLocalisationBE = (catId) => {
	if (window.location.host.indexOf('.be') > 0) {
		const axel = Math.random() + '';
		const a = axel * 10000000000000;

		const doubleClickIframe = document.createElement('iframe');
		doubleClickIframe.src = `https://4212618.fls.doubleclick.net/activityi;src=4212618;type=essoe0;cat=${
			catId ? catId : '2020_00'
		};u1=${shopLang};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1;num=${a}?`;
		doubleClickIframe.height = '1';
		doubleClickIframe.width = '1';
		doubleClickIframe.frameborder = '0';
		doubleClickIframe.style.display = 'none';

		const noScriptIframe = document.createElement('noscript');
		noScriptIframe.appendChild(doubleClickIframe.cloneNode(false));

		const firstBodyChild = document.body.firstChild;
		document.body.insertBefore(noScriptIframe, firstBodyChild);
		document.body.insertBefore(doubleClickIframe, firstBodyChild);
	}
};


export const addGTMLocalisationNL = (catId, type) => {
	if (window.location.host.indexOf('.nl') > 0) {
		const axel = Math.random() + '';
		const a = axel * 10000000000000;

		const doubleClickIframe = document.createElement('iframe');
		doubleClickIframe.src = `https://3743070.fls.doubleclick.net/activityi;src=3743070;type=${type};cat=${catId};u1=${shopLang};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1;num=${a}?`;
		doubleClickIframe.height = '1';
		doubleClickIframe.width = '1';
		doubleClickIframe.frameborder = '0';
		doubleClickIframe.style.display = 'none';

		const noScriptIframe = document.createElement('noscript');
		noScriptIframe.appendChild(doubleClickIframe.cloneNode(false));

		const firstBodyChild = document.body.firstChild;
		document.body.insertBefore(noScriptIframe, firstBodyChild);
		document.body.insertBefore(doubleClickIframe, firstBodyChild);
	}
};

export const clickGTM = (eventAction, eventCategory, eventLabel) => {
	ReactGA && ReactGA.event(eventAction,{
		category: eventCategory,
		label: eventLabel,
	});
};

export const pageViewGTM = (pagePath, pageTitle, pageLocation) => {
	ReactGA && ReactGA.send({
		hitType: 'pageview',
		page: pagePath,
		title: pageTitle,
		location: pageLocation
	});
};