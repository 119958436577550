import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import TabItem from './TabItem';
import { TabsProps } from './tabs.types';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import { TabsContainer } from './Tabs.styles';

SwiperCore.use([Navigation]);
const Tabs: React.FC<TabsProps> = ({
  tabs = [],
  activeTab,
  handleSetActiveTabs
}) => {
  return (
    <TabsContainer className="isCustomSlider">
      <Swiper
        initialSlide={activeTab}
        slidesPerView={'auto'}
        spaceBetween={8}
        navigation
      >
        {tabs.map(({ _id, title, _slug, icon, active_icon }, index) => {
          const tab = {
            title: title,
            icon: icon,
            activeIcon: active_icon,
            isActive: index === activeTab
          };
          return (
            <SwiperSlide
              key={_id || index.toString()}
              onClick={() => handleSetActiveTabs(index, title)}
            >
              <TabItem {...tab} tabIndex={tabs.length * 100 - index} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </TabsContainer>
  );
};

export default Tabs;
