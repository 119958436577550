import React from 'react';
import { useSelector } from 'react-redux';

import CompletedCampaign from './CompletedCampaign';
import LoggedInUser from './LoggedInUser';
import UnLoggedInUser from './UnLoggedInUser';
import { checkLoggedIn } from '../../../../../components/utils/_app-helpers';
import { eBikeGameProps } from './eBikeGame.types';
import {
  EBikeGameWrapper
} from './EBikeGame.styles';

const EBikeGame: React.FC<eBikeGameProps> = () => {
  const isLoggedIn = checkLoggedIn();
  const currentUser = useSelector((state: any) => state.currentUser);
  const isFinished = useSelector((state: any) => state?.campaignInfo?.campaignGames[0]?.isFinished);

  return (
      <EBikeGameWrapper>
        { isFinished ?
          <CompletedCampaign />
          : (isLoggedIn && currentUser && !!currentUser.accountID) ? (
              <LoggedInUser/>
            ) :
            <UnLoggedInUser/>
        }
      </EBikeGameWrapper>
  )
};

export default EBikeGame;
