import styled, { css } from 'styled-components';

export const ProductAutomaticRow = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    position: relative;
    width: 100%;

    .swiper-container {
      padding-bottom: ${size.px40};

      ${mediaQueries.sm} {
        padding-bottom: 0;
      }
    }
  `;
});
