import React, { Suspense, lazy, useState, useEffect } from "react";
import SVG from 'react-inlinesvg';
import qs from "qs";
import i18n from "../../../../../../config/i18n";
import {
	IconArrow,
	IconWorld,
	LangButton,
	LangButtonText,
	LangSelectorBox,
	LangSelectorWrapper
} from "./LanguageSelector.styles";
import {IconArrowDownGrey, IconWorldGrey} from "../../../../../assets";

import BelgLangs from './BelgLangs';
const DropDownMenu = lazy(() => import('./DropDownMenu'));

const LanguageSelector = () => {
	const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState(false);

	const changeLanguageWithParam = () => {
		let string = window.location.href.split('?').pop();
		let location = window.location.hash;
		const parsed = string && qs.parse(string);
		let lang = parsed && parsed.lang;

		if (lang === 'nl') {
			i18n.changeLanguage('nl');
			window.location.replace(location.replace('?lang=' + lang, ''));
			window.location.reload();
		} else if (lang === 'fr') {
			i18n.changeLanguage('fr');
			window.location.replace(location.replace('?lang=' + lang, ''));
			window.location.reload();
		}

		if (typeof lang === 'undefined' || (lang?.length && lang.length < 1)) {
			return false;
		}
	};

	const toggleActive = () => {
		setIsDropDownMenuOpen((prev) => !prev);
	};

	useEffect(() => {
		changeLanguageWithParam();
	})

	return (
		<LangSelectorWrapper>
			<LangSelectorBox>

				<LangButton onClick={toggleActive}
										className={isDropDownMenuOpen ? 'active' : ''}>
					<LangButtonText>
						<IconWorld>
							<SVG src={IconWorldGrey} />
						</IconWorld>
						{i18n.t("00_top_nav-menu-3")}
					</LangButtonText>
					<IconArrow>
						<SVG src={IconArrowDownGrey} />
					</IconArrow>
				</LangButton>

				<Suspense fallback={null}>
					<DropDownMenu isDropDownMenuOpen={isDropDownMenuOpen}/>
				</Suspense>
			</LangSelectorBox>

			<BelgLangs />

		</LangSelectorWrapper>
	);
};

export default LanguageSelector;
