import { useState, useEffect } from 'react';

const useWindowScrollY = () => {
  const [windowScrollY, setWindowScrollY] = useState(window.scrollY);

  useEffect(() => {
    function handleScrollY() {
      setWindowScrollY(window.scrollY);
    }
    window.addEventListener('scroll', handleScrollY);
    handleScrollY();
    return () => window.removeEventListener('resize', handleScrollY);
  }, []);

  return windowScrollY;
}

export default useWindowScrollY;
