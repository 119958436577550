const removeBrackets = (
    pattern: string,
    args: Record<string, string | number>
): string => {
    let path = pattern;

    Object.entries(args).forEach(([key, value]) => {
        const argument = new RegExp(`{${key}`, 'gi');

        path = path.replace(argument, value.toString());
    });

    return path.replace(/[\])}[{(]/g, '');
};

export default removeBrackets;