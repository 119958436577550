import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { shopLang } from '../../../../config/config';
import { checkLoggedIn } from '../../../../components/utils/_app-helpers';
import { useLoader, useImages } from '../../../../redesign/hooks';
import APIService from '../../EndYearCampaignCustom/apiEndYearCampaign/api';
import clearLocaleStorage from '../../../../redesign/utils/clearLocaleStorage';
import EYCHeader from './components/EYCHeader';
import EYCInfo from './components/EYCInfo';
import * as images from './assets';
import * as imagesPhase1 from './assets/phase1';
import * as imagesPhase2 from './assets/phase2';

const EYCCampaign = () => {
  const history = useHistory();
  const location = useLocation();
  const locale = shopLang.slice(0, 2).toLowerCase();
  const isLoggedIn = checkLoggedIn();
  const campaignGame = useSelector((state: any) => state?.infoEYC);
  const userData = useSelector((state: any) => state?.userDataEYC);
  const isStarted = useSelector((state: any) => state?.infoEYC?.isStarted);
  const isFinished = useSelector((state: any) => state?.infoEYC?.isFinished);
  const isCurrentUserDataLoaded = useSelector((state: any) => state?.isCurrentUserDataLoaded);
  const accountId = localStorage.getItem('accountId');
  const currentUser = useSelector((state: any) => state?.currentUser);
  const cardNumber = useSelector((state: any) => state?.currentUser?.masterCardNumber);
  const campaignId = useSelector((state: any) => state?.infoEYC?.campaignId);
  const isKeysLoaded = useSelector((state: any) => state?.transactionsEYC);
  const counterUpdateUser = useRef(0);
  const assets = useMemo(() => ({...images, ...imagesPhase1, ...imagesPhase2}),[]);
  const isAssetsLoaded = useImages(assets);
  const loader = useLoader(!isStarted || !isAssetsLoaded || ( (isLoggedIn && !isFinished)  ? (!isCurrentUserDataLoaded || !isKeysLoaded) : false));

  // //Analytics for End Year Campaign 2023
  // useEffect(() => {
  //     if(shopLang === 'nl_nl'){
  //         addGTM('/count0/extwinq4+standard', { u1: location.pathname }, 'DC-3743070');
  //         addGTMLocalisationNL('extwinq4', 'count0');
  //     }else if (shopLang === 'be_nl'){
  //         addGTM('/essoe0/2023_001+unique', { u1: shopLang }, 'DC-4212618');
  //         addGTMLocalisationBE('2023_001');
  //     }else{
  //         addGTM('/essoe0/2023_000+unique', { u1: shopLang }, 'DC-4212618');
  //         addGTMLocalisationBE('2023_000');
  //     }
  // }, [location.pathname]);
  // //End Analytics for End Year Campaign 2023
  //
  useEffect(() => {
    !campaignGame &&
    APIService.getCampaignBySlug(`ey-2024-${locale}`)
      .catch(() => {
        clearLocaleStorage();
        history.push({
          pathname: '/auth/login',
          state: {
            prevPath: location.pathname
          }
        })
      });
  }, [locale, campaignGame, history, location]);

  const dataSignIn = useMemo(() => {
    return {
      email: `${accountId}@example.com`,
      firstName: '',
      lastName: '',
      campaignId: campaignId,
      data: {
        accountId: accountId || '',
        cardNumber: cardNumber || ''
      }
    };
  }, [campaignId, accountId, cardNumber]);

  useEffect(() => {
    if (!userData && isLoggedIn && currentUser && !!currentUser.accountID && campaignId && isStarted && !isFinished) {
      APIService.signIn({ ...dataSignIn })
        .then(() => {
          return APIService.lotterySync();
        })
        .then(() => {
          counterUpdateUser.current++;
          return APIService.updateUser();
        })
        .then(() => APIService.setTransactions())
        .catch((err) => {
          console.error(err);
        });
    }
  }, [userData, isLoggedIn, isStarted, currentUser, currentUser.accountID, campaignId, dataSignIn, isFinished, counterUpdateUser]);

  useEffect(() => {
    if(userData && !counterUpdateUser?.current){
      counterUpdateUser.current++;
      APIService.updateUser()
        .then(() => APIService.setTransactions())
        .catch((err) => {
          console.error(err)
        })
    }
  },[userData, counterUpdateUser])

  return (
    loader ||
    <>
      <EYCHeader />
      <EYCInfo />
    </>
  );
};

export default EYCCampaign;