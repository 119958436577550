import styled, {css} from 'styled-components';

export const ConfettiStyled = styled.div(({theme}) => {
  const {mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    z-index: 10;
    
    ${mediaQueries.sm} {
      width: 100%;
    }

    canvas {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `;
});