import React from "react";

import { useModal } from "../../../redesign/hooks";
import { Toast, Button } from '../../../redesign/components/shared';
import { PrivacyModalDemo, InfoModalDemo } from '../../../redesign/components/shared/modals';

function ModalsDemo(){
  const {isShowed, toggle, close, isAnimated} = useModal();
  const {isShowed: isShowed2, toggle: toggle2, close: close2, isAnimated: isAnimated2} = useModal();
  const {isShowed: isShowedToast, toggle: toggleToast, close: closeToast, isAnimated: isAnimatedToast} = useModal();
  const {isShowed: isShowedToast2, toggle: toggleToast2, close: closeToast2, isAnimated: isAnimatedToast2} = useModal();
  const {isShowed: isShowedToast3, toggle: toggleToast3, close: closeToast3, isAnimated: isAnimatedToast3} = useModal();
  const {isShowed: isShowedToast4, toggle: toggleToast4, close: closeToast4, isAnimated: isAnimatedToast4} = useModal();

  return (
    <div style={{ padding: "40px" }}>
      <h3 style={{ marginBottom: "40px" }}>Demo Modals</h3>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Button style={{ margin: "0 10px 10px" }}
                text={"open modal"}
                onClick={() => toggle()} />

        <Button style={{ margin: "0 10px 10px" }}
                text={"open modal 2"}
                onClick={() => toggle2()} />

        <Button style={{ margin: "0 10px 10px" }}
                text={"show/hide toast error"}
                onClick={() => toggleToast()} />

        <Button style={{ margin: "0 10px 10px" }}
                text={"show/hide toast warning"}
                onClick={() => toggleToast2()} />

        <Button style={{ margin: "0 10px 10px" }}
                text={"show/hide toast info"}
                onClick={() => toggleToast3()} />

        <Button style={{ margin: "0 10px 10px" }}
                text={"show/hide toast success"}
                onClick={() => toggleToast4()} />
      </div>


      {isShowed && <PrivacyModalDemo isShowed={isShowed}
                                     onClose={() => close()}
                                     isAnimated={isAnimated}
                                     maxWidth="588px"
                                     isModalCloseBtn={true}
                                     title={"Algemene voorwaarden"} />}

      {isShowed2 && <InfoModalDemo isShowed={isShowed2}
                                   onClose={() => close2()}
                                   isAnimated={isAnimated2}
                                   maxWidth="384px"
                                   isModalCloseBtn={false}
                                   title={"Title"} />}

      <Toast toastType={"error"}
             positionTop={"50px"}
             isShowed={isShowedToast}
             onClose={() => closeToast()}
             isAnimated={isAnimatedToast}
             title={"Handeling voltooid"} />

      <Toast toastType={"warning"}
             positionTop={"160px"}
             isShowed={isShowedToast2}
             onClose={() => closeToast2()}
             isAnimated={isAnimatedToast2}
             title={"Kies een sterker wachtwoord"}
             text={"Je wachtwoord moet ten minste één hoofletter, één kleine letter, één nummer, één speciaal karakter en minimaal 8 karakter bevatten."} />

      <Toast toastType={"info"}
             positionTop={"450px"}
             isShowed={isShowedToast3}
             onClose={() => closeToast3()}
             isAnimated={isAnimatedToast3}
             title={"Welkom terug bij Esso Extras"} />

      <Toast toastType={"success"}
             positionTop={"550px"}
             isShowed={isShowedToast4}
             onClose={() => closeToast4()}
             isAnimated={isAnimatedToast4}
             title={"Welkom terug bij Esso Extras"}
             text={"Bekjk je puntensaldo of wissel je punten in voor een van onze unieke aanbiedingen."} />
    </div>
  );
}

export default ModalsDemo;
