import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { Trans, translate } from 'react-i18next'
import AppDownload from '../../ui/AppDownload'
import QR from 'qr-image'
import { onOpenModal } from './_modals-helper'

Modal.setAppElement('#root');

const ShowMemberCard = props => {
  let cardsNum = props.cardsData.cards.find(card => card.IsMasterCard).CardNumber;
  let generatedQR = cardsNum && QR.imageSync(cardsNum, { type: 'svg' });
  const number = props.currentUser.linkedPhoneNumber;
  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel="Why use phone number modal"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={props.handleCloseModal}
      onAfterOpen={onOpenModal}
      className="member-card-modal"
    >


      <section className="show-member-card">
        <EssoCard qr={generatedQR} phone={number} />
        <button onClick={props.handleCloseModal} className="close-button-big"></button>
        <p><Trans i18nKey="06-my_data_membercard_text_2"></Trans></p>
        <AppDownload height="49" />
      </section>
    </Modal>
  )
}

const EssoCard = props => {
  const newPhone = props.phone.replace(/./g, (c, i) => i < props.phone.length - 3 ? '*' : c)
  return (
    <div className="esso-card">
      <div className="esso-logo-2d" />
      <div className="generated-qr" dangerouslySetInnerHTML={{ __html: props.qr }} />
      {props.phone && <div className="holder-details phone-details">
        <span className="detail-name"><Trans i18nKey="06-my_data_membercard_tel"></Trans></span>
        <span className="phone-number">
          {newPhone} <br />
        </span>
        <span className="phone-description"><Trans i18nKey="06-my_data_membercard_text_1"></Trans></span>
      </div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { cardsData: state.cardsData }
};

export default connect(mapStateToProps)(translate('common')(ShowMemberCard));
