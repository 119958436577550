import React, { useRef } from "react";
import { CardButton, CardForm, CardImage, CardLink, CardTitle } from "./Card.styles";
import { handoverConstructor, linkConstructor } from "../../../../../../components/utils/_app-helpers";
import { handleHandoverForm } from "../../../../../../redesign/utils";
import i18n from "../../../../../../config/i18n";
type CardProps = {
  name: string;
  website: string;
  imageUrl: string;
  onLogin?: boolean;
}

const Card = ({ name, website, imageUrl, onLogin }: CardProps) => {
  const rewardLink = website.replace(
    /^(http:\/\/|https:\/\/)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?/,
    ''
  );
  const authToken = localStorage.getItem('authToken');
  const authTokenParse = authToken && JSON.parse(authToken);
  const accessToken = authTokenParse && authTokenParse.accessToken;
  const formRef = useRef<HTMLFormElement>(null);

  const cardContent = (
    <>
      <CardImage>
        <img src={imageUrl} alt={name} />
      </CardImage>
      <CardTitle>{name}</CardTitle>
      <CardButton><span>{i18n.t('hgc_product_card_button')}</span></CardButton>
    </>
  )

  return onLogin ? (
    <CardForm
      method="POST"
      action={handoverConstructor(rewardLink)}
      ref={formRef}
      onClick={() => handleHandoverForm(formRef, website, false)}
    >
      <input type="text" name="auth_key" value={accessToken} hidden readOnly />
      {cardContent}
    </CardForm>
  ) : (
    <CardLink href={linkConstructor(website)}>{cardContent}</CardLink>
  );
};

export default Card;
