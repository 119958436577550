import React from 'react';

import { SelectOption } from '../selectField.types';
import { Icon, DropdownOptionWrapper } from '../SelectField.styles';

const DropdownOption = (props: SelectOption) => (
  <DropdownOptionWrapper key={props.label}>
    {props.image && <Icon src={props.image} alt={props.label} />}
    <span>{props.value}</span>
  </DropdownOptionWrapper>
);

export default DropdownOption;
