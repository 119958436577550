import React from 'react';

import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';

import DialogWindow from './_template';

const WhyDialog = (props) => {
	return (
		<DialogWindow isOpen={props.isOpen} handleCloseModal={props.handleCloseModal}>
			<h2>
				<Trans i18nKey="05_Why_popup_title">
					Waar zouden wij uw <br /> telefoonnummer voor gebruiken?
				</Trans>
			</h2>
			<p>
				<Trans i18nKey="05_Why_popup_text_part1"></Trans>
			</p>
			<p>
				<Trans i18nKey="05_Why_popup_text_part2"></Trans>
			</p>
			<p className="small-caps center">
				<Trans i18nKey="05_Why_popup_text_part3">
					Heeft u meer vragen? <Link to="/faq">Bekijk de Veelgestelde Vragen </Link>
				</Trans>
			</p>
		</DialogWindow>
	);
};
export default WhyDialog;
