import styled, { css } from 'styled-components';

type ArticleTextProps = {
  lines: number;
};

type ArticleTitleProps = {
  typography: {
    fontSize: number;
    lines: number;
    lineHeight: number;
  };
};

export const ArticleDate = styled.p(
  ({ theme }) => {
    const { size, color, font, mediaQueries } = theme;

    return css`
      position: absolute;
      bottom: ${size.px15};
      left: ${size.px16};
      width: calc(100% - (${size.px16})*2);
      font-size: ${size.px12};
      color: ${color.graniteGray};
      line-height: 1.2;
      font-family: ${font.family.EMPrintRegular};
      font-weight: ${font.weight.regular};

      ${mediaQueries.sm} {
        left: 0;
        bottom: ${size.px10};
      }
    `;
  }
);

export const ArticleBox = styled.a(({ theme }) => {
  const { color, mediaQueries, size } = theme;

  return css`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: ${size.px8};

    color: ${color['grey-200']};
    background: ${color.white};
    border-radius: ${size.px16};
    transition: box-shadow 0.3s;
    text-decoration: none;

    ${mediaQueries.minWidthSM} {
      width: 100%;
      border: 0;

      &.link-box {
        padding-bottom: ${size.px24};
      }

      &:hover {
        box-shadow: 0 12px 16px rgba(178, 193, 224, 0.16);
      }

      &:active,
      &:focus,
      &:focus-within,
      &:target,
      &:visited,
      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  `;
});

export const ArticleImg = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: ${size.px12};

    overflow: hidden;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      vertical-align: bottom;
      border-radius: ${size.px12};
    }

    ${mediaQueries.minWidthSM} {
      height: 282px;
    }
  `;
});

export const ArticleContent = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    position: relative !important;
    min-height: 130px;
    padding: ${size.px10} 0;

    &.content-with-buttons {
      min-height: 180px;

      ${ArticleDate}{
        bottom: 65px;
      }
    }

    ${mediaQueries.minWidthSM} {
      padding: ${size.px15} ${size.px16};

      &.content-with-buttons {
        position: relative !important;
        
        button, a {
          margin-bottom: 0 !important;
        }
      }
    }
  `;
});

export const ArticleTitle = styled.h3<ArticleTitleProps>(
  ({ theme, typography }) => {
    const { color, font } = theme;
    const { fontSize, lines, lineHeight } = typography;

    return css`
      font-size: ${fontSize}px;
      color: ${color.darkCharcoal};
      line-height: ${lineHeight};
      font-family: ${font.family.EMPrintSemi};
      max-height: calc(${fontSize}px * ${lineHeight} * ${lines});
      margin-bottom: 5px;

      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }
);

export const ArticleText = styled.p<ArticleTextProps>(
  ({ theme, lines = 0 }) => {
    const { size, color, font } = theme;

    return css`
      font-size: ${size.px14};
      color: ${color.graniteGray};
      line-height: 1.5;
      font-family: ${font.family.sansSerif};
      max-height: calc(15px * 1.5 * ${lines});

      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }
);

export const ArticleAction = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    padding: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;

    button {
      margin-left: 0;
    }

    ${mediaQueries.sm} {
    }
  `;
});