import React from 'react';
import { useSelector } from 'react-redux';
import { Interpolate } from 'react-i18next';
import i18n from '../../../../../../config/i18n';
import { Button } from '../../../../../components/shared';
import {
  ResultWrapper,
  ResultSubTitle,
  ResultTitle,
  ResultDescription,
  ResultBox,
  ResultRow,
  ResultIcon,
  ResultText,
  ResultContent
} from '../../../ChestGame.styles';
import { Points_1000, Points_500, Points_250 } from '../../../assets';
import {shopLang} from "../../../../../../config/config";

type ResultModalProps = {
  onChestBack: () => void;
}
const ResultModal: React.FC<ResultModalProps> = (props) => {
  const { onChestBack } = props;
  const currentPrize = useSelector((state: any) => state?.currentPrizeDataEYC);
  const locale = shopLang.slice(0, 2).toLowerCase();
  const phase = useSelector((state: any) => state?.infoEYC?.name);

  return (
    <ResultWrapper className={phase !== `ey-2024-${locale}-blue` ? 'phase-2' : ''}>
      <ResultContent>
        {
          currentPrize?.prize ?
            <>
              <ResultSubTitle>{i18n.t('eyc_2024_2_popup_subTitle')}</ResultSubTitle>
              <ResultTitle>{i18n.t('eyc_2024_2_popup_win_points_title')}</ResultTitle>
              <ResultDescription>{i18n.t('eyc_2024_2_popup_text')}</ResultDescription>
              <ResultBox>
                <ResultRow>
                  <ResultIcon>
                    <img src={currentPrize?.prize?.code === '1000' ? Points_1000
                      : currentPrize?.prize?.code === '500' ? Points_500
                        : Points_250
                    } alt='points' />
                  </ResultIcon>
                  <ResultText>
                    <Interpolate i18nKey='eyc-2024-1-popup-win-points-pointsText'
                                 useDangerouslySetInnerHTML={true}
                                 points={currentPrize?.prize?.code} />
                  </ResultText>
                </ResultRow>
              </ResultBox>
              <Button text={i18n.t('eyc_2024_2_popup_button')}
                      onClick={onChestBack} />
            </> :
            <>
              <ResultSubTitle>{i18n.t('eyc_2024_2_popup_subTitle')}</ResultSubTitle>
              <ResultTitle>{i18n.t('eyc_2024_2_popup_lost_title')}</ResultTitle>
              <ResultDescription>{i18n.t('eyc_2024_2_popup_text')}</ResultDescription>
              <Button text={i18n.t('eyc_2024_2_popup_button')}
                      onClick={onChestBack} />
            </>
        }
      </ResultContent>
    </ResultWrapper>
  );
};

export default ResultModal;