import React from "react";
import { linkConstructor } from "../../components/utils/_app-helpers";
import clearLocaleStorage from "./clearLocaleStorage";

function handleHandoverForm(formRef: React.RefObject<HTMLFormElement>, url: string, isReload: boolean){
  const dateNow = Date.now();
  const accessExpiresAt = localStorage.getItem('accessExpiresAt');
  const authorized = localStorage.getItem('authorized');
  if (authorized && accessExpiresAt && (dateNow >= +accessExpiresAt)) {
    clearLocaleStorage();
    window.location.href = linkConstructor(url);
    isReload && window.location.reload();
  }else{
    return formRef?.current?.submit();
  }
}

export default handleHandoverForm;