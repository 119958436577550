import styled, { css } from 'styled-components';
import {
  Container,
  ContainerProps
} from '../../../redesign/theme/GlobalStyles';

type BannerWrapperProps = {
  image: {
    imageUrl?: string;
    imageUrlMob?: string;
  };
};

let imageWidth = '525px';

export const BannerWrapper = styled.div<BannerWrapperProps>(
  ({ theme, image }) => {
    const { color, mediaQueries } = theme;
    const { imageUrl, imageUrlMob } = image;

    return css`
      position: relative;
      width: 100%;
      min-height: 182px;
      background-color: ${color.sea};

      ${mediaQueries.sm} {
        min-height: auto;
        padding-top: 35vw;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: ${imageWidth};
        background-image: url('${imageUrl}');
        background-size: auto 100%;
        background-position: top left;
        background-repeat: no-repeat;

        ${mediaQueries.sm} {
          height: 35vw;
          width: 100%;
          max-width: 100%;
          background-image: url('${imageUrlMob}');
          background-size: 100% auto;
        }
      }
    `;
  }
);

export const BannerContainer = styled(Container)<ContainerProps>(
  ({ theme }) => {
    const { mediaQueries, size } = theme;

    return css`
      ${mediaQueries.sm} {
        padding-top: 0;
        padding-bottom: ${size.px28};
        margin-bottom: ${size.px12};
      }
    `;
  }
);

export const BannerContent = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    max-width: 548px;
    width: calc(100% - ${imageWidth} + 15px);

    ${mediaQueries.sm} {
      width: 100%;
      max-width: 100%;
    }
  `;
});

export const BannerTitle = styled.h2(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px24};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    color: ${color.white};
    margin-bottom: 8px;

    ${mediaQueries.sm} {
      font-size: ${size.px22};
    }
  `;
});

export const BannerText = styled.p(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px14};
    font-weight: ${font.weight.regular};
    line-height: 1.57;
    color: ${color.white};
    margin: 0;

    ${mediaQueries.sm} {
      font-size: ${size.px12};
      line-height: 1.33;
    }
  `;
});
