import React from "react";
import { LanguageSelector, BrandLinks } from "./parts";
import { TopNavigationBox, TopNavigationWrapper } from "./TopNavigation.styles";
import { HeaderContainer } from "../Header.styles";

import '../../../../components/common/Header/Header.scss';

function TopNavigation(){
  return (
    <TopNavigationWrapper>
      <HeaderContainer>
        <TopNavigationBox>
          <BrandLinks/>
          <LanguageSelector />
        </TopNavigationBox>
      </HeaderContainer>
    </TopNavigationWrapper>
  )
}
export default TopNavigation;
