import React from 'react';
import { Link } from 'react-router-dom';

import { CustomLink } from '../index';

import { ButtonWrapper } from './Button.styles';

export type ButtonProps = {
  text?: string;
  icon?: string;
  url?: string | object;
  buttontype?: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
  nativeLink?: boolean;
  backgroundcolor?: string;
  textcolor?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function Button({
  text,
  icon,
  url,
  buttontype = 'primary',
  disabled = false,
  nativeLink = false,
  backgroundcolor,
  textcolor,
  ...props
}: ButtonProps) {
  const linkProps =
    url && nativeLink
      ? {
          href: url
        }
      : {
          to: url
        };

  return (buttontype === 'tertiary' && typeof url === 'string') ? (
    <CustomLink url={url} text={text} nativeLink={nativeLink} />
  ) : (
    <ButtonWrapper
      buttontype={buttontype}
      backgroundcolor={backgroundcolor}
      textcolor={textcolor}
      disabled={disabled}
      as={url && nativeLink ? 'a' : url && !nativeLink ? Link : 'button'}
      {...linkProps}
      {...props}
    >
      {text && <span>{text}</span>}
    </ButtonWrapper>
  );
}

export default Button;
