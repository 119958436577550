import styled, { css } from 'styled-components';
import { LoaderInner, LoaderLayout } from "../Loader/Loader.styles";

type LoaderProps = {
  position: 'fixed' | 'absolute';
}

export const LoaderWrapper = styled.div<LoaderProps>(({position}) => {
  return css`
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
  `;
});

export const Spinner = styled.div<LoaderProps>(({position}) => {
  return css`
    z-index: 100;
    position: ${position};
    top: 15px;
    right: 15px;
    
    ${LoaderLayout}{
      min-height: auto;
    }
    
    ${LoaderInner}{
      width: 26px;
      height: 26px;
      border-width: 3px;
    }
  `;
});
