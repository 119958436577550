import styled, { css } from 'styled-components';
import Loader from '../../../../img/loader.svg';

export const LoaderLayout = styled.div(() => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
});

export const LoaderInner = styled.div(() => {
  return css`
    width: 100%;
    height: 50px;
    background: url(${Loader}) no-repeat center;
    background-size: 40px;
  `;
});
