import React from 'react';
import {
  AppButtonListStyles,
  AppStoreButton,
  PlayStoreButton
} from './AppButtonList.styles';

function AppButtonList() {
  return (
    <div>
      <AppButtonListStyles>
        <PlayStoreButton
          href="https://play.google.com/store/apps/details?id=com.exxonmobil.Esso_Extras"
          target={'_blank'}
        />
        <AppStoreButton
          href="https://apps.apple.com/nl/app/esso-extras/id1394581961?l=en"
          target={'_blank'}
        />
      </AppButtonListStyles>
    </div>
  );
}

export default AppButtonList;
