import React, {  useState } from "react";
import Carousel from '../Carousel';
import { useWindowSize } from "../../../../../redesign/hooks";
import { CarouselWrapper, NavigationBox, NextButton, PrevButton,
  DescriptionBox, DescriptionTitle, SlideTitle, DescriptionText, DescriptionButton } from '../Carousel/Carousel.styles';

type BannerCarouselProps = {
  setIdCategory: (id: null | string) => void;
  executeScroll: () => void;
}

const BannerCarousel = ({ setIdCategory, executeScroll }:BannerCarouselProps) => {
  const windowSize = useWindowSize();
  const [titleCategory, setTitleCategory] = useState<null | string>(null);
  const [subTitleCategory, setSubTitleCategory] = useState<null | string>(null);
  const [cta, setCta] = useState<null | string>(null);

  return (
    <CarouselWrapper>
      <Carousel executeScroll={executeScroll}
                setIdCategory={setIdCategory}
                setTitleCategory={setTitleCategory}
                setSubTitleCategory={setSubTitleCategory}
                setCta={setCta} />
      <NavigationBox>
        <PrevButton className={"prev"} />
        <SlideTitle>{titleCategory}</SlideTitle>
        <NextButton className={"next"} />
      </NavigationBox>
      {
        windowSize < 768 &&
        <DescriptionBox>
          <DescriptionTitle>{titleCategory}</DescriptionTitle>
          <DescriptionText>{subTitleCategory}</DescriptionText>
          <DescriptionButton onClick={executeScroll}><span>{cta}</span></DescriptionButton>
        </DescriptionBox>
      }
    </CarouselWrapper>
  );
};

export default BannerCarousel;
