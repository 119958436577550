import styled, { css } from 'styled-components';

export const TopNavWrapper = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    position: relative;
    width: 100%;

    background: ${color['grey-400']};

    display: flex;
    justify-content: space-between;
    align-content: center;
  `;
});

export const TopNavContainer = styled.div(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    width: 100%;
    margin: 0 auto;
    max-width: 1250px;

    padding: ${size.px24};

    ${mediaQueries.sm} {
      padding: ${size.px24} ${size.px16};
    }
  `;
});
