import styled, { css } from 'styled-components';
import { TextBase, TitleBase } from '../../../ChestGame.styles';

export const Description = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    max-width: 700px;
    min-height: 210px;
    margin: 0 auto 30px;
    
    button,
    a{
      background: #F37427;
      display: inline-flex;
      width: auto;

      &:hover,
      &:focus,
      &:focus-visible,
      &:active{
        background: #F37427;
      }
    }
    
    ${mediaQueries.sm} {
      margin-bottom: 20px;
    }
  `;
});

export const Title = styled(TitleBase)(({ theme }) => {
  const { size, color, mediaQueries } = theme;

  return css`
    color: ${color.navy};
    font-size: ${size.px48};
    margin-left: auto;
    margin-right: auto;

    ${mediaQueries.md} {
      font-size: ${size.px32};
    }

    ${mediaQueries.sm} {
      max-width: 345px;
      font-size: ${size.px28};
    }
  `;
});

export const Text = styled(TextBase)(({ theme }) => {
  const { color } = theme;

  return css`
    color: ${color.navy};
  `;
});