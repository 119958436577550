import styled, { css } from 'styled-components';

export const BrandBadgeLink = styled.a(({ theme }) => {
  const { size, color, font } = theme;

  return css`
    display: flex;
    align-items: center;
    padding: 0;
    text-decoration: none;

    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.regular};
    font-size: ${size.px24};

    cursor: pointer;

    color: ${color.white};
    transition: opacity 0.2s ease-in;

    &:hover {
      transition: opacity 0.2s ease-in;

      opacity: 0.75;
    }

    img {
      max-width: 164px;
    }
  `;
});

export const BrandBadgeTitle = styled.strong(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    padding: 0;

    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.regular};
    font-size: ${size.px24};

    color: ${color.white};

    &:last-child {
      padding-left: ${size.px16};
      border-left: 1px solid ${color.white};

      margin-left: ${size.px16};
    }
  `;
});
