import styled, { css } from "styled-components";

export const ProductsWrap = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    display: flex;
    flex-wrap: wrap;
    width: 102%;
    margin: 0 -1%;
    
    ${mediaQueries.sm} {
      width: 100%;
      margin: 0 0 15px;
    }
	`;
});
