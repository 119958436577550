import styled, { css } from 'styled-components';

export const HeadlineTop = styled.div(() => {
  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  `;
});

export const HeadlineTitle = styled.h4(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px32};
    line-height: 1.25;
    color: ${color.darkCharcoal};
    margin: 0;
    max-width: 70%;

    ${mediaQueries.sm} {
      font-size: ${size.px24};
      max-width: 100%;
    }
  `;
});

export const HeadlineBottom = styled.div(() => {
  return css`
    width: 100%;
    text-align: center;
  `;
});

export const HeadingSubtitle = styled.h3(({ theme }) => {
  const { color, font, size } = theme;

  return css`
    font-size: ${size.px16};
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    color: ${color.graniteGray};
    line-height: 1.5;
    margin: 0 0 ${size.px20};
  `;
});
