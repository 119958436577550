import React from 'react';
import { Link } from 'react-router-dom';

import { AnchorButtonWrapper } from './AnchorButton.styles';
import { handleScroll } from '../../../../components/utils/_app-helpers';

export type AnchorButtonProps = {
  anchorID?: string;
} & React.HTMLAttributes<HTMLElement>;

function AnchorButton({ anchorID, ...props }: AnchorButtonProps) {
  return (
    <AnchorButtonWrapper {...props}>
      <Link to="" onClick={(e) => handleScroll(e, anchorID)}></Link>
    </AnchorButtonWrapper>
  );
}

export default AnchorButton;
