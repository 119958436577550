import styled, { createGlobalStyle, css } from 'styled-components';
import type { CustomTheme } from './types';

export type ContainerProps = {
  maxWidth?: string;
  paddingBottom?: number;
};

type PreprPageWrapperProps = {
  backgroundColor?: string | null | undefined;
};

type ComponentWrapperProps = {
  backgroundColor?: string | null | undefined;
};

export const Wrapper = styled.div<ComponentWrapperProps>(({ theme, backgroundColor = 'transparent' }) => {
  const { color } = theme;

  return css`
    width: 100%;
    color: ${color.white};
    background-color: ${backgroundColor};
  `;
});

export const Container = styled.div<ContainerProps>(
  ({ theme, maxWidth = '850px', paddingBottom = 40 }) => {
    const { mediaQueries } = theme;

    return css`
      width: 100%;
      margin: 0 auto;
      max-width: ${maxWidth};
      padding: 40px 25px ${paddingBottom}px;

      ${mediaQueries.sm} {
        padding: 20px 25px ${paddingBottom}px;
      }
    `;
  }
);

export const AnimatedLayout = styled.div(() => {
  return css`
    opacity: 0;
    position: relative;
    z-index: 1000;
    visibility: hidden;

    &.fade-in {
      opacity: 1;
      visibility: visible;
      transition: opacity linear 0.3s, visibility linear 0s 0s;
    }

    &.fade-out {
      opacity: 0;
      visibility: hidden;
      transition: opacity linear 0.3s, visibility linear 0s 0.3s;
    }
  `;
});

export default createGlobalStyle(
  ({ theme }: { theme: CustomTheme }) => css`
    *,
    *:after,
    *:before {
      box-sizing: border-box;
    }

    html,
    body {
      font: ${theme.font.style.normal} ${theme.font.weight.regular}
          ${theme.size.px16} / 1.4 ${theme.font.family.EMPrintRegular},
        'Helvetica', 'Arial', sans-serif, system-ui, monospace;

      color: ${theme.color.text};
    }
    
    body{
      &.modal-open{
        overflow: hidden !important;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${theme.font.family.EMPrintSemi};
      font-weight: ${theme.font.weight.semiBold};
      color: ${theme.color.text};

      padding: 0;
      margin: 0;
    }

    a {
      text-decoration: none;
      color: ${theme.color.link};

      padding: 0;
      margin: 0;

      &:hover,
      &:active,
      &:focus,
      &:visited {
        outline: none;
      }
    }

    b,
    strong {
      font-weight: ${theme.font.weight.semiBold};
      font-style: normal;
    }

    p,
    span {
      font-family: ${theme.font.family.sansSerif};
      font-weight: ${theme.font.weight.regular};
      color: ${theme.color.text};
      line-height: 1.5rem;

      padding: 0;
      margin: 0;
    }

    button {
      font-weight: ${theme.font.weight.semiBold};
      color: ${theme.color.text};

      cursor: pointer;
      outline: none;
      border: none;

      padding: 0;
      margin: 0;
    }

    input[type='number']::placeholder {
      font-family: 'Ocra', serif;
    }

    img,
    svg,
    figure {
      vertical-align: top;
      max-width: 100%;
    }

    .isCustomSlider {
      padding: 12px 0;

      ${theme.mediaQueries.minWidthSM} {
        padding: 0;
      }
    }

    .isCustomTabs {
      //.swiper-wrapper {
      //  & > .swiper-slide.swiper-slide-prev {
      //    margin-left: -16px;
      //  }
      //}

      // ${theme.mediaQueries.minWidthSM} {
      //   .swiper-wrapper {
      //     & > .swiper-slide.swiper-slide-prev {
      //       padding-right: 30px;
      //       margin-left: 0;
      //     }
      //   }
      // }
    }

    .isCustomPagination {
      .swiper-slide {
        .content-with-buttons {
          position: static;
          padding-bottom: ${theme.size.px15};

          ${theme.mediaQueries.minWidthSM} {
            button,
            a {
              margin-bottom: ${theme.size.px16};
            }
          }
        }
      }

      .default-image {
        img {
          ${theme.mediaQueries.se} {
            width: 146px;
            height: 146px;
          }
        }
      }

      .swiper-pagination {
        bottom: 5px;
        left: auto;
        right: 42px;
        width: 100%;
        max-width: 45px;
        display: flex;
        justify-content: center;
      }

      .swiper-pagination-bullet {
        width: 6px;
        height: 4px;
        background: ${theme.color.steelBlue};
        opacity: 1;

        transform: scale(1);

        border-radius: 4px;

        ${theme.mediaQueries.sm} {
          display: none;
        }
      }

      .swiper-pagination-bullet-active {
        width: ${theme.size.px16};
        background: ${theme.color.admiral};
      }

      .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 2px;
      }

      .swiper-pagination-bullets-dynamic {
        .swiper-pagination-bullet-active-prev,
        .swiper-pagination-bullet-active-prev-prev,
        .swiper-pagination-bullet-active-main {
          display: none;
        }
      }
      span.swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
        display: inline-block;
      }

      .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        bottom: 0;
        top: auto;
        left: auto;

        margin-top: 0;
        color: ${theme.color.admiral};
        height: ${theme.size.px16};

        &:after {
          font-size: ${theme.size.px16};
          font-weight: bold;
        }

        ${theme.mediaQueries.sm} {
          display: none;
        }
      }

      .swiper-button-prev {
        right: 92px;
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        color: ${theme.color.grey};
        opacity: 1;
      }

      &.articlesPagination {
        .swiper-container {
          ${theme.mediaQueries.minWidthSM} {
            padding-bottom: ${theme.size.px55};
          }
        }
      }
    }

    #nprogress .bar {
      background: ${theme.color.blue};
      height: 4px;
    }

    #nprogress .spinner-icon {
      border-top-color: ${theme.color.blue};
      border-left-color: ${theme.color.blue};
      width: 26px;
      height: 26px;
      border-width: 3px;
    }

    #nprogress .peg {
      display: none;
    }

    .nprogress-busy {
      .loading-placeholder {
        display: inline-block;
      }
      .onload-element {
        display: none;
      }
    }
    
    main{
      min-height: 100vh;
    }
  `
);

export const PreprPageWrapper = styled.div<PreprPageWrapperProps>(({ theme, backgroundColor = '#fff' }) => {
  return css`
    min-height: 100vh;
    background: ${() => backgroundColor || '#fff'};
  `;
});