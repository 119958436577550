import styled, { css } from 'styled-components';
import { IconCheckOrange } from "../../../../prepr/assets";

type ContentPositionTypes = {
  descriptionPosition?: 'top' | 'bottom' | 'left' | 'right';
  isVideoVertical?: boolean;
}

export const WrappedItemVideo = styled.div<ContentPositionTypes>(({ theme, descriptionPosition= 'bottom', isVideoVertical = false  }) => {
  const { mediaQueries, size } = theme;

  return css`
    display: flex;
    max-width: ${() => (descriptionPosition === 'left' || descriptionPosition === 'right') ? '100%' : `calc(50% - ${size.px12}*2)`};
    flex-direction: ${() => (descriptionPosition === 'left' || descriptionPosition === 'right') ? `row` : 'column' };
    align-items: ${() => (descriptionPosition === 'left' || descriptionPosition === 'right') ? `center` : 'flex-start' };
    width: 100%;
    background: white;
    border-radius: ${size.px4};
    overflow: hidden;
    
    ${mediaQueries.sm} {
      max-width: initial;
      flex-wrap: wrap;
    }
    
    ${BoxItemVideo},
    ${ItemContent}{
       width: ${() => (descriptionPosition === 'left' || descriptionPosition === 'right') ? `calc(50% - ${size.px12})` : '100%' };

      ${mediaQueries.sm} {
        width: 100%;
      }
    }
    
    ${ItemVideo}{
      padding-top: ${() => isVideoVertical ? `100%` : '56.25%'};
    }

     ${BoxItemVideo}{
      order: ${() => (descriptionPosition === 'top' || descriptionPosition === 'left') ? 2 : 1 };
      margin-left: ${() => (descriptionPosition === 'left') ? `${size.px12}` : '0' };
      margin-right: ${() => (descriptionPosition === 'right') ? `${size.px12}` : '0' };

      ${mediaQueries.sm} {
        margin-left: 0;
        margin-right: 0;
      }
    }

    ${ItemContent}{
      order: ${() => (descriptionPosition === 'top' || descriptionPosition === 'left') ? 1 : 2 };
      padding: ${() => descriptionPosition === 'right' ? '30px 20px 30px 50px' :
        descriptionPosition === 'left' ? '30px 50px 30px 20px' : '30px 20px'};
      margin-left: ${() => (descriptionPosition === 'right') ? `${size.px12}` : '0' };
      margin-right: ${() => (descriptionPosition === 'left') ? `${size.px12}` : '0' };

      ${mediaQueries.sm} {
        margin-left: 0;
        margin-right: 0;
        padding: 20px;
      }
    }
  `;
});

export const ItemVideo = styled.div(({theme}) => {
  const { color } = theme;

  return css`
    position: relative;
    overflow: hidden;
    width: 100%;
    background: ${color.black};

    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  `;
});

export const BoxItemVideo = styled.div(({theme}) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.sm} {
    }
  `;
});

export const ItemContent = styled.div(({theme}) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.sm} {
    }
  `;
});

export const ItemDescription = styled.div(({theme}) => {
  const { size, color, font, mediaQueries } = theme;

  return css`
    h1, h2, h3, h4, h5, h6, p, a, span, li{
      line-height: 1.25;
      color: ${color.darkCharcoal};
      padding-bottom: 15px;
    }

    h1{
      font-size: ${size.px50};
    }
    
    h2{
      font-size: ${size.px40};
    }
    
    h3{
      font-size: ${size.px36};
    }
    
    h4{
      font-size: ${size.px32};
    }

    h5{
      font-size: ${size.px24};
    }

    h6{
      font-size: ${size.px18};
    }
    
    a {
      font-weight: ${font.weight.semiBold};
      text-decoration: underline;
    }
    
    ol{
      margin: 0;
      padding-left: 25px;
      
      li{
        list-style: auto;
      } 
    }
    
    ul{
      li{
        padding-left: 25px;
        position: relative;
        
        &:before{
          content: '';
          width: 16px;
          height: 14px;
          background-image: url('${IconCheckOrange}');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          top: 3px;
          left: 2px;
        }
      }
    }

    ${mediaQueries.sm} {
      h1, h2, h3{
        font-size: ${size.px32};
      }

      h4{
        font-size: ${size.px24};
      }

      h5{
        font-size: ${size.px20};
      }

      h1, h2, h3, h4, h5, h6, p, a, span, li{
        padding-bottom: 10px;
      }
    }
  `;
});

export const TitleVideo = styled.h4(({ theme }) => {
  const { size, font, color } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px20};
    font-weight: ${font.bold};
    line-height: 25px;
    letter-spacing: 0;
    color: ${color.darkCharcoal};
  `;
});
