import styled, { css } from 'styled-components';

export const TokensList = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: inline-flex;
    flex-wrap: wrap;
    background-color: #92A5CA;
    border-radius: 35px;
    max-width: 320px;
    padding: 4px;
    margin-bottom: 20px;
    max-height: 132px;
    overflow: hidden;
  
    ${mediaQueries.sm} {
      max-width: 100%;
      margin-bottom: 10px;
    }
  `;
});

export const Token = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    background-color: #92A5CA;
    padding: 5px;
    border-radius: 50%;
    
    img{
      width: 52px;
      height: 52px;
    }

    ${mediaQueries.sm} {
    }
  `;
});