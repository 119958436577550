import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../../../../../../config/i18n';
import { shopLang } from '../../../../../../../../config/config';
import { Button } from '../../../../../../../../redesign/components/shared';
import { checkLoggedIn } from '../../../../../../../../components/utils/_app-helpers';
import useWindowSize from '../../../../../../../../redesign/hooks/useWindowSize';
import APIService from '../../../../../api_campaign/api';
import { BALLOONS } from './balloons.const';
import { GameWrapProps } from './gameWrap.types';
import {
  GameWrapStyled, BalloonBox, BalloonWrapper, ButtonBox, PrizeBoxLost, PrizeBoxWon,
} from './GameWrap.styles';
import {
  PrizeLostNL, PrizeLostBE, PrizeWonNL, PrizeWonBENL, PrizeWonBEFR
} from '../../../../../../../assets';

const GameWrap: React.FC<GameWrapProps> = ({
                                             onSetActiveBalloonMob,
                                             onSetActiveBalloonDesktop,
                                             onSetUnActiveBalloonDesktop,
                                             onPlayGame,
                                             showModal,
                                             animatedBalloon,
                                             activeBalloon,
                                           }) => {
  const isLoggedIn = checkLoggedIn();
  const windowSize = useWindowSize();
  const isPlayBtnDisabled = useSelector((state: any) => state?.isPlayBtnDisabledCampaign);
  const isUserWon = useSelector((state: any) => state?.isUserWonCampaign);
  const transactions = useSelector((state: any) => state?.transactions);
  const isModalAnswerCorrect = useSelector((state: any) => state?.isModalAnswerCorrectCampaign);
  const isShowModalCampaign = useSelector((state: any) => state?.isShowModalCampaign);
  const isFinished = useSelector((state: any) => state?.campaignInfo?.campaignGames[0]?.isFinished);

  const balloonRefs = useRef<any[]>([]);
  balloonRefs.current = BALLOONS.map((element, i) => balloonRefs.current[i] ?? React.createRef());

  useEffect(() => {
    if(isLoggedIn && !isFinished && windowSize <= 767){
      const handleEventOutside = (e: any) => {
        const el = balloonRefs?.current?.find((ref) => ref?.current?.contains(e.target));
        if(!el && !isPlayBtnDisabled && !isShowModalCampaign){
          onSetActiveBalloonMob && onSetActiveBalloonMob(0);
        }else{
          // console.log('click element');
        }
      }

      document.addEventListener('click', handleEventOutside, true);

      return () => {
        document.removeEventListener('click', handleEventOutside, true);
      };
    }
  }, [isLoggedIn, isPlayBtnDisabled, onSetActiveBalloonMob, windowSize, isShowModalCampaign, isFinished])

  useEffect(() => {
    if (isModalAnswerCorrect) {
      typeof activeBalloon === 'number' && onPlayGame && onPlayGame(activeBalloon);
      APIService.setIsModalAnswerCorrect(false);
    }
  });

  return (
    <GameWrapStyled>
      {BALLOONS.map((balloon, i) => {
        return (
            <BalloonWrapper key={balloon.id} ref={balloonRefs.current[i]}
                            bottom={balloon.bottom}
                            right={balloon.right}
                            bottomMob={balloon.bottomMob}
                            rightMob={balloon.rightMob}
                            zIndex={balloon.zIndex}
                            isActive={activeBalloon === balloon.id}
                            isAnimated={animatedBalloon === balloon.id}
                            onMouseEnter={() => onSetActiveBalloonDesktop && onSetActiveBalloonDesktop(balloon.id)}
                            onMouseLeave={onSetUnActiveBalloonDesktop}>
              <BalloonBox onClick={() => onSetActiveBalloonMob && onSetActiveBalloonMob(balloon.id)} />
              {
                isLoggedIn && !isFinished &&
                  <ButtonBox>
                    <Button
                        disabled={isPlayBtnDisabled || !transactions}
                        onClick={() => showModal && showModal(balloon.id)}
                        text={i18n.t('eBike_btn_start_game')}
                        buttontype={'primary'}
                    />
                  </ButtonBox>
              }

              {(isLoggedIn && !isFinished && animatedBalloon) ?
                <>
                  {isUserWon ?
                      (
                        shopLang === 'nl_nl' ?
                            <PrizeBoxWon>
                                <img src={`${PrizeWonNL}`} alt='prize' />
                            </PrizeBoxWon> :
                            shopLang === 'be_nl' ?
                                <PrizeBoxWon>
                                    <img src={`${PrizeWonBENL}`} alt='prize' />
                                </PrizeBoxWon> :
                                <PrizeBoxWon>
                                    <img src={`${PrizeWonBEFR}`} alt='prize' />
                                </PrizeBoxWon>
                      ):
                      (
                        shopLang !== 'be_fr' ?
                            <PrizeBoxLost>
                                <img src={`${PrizeLostNL}`} alt='prize' />
                            </PrizeBoxLost> :
                            <PrizeBoxLost>
                                <img src={`${PrizeLostBE}`} alt='prize' />
                            </PrizeBoxLost>
                      )
                  }
                </> : <></>
              }
            </BalloonWrapper>
        )
      })}
    </GameWrapStyled>
  )
};

export default GameWrap;