import styled, { css } from 'styled-components';

export const WrapperBase = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;
    min-height: 100vh;

    .anim,
    .anim-delay{

      &-enter{
        opacity: 0;
      }

      &-enter-active{
        opacity: 1;
        transition: opacity 1000ms;
      }

      &-exit{
        opacity: 1;
      }

      &-exit-active {
        opacity: 0;
        transition: opacity 300ms;
      }
    }

    .anim-delay{
      &-enter-active{
        transition-delay: 1000ms;
      }
    }

    .anim-slow,
    .anim-slow-delay{

      &-enter{
        opacity: 0;
      }

      &-enter-active{
        opacity: 1;
        transition: opacity 100ms;
      }

      &-exit{
        opacity: 1;
      }

      &-exit-active {
        opacity: 0;
        transition: opacity 100ms;
      }
    }

    .anim-slow-delay{
      &-enter-active{
        transition-delay: 100ms;
      }
    }

    ${mediaQueries.sm} {
    }
  `;
});

export const Container = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    padding: 40px 25px;
    text-align: center;
    height: 100%;

    ${mediaQueries.sm} {
      padding: 30px 25px;
    }
  `;
});

export const TitleBase = styled.h1(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    margin-bottom: 20px;
    line-height: 1.2;

    ${mediaQueries.md} {
    }

    ${mediaQueries.sm} {
      margin-bottom: 10px;
    }
  `;
});

export const TextBase = styled.p(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    font-size: ${size.px14};
    margin-bottom: 30px;
    line-height: 1.5;

    ${mediaQueries.md} {
    }

    ${mediaQueries.sm} {
      margin-bottom: 20px;
    }
  `;
});

export const OverlayBase = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    ${mediaQueries.sm} {
    }
  `;
});

export const ResultWrapper = styled.div(({ theme }) => {
  const {color, mediaQueries} = theme;

  return css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: left;
    position: absolute;
    bottom: 0;
    opacity: 1;
    width: 100%;
    min-height: 450px;
    background: ${color.white};
    border: 4px solid #D5C790;
    border-radius: 12px;
    z-index: 1;
    padding: 40px;
    max-width: 890px;
    transform: translateX(-50%);
    left: 50%;

    &.phase-2{
      min-height: 380px;
      
      ${ResultContent}{
        ${mediaQueries.sm} {
          padding-top: 400px;
        }

        ${mediaQueries.xxs} {
          padding-top: 65vw;
        }
      }
    }
    
    button{
      width: 100%;
      background: linear-gradient(180deg, #E3D49A 0%, #A1966C 100%);
      border: 1px solid rgba(164, 153, 110, 0.6);
      border-image-source: linear-gradient(180deg, rgba(164, 153, 110, 0.6) 0%, rgba(221, 207, 150, 0.6) 100%);
      border-radius: 4px;
      
      &:active,
      &:focus,
      &:focus-visible{
        outline: none;
      }
      
      span{
        font-weight: 600;
      }
    }

    ${mediaQueries.sm} {
      bottom: initial;
      top: 0;
      max-height: calc(100% - 150px);
      border: none;
      border-radius: 0;
      padding: 25px;
    }
  `;
});

export const ResultContent = styled.div(({ theme }) => {
  const {mediaQueries} = theme;

  return css`
    width: 50%;
    max-width: 400px;
    

    ${mediaQueries.sm} {
      width: 100%;
      max-width: 100%;
      padding-top: 40vw;
    }
  `;
});

export const ResultSubTitle = styled.p(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px18};
    line-height: 1.2;
    color: ${color.black};
    margin-bottom: 20px;
  `;
});

export const ResultTitle = styled.h3(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  const fontStyle = css`
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px32};
    line-height: 1.2;
    color: ${color.black};
  `

  return css`
    ${fontStyle};
    margin-bottom: 20px;
    
    span{
      ${fontStyle};
    }

    ${mediaQueries.sm} {
      font-size: ${size.px28};
      
      span{
        font-size: ${size.px28};
      }
    }
  `;
});

export const ResultDescription = styled.p(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    line-height: 1.2;
    color: ${color.black};
    margin-bottom: 35px;
  `;
});

export const ResultBox = styled.div(() => {
  return css`
    margin-bottom: 35px;
    
    ${ResultRow}{
      &:last-child{
        border-bottom: none;
      }
    }
  `;
});

export const ResultRow = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid ${color.chineseGray};
  `;
});

export const ResultIcon = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 40px;
    margin-right: 20px;
    
    ${mediaQueries.sm} {
      margin-right: 10px;
    }
  `;
});

export const ResultText = styled.p(({ theme }) => {
  const { font, size, color } = theme;

  const fontStyle = css`
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.bold};
    font-size: ${size.px16};
    line-height: 1.2;
    color: ${color.black};
  `
  return css`
    display: flex;
    ${fontStyle};
    
    span{
      ${fontStyle};
    }
  `;
});