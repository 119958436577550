import React, { useEffect } from 'react';

import { FieldTextArea, TextAreaCounter } from './TextAreaField.styles';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { ChangeEvent, useCallback, useState } from 'react';

import { FieldContainer, FieldError } from '../InputField/InputField.styles';
import { FieldLabel } from '../UI';

type TextAreaFieldProps = {
  register: UseFormRegisterReturn;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  isError?: boolean;
  errorText: string | undefined;
  width?: number;
  type?: string;
  name: string;
  label: string;
  rows?: number;
  isRequired: boolean;
};

const TextAreaField = ({
  placeholder = '',
  disabled = false,
  isError = false,
  width = 100,
  maxLength = 250,
  errorText,
  register,
  name,
  label,
  rows = 8,
  isRequired
}: TextAreaFieldProps) => {
  const [content, setContent] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const setFormattedContent = useCallback(
    (text: string) => setContent(text.slice(0, maxLength)),
    [maxLength, setContent]
  );

  const handleChange = (event: ChangeEvent<{ value: string }>) => {
    setFormattedContent(event.target.value);
  };

  useEffect(() => {
    if (content.length > 0) {
      setHasError(false);
    } else {
      setHasError(isError);
    }
  }, [content, hasError, isError]);

  return (
    <FieldContainer width={width}>
      <FieldLabel name={name} label={label!} isRequired={isRequired} />
      <FieldTextArea
        {...register}
        id={name}
        className={hasError ? 'hasError' : ''}
        disabled={disabled}
        maxLength={maxLength}
        onChange={handleChange}
        placeholder={placeholder}
        rows={rows}
      />

      <TextAreaCounter isError={hasError}>
        {content.length}/{maxLength}
      </TextAreaCounter>

      {hasError && <FieldError>{errorText}</FieldError>}
    </FieldContainer>
  );
};

export default TextAreaField;
