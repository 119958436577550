import React, { useState, useCallback, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import { useWindowSize } from '../../../../../redesign/hooks';
import { CategoryList } from './caregoryList';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import {
  CarouselBox,
  ImgBox,
  DescriptionBox,
  DescriptionTitle,
  DescriptionText,
  DescriptionButton,
} from './Carousel.styles';

SwiperCore.use([Navigation, EffectCoverflow]);

type CarouselProps = {
  setTitleCategory: (title: null | string) => void;
  setSubTitleCategory: (title: null | string) => void;
  setCta: (cta: null | string) => void;
  setIdCategory: (id: null | string) => void;
  executeScroll: () => void;
}

const Carousel = ({ setTitleCategory, setSubTitleCategory, setCta, setIdCategory, executeScroll }: CarouselProps) => {
  const windowSize = useWindowSize();
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();

  const setCustomClass = useCallback((swiper: any): void => {
    Array.from(document.querySelectorAll('.swiper-slide')).forEach((el) => el.classList.remove('swiper-slide-custom-styled'));
    const afterNext = swiper?.slides[swiper?.activeIndex + 2];
    const beforePrev = swiper?.slides[swiper?.activeIndex - 2];
    afterNext?.classList.add('swiper-slide-custom-styled');
    beforePrev?.classList.add('swiper-slide-custom-styled');
  }, []);

  const handleSlideChange = useCallback((swiper: any): void => {
    const activeSlide = swiper?.slides[swiper?.activeIndex];
    const id = activeSlide?.getAttribute('data-id');
    const title = activeSlide?.getAttribute('data-title');
    const subTitle = activeSlide?.getAttribute('data-subtitle');
    const cta = activeSlide?.getAttribute('data-cta');
    setTitleCategory(title);
    setIdCategory(id);
    setSubTitleCategory(subTitle);
    setCta(cta);
    setCustomClass(swiper);
  }, [setTitleCategory, setSubTitleCategory, setCta, setIdCategory, setCustomClass])

  // const handleTransitionEnd = useCallback((swiper: any): void => {
  //   const activeSlide = swiper?.slides[swiper?.activeIndex];
  //   const id = activeSlide?.getAttribute('data-id');
  //   setIdCategory(id);
  // }, [setIdCategory])

  const swiperParams: Swiper = useMemo(
    () => ({
      loop: true,
      speed: 600,
      grabCursor: true,
      centeredSlides: true,
      effect: 'coverflow',
      slidesPerView: 2.4,
      slidesPerGroup: 1,
      spaceBetween: 0,
      initialSlide: 7,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 600,
        modifier: 1,
        slideShadows: false
      },
      navigation: {
        prevEl: '.prev',
        nextEl: '.next'
      },
      onSwiper: setSwiperInstance,
      onSlideChange: (swiper: any) => {
        handleSlideChange(swiper);
      },
    }),
    [handleSlideChange]
  );

  useEffect(() => {
    swiperInstance && setCustomClass(swiperInstance);
  }, [setCustomClass, swiperInstance, windowSize]);

  return (
    <CarouselBox>
      <Swiper {...swiperParams}>
        {CategoryList?.map((item) => {
          const id = process.env.REACT_APP_ENV === 'prod' ? item.id_prod : item.id_stage;

          return (
            <SwiperSlide key={uuidv4()}
                         data-title={item.title}
                         data-subtitle={item.subTitle}
                         data-cta={item.cta}
                         data-id={id}>
              <ImgBox>
                <img src={item.image} alt={item.title} />
              </ImgBox>
              {
                windowSize >= 768 &&
                <DescriptionBox>
                  <DescriptionTitle>{item.title}</DescriptionTitle>
                  <DescriptionText>{item.subTitle}</DescriptionText>
                  <DescriptionButton onClick={executeScroll}><span>{item.cta}</span></DescriptionButton>
                </DescriptionBox>
              }
            </SwiperSlide>
          );
        })}
      </Swiper>
    </CarouselBox>
  );
};

export default React.memo(Carousel);
