import React, { useRef } from "react";

import { handleHandoverForm } from "../../../utils";
import { handoverConstructor, linkConstructor } from '../../../../components/utils/_app-helpers';
import { ProductHighlightsCombineProps } from './productHighlights.types';

import {
  ProductContainerLink,
  ProductContainerForm,
  ImageBox,
  ContentBox,
  ContentText,
  StickerBox,
  StickerText,
  StickerPercent
} from './ProductHighlights.styles';

const ProductHighlights: React.FC<ProductHighlightsCombineProps> = (props) => {
  const { image, description, discount_percentage, detail_url, onLogin } =
    props;
  const rewardLink = detail_url.replace(
    /^(http:\/\/|https:\/\/)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?/,
    ''
  );
  const authToken = localStorage.getItem('authToken');
  const authTokenParse = authToken && JSON.parse(authToken);
  const accessToken = authTokenParse && authTokenParse.accessToken;
  const formRef = useRef<HTMLFormElement>(null);

  const productContent = (
    <>
      <ImageBox imageUrl={image?.[0]?.cdn_files?.[0]?.url || image?.[0]?.url} />
      <ContentBox>
        <ContentText>{description}</ContentText>
      </ContentBox>
      {discount_percentage !== null && discount_percentage !== undefined && (
        <StickerBox>
          <StickerText>tot wel</StickerText>
          <StickerPercent>{discount_percentage}%</StickerPercent>
          <StickerText>korting</StickerText>
        </StickerBox>
      )}
    </>
  );

  return onLogin ? (
    <ProductContainerForm
      method="POST"
      action={handoverConstructor(rewardLink)}
      ref={formRef}
      onClick={() => handleHandoverForm(formRef, detail_url, false)}
    >
      <input type="text" name="auth_key" value={accessToken} hidden readOnly />
      {productContent}
    </ProductContainerForm>
  ) : (
    <ProductContainerLink href={linkConstructor(detail_url)}>
      {productContent}
    </ProductContainerLink>
  );
};

export default ProductHighlights;
