import React, { useState, useEffect } from 'react';
import { AccordionData } from './accordion.types';

import { clickGTM } from '../../../../components/utils/_gtm-helpers';
import AccordionItem from './AccordionItem';
import { AccordionWrapper } from './Accordion.styles';

function Accordion({ items }: { items: Array<AccordionData> }) {
  const [currentIdx, setCurrentIdx] = useState(-1);

  const btnOnClick = (idx: number, question: string) => {
    if(idx === currentIdx){
      clickGTM('accordion_item_click', 'Accordion Close', `${question}`)
    }
    if(idx !== currentIdx){
      clickGTM('accordion_item_click', 'Accordion Open', `${question}`)
    }
    setCurrentIdx((currentValue) => (currentValue !== idx ? idx : -1));
  };

  useEffect(() => {
    setCurrentIdx(-1);
  }, [items]);

  return (
    <AccordionWrapper role="tabList" aria-label="List of Tabs">
      {items.map((item, idx) => (
        <AccordionItem
          tabIndex={items.length - idx}
          key={idx}
          data={item}
          isOpen={idx === currentIdx}
          btnOnClick={() => btnOnClick(idx, item?.question)}
        />
      ))}
    </AccordionWrapper>
  );
}

export default Accordion;
