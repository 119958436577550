import styled, { css } from 'styled-components';

export const ProductsWrapper = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    padding: 40px 25px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    color: ${color.white};
    
    ${mediaQueries.sm} {
      padding: 20px 25px;
    }

    .swiper-slide{
      width: 190px;
      margin-bottom: 20px;
    }
    
    .swiper-scrollbar{
      height: 1px;
      background: ${color.white};
    }
    
    .swiper-scrollbar-drag{
      background: ${color.white};
      height: 3px;
      margin-top: -1px;
    }
  `;
});