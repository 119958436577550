import React from 'react';
import { PageBannerProps } from './pageBanner.types';

import {
  PageBannerWrapper,
  PageBannerOverlay,
  PageBannerContent,
  PageBannerTitle
} from './PageBanner.styles';

const PageBanner: React.FC<PageBannerProps> = (props) => {
  const { headline, image } = props;
  const img = image?.[0];

  return (
    <PageBannerWrapper image={image}>
      <PageBannerOverlay
        src={img.cdn_files?.[0]?.url || img.url}
        alt={headline}
      />
      <PageBannerContent>
        <PageBannerTitle>{headline}</PageBannerTitle>
      </PageBannerContent>
    </PageBannerWrapper>
  );
};

export default PageBanner;
