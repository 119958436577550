import React from 'react';

import { useWindowSize } from "../../hooks";
import TopNavigation from './TopNavigation';
import MainNavigation from "./MainNavigation";
import { HeaderWrapper } from "./Header.styles";

function Header(){
  const windowSize = useWindowSize();

  return (
    <HeaderWrapper>
      {windowSize > 1024 && <TopNavigation/>}
      <MainNavigation/>
    </HeaderWrapper>
  )
}
export default Header;
