import styled, { css } from 'styled-components';

export const BottomNavWrapper = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    position: relative;
    width: 100%;

    background: ${color.darkGrey};

    display: flex;
    justify-content: space-between;
    align-content: center;
  `;
});

export const BottomNavContainer = styled.div(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    width: 100%;
    margin: 0 auto;
    max-width: 1250px;

    padding: ${size.px24};

    ${mediaQueries.sm} {
      padding: ${size.px24} ${size.px16};
    }
  `;
});

export const Col = styled.div(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    line-height: 1;
    gap: ${size.px8};

    ul {
      min-width: 460px;
      max-width: 465px;
    }

    ${mediaQueries.sm} {
      gap: ${size.px16};

      align-items: flex-start;
      text-align: left;

      ul {
        min-width: 100%;
        max-width: 100%;
        margin-top: ${size.px16};
      }
    }
  `;
});

export const Copy = styled.span(({ theme }) => {
  const { color, size, font, mediaQueries } = theme;

  return css`
    display: block;

    color: ${color.chineseGray};
    font-family: ${font.family.sansSerif};
    font-size: ${size.px12};
    font-weight: ${font.weight.regular};
    line-height: 1.4;

    min-width: 415px;
    max-width: 450px;

    ${mediaQueries.sm} {
      min-width: 250px;
      max-width: 260px;
    }
  `;
});
