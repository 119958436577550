import styled, { css } from 'styled-components';

type QuotesContainerTypes = {
  backgroundColor?: string;
}

type QuotesContentTypes = {
  fontColor?: string;
}

export const QuotesContainer = styled.div<QuotesContainerTypes>(({ theme, backgroundColor}) => {
  const { color, mediaQueries } = theme;

  return css`
    padding: 40px 15px;
    border-radius: 12px;
    background-color: ${() => backgroundColor || color.lightblue};

    ${mediaQueries.sm}{

    }
  `;
});

export const QuotesContent = styled.div<QuotesContentTypes>(({ theme, fontColor }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    width: 100%;
    max-width: 915px;
    margin: 0 auto;
    text-align: center;
 
    h1, h2, h3, h4, h5, h6, p, a, span, ol, ul, li{
      font-family: ${font.family.EMPrintRegular};
      font-weight: ${font.weight.semiBold};
      font-size: ${size.px32};
      line-height: 1.25;
      color: ${() => fontColor || color.graniteGray};
      word-wrap: break-word;

      ${mediaQueries.sm}{
        font-size: ${size.px20};
      }
    }
    
    a {
      color: ${color.sea};
      text-decoration: underline;
    }


    ol, ul{
      padding-left: 16px;
      margin: 10px 0;
      
      ol, ul{
        margin: 0;
      }
    }

    ol {
      & > li {
        list-style-type: decimal;
      }
    }

    ul {
      & > li {
        list-style-type: disc;

        ul {
          & > li {
            list-style-type: circle;
          }
        }
      }
    }
  `;
});
