const rootElement = document.getElementById('root');
let windowScroll = 0,
    isFixed = false;

export const onOpenModal = () => {
  windowScroll = window.scrollY;
  rootElement.style.top = -windowScroll + 'px';
  rootElement.style.width = '100%';
  isFixed = true;
  setTimeout(() => {
    rootElement.style.position = 'fixed';
  })
}

export const onCloseModal = () => {
  rootElement.removeAttribute('style');
  if(isFixed) {
    window.scrollTo(0, windowScroll);
    isFixed = false;
  }
}

window.addEventListener("hashchange", event => {
  onCloseModal();
  window.scrollTo(0, 0);
})