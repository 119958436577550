import styled, { css } from 'styled-components';
import {
  ChestSprite,
  Key_Sprite,
  Key_Sprite_Full,
  Points_1000_Sprite,
  Points_250_Sprite,
  Points_500_Sprite
} from '../../../Phase1/assets/sprites';

export const ChestWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    ${mediaQueries.sm} {
      position: static;
    }
  `;
});

export const ChestRow = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    //height: 50vw; //28vw;
    //max-height: 500px; //365px;
    height: 345px;
    margin: 50px auto 90px; //50px auto;

    * {
      &:focus-visible,
      &:focus {
        border: 0;
        outline: none;
      }
    }

    ${mediaQueries.sm} {
      margin: 50px auto;
    }
    ${mediaQueries.xxs} {
      height: 56vw;
    }
  `;
});

export const ChestInner = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;
    height: 100%;

    ${mediaQueries.sm} {
    }
  `;
});

export const ChestBox = styled.div<{ shadow: boolean }>(({ theme, shadow }) => {
  // @ts-ignore
  const { mediaQueries } = theme;

  return css`
    width: 500px; //400px;
    height: 345px; //365px;
    transition: all 500ms;

    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -250px;
    
    &.hover {
      transform: translateX(10px);
    }
    
    ${mediaQueries.sm} {
      transform: translateX(-50%);
      margin-left: 0;
    }

    ${mediaQueries.xxs} {
      width: 80vw;
      height: 56vw;
    }

    img {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      &:focus {
        outline: none !important;
      }
    }

    video, img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `;
});

export const ChestAnimation = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    z-index: -1;
    width: 500px; //400px;
    height: 345px; //365px;
    transform: translate(0, 0);
    transition: all 300ms;

    &.active-chest-animation-2 {
      top: 0;
      left: calc((100% - 500px * 3) / 2 + 500px + 10px);
      z-index: 2;

      @media (max-width: 1250px) {
        left: calc((100% - 500px * 3) / 2 + 500px + 10px);
      }

      &.completed-chest-animation-2 {
        @media (min-width: 1250px) {
          left: calc((1200px - 890px) / 2 + 10px);
          
          ${PointsIOS250}{
            transform: scale(0.8);
            margin-left: -50px;
            margin-top: -30px;
          }
        }
        
        width:400px;
        height: 275px;
        left: calc((100% - 890px) / 2 + (890px / 2 - 400px) / 2);
        top: 50%;
        margin-top: -170px;
        margin-left: 0;

        ${mediaQueries.md} {
          width: 340px;
          height: 235px;
          left: calc((100% - 890px) / 2 + (890px / 2 - 340px) / 2);
          margin-top: -145px;
        }

        @media (max-width: 940px) {
          width: 40vw;
          height: 30vw;
          margin-top: -17vw;
          left: 4vw;
        }
      }
    }
    

    ${mediaQueries.sm} {
      &.active-chest-animation-2{
        top: 50%;
        left: 50%;
        margin-left: -250px;
        margin-top: -50%;
        animation-name: scaleIn;
        animation-duration: 800ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        
        &.completed-chest-animation-2 {
          width: 500px;
          height: 345px;
          top: 30px;
          margin-top: 0;
          left: 50%;
          margin-left: -250px;
        }
        
        &.hide-chest-animation-mob {
          top: 30%;
          margin-top: 0;
          animation-name: opacityOut;
          animation-duration: 200ms;
        }
      }

      @keyframes scaleIn {
        from {
          transform: scale(0);
          opacity: 0;
        }
        to {
          transform: scale(1);
          opacity: 1;
        }
      }

      @keyframes opacityOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    }

    ${mediaQueries.xxs} {
      width: 80vw;
      height: 56vw;
      
      &.active-chest-animation-2{
        margin-left: -40vw;

        &.completed-chest-animation-2 {
          margin-left: -40vw;
          width: 80vw;
          height: 56vw;
        }
      }
    }

    img {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:focus {
        outline: none !important;
      }
    }

    video, img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `;
});

const countFramesChestX = 4; // from chest_spritesheet.png
const countFramesChestY = 4; // from chest_spritesheet.png

const countFramesKeyX = 6; // from key_spritesheet.png
const countFramesKeyY = 3; // from key_spritesheet.png

const countFramesKeyFullX = 6; // from key_spritesheet.png
const countFramesKeyFullY = 4; // from key_spritesheet.png

const countFramesPoints1000X = 7; // from 1000p_spritesheet.png
const countFramesPoints1000Y = 3; // from 1000p_spritesheet.png

const countFramesPoints500X = 6; // from 500p_spritesheet.png
const countFramesPoints500Y = 3; // from 500p_spritesheet.png

const countFramesPoints250X = 6; // from 250p_spritesheet.png
const countFramesPoints250Y = 3; // from 250p_spritesheet.png

const imgWidth = '500px'; //400px
const imgHeight = '375px'; //'275px';

const imgWidthMd = '31vw';
const imgHeightMd = '31vw / (800 / 550)';

const imgWidthSm = '50vw';
const imgHeightSm = '34.375vw';

export const TIME_IOS_ANIMATION = 2000;

export const PointsIOS1000 = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${ChestSprite}');
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 1250px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Key_Sprite_Full}');
      background-size: calc(${imgWidth} * ${countFramesKeyFullX}) calc(${imgHeight} * ${countFramesKeyFullY});
      animation: key_full_X ${TIME_IOS_ANIMATION}ms steps(${countFramesKeyFullX - 1}) ${TIME_IOS_ANIMATION}ms 1 forwards,
      key_full_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesKeyFullY - 1}) ${TIME_IOS_ANIMATION}ms 1 forwards;

      @keyframes key_full_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesKeyFullX} - 1));
        }
      }

      @keyframes key_full_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesKeyFullY} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesKeyFullX}) calc(${imgHeightMd} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesKeyFullY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesKeyFullX}) calc(${imgHeightSm} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesKeyFullY} - 1));
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_1000_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints1000X}) calc(${imgHeight} * ${countFramesPoints1000Y});
      animation: points_1000_X ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints1000X - 1}) calc(${TIME_IOS_ANIMATION}ms * 2) 1 forwards,
      points_1000_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints1000Y - 1}) calc(${TIME_IOS_ANIMATION}ms * 2) 1 forwards;

      @keyframes points_1000_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints1000X} - 1));
        }
      }

      @keyframes points_1000_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints1000Y} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints1000X}) calc(${imgHeightMd} * ${countFramesPoints1000Y});

        @keyframes points_1000_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints1000X} - 1));
          }
        }

        @keyframes points_1000_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints1000Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints1000X}) calc(${imgHeightSm} * ${countFramesPoints1000Y});

        @keyframes points_1000_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints1000X} - 1));
          }
        }

        @keyframes points_1000_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints1000Y} - 1));
          }
        }
      }
    }
  `;
});

export const PointsIOS500 = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${ChestSprite}');
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 1250px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Key_Sprite_Full}');
      background-size: calc(${imgWidth} * ${countFramesKeyFullX}) calc(${imgHeight} * ${countFramesKeyFullY});
      animation: key_full_X ${TIME_IOS_ANIMATION}ms steps(${countFramesKeyFullX - 1}) ${TIME_IOS_ANIMATION}ms 1 forwards,
      key_full_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesKeyFullY - 1}) ${TIME_IOS_ANIMATION}ms 1 forwards;

      @keyframes key_full_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesKeyFullX} - 1));
        }
      }

      @keyframes key_full_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesKeyFullY} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesKeyFullX}) calc(${imgHeightMd} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesKeyFullY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesKeyFullX}) calc(${imgHeightSm} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesKeyFullY} - 1));
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_500_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints500X}) calc(${imgHeight} * ${countFramesPoints500Y});
      animation: points_500_X ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints500X - 1}) calc(${TIME_IOS_ANIMATION}ms * 2) 1 forwards,
      points_500_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints500Y - 1}) calc(${TIME_IOS_ANIMATION}ms * 2) 1 forwards;

      @keyframes points_500_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints500X} - 1));
        }
      }

      @keyframes points_500_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints500Y} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints500X}) calc(${imgHeightMd} * ${countFramesPoints500Y});

        @keyframes points_500_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints500X} - 1));
          }
        }

        @keyframes points_500_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints500Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints500X}) calc(${imgHeightSm} * ${countFramesPoints500Y});

        @keyframes points_500_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints500X} - 1));
          }
        }

        @keyframes points_500_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints500Y} - 1));
          }
        }
      }
    }
  `;
});

export const PointsIOS250 = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${ChestSprite}');
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 1250px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Key_Sprite_Full}');
      background-size: calc(${imgWidth} * ${countFramesKeyFullX}) calc(${imgHeight} * ${countFramesKeyFullY});
      animation: key_full_X ${TIME_IOS_ANIMATION}ms steps(${countFramesKeyFullX - 1}) ${TIME_IOS_ANIMATION}ms 1 forwards,
      key_full_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesKeyFullY - 1}) ${TIME_IOS_ANIMATION}ms 1 forwards;

      @keyframes key_full_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesKeyFullX} - 1));
        }
      }

      @keyframes key_full_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesKeyFullY} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesKeyFullX}) calc(${imgHeightMd} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesKeyFullY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesKeyFullX}) calc(${imgHeightSm} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesKeyFullY} - 1));
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_250_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints250X}) calc(${imgHeight} * ${countFramesPoints250Y});
      animation: points_250_X ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints250X - 1}) calc(${TIME_IOS_ANIMATION}ms * 2) 1 forwards,
      points_250_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints250Y - 1}) calc(${TIME_IOS_ANIMATION}ms * 2) 1 forwards;

      @keyframes points_250_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints250X} - 1));
        }
      }

      @keyframes points_250_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints250Y} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints250X}) calc(${imgHeightMd} * ${countFramesPoints250Y});

        @keyframes points_250_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints250X} - 1));
          }
        }

        @keyframes points_250_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints250Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints250X}) calc(${imgHeightSm} * ${countFramesPoints250Y});

        @keyframes points_250_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints250X} - 1));
          }
        }

        @keyframes points_250_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints250Y} - 1));
          }
        }
      }
    }
  `;
});

export const KeyIOS = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${ChestSprite}');
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 1250px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Key_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesKeyX}) calc(${imgHeight} * ${countFramesKeyY});
      animation: key_X ${TIME_IOS_ANIMATION}ms steps(${countFramesKeyX - 1}) ${TIME_IOS_ANIMATION}ms 1 forwards,
      key_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesKeyY - 1}) ${TIME_IOS_ANIMATION}ms 1 forwards;

      @keyframes key_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesKeyX} - 1));
        }
      }

      @keyframes key_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesKeyY} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesKeyX}) calc(${imgHeightMd} * ${countFramesKeyY});

        @keyframes key_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesKeyX} - 1));
          }
        }

        @keyframes key_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesKeyY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesKeyX}) calc(${imgHeightSm} * ${countFramesKeyY});

        @keyframes key_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesKeyX} - 1));
          }
        }

        @keyframes key_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesKeyY} - 1));
          }
        }
      }
    }
  `;
});