import styled, { css } from 'styled-components';
import {LangButton} from '../LanguageSelector.styles';
export const BelgLangsWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: none;
    
    &.active{
      display: block;
    }

    ${mediaQueries.md} {
      width: 100%;
    }

    ${LangButton}{
      display: none;

      ${mediaQueries.md} {
        display: block;
      }
    }
  `;
});


export const Separator = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: inline-block;
    vertical-align: middle;
    height: 13px;
    width: 2px;
    border: 1px solid #828282;
    margin: 0 18px;

    ${mediaQueries.md} {
      display: none;
    }
  `;
});

export const LangItem = styled.span(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    position: relative;
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    line-height: 1;
    color: ${color.graniteGray};
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.2s ease;
    
    &.active{
      opacity: 1;
    }

    ${mediaQueries.md} {
      display: none;
    }
  `;
});

export const LangSeparator = styled(LangItem)(({ theme }) => {
  const { mediaQueries } = theme;
  return css`
    cursor: default;
    opacity: 1;
    padding: 0 5px;

    ${mediaQueries.md} {
      display: none;
    }
  `;
});