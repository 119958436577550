import styled, { css } from 'styled-components';


export const VideoWrapper = styled.div(({ theme }) => {
  return css`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007DDE;
    //background: linear-gradient(315deg, #007DDE 0%, #0047BA 100%);
  `;
});

export const VideoContainer = styled.div(({ theme }) => {
  return css`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    h1{
      text-align: center;
      color: #fff;
      width: 100%;
      margin-bottom: 30px;
    }
  `;
});

export const VideoBox = styled.div(({ theme }) => {
  return css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 720px;
    height: 400px;
    max-width: 100%;
    
    img{
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      //z-index: 1;

      &:focus {
        outline: none !important;
      }
    }
    
    video, img{
      max-width: 100%;
      max-height: 100%;
      position: absolute;
    }
    
    video{
      //background: #007DDE !important;
      //pointer-events: none;
    }
    //
    //*::-webkit-media-controls-panel {
    //  display: none!important;
    //  -webkit-appearance: none;
    //}
    //
    //*::-webkit-media-controls-play-button {
    //  display: none!important;
    //  -webkit-appearance: none;
    //}
    //
    //*::-webkit-media-controls-start-playback-button {
    //  display: none!important;
    //  -webkit-appearance: none;
    //}
    //
    //*::-webkit-media-controls {
    //  display:none !important;
    //}
    //
    //*::-webkit-media-controls-panel {
    //  display: none!important;
    //  -webkit-appearance: none;
    //}
  `;
});