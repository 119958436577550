import PageHeaderHelp from './PageHeaderHelp/PageHeaderHelp';
import LinkCollection from './LinkCollection';
import FAQCollection from './FAQCollection/FAQCollection';
import ContactComponent from './ContactComponent';
import ContentBlock from './ContentBlock';
import NavigationCollection from './NavigationCollection';
import Form from './Form';
import PageHeaderProductCategory from './PageHeaderProductCategory';
import ProductCollectionHighlights from './ProductCollectionHighlights';
import ProductCollectionAutomatic from './ProductCollectionAutomatic';
import StepsHorizontal from './StepsHorizontal';
import StepsVertical from './StepsVertical';
import StepsPopup from './StepsPopup';
import PageHeader from './PageHeader';
import Sparen from './Sparen';
import HeroBanner from '../../redesign/components/shared/HeroBanner';
import PageHeaderHome from './PageHeaderHome';
import ProductCollection from './ProductCollection';
import ModalsDemo from './ModalsDemo';
import ProductCollectionManual from './ProductCollectionManual';
import ProductCollectionManualGrid from './ProductCollectionManualGrid';
import ArticleOverview from './ArticleOverview';
import { Banner, BannerNoImage } from './Banners';
import VideoVimeo from './VideoVimeo';
import JustText from './JustText';
import Custom from './Custom';
import TermsContent from './TermsContent';
import AssetComponent from './AssetComponent';
import Quotes from './Quotes';
import LikeDislike from './LikeDislike';
import LoyaltyProgramCollection from './LoyaltyProgramCollection';
import EBikeCampaignCustom from './EBikeCampaignCustom';
import EndYearCampaignCustom from './EndYearCampaignCustom';

export const components: any = {
  PageHeaderHelp,
  LinkCollection,
  FAQCollection,
  ContactComponent,
  ContentBlock,
  NavigationCollection,
  Form,
  PageHeaderProductCategory,
  ProductCollectionHighlights,
  ProductCollectionAutomatic,
  StepsHorizontal,
  StepsVertical,
  StepsPopup,
  PageHeader,
  Sparen,
  PageHeaderHome,
  HeroBanner,
  ProductCollection,
  ModalsDemo,
  ProductCollectionManual,
  ProductCollectionManualGrid,
  ArticleOverview,
  Banner,
  BannerNoImage,
  VideoVimeo,
  JustText,
  Custom,
  TermsContent,
  AssetComponent,
  Quotes,
  LikeDislike,
  LoyaltyProgramCollection,
  EBikeCampaignCustom,
  EndYearCampaignCustom,
};
