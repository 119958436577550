import React from 'react';

import { useSelector } from 'react-redux';

import { BreadcrumbsBox, BreadcrumbsText } from './Breadcrumbs.styles';
import { getLocalePrepr } from '../../../../components/utils/_app-helpers';
import { Container } from 'redesign/theme/GlobalStyles';

type BreadcrumbsType = {
  alignmentBreadcrumbs?: string;
};

const Breadcrumbs: React.FC<BreadcrumbsType> = (props) => {
  const baseUrl = window.location.origin;

  const breadcrumbData = useSelector(
    (state: { CMSData: any }) => state!.CMSData?.breadcrumb?.[0]
  );

  const type_of_breadcrumbs = useSelector(
    (state: { CMSData: any }) => state!.CMSData?.type_of_breadcrumbs
  );
  let locale = getLocalePrepr();

  let backToStr = locale === 'fr-BE' ? 'Retour à la' : 'Terug naar';

  return (
    breadcrumbData && (
      <Container maxWidth="1250px" paddingBottom={0}>
        <BreadcrumbsBox alignmentBreadcrumbs={props?.alignmentBreadcrumbs || 'left'}>
          <BreadcrumbsText
            primary={type_of_breadcrumbs}
            as={'a'}
            href={`${baseUrl}/#${breadcrumbData?._slug}`}
          >
            {backToStr} {breadcrumbData?.internal_name}
          </BreadcrumbsText>
        </BreadcrumbsBox>
      </Container>
    )
  );
};

export default Breadcrumbs;
