import styled, { css } from 'styled-components';
import { StickerBoxProps } from './productCard.types';

type CardTitleProps = {
  typography: {
    fontSize: number;
    lines: number;
    lineHeight: number;
  };
};

type CardCostProps = {
  lines: number;
};

type CardImageProps = {
  minheight?: number;
};

const setCardContainerStyles = (theme: any) => {
  return css`
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    height: 100%;
    width: 100%;
    padding: 8px;
    background: transparent;
    border-radius: 16px;
    transition: box-shadow 0.3s;
    text-decoration: none;
    cursor: pointer;

    ${theme.mediaQueries.minWidthSM} {
      width: 100%;
      border: 0;

      &:hover {
        box-shadow: 0 12px 16px rgba(178, 193, 224, 0.16);
      }

      &:active,
      &:focus,
      &:focus-within,
      &:target,
      &:visited,
      &:focus-visible {
        border: none;
        outline: none;
      }
    }

    ${theme.mediaQueries.sm} {
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  `;
};

export const CardContainerForm = styled.form(({ theme }) => {
  return css`
    ${setCardContainerStyles(theme)}
  `;
});

export const CardBox = styled.a(({ theme }) => {
  return css`
    ${setCardContainerStyles(theme)}
  `;
});

export const CardImg = styled.div<CardImageProps>(({ theme, minheight }) => {
  const { color, mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: ${color.white};

    position: relative;
    width: 100%;

    :after {
      content: '';
      display: block;

      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background: ${color.white};
      border-radius: 16px;
      z-index: 1;
    }

    img {
      width: 230px;
      height: 230px;

      margin: 0 auto;

      vertical-align: bottom;
      border-radius: 12px;
      z-index: 2;

      ${mediaQueries.sm} {
        ${minheight && `height: ${minheight}px`};
        max-width: 146px;
        width: 146px;

        height: 146px;
      }

      ${mediaQueries.se} {
        max-width: 230px;
        max-height: 230px;

        width: 100%;
        height: 100%;
      }
    }

    .manual-grid & {
      img {
        aspect-ratio: 1;
        width: 100%;
        height: 100%;

        max-width: 100%;
        vertical-align: bottom;
        border-radius: 12px;
        z-index: 2;

        ${mediaQueries.sm} {
          ${minheight && `min-height: ${minheight}px`}
          max-height: 208px;
        }
      }
    }

    &.resizedImage {
      min-height: 282px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        aspect-ratio: 1.5;
        width: 80%;
        height: auto;
      }

      ${mediaQueries.md} {
        min-height: 180px;

        .row-element & {
          min-height: 240px;
        }
      }

      ${mediaQueries.sm} {
        min-height: 282px;

        img {
          max-width: 80%;
        }
      }

      ${mediaQueries.xs} {
        min-height: 208px;
        .row-element & {
          min-height: 208px;
          max-height: 208px;
        }
      }
    }
  `;
});

export const CardContent = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 15px 16px 8px;

    width: 100%;
    height: 100%;
    min-height: 100px;

    ${mediaQueries.sm} {
      padding: 10px 0;
      min-height: auto;
    }
  `;
});

export const CardTitle = styled.h3<CardTitleProps>(({ theme, typography }) => {
  const { color, font, mediaQueries, size } = theme;
  const { fontSize, lines, lineHeight } = typography;

  return css`
    color: ${color.darkCharcoal};
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px18};
    color: ${color.darkCharcoal};
    line-height: 1.3;
    margin-bottom: ${size.px4};

    ${mediaQueries.minWidthSM} {
      font-size: '${fontSize}px';
      line-height: ${lineHeight};
      max-height: calc('${fontSize}px' * ${lineHeight} * ${lines});

      margin-bottom: 0;

      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;
});

export const CardCost = styled.p<CardCostProps>(({ theme, lines = 0 }) => {
  const { size, color, font, mediaQueries } = theme;

  return css`
    font-size: ${size.px14};
    color: ${color.graniteGray};

    margin: 0;
    line-height: 1.5;
    font-family: ${font.family.sansSerif};

    ${mediaQueries.minWidthSM} {
      max-height: calc(24px * 1.5 * ${lines});

      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      color: currentColor;
    }
  `;
});

export const StickerText = styled.p(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px14};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    color: ${color.white};
    margin: 0 auto;
    max-width: 100%;

    span {
      display: block;
      color: ${color.white};
      font-size: ${size.px20};
      font-weight: ${font.weight.semiBold};
    }

    ${mediaQueries.sm} {
      font-family: ${font.family.sansSerif};
      font-size: ${size.px10};
      font-weight: ${font.weight.bold};
      line-height: 1;

      span {
        font-size: ${size.px14};
        line-height: 1.2;
      }
    }
  `;
});

export const StickerBox = styled.div<StickerBoxProps>(
  ({ theme, isVisible = true, isDiscount = true }) => {
    const { color, mediaQueries, size } = theme;

    return css`
      display: ${() => (isVisible ? 'flex' : 'none')};
      position: absolute;
      bottom: 8px;
      left: 8px;
      width: 88px;
      height: 88px;
      border-radius: 50%;
      background: ${() =>
        isDiscount ? `${color.azure}` : `${color.lightblue}`};
      box-shadow: ${() =>
        isDiscount ? `0 4px 20px rgba(0, 174, 255, 0.24)` : `none`};
      padding: 0 10px;
      text-align: center;

      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;

      ${StickerText} {
        color: ${() => (isDiscount ? `${color.white}` : `${color.azure}`)};
      }

      ${mediaQueries.sm} {
        padding: 0 ${size.px4};

        width: 56px;
        height: 56px;
      }
    `;
  }
);
