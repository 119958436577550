import React, { useCallback, useRef, useEffect, useState } from "react";
import {BannerCarousel, Products, CampaignCompleted} from "./parts";
import { HGContainer, HGWrapper, RefWrapp } from "./Custom.styles";
import { CustomProps } from "./custom.types";

const Custom: React.FC<CustomProps> = ({params}) => {
  const [idCategory, setIdCategory] = useState<null | string>(null);
  const scrollTo = useRef<HTMLDivElement>(null);
  const scrollToCustom = useRef<HTMLDivElement>(null);

  const executeScroll = useCallback(() => {
    scrollTo?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, []);

  const executeScrollToCustom = useCallback(() => {
    scrollToCustom?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [scrollToCustom])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  useEffect(() => {
    executeScrollToCustom();
  },[executeScrollToCustom])

  return (
    <HGWrapper>
      <RefWrapp ref={scrollToCustom} />
      {params?.campaign_completed && <CampaignCompleted />}
      <HGContainer maxWidth={'1440px'}>
        <BannerCarousel executeScroll={executeScroll}
                        setIdCategory={setIdCategory}/>

        <RefWrapp ref={scrollTo}>
          <Products idCategory={idCategory}/>
        </RefWrapp>
      </HGContainer>
    </HGWrapper>
  )
};

export default Custom;
