import styled, { css } from 'styled-components';

type TextProps = {
  lines: number;
};

type TitleProps = {
  typography: {
    fontSize: number;
    lines: number;
    lineHeight: number;
  };
};

type ButtonProps = {
  buttonType: 'primary' | 'secondary' | 'tertiary';
};

export const Container = styled.div(({ theme }) => {
  const { size, color, mediaQueries } = theme;

  return css`
    height: 205px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: ${size.px8};
    background: ${color.white};
    border-radius: ${size.px16};

    ${mediaQueries.sm} {
      height: auto;
      flex-wrap: wrap;
      padding: ${size.px10};
    }
  `;
});

export const ImgWrap = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;
  return css`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: ${size.px12};
    overflow: hidden;

    img {
      width: auto;
      max-width: max-content;
      height: 100%;
      vertical-align: bottom;
      border-radius: ${size.px12};
    }

    ${mediaQueries.sm} {
      width: 100%;
      
      img{
        max-width: 100%;
        height: auto;
      }
    }
  `;
});

export const ContentWrap = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    position: relative;
    width: 50%;
    padding: 5px 0;

    ${mediaQueries.sm} {
      width: 100%;
      padding: ${size.px12} 0 0;
    }
  `;
});

export const Title = styled.h3<TitleProps>(
  ({ theme, typography }) => {
    const { color, font, mediaQueries } = theme;
    const { fontSize, lines, lineHeight } = typography;

    return css`
      font-family: ${font.family.EMPrintRegular};
      font-weight: ${font.weight.semiBold};
      font-size: ${fontSize}px;
      color: ${color.darkCharcoal};
      line-height: ${lineHeight};
      margin-bottom: 5px;
      max-height: calc(${fontSize}px * ${lineHeight} * ${lines});

      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      ${mediaQueries.sm} {
        display: block;
        max-height: inherit;
      }
    `
  }
);

export const Text = styled.p<TextProps>(
  ({ theme, lines = 0 }) => {
    const { size, color, font, mediaQueries } = theme;

    return css`
      font-size: ${size.px14};
      color: ${color.graniteGray};
      line-height: 1.5;
      font-family: ${font.family.sansSerif};
      max-height: calc(14px * 1.5 * ${lines});

      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      
      ${mediaQueries.sm} {
        margin-bottom: 10px;
        display: block;
        max-height: inherit;
      }
    `;
  }
);

export const ButtonWrap = styled.div<ButtonProps>(({ theme, buttonType }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    bottom: 5px;
    left: 0;
    width: ${() => buttonType !== 'tertiary' ? '100%' : 'auto'};
    
    button, a{
      min-width: auto;
      padding: 1rem;
    }

    ${mediaQueries.md} {
      button, a{
        min-width: ${() => buttonType !== 'tertiary' ? '200px' : 'auto'};
        margin: 0;
        max-width: fit-content;
      }
    }

    ${mediaQueries.sm} {
      position: static;
    }
  `;
});