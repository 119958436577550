import React from "react";
import { ModalTypes } from "../Modal/modal.types";

import Button from "../../Button";
import Modal from "../Modal/Modal";

import {
  ButtonsGroup,
  ModalContent,
  ModalText
} from "../Modal/Modal.styles";

const InfoModalDemo: React.FC<ModalTypes> = ({ ...props }) => {
  const handleBtnAction1 = () => {
    props.onClose();
  };

  const handleBtnAction2 = () => {
    props.onClose();
  };

  return (
    <Modal {...props}>
      <ModalContent>
        <ModalText>
          Would you like to receive push notifications from our website?
        </ModalText>
      </ModalContent>
      <ButtonsGroup>
        <Button text={"no"}
                buttontype={"secondary"}
                onClick={handleBtnAction1} />
        <Button text={"yes"}
                onClick={handleBtnAction2} />
      </ButtonsGroup>
    </Modal>
  );
};

export default InfoModalDemo;
