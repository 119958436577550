import React from 'react';
import { Article, Headline } from '../../../redesign/components/shared';
import { ArticleProps } from '../../../redesign/components/shared/Article/article.types';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import { sortArticles } from '../../../redesign/utils/sortArticles';
import useWindowSize from '../../../redesign/hooks/useWindowSize';
import { URLProps } from '../../interfaces/Page';
import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { ArticlesWrap, ArticleContainer } from './LinkCollection.styles';

interface ArticleCollectionBigProps {
  params?: {
    title: string;
    cta_label: string;
    cta_url: string;
    internal_link: URLProps[];
    links: ArticleProps[];
    max_width: string;
    items_per_row: string;

    _id: string;
    __typename: string;
    description: string;
  };
}

SwiperCore.use([Navigation, Pagination]);

const LinkCollection: React.FC<ArticleCollectionBigProps> = ({ params }) => {
  let windowSize = useWindowSize();

  const baseUrl = window.location.origin;

  const pageURL = params?.internal_link?.[0];

  const isCarouselActive =
    ((+params!.items_per_row > 0 && Number(params!.items_per_row) < params!.links.length) ||
      (+params!.items_per_row <= 0 && params!.links.length >= 4));
  const isCarouselMobActive =
    ((params!.links.length >= 3  && windowSize < 768 && windowSize > 440) ||
      (params!.links.length >= 2  && windowSize < 440));

  return (
    <Wrapper>
      <Container
        maxWidth={
          params?.max_width === 'narrow'
            ? '850px'
            : params?.max_width === 'wide'
              ? '1250px'
              : '100%'
        }
      >
        <Headline
          title={params?.title}
          urlText={params?.cta_label}
          url={
            params?.cta_url ? params?.cta_url : `${baseUrl}/#${pageURL?._slug}`
          }
          subtitle={params?.description}
          nativeLink={!!pageURL?._slug || !!params?.cta_url}
        >
          <ArticlesWrap
            itemsPerRow={params?.links?.length}
            className={`isCustomPagination ${
              isCarouselActive
                ? 'isActiveCarousel articlesPagination'
                : 'articlesRow'
            }`}
          >
            {
              (isCarouselActive || isCarouselMobActive)
                ? (
                  <Swiper
                    slidesPerView={1}
                    breakpoints={{
                      0: {
                        slidesPerView: params!.links.length === 1 ? 1 : 1.15,
                        slidesPerGroup: 1
                      },
                      440: {
                        slidesPerView: params!.links.length === 2 ? 2 : 2.15,
                        slidesPerGroup: 1
                      },
                      768: {
                        slidesPerView:
                          +params!.items_per_row <= 0
                            ? 3
                            : Number(params!.items_per_row),
                        slidesPerGroup:
                          +params!.items_per_row <= 0
                            ? 3
                            : Number(params!.items_per_row)
                      }
                    }}
                    spaceBetween={windowSize < 768 ? 5 : 12}
                    navigation={isCarouselActive}
                    pagination={{
                      type: 'bullets'
                    }}
                  >
                    {params?.links?.length && sortArticles(params.links)
                      ?.map((link, index) => {
                        return (
                          <SwiperSlide key={`${link._id}-${index}`}>
                            <Article {...link} />
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                ) : (
                  <>
                    {params?.links?.length &&
                      sortArticles(params.links)?.map((link, index) => {
                        return (
                          <ArticleContainer
                            key={link._id || index.toString()}
                            itemsPerRow={
                              +params?.items_per_row <= 0
                                ? '3'
                                : params?.items_per_row
                            }
                          >
                            <Article {...link} />
                          </ArticleContainer>
                        );
                      })}
                  </>
                )}
          </ArticlesWrap>
        </Headline>
      </Container>
    </Wrapper>
  );
};

export default LinkCollection;
