import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { NavigationIcon } from "../../MainNavigation.styles";
import { IconDelete, IconProfileBlue } from "../../../../../assets";

type ProfileIconTypes = {
  isDeleteStatus: boolean;
}
export const ProfileWrapper = styled(NavLink)(({ theme }) => {
  const{ color, mediaQueries } = theme;

  return css`
    display: inline-flex;
    align-items: center;
    padding: 0 15px;
    height: 100%;

    ${mediaQueries.md} {
      justify-content: center;
      padding: 0 8px;
    }
    
    &.active{
      background-color: ${color.lightblue};
    }
  `;
});

export const ProfileIcon = styled(NavigationIcon)<ProfileIconTypes>(({isDeleteStatus}) => {
  return css`
    position: relative;
    background-image: url('${IconProfileBlue}');
    ${isDeleteStatus && `
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: -5px;
        right: -5px;
        width: 16px;
        height: 16px;
        background-image: url('${IconDelete}');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    `}
  `;
});

export const ProfileDetails = styled.div(({ theme }) => {
  const{ mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
    
    ${mediaQueries.md} {
    }
  `;
});

export const ProfileText = styled.span(({ theme }) => {
  const{ font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px14};
    line-height: 1;
    color: ${color.blue};

    ${mediaQueries.md} {
      font-size: ${size.px12};
    }
  `;
});

export const ProfileSubText = styled.span(({ theme }) => {
  const{ font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.regular};
    font-size: ${size.px12};
    line-height: 1;
    color: ${color.blue};
    text-transform: capitalize;

    ${mediaQueries.md} {
      font-size: ${size.px10};
    }
  `;
});


