import styled, { css } from 'styled-components';
import { Container } from "../../../redesign/theme/GlobalStyles";

type EditorTypes = {
  fontColor?: string;
}

export const TermsContainer = styled(Container)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    padding-top: 60px;
    padding-bottom: 60px;
    
    ${mediaQueries.sm} {
    }
  `;
  }
);

export const Editor = styled.div<EditorTypes>(({ theme, fontColor = theme.color.navy }) => {
  const { color, font, size, mediaQueries } = theme;

  return css`
    background-color: ${color.white};
    padding: 40px;
    
    h1, h2, h3, h4, h5, h6{
      color: ${fontColor};
      font-weight: ${font.weight.regular};
      line-height: 1.2;
      word-wrap: break-word;
    }

    h1, h2, h3, h4, h5, h6, p, ol, ul{
      margin-bottom: 15px;
    }


    p, a, span, ol, ul, li{
      font-family: ${font.family.sansSerif};
      font-size: ${size.px14};
      font-weight: ${font.weight.regular};
      line-height: 1.5;
      color: ${fontColor};
    }
    
    ol, ul{
      padding-left: 16px;

      p{
        margin-bottom: 0;
      }

      ul, ol{
        margin-bottom: 0;
      }
    }
    
    ol {
      & > li {
        list-style-type: decimal;
      }
    }

    ul {
      & > li {
        list-style-type: circle;

        ul {
          & > li {
            list-style-type: disc;
          }
        }
      }
    }
    
    h1{
      font-size: ${size.px36};

      ${mediaQueries.sm} {
        font-size: ${size.px28};
      }
    }
    
    h2, h3{
      font-size: ${size.px28};

      ${mediaQueries.sm} {
        font-size: ${size.px20};
      }
    }

    h4, h5{
      font-size: ${size.px14};
    }
    
    h1, h2, h3{
      margin-top: 45px;
    }

    a{
      &:link,
      &:visited {
        text-decoration: underline;
      }
      &:hover,
      &:active {
        cursor: pointer;
        color: rgba(0, 33, 100, 0.7);
      }
    }
  `;
});
