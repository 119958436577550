import styled, { css } from 'styled-components';
import { Wrapper } from "../../../redesign/theme/GlobalStyles";

export const WrapperLikeDislike = styled(Wrapper)(({ theme}) => {
  const { color } = theme;

  return css`
    background-color: ${color.lightblue};
  `;
});

export const Headline = styled.p(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    text-align: center;
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px32};
    line-height: 1.25;
    color: ${color.darkCharcoal};
    margin-bottom: 20px;

    ${mediaQueries.sm} {
      font-size: ${size.px24};
    }
  `;
});

export const ButtonsWrapper = styled.div(() => {
  return css`
    display: flex;
    justify-content: center;
  `;
});

export const ButtonEmoji = styled.button(() => {
  return css`
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 7px;
    width: 55px;
    height: 55px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    &:disabled{
      cursor: not-allowed;
    }
  `;
});