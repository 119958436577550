import styled, { css } from 'styled-components';

export const InfoWrapper = styled.div(({ theme }) => {
  const {mediaQueries } = theme;

  return css`
    width: 35%;
    min-width: 320px;
    z-index: 11;

    ${mediaQueries.sm} {
      width: calc(100% + 40px);
      margin: 0 -20px;
      padding: 0 20px 40px;
      min-width: calc(100% + 40px);
      background: #DBECC1;
      position: relative;
      z-index: 33;
      min-height: 225px;
    }
  `;
});

export const Title = styled.h3(({ theme }) => {
  const {font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px32};
    line-height: 1.2;
    color: ${color.darkCharcoal};
    margin-bottom: 20px;

    ${mediaQueries.sm} {
      margin-bottom: 10px;
    }
  `;
});

export const Description = styled.p(({ theme }) => {
  const {font, size, color, mediaQueries } = theme;

  return css`    
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    line-height: 1.5;
    color: ${color.graniteGray};
    margin-bottom: 10px;

    ${mediaQueries.sm} {
    }
  `;
});

export const ButtonBox = styled.div(({ theme }) => {
  const {mediaQueries } = theme;

  return css`
    display: inline-flex;;

    ${mediaQueries.sm} {
    }
  `;
});