import React, {Component} from 'react';
import Cleave from 'cleave.js/react';
import {Trans} from 'react-i18next';

export class Input extends Component {
	constructor(props) {
		super(props);
		this.state = {
			valid: true,
			empty: true,
			text: false,
		};
		this.handleEye = this.handleEye.bind(this);
		this.handleEyeUp = this.handleEyeUp.bind(this);
	}

	componentWillMount() {
		if (this.props.state === 'not-empty') {
			this.setState({
				empty: false,
			});
		}
	}

	handleEye(event) {
		this.setState({
			text: true,
		});
	}

	handleEyeUp(event) {
		this.setState({
			text: false,
		});
	}

	inputCheck = (event) => {
		let value = event.target.value;
		if (!this.props.hint) {
			value.length > 0 ? this.setState({empty: false}) : this.setState({empty: true});
		}
	};

	render() {
		let status = this.state.empty ? 'empty' : 'not-empty';
		let fieldType = this.state.text ? 'text' : this.props.type;
		let eyeActive = this.state.text ? 'active' : 'not-active';
		let options = this.props.options ? this.props.options : {blocks: [999], delimiter: '~'};
		return (
			<div className={`material-input ${status}`}>
				<div className={`group ${this.props.className || ''}`}>
					<Cleave
						options={options}
						type={fieldType}
						name={this.props.name}
						maxLength={this.props.maxLength}
						required
						disabled={this.props.disabled}
						value={this.props.value}
						onChange={this.inputCheck}
						onInit={this.props.onInit}
						onInput={this.props.onInputCallback}
						placeholder={this.props.hint}
						autoComplete={this.props.autocomplete}
						onCut={this.props.onCut}
						onCopy={this.props.onCopy}
						onPaste={this.props.onPaste}
					/>

					{this.props.errorText && <span className="invalid-message">{this.props.errorText}</span>}
					<span className="highlight"></span>
					<span className="bar"></span>
					<label>{this.props.placeholder}</label>
				</div>

				{/* {this.props.name === "card-num" && <span className="bold-placeholder">7036</span>} */}
				{this.props.eyeButton && (
					<button
						type="button"
						className={`eye-indicator ${eyeActive}`}
						onMouseDown={this.handleEye}
						onTouchStart={this.handleEye}
						onMouseUp={this.handleEyeUp}
						onTouchEnd={this.handleEyeUp}
						onMouseLeave={this.handleEyeUp}
						onClick={(e) => {
							e.preventDefault();
						}}
					></button>
				)}

				{this.props.linkText && (
					<a href={this.props.linkHref} onClick={this.props.onClickLink} className="input-link">
						{this.props.linkText}
					</a>
				)}
			</div>
		);
	}
}

export const SwitchBox = (props) => {
	return (
		<label className="styled-checkbox">
			<input type="checkbox" {...props} />
			<span>
				<i className="inactive">
					<Trans i18nKey="05_Login_unknown_switcher_no"></Trans>
				</i>
				<i className="active">
					<Trans i18nKey="05_Login_unknown_switcher_yes"></Trans>
				</i>
			</span>
		</label>
	);
};

export const StyledBox = (props) => {
	return (
		<div className="normal-checkbox">
			<label>
				<input type="checkbox" {...props} defaultChecked={props.defaultChecked} />
				<span></span>
			</label>
			{props.text && <span className="checkbox-text">{props.text}</span>}
		</div>
	);
};

export const Button = (props) => {
	return <button {...props}>{props.text}</button>;
};

export const Select = (props) => {
	return (
		<select name={props.name} id={props.id} onChange={props.onChange} className={props.className}>
			{props.options &&
				props.options.map((option, i) => {
					return (
						<option value={option.value ? option.value : option.text} {...option} key={i}>
							{option.text}
						</option>
					);
				})}
		</select>
	);
};

export const StyledRadioBox = (props) => {
	return (
		<div className="styled-radiobox">
			<input type="radio" name={props.name} value={props.value} id={props.id} />
			<label htmlFor={props.id}>
				<img src={props.src} alt={props.text} />
				<span className="label-text">{props.text}</span>
			</label>
		</div>
	);
};

export class Textarea extends Component {
	constructor() {
		super();
		this.state = {
			isEmpty: true,
		};
	}
	onChange = (event) => {
		event.target.value.length > 0 ? this.setState({isEmpty: false}) : this.setState({isEmpty: true});
	};
	render() {
		return (
			<div className="textarea-field">
				<textarea
					id={this.props.id}
					name={this.props.name}
					onChange={this.onChange}
					placeholder={this.props.placeholder}
					className={`${!this.state.isEmpty ? 'not-empty' : ''}`}
				/>
				<label htmlFor="question" className="form-label">
					{this.props.label}
				</label>
			</div>
		);
	}
}

export class Notification extends Component {
	handleScrollSticky = () => {
		const notify = document.querySelector('.notification-wrapper');
		const mainToTop = document.querySelector('main').getBoundingClientRect().top;
		if (notify) {
			mainToTop < 0 ? notify.classList.add('sticky') : notify.classList.remove('sticky');
		}
	};

	componentDidMount() {
		let selector = document.querySelector('.notification-wrapper');
		setTimeout(() => {
			selector.classList.add('active');
		}, 1);
		//Check position after loading
		this.handleScrollSticky();
		//And check it every time, after user scroll
		window.onscroll = this.handleScrollSticky;
	}

	render() {
		return (
			<div className={`notification-wrapper ${this.props.type}`}>
				<span className="color-margin"></span>
				<span className="notification-text" dangerouslySetInnerHTML={{__html: this.props.text}}></span>
				<button className="notification-close" onClick={this.props.onClick}></button>
			</div>
		);
	}
}

export default Notification;
