import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { store } from '../redux/store';
import { addCMSData } from '../redux/actions';

import { pageViewGTM } from '../components/utils/_gtm-helpers';
import { shopLang } from '../config/config';
import { components } from './components';
import { Breadcrumbs, Loader } from '../redesign/components/shared';
import Error404 from '../redesign/components/Error404';
import { Page } from './interfaces/Page';
import getPage from './queries/getPage';
import { PreprPageWrapper } from '../redesign/theme/GlobalStyles';

interface PreprPageProps {
  onLogin: boolean;
}

export const PreprPage: React.FC<PreprPageProps> = ({ onLogin }) => {
  const location = useLocation();
  const history = useHistory();
  const slug = useMemo(() => location.pathname.slice(1), [location.pathname]);
  const [page, setPage] = useState<Page>();
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [alignmentBreadcrumbs, setAlignmentBreadcrumbs] = useState<string>('left');
  const [pageBackgroundColor, setPageBackgroundColor] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>();

  const fetchPage = useCallback(async () => {
    setIsLoading(true);

    try {
      setErrors([]);
      const data = await getPage(slug);

      setPage(data.data.Page);


      data.data.Page.breadcrumb.length > 0 &&
        setBreadcrumbs(data.data.Page.breadcrumb);

      setPageBackgroundColor(data.data.Page.background_color);

      data.data.Page?.alignment_of_breadcrumbs && setAlignmentBreadcrumbs(data.data.Page.alignment_of_breadcrumbs);

      store.dispatch(addCMSData({ ...data.data.Page }));
    } catch (e) {
      setErrors(['There is an error on the page']);
    }

    setIsLoading(false);
  }, [slug]);

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  // temporary redirects for links from 'Shop'
  useEffect(() => {
    if (window.location.hash === '#/rewards/all/') {
      window.location.replace(
        window.location.hash.replace('#/rewards/all/', '#/rewards/all')
      );
      window.location.reload();
    }
    else if(window.location.hash === '#/contact-us/contact-us/'){
      window.location.replace(
        window.location.hash.replace('#/contact-us/contact-us/', '#/info-contact')
      );
      window.location.reload();
    }
    else if(window.location.hash === '#/save/all/'){
      window.location.replace(
        window.location.hash.replace('#/save/all/', '#/save')
      );
      window.location.reload();
    }
    else if(shopLang === 'be_nl' && window.location.hash === '#/cycloo-logged-in' && !onLogin){
      window.location.replace(
        window.location.hash.replace('#/cycloo-logged-in', '#/cycloo-logged-out')
      );
      window.location.reload();
    }
    else if(shopLang === 'be_nl' && window.location.hash === '#/cycloo-logged-out' && onLogin){
      window.location.replace(
        window.location.hash.replace('#/cycloo-logged-out', '#/cycloo-logged-in')
      );
      window.location.reload();
    }
  })
  // end temporary redirects for links from 'Shop'

  useEffect(() => {
    if (location.pathname === '/delete-account' && !onLogin) {
      history.push({
        pathname: '/auth/login',
        state: {
          prevPath: location.pathname
        }
      })
    }
  }, [history, location.pathname, onLogin]);

  useEffect(() => {
    if(location.pathname === '/info-contact'){
      pageViewGTM(
        `/${window.location.hash}`,
        'Info & Contact - Esso Extras',
        `${window.location.href}`
      );
    }
    if(location.pathname === '/homepage-member-active'){
      pageViewGTM(
        `/${window.location.hash}`,
        `Home Page Member Active - Esso Extras`,
        `${window.location.href}`
      );
    }
    if(location.pathname === '/homepage-member-inactive'){
      pageViewGTM(
        `/${window.location.hash}`,
        `Home Page Member Inactive - Esso Extras`,
        `${window.location.href}`
      );
    }
    if(location.pathname === '/home-page'){
      pageViewGTM(
        `/${window.location.hash}`,
        `Home Page - Esso Extras`,
        `${window.location.href}`
      );
    }
  }, [location.pathname]);

  return (
    <PreprPageWrapper backgroundColor={pageBackgroundColor}>
      {breadcrumbs && <Breadcrumbs alignmentBreadcrumbs={alignmentBreadcrumbs}/>}
      {errors && errors?.length > 0 && <Error404/>}

      {isLoading ? (
        <Loader />
      ) : (
        page?.components?.map((i: any, idx) => {
          const Component = components[i.__typename];
          return (
            <div key={i._id + idx}>
              {Component ? (
                <Component params={i} onLogin={onLogin} slug={slug} pageId={page?._id}/>
              ) : (
                <div>Undefined component {i.__typename}</div>
              )}
            </div>
          );
        })
      )}
    </PreprPageWrapper>
  );
};
