import React from 'react';
import { ToastWrapper, ToastIcon, ToastText } from './Toast.styles';

export interface ToastTypes extends React.HTMLAttributes<HTMLDivElement> {
  type: 'blueChest' | 'goldChest' | 'keys';
}

interface ToastProps extends ToastTypes {
  message: string;
}

const Toast: React.FC<ToastProps> = ({message, type, ...props}) => {
    return (
      <ToastWrapper type={type}
                    {...props}>
        <ToastIcon />
        <ToastText>{message}</ToastText>
      </ToastWrapper>
    )
}

export default Toast;