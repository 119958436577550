import React from 'react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ChestGame from './ChestGame';
// import { reCAPTCHA } from './api/const';

const EYC = () => {
  return (
    // reCAPTCHA ?
    //     <GoogleReCaptchaProvider reCaptchaKey={`${reCAPTCHA}`} useEnterprise>
    //         <ChestGame/>
    //     </GoogleReCaptchaProvider> :
    <ChestGame/>
  )
};
export default EYC;