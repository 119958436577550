import React from 'react';

import { Headline } from '../../../redesign/components/shared';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { FormSubtitle } from './Form.styles';
import ContactForm from '../../../redesign/components/shared/ContactForm';
import AccountDeletionForm from '../../../redesign/components/shared/AccountDeletionForm';

interface FormProps {
  params?: {
    title: string;
    label: string;
    form: string;
  };
}

const Form: React.FC<FormProps> = ({ params }) => {
  return (
    <Wrapper>
      <Container>
        <Headline title={params?.title}>
          <FormSubtitle>{params?.label}</FormSubtitle>
        </Headline>
        {params?.form === 'accountDeletionForm' ?
          <AccountDeletionForm /> :
          <ContactForm />
        }
      </Container>
    </Wrapper>
  );
};

export default Form;
