import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SVG from 'react-inlinesvg';
// @ts-ignore
import { Interpolate } from 'react-i18next';

import Button from '../../shared/Button';
import { InputField, SelectAutocomplete, Checkbox } from '../../shared/Form';
import WhyDialog from '../../../../components/common/dialogs/Why';
import { LocationAgreeDialog } from '../../../../components/common/dialogs/LocationAgree';
import { ContactService } from '../../../../components/common/dialogs/ContactService';
import { TermsAlert } from '../../../../components/common/modals/TermsAlert';
import { NewsLetterModal } from '../../shared/modals';

import i18n from '../../../../config/i18n';
import { useModal } from '../../../hooks';
import { countriesList, errorsList, regExpList } from '../../shared/Form/data';
import { apiLang, authLang } from '../../../../config/config';
import { regNoCard, regWithCard } from '../../../../components/utils/_auth-helpers';
import { onCloseModal } from '../../../../components/common/modals/_modals-helper';
import { clickGTM, pageViewGTM } from '../../../../components/utils/_gtm-helpers';

import { Container } from '../../../theme/GlobalStyles';
import { BreadcrumbsBox, BreadcrumbsText } from '../../shared/Breadcrumbs/Breadcrumbs.styles';
import {
  BreadcrumbsWrapper,
  RegistrationWrapper,
  RegistrationTitle,
  RegistrationText,
  RegistrationTextLink,
  FormWrapper,
  FormSection,
  FormSectionTitle,
  FieldWrapperReg,
  IconInfoBox,
  FieldWrapperRegIcon,
  WhyDialogBox,
  LocationAgreeBox,
  HelpText,
  DetailsCard,
  CheckboxWrapper,
  CheckboxContainer,
  FixAutoFillInput,
  FormSectionNewsLetter,
  InfoLabelBox,
  InfoLabelText,
} from './Registration.styles';
import { IconInfo } from '../../../assets';

type InputsType = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
  cardNumber?: string;
  cardCode?: string;
  registrationWithCard: string;
  communicationAgree?: boolean | null;
  locationAgree?: boolean | null;
  termsAgree: boolean;
};

type RegistrationType = {
  showNotice: (text: string, type: string) => void;
};

function Registration({ ...props }: RegistrationType) {
  const { isShowed, toggle, close, isAnimated } = useModal();
  const counterNewsLetterModal = useRef<number>(0);
  const [captchaPassed, setCaptchaPassed] = useState<boolean>(false);
  const [isLocationAgreeDialogVisible, setIsLocationAgreeDialogVisible] = useState<boolean>(false);
  const [isWhyDialogVisible, setIsWhyDialogVisible] = useState<boolean>(false);
  const [isContactServiceVisible, setIsContactServiceVisible] = useState<boolean>(false);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const communicationAgreeRef = useRef<HTMLInputElement>(null);

  const passwordScheme = ({
    uppercase: {
      regexp: regExpList.password.uppercase,
      errorText: errorsList.password.uppercase
    },
    lowercase: {
      regexp: regExpList.password.lowercase,
      errorText: errorsList.password.lowercase
    },
    number: {
      regexp: regExpList.password.number,
      errorText: errorsList.password.number
    },
    specialCharacter: {
      regexp: regExpList.password.specialCharacter,
      errorText: errorsList.password.specialCharacter
    },
    eightCharacter: {
      regexp: regExpList.password.eightCharacter,
      errorText: errorsList.password.eightCharacter
    }
  });

  const validationSchema = ({
    password: { ...passwordScheme },
    passwordConfirmation: {
      ...passwordScheme,
      match: {
        regexp: null,
        errorText: errorsList.password.match
      }
    },
    phoneNumber: {
      selectCountry: {
        regexp: null,
        errorText: errorsList.phoneNumber.selectCountry
      },
      nullStarted: {
        regexp: regExpList.phoneNumber.nullStarted,
        errorText: errorsList.phoneNumber.nullStarted
      },
      onlyNumbers: {
        regexp: regExpList.phoneNumber.onlyNumbers,
        errorText: errorsList.phoneNumber.onlyNumbers
      },
      minMaxLength: {
        regexp: regExpList.phoneNumber.minMaxLength,
        errorText: errorsList.phoneNumber.minMaxLength
      }
    }
  });

  const schema =
    yup
      .object()
      .shape({
        cardCode: yup
          .string()
          .when('registrationWithCard', (valueRadioBox, schema) => {
            if (String(valueRadioBox) === 'withCard')
              return yup
                .string()
                .required(`${errorsList.cardCode.required}`)
                .matches(regExpList.cardCode.required, `${errorsList.cardCode.required}`);
            return schema;
          }),
        cardNumber: yup
          .string()
          .when('registrationWithCard', (valueRadioBox, schema) => {
            if (String(valueRadioBox) === 'withCard')
              return yup
                .string()
                .required(`${errorsList.cardNumber.required}`)
                .matches(regExpList.cardNumber.format, `${errorsList.cardNumber.format}`);
            return schema;
          }),
        email: yup
          .string()
          .required(`${errorsList.email.required}`)
          .matches(regExpList.email.required, `${errorsList.email.required}`),
        lastName: yup
          .string()
          .required(`${errorsList.lastName.required}`)
          .matches(regExpList.lastName.required, `${errorsList.lastName.required}`),
        firstName: yup
          .string()
          .required(`${errorsList.firstName.required}`)
          .matches(regExpList.firstName.required, `${errorsList.firstName.required}`),
        password: yup
          .string()
          .required(`${errorsList.password.required}`)
          .test({
            message: errorsList.password.required,
            test: (value) => {
              let errors = [];

              if (!validationSchema.password.uppercase.regexp.test(value)) {
                errors.push(validationSchema.password.uppercase.errorText);
              }
              if (!validationSchema.password.lowercase.regexp.test(value)) {
                errors.push(validationSchema.password.lowercase.errorText);
              }
              if (!validationSchema.password.number.regexp.test(value)) {
                errors.push(validationSchema.password.number.errorText);
              }
              if (!validationSchema.password.specialCharacter.regexp.test(value)) {
                errors.push(validationSchema.password.specialCharacter.errorText);
              }
              if (!validationSchema.password.eightCharacter.regexp.test(value)) {
                errors.push(validationSchema.password.eightCharacter.errorText);
              }

              if (errors.length > 0) {
                // @ts-ignore
                throw new yup.ValidationError({
                  errors: errors,
                  inner: true,
                  path: 'password',
                  message: errors,
                  value: value,
                  name: 'ValidationError'
                });
              }

              return true;
            }
          }),
        passwordConfirmation: yup
          .string()
          .required(`${errorsList.password.required}`)
          .test({
            message: errorsList.password.required,
            test: (value, obj) => {
              const { parent } = obj;
              let errors = [];

              if (!validationSchema.passwordConfirmation.uppercase.regexp.test(value)) {
                errors.push(validationSchema.passwordConfirmation.uppercase.errorText);
              }
              if (!validationSchema.passwordConfirmation.lowercase.regexp.test(value)) {
                errors.push(validationSchema.passwordConfirmation.lowercase.errorText);
              }
              if (!validationSchema.passwordConfirmation.number.regexp.test(value)) {
                errors.push(validationSchema.passwordConfirmation.number.errorText);
              }
              if (!validationSchema.passwordConfirmation.specialCharacter.regexp.test(value)) {
                errors.push(validationSchema.passwordConfirmation.specialCharacter.errorText);
              }
              if (!validationSchema.passwordConfirmation.eightCharacter.regexp.test(value)) {
                errors.push(validationSchema.passwordConfirmation.eightCharacter.errorText);
              }
              if (value !== parent.password) {
                errors.push(validationSchema.passwordConfirmation.match.errorText);
              }

              if (errors.length > 0) {
                // @ts-ignore
                throw new yup.ValidationError({
                  errors: errors,
                  inner: true,
                  path: 'passwordConfirmation',
                  message: errors,
                  value: value,
                  name: 'ValidationError'
                });
              }
              return true;
            }
          }),
        phoneNumber: yup
          .string()
          .required(`${errorsList.phoneNumber.required}`)
          .test({
            message: `${i18n.t(errorsList.phoneNumber.required)}`,
            test: (value, obj) => {
              let errors = [];

              let country = countriesList.find((country) => {
                return value.startsWith(String(country?.value));
              });

              if (!country) {
                errors.push(validationSchema.phoneNumber.selectCountry.errorText);
              }

              let val = value.split(')');

              if (val[1] === undefined || !validationSchema.phoneNumber.nullStarted.regexp.test(val[1])) {
                errors.push(validationSchema.phoneNumber.nullStarted.errorText);
              }
              if (val[1] === undefined || !validationSchema.phoneNumber.onlyNumbers.regexp.test(val[1])) {
                errors.push(validationSchema.phoneNumber.onlyNumbers.errorText);
              }
              if (val[1] === undefined || !validationSchema.phoneNumber.minMaxLength.regexp.test(val[1])) {
                errors.push(validationSchema.phoneNumber.minMaxLength.errorText);
              }

              if (errors.length > 0) {
                // @ts-ignore
                throw new yup.ValidationError({
                  errors: errors,
                  inner: true,
                  path: 'phoneNumber',
                  message: errors,
                  value: value,
                  name: 'ValidationError'
                });
              }

              return true;
            }
          }),
        registrationWithCard: yup
          .string()
          .required(),
        communicationAgree: yup
          .boolean()
          .nullable()
          .notRequired(),
        locationAgree: yup
          .boolean()
          .nullable()
          .notRequired(),
        termsAgree: yup
          .boolean()
          .required()
          .oneOf([true])
      }).required();

  const methods = useForm<InputsType>({
    resolver: yupResolver(schema),
    mode: 'all',
    shouldUnregister: true
  });

  const {
    watch,
    unregister,
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = methods;

  const [isTermsAlertVisible, setIsTermsAlertVisible] = useState<boolean>(false);

  const registrationWithCard = watch('registrationWithCard');
  const locationAgree = watch('locationAgree');
  const communicationAgree = watch('communicationAgree');

  useEffect(() => {
    if (registrationWithCard === 'withoutCard') {
      unregister(['cardNumber', 'cardCode'], { keepIsValid: true, keepError: true });
    }
  }, [registrationWithCard, unregister]);

  useEffect(() => {
    !!errors.termsAgree && setIsTermsAlertVisible(true);
  }, [errors.termsAgree]);

  useEffect(() => {
    pageViewGTM(`/${window.location.hash}`, 'Home Page – create account - v2', `${window.location.href}`);
  }, [])

  const handleNewsletter = useCallback(() => {
    if (isValid && !locationAgree && !communicationAgree && counterNewsLetterModal.current < 1) {
      toggle();
      counterNewsLetterModal.current++;
    }
  },[isValid, locationAgree, communicationAgree, toggle]);

  const handleCloseWhyDialog = (e: any) => {
    e.preventDefault();
    setIsWhyDialogVisible(false);
  };

  const handleOpenWhyDialog = (e: any) => {
    e.preventDefault();
    setIsWhyDialogVisible(true);
  };

  const handleCloseLocationAgreeDialog = (e: any) => {
    e.preventDefault();
    setIsLocationAgreeDialogVisible(false);
  };

  const handleOpenLocationAgreeDialog = (e: any) => {
    e.preventDefault();
    setIsLocationAgreeDialogVisible(true);
  };

  const handleCloseTermsAlert = (e: any) => {
    e.preventDefault();
    setIsTermsAlertVisible(false);
    onCloseModal();
  };

  const handleSubscribe = useCallback(() => {
    communicationAgreeRef?.current?.click();
    close();
  }, [communicationAgreeRef, close])

  useEffect(() => {
    handleNewsletter();
  }, [handleNewsletter]);

  const onSubmit: SubmitHandler<InputsType> = (formData) => {
    let preferences = {
      dataCollectOptin: formData.termsAgree ? 1 : 2,
      communicationOptin: formData?.communicationAgree ? 1 : 2,
      locationOptin: formData.hasOwnProperty('locationAgree') ? (formData?.locationAgree ? 1 : 2) : 3,
      appVersion: `Website ${Date.now()}`,
      date: Date.now()
    };
    let settings = {
      preferences,
      email: formData.email,
      password: formData.password,
      firstName: formData.firstName,
      lastName: formData.lastName,
      country: apiLang,
      language: authLang,
      prefix: 'Beste',
      gender: 3,
      phoneNumber: formData.phoneNumber.replace('+', '').replace('(0)', '').trim(),
      usePhoneNumber: false
    };

    clickGTM('button_click', 'Button Clicks', 'maakaccount_registreren');

    if (formData.registrationWithCard === 'withoutCard') {
      regNoCard(settings)
        .then(function(response) {
          localStorage.setItem('userEmail', formData.email);
          window.location.href = '#/email-sent';
        })
        .catch(function(error) {
          setCaptchaPassed(false);
          if (error.response.data.code === 4006) {
            props.showNotice(i18n.t('08_wrong_card_or_act_code_4006'), 'error');
          } else if (error.response.data.code === 406) {
            props.showNotice(i18n.t('08_blacklisted_password_error'), 'error');
          } else if (error.response.data.code === 4008) {
            props.showNotice(i18n.t('email_format_invalid_error'), 'error');
          } else {
            props.showNotice((error.response.data.message || error?.message), 'error');
          }
        });
    } else {
      let settingsWithCard = {
        ...settings,
        cardNumber: formData?.cardNumber?.replace(/ /g, ''),
        activationCode: formData.cardCode
      };

      regWithCard(settingsWithCard)
        .then(function(response) {
          localStorage.setItem('userEmail', formData.email);
          window.location.href = '#/email-sent';
          localStorage.removeItem('cardNumber');
        })
        .catch(function(error) {
          setCaptchaPassed(false);
          if (error.response.data.code === 4006) {
            props.showNotice(i18n.t('08_wrong_card_or_act_code_4006'), 'error');
          } else if (error.response.data.code === 406) {
            props.showNotice(i18n.t('08_blacklisted_password_error'), 'error');
          } else {
            props.showNotice(error.response.data.message, 'error');
          }
        });
    }
  };

  return (
    <>
      <BreadcrumbsWrapper>
        <Container maxWidth='1400px' paddingBottom={0}>
          <BreadcrumbsBox>
            <BreadcrumbsText
              primary={'primary'}
              as={'a'}
              href={`#/auth/login`}
            >
              {i18n.t('btn_back_to_login')}
            </BreadcrumbsText>
          </BreadcrumbsBox>
        </Container>
      </BreadcrumbsWrapper>

      <RegistrationWrapper>
        <Container maxWidth='800px'>
          <RegistrationTitle>{i18n.t('registration_title')}</RegistrationTitle>
          <RegistrationText>
            {i18n.t('registration_subtitle')}
            <RegistrationTextLink to={'/auth/login'}
                                  onClick={() => clickGTM('button_click', 'Button Clicks', 'maakaccount_login')}>
              {' '} {i18n.t('registration_subtitle_link')}
            </RegistrationTextLink>
          </RegistrationText>

          <FormProvider {...methods}>
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
              <FormSection>
                <FormSectionTitle>{i18n.t('registration_title_personal_info')}</FormSectionTitle>
                <FieldWrapperReg>
                  <InputField
                    register={{
                      ...register('email')
                    }}
                    type='text'
                    placeholder={i18n.t('05_Login_email_field')}
                    errorText={errors?.email?.message}
                    name='email'
                    label={i18n.t('05_Login_email_field')}
                    width={100}
                    isError={!!errors.email}
                    isRequired
                    onCut={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </FieldWrapperReg>

                <FieldWrapperReg>
                  <InputField
                    register={{
                      ...register('firstName')
                    }}
                    type='text'
                    placeholder={i18n.t('05_Reg_first_name')}
                    errorText={errors?.firstName?.message}
                    name='firstName'
                    label={i18n.t('05_Reg_first_name')}
                    width={100}
                    isError={!!errors.firstName}
                    isRequired
                    onCut={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </FieldWrapperReg>

                <FieldWrapperReg>
                  <InputField
                    register={{
                      ...register('lastName')
                    }}
                    type='text'
                    placeholder={i18n.t('05_Reg_last_name')}
                    errorText={errors?.lastName?.message}
                    name='lastName'
                    label={i18n.t('05_Reg_last_name')}
                    width={100}
                    isError={!!errors.lastName}
                    isRequired
                    onCut={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </FieldWrapperReg>
                <FieldWrapperRegIcon>
                  <SelectAutocomplete
                    refEl={phoneNumberRef}
                    width={100}
                    label={`${i18n.t('13_Contact-form_phonenumber')}*`}
                    name='phoneNumber'
                    hasDefaultValue
                    options={countriesList}
                    errorText={errors?.phoneNumber?.message}
                    isError={!!errors.phoneNumber}
                    isRequired
                    isErrorsListActive={true}
                    fieldScheme={validationSchema.phoneNumber}
                    isDisabledClipboardEvent={true}
                  />
                  <HelpText>{i18n.t('05_Reg_phonenumber_text')}</HelpText>
                  <WhyDialogBox className={'whyDialogBox'}>
                    <IconInfoBox
                      onClick={(e) => handleOpenWhyDialog(e)}>
                      <SVG src={IconInfo} />
                    </IconInfoBox>
                    <WhyDialog
                      handleCloseModal={handleCloseWhyDialog}
                      isOpen={isWhyDialogVisible}
                    />
                  </WhyDialogBox>
                </FieldWrapperRegIcon>
              </FormSection>
              <FormSection>
                <FormSectionTitle>{i18n.t('registration_title_password')}</FormSectionTitle>
                <FieldWrapperReg>
                  <InputField
                    register={{
                      ...register('password')
                    }}
                    type='password'
                    placeholder={i18n.t('05_Login_password')}
                    errorText={errors?.password?.message}
                    name='password'
                    label={i18n.t('05_Login_password')}
                    width={100}
                    isError={!!errors.password}
                    isRequired
                    isEyeIconActive={true}
                    isErrorsListActive={true}
                    fieldScheme={validationSchema.password}
                    onCut={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </FieldWrapperReg>
                <FixAutoFillInput type='text' />
                <FieldWrapperReg>
                  <InputField
                    register={{
                      ...register('passwordConfirmation')
                    }}
                    type='password'
                    placeholder={i18n.t('05_Reg_confirm_password_field')}
                    errorText={errors?.passwordConfirmation?.message}
                    name='passwordConfirmation'
                    label={i18n.t('05_Reg_confirm_password_field')}
                    width={100}
                    isError={!!errors.passwordConfirmation}
                    isRequired
                    isEyeIconActive={true}
                    isErrorsListActive={true}
                    fieldScheme={validationSchema.passwordConfirmation}
                    onCut={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </FieldWrapperReg>
              </FormSection>
              <FormSection>
                <FormSectionTitle>{i18n.t('registration_title_cards')}</FormSectionTitle>
                <CheckboxWrapper>
                  <CheckboxContainer>
                    <Checkbox
                      control={control}
                      value='withCard'
                      name='registrationWithCard'
                      type='radio'
                      styledType='circle'
                      label={i18n.t('05_Authorization_register_choice1')}
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      control={control}
                      value='withoutCard'
                      name='registrationWithCard'
                      type='radio'
                      styledType='circle'
                      label={i18n.t('05_Authorization_register_choice2')}
                    />
                  </CheckboxContainer>
                </CheckboxWrapper>
                {registrationWithCard === 'withCard' &&
                  <DetailsCard>
                    <FormSectionTitle>{i18n.t('registration_title_details_cards')}</FormSectionTitle>
                    <FieldWrapperReg>
                      <InputField
                        register={{
                          ...register('cardNumber')
                        }}
                        type='text'
                        placeholder={'0000 0000 0000 0000 000'}
                        errorText={errors?.cardNumber?.message}
                        name='cardNumber'
                        label={i18n.t('05_Login_reg_cardnumber')}
                        width={100}
                        isError={!!errors?.cardNumber}
                        isRequired
                        onCut={(e) => e.preventDefault()}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                      />
                    </FieldWrapperReg>
                    <FieldWrapperReg>
                      <InputField
                        register={{
                          ...register('cardCode')
                        }}
                        type='text'
                        placeholder={i18n.t('05_Reg_activation_code')}
                        errorText={errors?.cardCode?.message}
                        name='cardCode'
                        label={i18n.t('05_Reg_activation_code')}
                        width={100}
                        isError={!!errors?.cardCode}
                        isRequired
                        onCut={(e) => e.preventDefault()}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                      />

                      <HelpText>
                        {i18n.t('registration_help_text_details_cards')}{' '}
                        <span className='contactServiceBox'
                              onClick={() => setIsContactServiceVisible(true)}>
                          {i18n.t('registration_help_btn_details_cards')}
                        </span>
                      </HelpText>

                      <ContactService
                        handleCloseModal={() => setIsContactServiceVisible(false)}
                        isOpen={isContactServiceVisible}
                      />
                    </FieldWrapperReg>
                  </DetailsCard>
                }
              </FormSection>
              <FormSectionNewsLetter>
                <FormSectionTitle>{i18n.t('registration_title_bonuses')}</FormSectionTitle>
                <HelpText>{i18n.t('registration_text_bonuses')}</HelpText>
                <CheckboxWrapper>
                  <CheckboxContainer>
                    <Checkbox
                      refEl={communicationAgreeRef}
                      control={control}
                      value={true}
                      name='communicationAgree'
                      type='checkbox'
                      styledType='square'
                      label={i18n.t('registration_bonuses_1_option')}
                    />
                    <InfoLabelBox>
                      <InfoLabelText>{i18n.t('registration_bonuses_label')}</InfoLabelText>
                    </InfoLabelBox>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      control={control}
                      value={true}
                      name='locationAgree'
                      type='checkbox'
                      styledType='square'
                      label={i18n.t('registration_bonuses_2_option')}
                    />
                    <IconInfoBox className='locationDialogBox'
                                 onClick={(e) => handleOpenLocationAgreeDialog(e)}>
                      <SVG src={IconInfo} />
                    </IconInfoBox>
                  </CheckboxContainer>
                  <LocationAgreeBox className='locationDialogBox'>
                    <LocationAgreeDialog
                      handleCloseModal={handleCloseLocationAgreeDialog}
                      isOpen={isLocationAgreeDialogVisible}
                    />
                  </LocationAgreeBox>
                </CheckboxWrapper>
              </FormSectionNewsLetter>
              <FormSection>
                <CheckboxContainer>
                  <Checkbox
                    control={control}
                    value={true}
                    name='termsAgree'
                    type='checkbox'
                    styledType='square'
                    label={<Interpolate i18nKey='registration_terms'
                                        useDangerouslySetInnerHTML={true}
                                        link={<Link to={'/terms'}>{i18n.t('05_Registration_terms_title')}</Link>} />}
                  />
                </CheckboxContainer>
              </FormSection>

              {
                !captchaPassed &&
                <ReCAPTCHA
                  style={{ marginBottom: '30px', marginTop: '30px' }}
                  sitekey='6LfripkUAAAAAF9Pbf9umo1MQ6PCPthtFVc8ez8D'
                  onChange={() => setCaptchaPassed(true)}
                />
              }

              {
                captchaPassed &&
                <Button className={'btnLogin'}
                        disabled={!isValid || !isDirty}
                        text={i18n.t('05_Reg_register_button')}
                        type='submit' />
              }
            </FormWrapper>
          </FormProvider>
        </Container>

        {isShowed &&
          <NewsLetterModal
            onSubscribe={handleSubscribe}
            isShowed={isShowed}
            onClose={() => close()}
            isAnimated={isAnimated}
            maxWidth='384px'
            isModalCloseBtn={true}
            title={i18n.t('registration_news_letter_popup_title')} />}

        <TermsAlert handleCloseModal={(e: any) => handleCloseTermsAlert(e)} isOpen={isTermsAlertVisible} />
      </RegistrationWrapper>
    </>
  );
}

export default Registration;