import React from 'react';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import EBikeCampaign from './EBikeCampaign';
import { reCAPTCHA } from './api_campaign/const';

const EBikeCampaignCustom = ()=> {
  return (
      reCAPTCHA ?
          <GoogleReCaptchaProvider reCaptchaKey={`${reCAPTCHA}`} useEnterprise>
              <EBikeCampaign/>
          </GoogleReCaptchaProvider> :
          <EBikeCampaign/>
  )
};
export default EBikeCampaignCustom;