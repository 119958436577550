import { SelectOption } from '../../Form/SelectField/selectField.types';
import i18n from '../../../../../config/i18n';

export const questionsList: SelectOption[] = [
  {
    label: `${i18n.t('13_Contact-form_q_reg-activation-code')}`,
    value: `${i18n.t('13_Contact-form_q_reg-activation-code')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_reg-difficulties')}`,
    value: `${i18n.t('13_Contact-form_q_reg-difficulties')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_password-forgotten')}`,
    value: `${i18n.t('13_Contact-form_q_password-forgotten')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_security-form_question')}`,
    value: `${i18n.t('13_Contact-form_q_security-form_question')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_card-lost')}`,
    value: `${i18n.t('13_Contact-form_q_card-lost')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_app-site-work')}`,
    value: `${i18n.t('13_Contact-form_q_app-site-work')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_points-recieving')}`,
    value: `${i18n.t('13_Contact-form_q_points-recieving')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_giftcards')}`,
    value: `${i18n.t('13_Contact-form_q_giftcards')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_points-expiring')}`,
    value: `${i18n.t('13_Contact-form_q_points-expiring')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_link-card')}`,
    value: `${i18n.t('13_Contact-form_q_link-card')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_leave-the-program')}`,
    value: `${i18n.t('13_Contact-form_q_leave-the-program')}`
  },
  {
    label: `${i18n.t('13_Contact-form_q_other-reason')}`,
    value: `${i18n.t('13_Contact-form_q_other-reason')}`
  }
];
