import React from 'react';
import { PageHeaderProps } from './pageHeader.types';
import { Button } from '../../../redesign/components/shared';
import {
  BannerWrapper,
  BannerContainer,
  BannerContent,
  BannerTitle,
  BannerText,
  BannerSubTitle,
  ButtonBox,
  BannerImage
} from './PageHeader.styles';
import { useSelector } from 'react-redux';

const PageHeader: React.FC<PageHeaderProps> = ({ params }) => {
  const image = params?.image?.[0];
  const imageUrl = image?.cdn_files?.[0]?.url || image?.url;
  const imageAlignment = params?.image_alignment;

  const pageURL = params?.internal_link?.[0];
  const btnLink = pageURL ? pageURL._slug : params?.cta_url;
  const hasLink = pageURL || params?.cta_url !== null;

  const breadcrumbData = useSelector(
    (state: { CMSData: any }) => state!.CMSData?.breadcrumb?.[0]
  );

  return (
    <BannerWrapper imagePosition={params?.image_position}>
      <BannerContainer maxWidth="1250px">
        <BannerContent>
          <BannerSubTitle>{params?.label}</BannerSubTitle>
          <BannerTitle>{params?.headline}</BannerTitle>
          <BannerText dangerouslySetInnerHTML={{ __html: `${params?.text}` }} />
          {params?.cta_label && hasLink && (
            <ButtonBox>
              <Button
                url={btnLink}
                text={params?.cta_label}
                nativeLink={!!params?.cta_url}
              />
            </ButtonBox>
          )}
        </BannerContent>
        <BannerImage
          image={{ imageUrl, imageAlignment }}
          topPosition={breadcrumbData ? '-64' : '0'}>
        </BannerImage>
      </BannerContainer>
    </BannerWrapper>
  );
};

export default PageHeader;
