import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import clearLocaleStorage from '../../utils/clearLocaleStorage';
import Phase1 from './Phase1';
import Phase2 from './Phase2';
import { PAGE_PATH } from '../../utils';
import {checkLoggedIn} from '../../../components/utils/_app-helpers';
import APIService from '../../../prepr/components/EndYearCampaignCustom/apiEndYearCampaign/api';
import { shopLang } from '../../../config/config';
import { useLoader } from '../../hooks';
import clearLocaleStorage from '../../utils/clearLocaleStorage';

const ChestGame = () => {
  const history = useHistory();
  const location = useLocation();
  const locale = shopLang.slice(0, 2).toLowerCase();
  const isLoggedIn = checkLoggedIn();
  const accountId = localStorage.getItem('accountId');
  const campaignGame = useSelector((state: any) => state?.infoEYC);
  const userData = useSelector((state: any) => state?.userDataEYC);
  const phase = useSelector((state: any) => state?.infoEYC?.name);
  const isStarted = useSelector((state: any) => state?.infoEYC?.isStarted);
  const isFinished = useSelector((state: any) => state?.infoEYC?.isFinished);
  const loader = useLoader(!isStarted);
  const currentUser = useSelector((state: any) => state?.currentUser);
  const cardNumber = useSelector((state: any) => state?.currentUser?.masterCardNumber);
  const campaignId = useSelector((state: any) => state?.infoEYC?.campaignId);
  const counterUpdateUser = useRef(0);

  useEffect(() => {
    if (isFinished || !isLoggedIn) {
      history.push({
        pathname: `/${PAGE_PATH.EYC_2024_LANDING}`,
        state: {
          prevPath: location.pathname
        }
      });
    }
  }, [history, location.pathname, isFinished, isLoggedIn]);

  // //Analytics for End Year Campaign 2023
  // useEffect(() => {
  //     if(shopLang === 'nl_nl'){
  //         addGTM('/count0/extwinq4+standard', { u1: location.pathname }, 'DC-3743070');
  //         addGTMLocalisationNL('extwinq4', 'count0');
  //     }else if (shopLang === 'be_nl'){
  //         addGTM('/essoe0/2023_001+unique', { u1: shopLang }, 'DC-4212618');
  //         addGTMLocalisationBE('2023_001');
  //     }else{
  //         addGTM('/essoe0/2023_000+unique', { u1: shopLang }, 'DC-4212618');
  //         addGTMLocalisationBE('2023_000');
  //     }
  // }, [location.pathname]);
  // //End Analytics for End Year Campaign 2023
  //
  useEffect(() => {
    !campaignGame && isLoggedIn &&
    APIService.getCampaignBySlug(`ey-2024-${locale}`)
      .catch(() => {
        clearLocaleStorage();
        history.push({
          pathname: '/auth/login',
          state: {
            prevPath: location.pathname
          }
        })
      });
  }, [locale, campaignGame, isLoggedIn, history, location]);

  const dataSignIn = useMemo(() => {
    return {
      email: `${accountId}@example.com`,
      firstName: '',
      lastName: '',
      campaignId: campaignId,
      data: {
        accountId: accountId || '',
        cardNumber: cardNumber || ''
      }
    };
  }, [campaignId, accountId, cardNumber]);

  useEffect(() => {
    if (!userData && isLoggedIn && currentUser && !!currentUser.accountID && campaignId && isStarted && !isFinished) {
      APIService.signIn({ ...dataSignIn })
        .then(() => APIService.lotterySync())
        .then(() => {
          counterUpdateUser.current++;
          return APIService.updateUser();
        })
        .then(() => APIService.setTransactions())
        .catch((err) => {
          console.error(err);
          console.info(`user doesn't sign in`);
        });
    }
  }, [userData, isLoggedIn, isStarted, currentUser, currentUser.accountID, campaignId, dataSignIn, isFinished, counterUpdateUser]);

  useEffect(() => {
    if (userData && !counterUpdateUser?.current) {
      counterUpdateUser.current++;
      APIService.updateUser()
        .then(() => APIService.setTransactions())
        .catch((err) => {
          console.error(err);
        });
    }
  }, [userData, counterUpdateUser]);

  useEffect(() => {
    return () => {
      APIService.setIsDefineWinnerCompleted(false);
      APIService.setIsReplayGame(false);
      APIService.setIsGameResultAnimated(false);
      APIService.setIsShowModal(false);
      APIService.setIsModalAnswerCorrect(false);
    };
  }, []);

  // useEffect(() => {
  //   const dateNow = Date.now();
  //   const accessExpiresAt = localStorage.getItem('accessExpiresAt');
  //   const authorized = localStorage.getItem('authorized');
  //
  //   if (authorized && accessExpiresAt && (dateNow >= +accessExpiresAt)) {
  //     clearLocaleStorage();
  //     history.push({
  //       pathname: '/auth/login',
  //       state: {
  //         prevPath: location.pathname
  //       }
  //     })
  //   }
  // }, [history, location.pathname]);

  return (
    loader || (
      phase === `ey-2024-${locale}-blue` ?
        <Phase1 /> :
        <Phase2 />
    )
  );
};

export default ChestGame;