import React, {useEffect, useMemo, useRef} from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../config/i18n';

import { EBikeHeader, EBikeGame, EBikePopup } from './components';
import ModalQuestions from './components/EBikeGame/parts/ModalQuestions';
import { shopLang } from '../../../config/config';
import {useLoader, useModal} from '../../../redesign/hooks';
import { useRecaptcha } from '../../../redesign/hooks';
import APIService from './api_campaign/api';
import { checkLoggedIn } from '../../../components/utils/_app-helpers';
import { reCAPTCHA } from './api_campaign/const';
import { eBikeCampaignCustomProps } from './eBikeCampaignCustom.types';
import {
    EBikeCustomWrapper,
} from './EBikeCampaignCustom.styles';

const EBikeCampaign: React.FC<eBikeCampaignCustomProps> = ({params}) => {
    const { recaptcha } = useRecaptcha(`${reCAPTCHA}`);
    const locale = shopLang.slice(0, 2).toLowerCase();
    const isLoggedIn = checkLoggedIn();
    const isStarted = useSelector((state: any) => state?.campaignInfo?.campaignGames[0]?.isStarted);
    const isFinished = useSelector((state: any) => state?.campaignInfo?.campaignGames[0]?.isFinished);
    const accountId = localStorage.getItem('accountId');
    const currentUser = useSelector((state: any) => state.currentUser);
    const cardNumber = useSelector((state: any) => state.currentUser.masterCardNumber);
    const campaignId = useSelector((state: any) => state?.campaignInfo?.id);
    const {isShowed, toggle, close, isAnimated} = useModal();
    const counter = useRef<number>(0);
    const loader = useLoader( !isStarted || !recaptcha);

    useEffect(() => {
        APIService.getCampaignBySlug(`ebike-${locale}`)
          .catch(() => {
              console.error('Something is wrong in getCampaignBySlug');
          })
    },[locale])

    const dataSignIn = useMemo(() => {
        return {
            email: `${accountId}@example.com`,
            firstName: '',
            lastName: '',
            campaignId: campaignId,
            data: {
                accountId: accountId || '',
                cardNumber: cardNumber || ''
            }
        }
    }, [campaignId, accountId, cardNumber])

    useEffect(() => {
        if(isLoggedIn && currentUser && !!currentUser.accountID && campaignId && isStarted && !isFinished && recaptcha){
            APIService.signIn({...dataSignIn})
                .then(() => {
                    console.info('user signs in');
                    return APIService.lotterySync()
                })
                .then(()=> APIService.updateUser())
                .then(() => APIService.setTransactions())
                .catch((err) => {
                    console.error(err);
                    console.info(`user doesn't sign in`)
                })
        }
    },[isLoggedIn, isStarted, currentUser, currentUser.accountID, campaignId, dataSignIn, isFinished, recaptcha])

    useEffect(() => {
        return () => {
            APIService.setIsPlayBtnDisabled(false);
            APIService.setIsFormBtnDisabled(false);
            APIService.setIsUserWon(false);
            APIService.setIsReplayGame(false);
            APIService.setIsFormShowed(false);
            APIService.setIsConfettiShowed(false);
            APIService.setActiveBalloon(0);
            APIService.setAnimatedBalloon(0);
            APIService.setIsShowModal(false);
            APIService.setIsModalAnswerCorrect(false);
            APIService.setTransactions();

            console.log('clearStatesToInitial');
        }
    },[])

    useEffect(() => {
        if(!counter.current){
            toggle();
            counter.current++;
        }
    }, [toggle]);

    return (
        loader ||
        <EBikeCustomWrapper>
            <EBikeHeader/>
            <EBikeGame/>
            {shopLang !== 'nl_nl' && <ModalQuestions />}
            {isShowed &&
              <EBikePopup isShowed={true}
                          onClose={() => close()}
                          isAnimated={isAnimated}
                          maxWidth='500px'
                          isModalCloseBtn={false}
                          title={i18n.t('eBike_popup_title')} />}
        </EBikeCustomWrapper>
    )
};

export default EBikeCampaign;
