import React, {useEffect, useRef, useCallback} from 'react';
import confetti from 'canvas-confetti';
import APIService from '../../../../api_campaign/api';
import {ConfettiStyled} from './Confetti.styles';

const Confetti = () => {
  const refConfetti = useRef<HTMLDivElement>(null);
  const confettiGenerator = useCallback(() => {
    const confettiItem = document.createElement('canvas');
    /* eslint-disable */
    refConfetti?.current && refConfetti?.current?.appendChild(confettiItem);

    const myConfetti = confetti.create(confettiItem, {
      resize: true,
      useWorker: true
    });

    const count = 300;
    const defaults = {
      origin: {y: 0.5},
      colors:['#DB0007', '#003399', '#ffffff'],
      shapes:['square', 'square', 'square']
    };

    function fire(particleRatio: number, opts: object) {
      // @ts-ignore
      myConfetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      }));
    }

    fire(0.25, {
      spread: 100,
      startVelocity: 105,
    });
    fire(0.2, {
      spread: 180,
      startVelocity: 45,
    });
    fire(0.35, {
      spread: 180,
      decay: 0.91,
      scalar: 1.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.5
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });

    setTimeout(function () {
      if (refConfetti.current)
        refConfetti.current.removeChild(confettiItem);
    }, 3500);
  }, [])

  useEffect(() => {
    confettiGenerator();

    let counter = 0;
    const confettiLoop = setInterval(() => {
      if(++counter === 3){
        APIService.setIsConfettiShowed(false);
        clearInterval(confettiLoop)
      }else{
        confettiGenerator()
      }}, 3500)


    return () => {
      console.log('clear confettiLoop');
      clearInterval(confettiLoop);
    }
  }, [confettiGenerator])

  return (
    <ConfettiStyled ref={refConfetti} className='confetti'/>
  )
}

export default Confetti;