import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.header(({ theme }) => {
  const { color } = theme;
  return css`
    width: 100%;
    border-bottom: 4px solid ${color.blue};
  `;
});

export const HeaderContainer = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 25px;

    ${mediaQueries.md} {
      padding: 0;
    }
  `;
});

