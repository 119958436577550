import React from "react";
import { ModalTypes } from "../Modal/modal.types";

import Button from "../../Button";
import Modal from "../Modal";

import {
  ButtonsGroup,
  ModalContent,
  ModalSubTitle,
  ModalText
} from "../Modal/Modal.styles";

const PrivacyModalDemo: React.FC<ModalTypes> = ({ ...props }) => {
  const handleBtnAction1 = () => {
    props.onClose();
  };

  const handleBtnAction2 = () => {
    props.onClose();
  };

  return (
    <Modal {...props}>
      <ModalContent>
        <ModalSubTitle>
          Keuze mogelijkheden voor cookies en privacy
        </ModalSubTitle>
        <ModalText>
          Een cookie is een klein bestandje met letters en cijfers dat mogelijk op uw apparaat wordt opgeslagen als u een website bezoekt en informatie bevat die op uw computer wordt opgeslagen. Deze informatie kan bij ieder volgend bezoek worden teruggestuurd naar de website of naar andere websites die deze cookie herkennen. Deze informatie kan betrekking hebben op uzelf, uw voorkeuren of uw apparaat en helpt ons om u een betere online-ervaring te bieden (bijvoorbeeld door het aantal sitebezoeken te meten en ervoor te zorgen dat bezoekers eenvoudig kunnen vinden wat ze zoeken). Cookies kunnen ook worden gebruikt voor marketing- of advertentiedoeleinden. U kunt ervoor kiezen om sommige soorten cookies te weigeren. U kunt 'Alles toestaan' selecteren om toestemming te geven voor alle cookies of de schuifknop naast de verschillende onderstaande cookiecategorieën aanpassen naar 'Actief' of 'Inactief'. U kunt uw cookiekeuze altijd op een later moment wijzigen door naar dit menu te gaan via de 'Privacy voorkeuren'-link onderaan de website. Klik op de namen van de verschillende categorieën hieronder voor meer informatie over elke cookiecategorie en om uw instellingen aan te passen. Houd er rekening mee dat de bewaartermijn van elke vermelde cookie een standaardperiode is die wordt aangegeven door de cookie, maar dat de daadwerkelijke duur afhankelijk is van uw keuzemogelijkheid voor privacy. U kunt van tijd tot tijd worden gevraagd om uw keuzemogelijkheid voor privacy opnieuw te bevestigen, in overeenstemming met de toepasselijke wetgeving. Als u ervoor kiest om sommige soorten cookies te weigeren, kan dit invloed hebben op uw site-ervaring en de diensten die we u kunnen aanbieden.        </ModalText>
      </ModalContent>
      <ButtonsGroup>
        <Button text={"no"}
                buttontype={"secondary"}
                onClick={handleBtnAction1} />
        <Button text={"yes"}
                onClick={handleBtnAction2} />
      </ButtonsGroup>
    </Modal>
  );
};

export default PrivacyModalDemo;
