import React from 'react';
import { shopLang } from "../../../../../../config/config";
import { NavLinksProps } from './navLinks.types';

import {
  NavListItem,
  NavListItemLink,
  NavListWrapper
} from './NavLinks.styles';

const NavLinks: React.FC<NavLinksProps> = ({ links, alignment, children }) => {
  return (
    <NavListWrapper alignment={alignment}>
      {links.map((item, index) => {
        const setUrl = () => shopLang === 'be_fr' ? item.url_be_fr
          : shopLang === 'be_nl' ? item.url_be_nl : item.url_nl;
        return (
          <NavListItem key={item.title}>
            <NavListItemLink
              href={setUrl()}
              rel={item?.rel}
              target={item?.target}
            >
              {item.title}
            </NavListItemLink>
          </NavListItem>
        );
      })}

      {children && <NavListItem>{children}</NavListItem>}
    </NavListWrapper>
  );
};

export default NavLinks;
