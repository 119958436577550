import styled, { css } from 'styled-components';

export const LoaderLayout = styled.div(() => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  `;
});

export const LoaderInner = styled.div(({theme}) => {
  const {color} = theme;

  return css`
    width: 48px;
    height: 48px;
    box-sizing: border-box;

    border: solid 4px transparent;
    border-top-color: ${color.blue};
    border-left-color: ${color.blue};
    border-radius: 50%;
    
    animation: spinner 400ms linear infinite;

    @keyframes spinner {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
});
