import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../../../../config/i18n';
import { Button } from '../../../../../components/shared';
import { stationsLink } from '../../../../../../prepr/components/EndYearCampaignCustom/2024/utils/helpers';
import { Description, Title, Text } from './Intro.styles';

const Intro = () => {
  const keysBlue = useSelector((state: any) => state?.transactionsEYC?.blue);
  const isReplayGame = useSelector((state: any) => state?.isReplayGameEYC);
  const isDefineWinnerCompleted = useSelector((state: any) => state?.isDefineWinnerCompletedEYC);
  return (
    <>
      <Description>
        {/*no keys + no first game*/}
        {!keysBlue && isReplayGame && isDefineWinnerCompleted &&
          <>
            <Title>{i18n.t('eyc_2024_1_title_noTokens_replay_game')}</Title>
            <Text>{i18n.t('eyc_2024_1_text_noTokens_replay_game')}</Text>
            <Button text={i18n.t('eyc_2024_btn_to_stations')}
                    url={stationsLink}
                    nativeLink={true}>
            </Button>
          </>
        }

        {/*no keys + first game*/}
        {!keysBlue && !isReplayGame && isDefineWinnerCompleted &&
          <>
            <Title>{i18n.t('eyc_2024_1_title_noTokens')}</Title>
            <Text>{i18n.t('eyc_2024_1_text_noTokens')}</Text>
          </>
        }

        {/*keys*/}
        {(!!keysBlue || !isDefineWinnerCompleted) &&
          <>
            <Title>{i18n.t('eyc_2024_1_title_tokens')}</Title>
            <Text>{i18n.t('eyc_2024_1_text_tokens')}</Text>
          </>
        }
      </Description>

    </>
  );
};

export default Intro;