import React from 'react';
import { Headline, LoyaltyProgram } from '../../../redesign/components/shared';
import { LoyaltyProgramCollectionProps } from './loyaltyProgramCollection.types';
import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import {
  LoyaltyProgramGrid,
  LoyaltyProgramRow,
  LoyaltyProgramWrap
} from './LoyaltyProgramCollection.styles';

const LoyaltyProgramCollection: React.FC<LoyaltyProgramCollectionProps> = ({ params }) => {
  return (
    <Wrapper>
      <Container maxWidth={
        params?.max_width === 'narrow' ? '850px' :
          params?.max_width === 'wide' ? '1250px' : '1440px'}>
        <Headline title={params?.title}>
         <>
           <LoyaltyProgramGrid>
             <LoyaltyProgramRow>
               {params?.list.map((program, index) => {
                 return (
                   <LoyaltyProgramWrap key={program._id || index.toString()}>
                     <LoyaltyProgram{...program} />
                   </LoyaltyProgramWrap>
                 );
               })}
             </LoyaltyProgramRow>
           </LoyaltyProgramGrid>
         </>
        </Headline>
      </Container>
    </Wrapper>
  );
};

export default LoyaltyProgramCollection;