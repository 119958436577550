import styled, { css } from "styled-components";

type SparenItemProps = {
  textAlign?: string;
}

export const SparenTitle = styled.h2(({theme}) => {
  const {font, size, color, mediaQueries} = theme;

  return css`
    text-align: center;
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px32};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    color: ${color.darkCharcoal};
    margin-bottom: 40px;

    ${mediaQueries.sm} {
      text-align: left;
      font-size: ${size.px24};
    }
	`;
});


export const SparenContent = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;

    ${mediaQueries.sm} {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
	`;
});

export const SparenItem = styled.div<SparenItemProps>(({theme, textAlign='left'}) => {
  const {mediaQueries} = theme;

  return css`
    width: 32%;
    text-align: ${textAlign};

    ${mediaQueries.sm} {
      width: 100%;
      margin-bottom: 20px;
      text-align: left;
    }
	`;
});

export const SparenItemTitle = styled.h3(({theme}) => {
  const {font, size, color, mediaQueries} = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px20};
    font-weight: ${font.weight.semiBold};
    line-height: 1.3;
    color: ${color.black};
    margin-bottom: 5px;

    ${mediaQueries.sm} {
      color: ${color.darkCharcoal};
    }
	`;
});

export const SparenItemText = styled.p(({theme}) => {
  const {font, size, color, mediaQueries} = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px14};
    font-weight: ${font.weight.regular};
    line-height: 1.57;
    color: ${color.graniteGray};
    margin-bottom: 25px;

    ${mediaQueries.sm} {
      margin-bottom: 15px;
    }
	`;
});

export const SparenItemImage = styled.div(() => {
  return css`
    text-align: center;
    
    img {
      max-width: 385px;
      width: 100%;
      vertical-align: bottom;
      height: auto;
    }
	`;
});

export const ButtonBox = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    display: flex;  
    
    a{
      text-decoration: none;
    }
    
    ${mediaQueries.sm} {
      display: block;
    }
	`;
});