import { Selector } from '../../Form/InputSelector/inputSelector.types';

import {
  IconAndroid,
  IconAppActive,
  IconIos,
  IconWebApp,
  IconWebAppActive
} from '../../../../../prepr/assets';
import i18n from '../../../../../config/i18n';

type SelectorItem = Omit<Selector, 'register'>;

export const selectorList: SelectorItem[] = [
  {
    label: `${i18n.t('13_Contact-form_website')}`,
    value: 'website',
    name: 'device',
    width: 33,
    icon: `${IconWebApp}`,
    activeIcon: `${IconWebAppActive}`,
    isRequired: false
  },
  {
    label: `${i18n.t('13_Contact-form_iOSApp')}`,
    value: 'iOS',
    name: 'device',
    width: 33,
    icon: `${IconIos}`,
    activeIcon: `${IconAppActive}`,
    isRequired: false
  },
  {
    label: `${i18n.t('13_Contact-form_AndroidApp')}`,
    value: 'android',
    name: 'device',
    width: 33,
    icon: `${IconAndroid}`,
    activeIcon: `${IconAppActive}`,
    isRequired: false
  }
];
