import React from 'react';
import { topNavLinksList } from '../../data/topNavLinksList';
import { FooterRow } from '../../Footer.styles';

import { TopNavWrapper, TopNavContainer } from './TopNavigation.styles';
import i18n from '../../../../../../config/i18n';
import { BrandBadge, NavLinks } from '../index';
import { AppButtonList } from '../../../index';

function TopNavigation() {
  return (
    <TopNavWrapper>
      <TopNavContainer>
        <FooterRow>
          <BrandBadge />
          <NavLinks alignment='center' links={topNavLinksList}>
            <button className='optanon-toggle-display footer-link'>
              <span className='ot-sdk-show-settings'>{i18n.t('00_footer_cookies_settings')}</span>
            </button>
          </NavLinks>
          <AppButtonList />
        </FooterRow>
      </TopNavContainer>
    </TopNavWrapper>
  );
}
export default TopNavigation;
