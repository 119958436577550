import styled, { css } from 'styled-components';

export const KeysWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    margin: 0 10px 30px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 5px 25px;
    min-width: 145px;
    min-height: 76px;
    background-color: rgba(255, 255, 255, 0.16);
    border-radius: 4px;

    img{
      width: 60px;
      margin-right: 15px;
    }
    
    ${mediaQueries.sm} {
      min-width: 105px;
      min-height: 52px;
      padding: 5px 15px;
      margin: 0 0 15px;
      z-index: 1;

      img{
        width: 42px;
        margin-right: 10px;
      }
    }

    ${mediaQueries.s} {
      padding: 5px 10px;

      img{
        width: 35px;
      }
    }
  `;
});

export const KeysText = styled.span(({ theme }) => {
  const { size, color, font, mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    color: ${color.cottonGrey};
    font-size: ${size.px16};
    line-height: 1;
    font-weight: ${font.weight.semiBold};
    font-family: ${font.family.EMPrintSemi};
    padding: 5px 0;

    ${mediaQueries.s} {
      font-size: ${size.px14};
    }
  `;
});

export const KeysSeparator = styled.span(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: inline-flex;
    background-color: rgba(255, 255, 255, 0.16);
    width: 1px;
    height: 28px;
    margin: 0 25px;

    ${mediaQueries.sm} {
      height: 20px;
      margin: 0 15px;
    }

    ${mediaQueries.s} {
      margin: 0 10px;
    }
  `;
});

export const Box = styled.span(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    height: 100%;

    ${mediaQueries.sm} {
    }

    ${mediaQueries.s} {
    }
  `;
});