import styled, { css } from 'styled-components';

export const FeatureBox = styled.div(({ theme }) => {
  const { mediaQueries, color } = theme;

  return css`
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    padding: 50px 15px;
    width: 49%;
    min-height: 288px;
    border-radius: 8px;
    background: ${color.white};
    box-shadow: 0 12px 16px rgba(178, 193, 224, 0.16);
    margin-bottom: 15px;

    ${mediaQueries.sm} {
      text-align: left;
      width: 100%;
      min-height: auto;
      padding: 15px;
    }
  `;
});

export const FeatureIcon = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: flex-end;
    margin: 0 auto 20px;

    ${mediaQueries.sm} {
      margin: 0;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  `;
});

export const FeatureContent = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    margin-bottom: 20px;

    ${mediaQueries.sm} {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  `;
});

export const FeatureInfo = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.sm} {
      width: calc(100% - 70px);
    }
  `;
});

export const FeatureTitle = styled.div(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px20};
    line-height: 1.3;
    color: ${color.darkCharcoal};
    margin: 0 0 5px;
  `;
});

export const FeatureText = styled.div(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px14};
    line-height: 1.5;
    color: ${color.graniteGray};
    margin: 0 0 5px;
  `;
});

export const FeatureLink = styled.a(({ theme }) => {
  const { mediaQueries, color } = theme;

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    min-width: 116px;
    min-height: 48px;
    background: ${color.lightblue};
    border-radius: 4px;
    border: none;
    outline: none;
    text-decoration: none;

    &:active,
    &:focus,
    &:focus-within,
    &:target,
    &:visited,
    &:focus-visible {
      outline: none;
    }

    ${mediaQueries.sm} {
      width: 100%;
    }
  `;
});

export const FeatureLinkText = styled.span(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    position: relative;
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px14};
    line-height: 1.2;
    color: ${color.blue};
  `;
});
