import React from 'react';
import { useSelector } from 'react-redux';
import HeaderLoggedOut from './LoggedOut';
import HeaderLoggedIn from './LoggedIn';
import HeaderCompleted from './Completed';
import {checkLoggedIn} from '../../../../../../components/utils/_app-helpers';


const EYCHeader = () => {
  const isLoggedIn = checkLoggedIn();
  const currentUser = useSelector((state: any) => state.currentUser);
  const isFinished = useSelector((state: any) => state?.infoEYC?.isFinished);

  return (
    isFinished ?
      <HeaderCompleted />
      : (isLoggedIn && currentUser && !!currentUser.accountID) ? (
          <HeaderLoggedIn />
        ) :
        <HeaderLoggedOut />
  );
};

export default EYCHeader;