import React, { IframeHTMLAttributes, RefObject } from 'react';
import { Control, useController } from 'react-hook-form';
import {
  CheckboxLabel,
  CheckboxInput,
  CheckboxCircle,
  CheckboxSquare,
  CheckboxText,
} from './Checkbox.styles';

interface CheckboxesProps extends IframeHTMLAttributes<HTMLInputElement>{
  control: Control<any>;
  value: any;
  name: string;
  type: string
  label: any;
  styledType?: 'square' | 'circle';
  handleChange?: (value: any) => void;
  refEl?: RefObject<HTMLInputElement>;
}

const Checkbox: React.FC<CheckboxesProps> = ({
                                                 control,
                                                 value,
                                                 name,
                                                 type,
                                                 label,
                                                 styledType = 'circle',
                                                 handleChange,
                                                 refEl,
                                               }) => {
  const { field } = useController({
    control,
    name
  });

  return (
    <CheckboxLabel>
      <CheckboxInput
        ref={refEl}
        onChange={(e) => {
          let newValue = e.target.checked ? e.target.value : false;
          field.onChange(newValue);
          handleChange && handleChange(newValue);
        }}
        type={type}
        value={value}
        name={name}
      />

      {
        styledType === 'square' ?
          <CheckboxSquare /> :
          <CheckboxCircle />
      }
      <CheckboxText>{label}</CheckboxText>
    </CheckboxLabel>
  );
};

export default Checkbox;