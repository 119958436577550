import axios from 'axios';
import { store } from 'redux/store';
import * as Sentry from '@sentry/react';
import {getAccessToken, getRefreshToken, getTransactions} from './selectors';
import {environment, reCAPTCHA} from './const';
import {
  getCampaignInfo,
  addAuthData,
  addUserData,
  setTransactions,
  setParticipant,
  setCurrentPrize,
  setDefaultAnimation,
  setActiveGameState,
  setIsPlayBtnDisabled,
  setIsUserWon,
  setIsGameLoaderActive,
  setIsShowModal,
  setIsModalAnswerCorrect,
  setIsDefineWinnerActive,
  setIsReplayGame,
  setResultGameForAnimation,
  setIsLabelClosed,
  setIsConfettiShowed,
  setIsFormShowed,
  setIsFormBtnDisabled,
  setAnimatedBalloon,
  setActiveBalloon,
} from "../../../../redux/actions_campaign";

class APIService {
  _getApiUrl(){
    if (environment === 'prod') {
      return 'https://api.esso-ebike.production.nl-be.platform-tbwa-x.com';
    }else{
      return 'https://api.esso-ebike.staging.nl-be.platform-tbwa-x.com';
    }
  }

  _apiUrl = this._getApiUrl();

  _axios = axios.create({
    baseURL: `${this._apiUrl}/api`,
    withCredentials: true
  })

  _requests = new Map();

  _anonymousUrls = [
    '/auth/token/refresh',
    '/auth/token/revoke',
    '/auth/signin'
  ];

  constructor() {
    this.createInterceptors();
  }

  _updateRequests(url = '', add = false) {
    const current = this._requests.get(url) ?? 0;
    this._requests.set(url, current + +add * 2 - 1);
  }

  createInterceptors() {
    this._axios.interceptors.request.use(async (config) => {
      if (config?.url && !this._anonymousUrls.includes(config.url)) {
        const state = store.getState();
        const accessToken = getAccessToken(state);

        if (accessToken && config?.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      this._updateRequests(config.url, true);
      return config;
    });

    this._axios.interceptors.response.use(
      async (response) => {
        this._updateRequests(response.config.url);
        return response;
      },

      async (error) => {
        const originalRequest = error.config;
        this._updateRequests(originalRequest.url);
        const { message } = error.response?.data ?? {};

        const unauthorized = error.response?.status === 401;
        const invalid = message === 'Access token is invalid or expired';

        if (invalid && unauthorized) {
          if (originalRequest._retry) return this._revokeToken();

          const state = store.getState();
          const accessToken = getAccessToken(state);
          const refreshToken = getRefreshToken(state);

          await this._refreshToken(refreshToken);
          originalRequest._retry = true;

          if (accessToken) {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return this._axios(originalRequest);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  async _getReCaptchaConfig() {
    if(reCAPTCHA){
      if (!window.grecaptcha) {
        if (reCAPTCHA)
          throw new Error('ReCaptcha not initialized.');
        return;
      }

      try {
        const { enterprise } = window.grecaptcha;
        const token = await enterprise.execute(reCAPTCHA, { action: 'post' });
        return { headers: { 'x-recaptcha-token': token } };
      } catch {
        throw new Error('ReCaptcha not fetched.');
      }
    }
  }

  async _refreshToken(token){
    let resp = await this._axios.post(`/auth/token/refresh`, {refreshToken: token});


    const { accessToken, refreshToken, accessExpiresIn, refreshExpiresIn } =
      resp.data;

    const refreshExpiresAt = this._getAccessExpiration(refreshExpiresIn);
    const accessExpiresAt = this._getAccessExpiration(accessExpiresIn);

    store.dispatch(
      addAuthData({
        refreshExpiresAt,
        accessExpiresAt,
        refreshToken,
        accessToken
      })
    );

    return resp.data;
  }

  async _revokeToken(){
    store.dispatch(
      addAuthData({
        refreshExpiresAt: null,
        accessExpiresAt: null,
        refreshToken: null,
        accessToken: null
      })
    );

    return (await this._axios.post(`/auth/token/revoke`)).data;
  }

  _getAccessExpiration(delay = 0) {
    return Date.now() + delay * 1000;
  }

  async getCampaignBySlug (slug){
    let resp = await this._axios.get(`/campaigns/${slug}`);
    store.dispatch(getCampaignInfo(resp.data));
    return resp.data;
  }

  async signIn (data){
    const config = await this._getReCaptchaConfig();
    let resp = await this._axios.post(`/auth/signin`, {...data}, config);
    const { accessToken, refreshToken, accessExpiresIn, refreshExpiresIn } =
      resp.data.token;

    const refreshExpiresAt = this._getAccessExpiration(refreshExpiresIn);
    const accessExpiresAt = this._getAccessExpiration(accessExpiresIn);

    store.dispatch(
      addAuthData({
        refreshExpiresAt,
        accessExpiresAt,
        refreshToken,
        accessToken
      })
    );

    return resp.data;
  }

  async lotterySync () {
    try{
      let getTokenCommon = JSON.parse(localStorage.getItem('authToken'));
      let accessTokenCommon = getTokenCommon && getTokenCommon.accessToken;
      const config = await this._getReCaptchaConfig();

      let resp = await this._axios.post(`/esso/lottery-tickets-sync`, {token: accessTokenCommon}, config);
      return resp.data;
    } catch(e){
      Sentry.captureMessage(`Error in lotterySync: ${e}`, `info`);
    }
  }

  async updateUser (){
    let resp = await this._axios.get(`/users/me`);

    store.dispatch(addUserData({...resp.data}));

    return resp.data;
  }

  // createParticipant and runLottery in 1 call
  async createParticipantWithLottery(data) {
    const config = await this._getReCaptchaConfig();
    let resp = await this._axios.post(`/participants/with-lottery`, {...data}, config);
    let {lotteryPrize} = resp.data;

    store.dispatch(setParticipant(resp.data));
    store.dispatch(setCurrentPrize(lotteryPrize));

    return resp.data;
  }

  // async createParticipant (data) {
  //   const config = await this._getReCaptchaConfig();
  //   let resp = await this._axios.post(`/participants`, {...data}, config);
  //   store.dispatch(setParticipant(resp.data));
  //   return resp.data;
  // }

  // async runLottery(participantId) {
  //   let resp = await this._axios.patch(`/participants/${participantId}/lottery`, null);
  //
  //   let {lotteryPrize} = resp.data;
  //   store.dispatch(setCurrentPrize(lotteryPrize));
  //
  //   return resp.data;
  // }

  async sendForm (data, participantId){
    const config = await this._getReCaptchaConfig();
    let resp = await this._axios.patch(`/participants/${participantId}/address`, {...data}, config);

    return resp.data;
  }

  async syncPrize(participantId) {
    let getTokenCommon = JSON.parse(localStorage.getItem('authToken'));
    let accessTokenCommon = getTokenCommon && getTokenCommon.accessToken;

    let resp = await this._axios.patch(`/esso/participants/${participantId}/sync`, {token: accessTokenCommon});

    return resp.data;
  }

  //moved to backend
  async shareUsedTransactions(participantId) {
    let getTokenCommon = JSON.parse(localStorage.getItem('authToken'));
    let accessTokenCommon = getTokenCommon && getTokenCommon.accessToken;

    let resp = await this._axios.post(`/esso/participants/${participantId}/share-used-transaction`, {token: accessTokenCommon});

    return resp.data;
  }

  //moved to backend
  async syncIssuePoints(participantId) {
    let getTokenCommon = JSON.parse(localStorage.getItem('authToken'));
    let accessTokenCommon = getTokenCommon && getTokenCommon.accessToken;

    let resp = await this._axios.post(`/esso/participants/${participantId}/issue-points`, {token: accessTokenCommon});

    return resp.data;
  }

  //moved to backend
  async syncPlaceOrder(participantId) {
    let getTokenCommon = JSON.parse(localStorage.getItem('authToken'));
    let accessTokenCommon = getTokenCommon && getTokenCommon.accessToken;

    let resp = await this._axios.post(`/esso/participants/${participantId}/place-order`, {token: accessTokenCommon});

    return resp.data;
  }

  setTransactions (){
    const state = store.getState();
    const transactions = getTransactions(state);

    store.dispatch(setTransactions(transactions));
  }

  setIsUserWon(data){
    store.dispatch(setIsUserWon(data));
  }

  setIsPlayBtnDisabled(data){
    store.dispatch(setIsPlayBtnDisabled(data));
  }

  setDefaultAnimation(data){
    store.dispatch(setDefaultAnimation(data));
  }

  setActiveGameState(data){
    store.dispatch(setActiveGameState(data));
  }

  setIsGameLoaderActive(data){
    store.dispatch(setIsGameLoaderActive(data));
  }

  setIsShowModal(data){
    store.dispatch(setIsShowModal(data));
  }

  setIsModalAnswerCorrect(data){
    store.dispatch(setIsModalAnswerCorrect(data))
  }

  setIsDefineWinnerActive(data){
    store.dispatch(setIsDefineWinnerActive(data))
  }

  setIsReplayGame(data){
    store.dispatch(setIsReplayGame(data))
  }

  setResultGameForAnimation(data){
    store.dispatch(setResultGameForAnimation(data))
  }

  setIsLabelClosed(data){
    store.dispatch(setIsLabelClosed(data))
  }

  setIsConfettiShowed(data){
    store.dispatch(setIsConfettiShowed(data))
  }

  setIsFormShowed(data){
    store.dispatch(setIsFormShowed(data))
  }

  setActiveBalloon(data){
    store.dispatch(setActiveBalloon(data))
  }

  setAnimatedBalloon(data){
    store.dispatch(setAnimatedBalloon(data))
  }

  setIsFormBtnDisabled(data){
    store.dispatch(setIsFormBtnDisabled(data))
  }
}

export default new APIService();
