import styled, { css } from 'styled-components';
import { InputField, InputPlaceholder } from '../InputField/InputField.styles';
import { CustomTheme } from '../../../../theme/types';

interface TextAreaFieldParams {
  disabled?: boolean;
  theme: CustomTheme;
}

export function TextAreaField({ disabled, theme }: TextAreaFieldParams) {
  const { color } = theme;

  return css`
    ${InputField({ disabled: disabled, theme: theme })}
    max-height: 162px;

    height: 100%;
    resize: none;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      background-color: ${color.lightBlue};
      border-color: ${color.steelBlue};
      border-width: 2px;
      caret-color: ${color.black};
      color: ${color.black};
      outline: none;
    }

    &:invalid {
      background-color: ${color.white};
      border-color: ${color.red};
      border-width: 1px;
    }
  `;
}

export const FieldTextArea = styled.textarea(
  ({ disabled, theme }) => css`
    ${TextAreaField({ disabled: disabled, theme: theme })}

    ::placeholder {
      ${InputPlaceholder({ theme: theme })}
    }
  `
);

type TextAreaCounterAttributes = {
  isError?: boolean;
  theme: CustomTheme;
};

export const TextAreaCounter = styled.small(
  ({ isError, theme }: TextAreaCounterAttributes) => {
    const { size, color } = theme;
    return css`
      line-height: ${size.px12};
      font-size: ${size.px12};

      color: ${color.red};

      display: block;

      margin-top: ${size.px4};
      text-align: right;

      ${!isError &&
      css`
        color: ${color.grey};
      `}
    `;
  }
);

export const FieldError = styled.span(({ theme }) => {
  const { color, size, font } = theme;
  return css`
    font-size: ${size.px12};
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};

    color: ${color.red};
    position: absolute;
    bottom: -${size.px24};
    display: block;
    width: 100%;
  `;
});
