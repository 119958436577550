import styled, { css } from 'styled-components';
import { StepBox, StepText, StepTitle } from "../../../redesign/components/shared/Step/Step.styles";
import { HeadingSubtitle, HeadlineTitle } from "../../../redesign/components/shared/Headline/Headline.styles";
import { IconClose } from "../../assets";

export const AnimatedLayout = styled.div(() => {
  return css`
    position: relative;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    
    &.fade-out {
      opacity: 0;
      visibility: hidden;
      transition: opacity linear 0.3s, visibility linear 0s 0.3s;
    }
  `;
});

export const StepsPopupOverlay = styled.div(() => {
  return css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
  `;
})

export const StepsPopupWrapper = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 96%;
    max-width: 1200px;
    z-index: 10;
    max-height: 70%;
    overflow-y: auto;
    background-color: ${color.sea};
    border-radius: 16px;
    
    ${mediaQueries.sm} {
      top: 45px;
      max-height: calc(100% - 90px);
    }
  `;
});

export const PopupCloseBtn = styled.button(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    border: none;
    outline: none;
    width: 34px;
    height: 34px;
    background: url('${IconClose}') no-repeat center transparent;
    background-size: 60%;
    position: absolute;
    right: 20px;
    top: 20px;

    ${mediaQueries.sm} {

    }
  `;
});

export const StepsPopupContainer = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    padding: 40px 20px 20px;
    max-width: 1040px;
    margin: 0 auto;
    
    ${StepBox}{
      background-color: transparent;
    }
    
    ${StepTitle},
    ${StepText},
    ${HeadlineTitle},
    ${HeadingSubtitle}{
      color: ${color.white};
      * {
        color: ${color.white};
      }
      
      a{
        text-decoration: underline;
      }
    }
  `;
});