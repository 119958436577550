import React, { useEffect } from 'react';
import { translate } from 'react-i18next';
import ReactGA from 'react-ga4';

import Main from './components/common/Main';

import {
  checkLoggedIn,
  setMetaDesription
} from './components/utils/_app-helpers';
import { shopLang } from "./config/config";

window.onTokenRefreshed = () => {
  window.location.replace(process.env.PUBLIC_URL + '/#/mobile-bridge');
};

const App = () => {
  const isLoggedIn = checkLoggedIn();

  const mutationObserver = new MutationObserver (mutationsList => {
    let oneTrustConsentSdk =  mutationsList?.length > 0 && mutationsList.find(mutation => mutation.addedNodes[0]?.id === 'onetrust-consent-sdk');
    if(oneTrustConsentSdk){
      let oneTrustDocument = document.getElementById('onetrust-accept-btn-handler');
      let acceptRecommendedBtn = document.getElementById('accept-recommended-btn-handler');
      let btnsArray = [oneTrustDocument, acceptRecommendedBtn];
      if(oneTrustDocument || acceptRecommendedBtn){
        btnsArray.forEach((btn) => {
          btn && btn.addEventListener('click',
            () => {
              // ReactGA
              ReactGA.initialize(process.env.REACT_APP_GA_ENV);
              // Facebook Pixel Code is moved from index.html
              const fbAnalyticsScript = document.createElement('script');
              fbAnalyticsScript.text = `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '650512808693337');
              fbq('track', 'PageView');
              `;
              document.head.appendChild(fbAnalyticsScript);
              const fbAnalyticsNoScript = document.createElement('noscript');
              const img = document.createElement('img');
              img.height = 1;
              img.width = 1;
              img.style.display = 'none';
              img.src = 'https://www.facebook.com/tr?id=650512808693337&ev=PageView&noscript=1';
              fbAnalyticsNoScript.appendChild(img);
              document.head.appendChild(fbAnalyticsNoScript);
              // End Facebook Pixel Code
              mutationObserver.disconnect();
            }
          );
        });
      }
    }
  });

  useEffect(() => {
    setMetaDesription();
    // window.ga("create", process.env.REACT_APP_GA_ENV, "auto");
  }, []);

  useEffect(() => {
    mutationObserver.observe(document.body, {
      childList: true,
    });
    return () => {
      mutationObserver.disconnect();
    };
  }, [mutationObserver]);

  // checking whether the user has accepted cookies. If yes, then connect GA4
  useEffect(() => {
    if(document.cookie.match(process.env.REACT_APP_GA_ENV.split('-')[1])){
      ReactGA.initialize(process.env.REACT_APP_GA_ENV);
    }
  }, []);
  // end checking whether the user has accepted cookies. If yes, then connect GA4

    //Analytics for E-bike Campaign and End Year Campaign 2023
    useEffect(() => {
      const id = shopLang === 'nl_nl' ? '3743070' : '4212618';

      // Global site tag (gtag.js) - Google Marketing Platform
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=DC-${id}`;
      document.head.appendChild(script);

      const script2 = document.createElement('script');
      script2.type = 'text/plain';
      script2.className='optanon-category-C0002';
      script2.text=`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'DC-${id}');`
      document.head.appendChild(script2);
      // End of global snippet: Please do not remove
    }, []);
    //end Analytics for E-bike Campaign and End Year Campaign 2023

  return <Main isLoggedIn={isLoggedIn} />;
};

export default translate('common')(App);