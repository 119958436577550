import React from 'react';
import { useLocation } from 'react-router-dom';

import i18n from '../../../../../../../../config/i18n';
import { Button } from '../../../../../../../../redesign/components/shared';
import { InfoUnLoggedInUserProps } from './infoUnLoggedInUser.types';
import { ButtonBox, Description, InfoWrapper, Title } from '../InfoBlock.styles';
import { Subtitle } from '../../../EBikeGame.styles';

const InfoUnLoggedInUser: React.FC<InfoUnLoggedInUserProps> = () => {
  const location = useLocation();

  return (
      <InfoWrapper>
        <Title>{i18n.t('eBike_title_unLoggedIn')}</Title>
        <Subtitle>{i18n.t('eBike_subtitle_unLoggedIn')}</Subtitle>
        <Description>{i18n.t('eBike_text_unLoggedIn')}</Description>
        <ButtonBox>
          <Button
            text={i18n.t('eBike_btn_unLoggedIn')}
            buttontype={'primary'}
            url={{ pathname: '/auth/login', state: { prevPath: location.pathname } }}
          />
        </ButtonBox>
      </InfoWrapper>
  )
};

export default InfoUnLoggedInUser;