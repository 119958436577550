const BALLOONS = Object.freeze([
  {
    id: 1,
    bottom: '-132%',
    right: '70%',
    bottomMob: '-108%',
    rightMob: '66%',
    zIndex: 8,
  },
  {
    id: 2,
    bottom: '-142%',
    right: '25%',
    bottomMob: '-125%',
    rightMob: '29%',
    zIndex: 7,
  },
  {
    id: 3,
    bottom: '-120%',
    right: '1%',
    bottomMob: '-117%',
    rightMob: '-4%',
    zIndex: 6,
  },
  {
    id: 4,
    bottom: '-101%',
    right: '27%',
    bottomMob: '-100%',
    rightMob: '34%',
    zIndex: 5,
  },
  {
    id: 5,
    bottom: '-77%',
    right: '65%',
    bottomMob: '-82%',
    rightMob: '56%',
    zIndex: 4,
  },
  {
    id: 6,
    bottom: '-76%',
    right: '-5%',
    bottomMob: '-89%',
    rightMob: '1%',
    zIndex: 3,
  },
  {
    id: 7,
    bottom: '-57%',
    right: '36%',
    bottomMob: '-70%',
    rightMob: '27%',
    zIndex: 2,
  }
])

export {BALLOONS}