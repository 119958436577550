import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { FieldWrapper, FormWrapperBase } from '../../shared/Form/Form.styles';
import { FieldContainer } from '../../shared/Form/InputField/InputField.styles';
import { SelectWrapper } from '../../shared/Form/SelectField/SelectField.styles';
import { SelectorWrapper } from '../../shared/Form/InputSelector/InputSelector.styles';

export const BreadcrumbsWrapper = styled.div(({ theme }) => {
  const { color } = theme;
  return css`
    background-color: ${color.white};
  `;
});

export const RegistrationWrapper = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    background-color: ${color.white};
    min-height: 100vh;
  `;
});

export const RegistrationTitle = styled.h1(({theme}) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px32};
    line-height: 1.2;
    font-weight: ${font.weight.semiBold};
    color: ${color.darkCharcoal};
    margin-bottom: 15px;

    ${mediaQueries.sm} {
      font-size: ${size.px24};
    }
  `;
});

export const RegistrationText = styled.p(({theme}) => {
  const { size, mediaQueries } = theme;

  return css`
    margin-bottom: 30px;
    
    ${mediaQueries.sm} {
      font-size: ${size.px14};
    }
  `;
});

export const RegistrationTextLink = styled(Link)(({theme}) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px14};
    line-height: 1.2;
    font-weight: ${font.weight.semiBold};
    color: ${color.blue};
  `;
});

export const FormSection = styled.div(({theme}) => {
  const { color } = theme;

  return css`
    position: relative;
    width: 100%;
    
    &:after{
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid ${color.cottonGrey};
      margin: 40px 0;
    }
  `;
});

export const FormWrapper = styled(FormWrapperBase)(() => {
  return css`
    
    ${FormSection}{
      &:last-of-type{
        margin-bottom: 40px;
        
        &:after{
          display: none;
        }
      }
    }
  `;
});

export const FormSectionTitle = styled.h2(({theme}) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px18};
    line-height: 1.2;
    font-weight: ${font.weight.semiBold};
    color: ${color.darkCharcoal};
    margin-bottom: 25px;
  `;
});

export const FieldWrapperReg = styled(FieldWrapper)(({theme}) => {
  const { mediaQueries } = theme;
  return css`
    width: 50%;
    margin: 0;

    ${mediaQueries.sm} {
      width: 100%;
    }
    
    ${FieldContainer}, ${SelectWrapper}, ${SelectorWrapper} {
      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }
    }
  `;
});

export const IconInfoBox = styled.button(() => {
  return css`
    position: absolute;
    top: 35px;
    left: calc(100% + 10px);
    outline: none;
    border: none;
    background: transparent;
    width: 25px;
    height: 25px;
  `;
});

export const FieldWrapperRegIcon = styled(FieldWrapperReg)(({theme}) => {
  const { mediaQueries } = theme;

  return css`
    ${mediaQueries.sm} {
      width: calc(100% - 35px)
    }
  `;
});

export const WhyDialogBox = styled.div(() => {
  return css`
    width: 100%;
  `;
})

export const LocationAgreeBox = styled.div(({theme}) => {
  const { mediaQueries } = theme;

  return css`
    .dialog-window{
      left: 0;
      width: 50%;
      
      p{
        margin: 10px 0;
      }
    }

    ${mediaQueries.sm} {
      .dialog-window{
        width: calc(100vw - 50px);
      }
    }
  `;
})

export const HelpText = styled.p(({theme}) => {
  const { size, color } = theme;

  return css`
    font-size: ${size.px12};
    color: ${color.grey};
  `;
});

export const DetailsCard = styled.div(() => {
  return css`
    margin-top: 40px;
  `;
});

export const FixAutoFillInput = styled.input(() => {
  return css`
    display: inherit;
    opacity: 0;
    width: 0;
    height: 0;
    line-height: 1;
    z-index: -33333;
    padding: 0 !important;
    padding-block: 0 !important;
    padding-inline: 0 !important;
    border: 0 !important;
  `;
});

export const CheckboxWrapper = styled.div(() => {
  return css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `;
});

export const CheckboxContainer = styled.div(() => {
  return css`
    position: relative;
  `;
});

export const InfoLabelBox = styled.div(({theme}) => {
  const { color } = theme;

  return css`
    position: absolute;
    top: -2px;
    left: calc(100% + 10px);
    background: ${color.blue};
    padding: 0 8px;
    border-radius: 4px;
    width: max-content;
  `;
});

export const InfoLabelText = styled.span(({theme}) => {
  const { size, color } = theme;

  return css`
    text-transform: uppercase;
    font-size: ${size.px10};
    color: ${color.white};
  `;
});

export const FormSectionNewsLetter = styled(FormSection)(({theme}) => {
  const { size, mediaQueries } = theme;

  return css`
    ${FormSectionTitle}{
      margin-bottom: 0;
    }
    
    ${HelpText}{
      font-size: ${size.px14};
      margin-bottom: 25px;
    }
    
    ${IconInfoBox}{
      top: 1px;
    }

    ${CheckboxWrapper}{
      ${mediaQueries.sm} {
        max-width: 65%;
      }
    }
  `;
});