import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useWindowSize } from "../../../redesign/hooks";
import { Headline, Step } from "../../../redesign/components/shared";
import { StepsPopupProps } from "./stepsPopup.types";
import {
  AnimatedLayout,
  PopupCloseBtn,
  StepsPopupContainer,
  StepsPopupOverlay,
  StepsPopupWrapper
} from "./StepsPopup.styles";
import { StepsFlexRow, StepsWrapper } from "../StepsHorizontal/StepsHorizontal.styles";

const StepsPopup: React.FC<StepsPopupProps> = ({ params }) => {
  const campaignPopup = localStorage.getItem('campaignPopup');
  const [isShowed, setIsShowed] = useState(!!campaignPopup);
  const [isAnimated, setIsAnimated] = useState(false);
  const windowSize = useWindowSize();
  const [animationState, setAnimationState] = useState<{
    fadeType: string | null;
  }>({ fadeType: null });

  const close = () => {
    localStorage.removeItem('campaignPopup');
    setIsAnimated(true);
    setTimeout(() => {
      setIsShowed(false);
      setIsAnimated(false);
    }, 300);
  };

  useEffect(() => {
    isShowed
      ? isAnimated
        ? setAnimationState({ fadeType: "out" })
        : setTimeout(() => setAnimationState({ fadeType: "in" }), 0)
      : setAnimationState({ fadeType: "out" });
  }, [isShowed, isAnimated]);

  useEffect(() => {
    if (isShowed) {
      document.body.classList.add("modal-open");
      document.body.style.marginRight =
        window.innerWidth - document.body.clientWidth + "px";

      return () => {
        document.body.classList.remove("modal-open");
        document.body.style.removeProperty("margin-right");
      };
    }
  }, [isShowed, windowSize]);

  return (
    isShowed ? ReactDOM.createPortal(
        <AnimatedLayout className={`fade-${animationState.fadeType}`}>
          <StepsPopupOverlay onClick={() => close()} />
          <StepsPopupWrapper>
            <PopupCloseBtn onClick={() => close()} />
            <StepsPopupContainer>
              <Headline title={params?.headline} subtitle={params?.label} />
              <StepsWrapper>
                <StepsFlexRow itemsPerRow={params?.step_items?.length || 3}>
                  {params?.step_items?.map((step, index) => {
                    return <Step key={step._id || index.toString()} {...step} />;
                  })}
                </StepsFlexRow>
              </StepsWrapper>
            </StepsPopupContainer>
          </StepsPopupWrapper>
        </AnimatedLayout>,
        document.body
      )
      : null
  );
};

export default StepsPopup;
