import React from 'react';
import i18n from 'i18next';
import ios from '../../img/ios.svg';
import android from '../../img/android.svg';
import LazyLoad from 'react-lazyload';

const AppDownload = (props) => {
	return (
		<div className="app-download-icons">
			<a href={i18n.t('01_store_link_android')}>
				<LazyLoad height={150} offset={350} once>
					<img src={android} alt="" width={114} height={props.height} />
				</LazyLoad>
			</a>
			<a href={i18n.t('01_store_link_ios')}>
				<LazyLoad height={150} offset={350} once>
					<img src={ios} alt="" width={128} height={props.height} />
				</LazyLoad>
			</a>
		</div>
	);
};

export default AppDownload;
