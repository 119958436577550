import React, {RefObject, useMemo, useCallback, useEffect} from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';

import { shopLang } from '../../../../../config/config';
import { DropdownIndicator, DropdownOption } from './components';
import ErrorsList from '../ErrorsList';
import { FieldLabel } from '../UI';

import { SelectOption, SelectOptionListProps } from './selectField.types';
import { colourStyles } from './config';
import {
  Icon,
  InputIcon,
  SelectPlaceholder,
  SelectHolder,
  SelectWrapper,
  PlaceholderText
} from './SelectField.styles';
import { FieldError } from '../InputField/InputField.styles';

export type SelectProps = {
  name: string;
  hasDefaultValue?: boolean;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  width: number;
  isError?: boolean;
  errorText?: string;
  defaultValue?: string;
  isErrorsListActive?: boolean;
  fieldScheme?: object;
  isDisabledClipboardEvent?: boolean;
  refEl?: RefObject<HTMLInputElement>;
};

const removeLastWord = (str: string) => {
  const lastWhiteSpaceIndex = str.lastIndexOf(' ');
  return str.substring(0, lastWhiteSpaceIndex + 1);
};

const SelectAutocomplete: React.FC<SelectOptionListProps & SelectProps> = ({
  options,
  name,
  hasDefaultValue,
  placeholder,
  isRequired = false,
  label,
  width,
  isError = false,
  errorText,
  defaultValue = '',
  isErrorsListActive = false,
  refEl,
  fieldScheme,
  isDisabledClipboardEvent = false
}) => {
  const optionsList: {
    label: string;
    value: string | number;
    image?: string;
    code?: string | number;
  }[] = useMemo(() => options.map((option: SelectOption) => ({
    label: option.label,
    value: option.value,
    image: option.image,
    code: option.code
  })),[options]);

  const { control, setValue } = useFormContext();

  const [selected, setSelected] = React.useState([]);
  const [input, setInput] = React.useState(defaultValue);
  const [icon, setIcon] = React.useState(options[0].image);
  const [isVisiblePlaceholder, setVisiblePlaceholder] = React.useState(true);

  const handleChange = (s: any) => {
    setSelected({ ...s });

    setInput((input) => removeLastWord(input) + s.value);
    setIcon(s.image);
  };

  const inputChange = useCallback((value: string) => {
    const str = value.substring(0, 3);
    const matches = optionsList.filter((option) =>
      String(option.value).includes(str)
    );

    setInput(value);
    setValue(name, value);

    if (value.length >= 1) {
      setIcon(matches[0]?.image);
    } else {
      shopLang === 'nl_nl' ?
        setIcon(options[0]?.image) :
        setIcon(options[1]?.image);
    }
  }, [name, options, setValue, optionsList])

  const handleInputChange = useCallback((newValue: string, meta: { action: string }) => {
    if (meta.action === 'input-change') {
      inputChange(newValue);
    }
  },[inputChange]);

  React.useEffect(() => {
    input.length === 0
      ? setVisiblePlaceholder(true)
      : setVisiblePlaceholder(false);
  }, [input, selected]);

  React.useLayoutEffect(() => {
    const inputEl = document.getElementById(name);
    if (!inputEl) return;
    inputEl.setAttribute('inputMode', 'tel');
  }, [name, selected]);

  useEffect(() => {
    inputChange(defaultValue)
  },[defaultValue, inputChange]);

  useEffect(() => {
    refEl?.current && isDisabledClipboardEvent &&
    ['copy', 'paste', 'cut'].forEach( ev =>
      refEl?.current?.addEventListener(ev, (e: any) => e.preventDefault())
    );

    return () => {
      refEl?.current && isDisabledClipboardEvent &&
      ['copy', 'paste', 'cut'].forEach( ev =>
        refEl?.current?.removeEventListener(ev, (e: any) => e.preventDefault())
      );
    };
  }, [isDisabledClipboardEvent, refEl]);

  return (
    <SelectWrapper
      ref={refEl}
      style={{ width: `${width}%` }} className={isError ? 'hasError' : ''}>
      <FieldLabel name={name} label={label!} isRequired={isRequired} />
      <SelectHolder>
        {isVisiblePlaceholder && (
          <SelectPlaceholder>
            {hasDefaultValue && <Icon src={icon} alt='icon' />}
            <PlaceholderText>
              {placeholder ? placeholder :
                shopLang === 'nl_nl' ?
                  options[0].value :
                  options[1].value
              }
            </PlaceholderText>
          </SelectPlaceholder>
        )}
        {icon && (
          <InputIcon className='CustomSelectIcon' src={icon} alt='icon' />
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{ required: errorText }}
          render={({ field: { onBlur, onChange } }) => (
            <>
              <Select
                styles={colourStyles}
                classNamePrefix='CustomSelect'
                placeholder={placeholder ? placeholder : false}
                inputId={name}
                onChange={(newValue, meta) => {
                  handleChange(newValue);
                  onChange(newValue?.value, meta);
                }}
                blurInputOnSelect={false}
                inputValue={input}
                onInputChange={(newValue, meta) => {
                  if(meta.action === 'input-change'){
                    handleInputChange(newValue, meta);
                    onChange(newValue, meta);
                  }
                }}
                onBlur={onBlur}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: null,
                  SingleValue: () => null
                }}
                options={optionsList}
                formatOptionLabel={DropdownOption}
                isSearchable
                hideSelectedOptions={false}
                noOptionsMessage={() => null}
              />
            </>
          )}
        />
      </SelectHolder>
      { isError && !isErrorsListActive && <FieldError>{errorText}</FieldError>}

      {
        isError && isErrorsListActive && fieldScheme &&
        <ErrorsList fieldScheme={fieldScheme}
                    errorText={errorText}/>
      }

    </SelectWrapper>
  );
};

export default SelectAutocomplete;
