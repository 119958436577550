import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { loadProgressBar } from 'axios-progress-bar';

// import "babel-polyfill";
// import "react-app-polyfill/ie11";
// import "nodelist-foreach-polyfill";
import 'element-closest';

import App from './App';
import { store } from './redux/store';

import i18n from './config/i18n';
import './components/utils/_errors-interceptor';
import './redesign/utils/_checkAccessToken';

import 'normalize.css';
import 'axios-progress-bar/dist/nprogress.css';
import 'assets/scss/common.scss';
import APIServiceEBike from './prepr/components/EBikeCampaignCustom/api_campaign/api';

import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';

loadProgressBar();
loadProgressBar('', APIServiceEBike._axios); // for EBike

if (process.env.REACT_APP_ENV !== 'dev') {
  Sentry.init({
    dsn: "https://2cef42370d42481db62074dfa0e8b3d7@o1243767.ingest.sentry.io/4505433299091456",
    environment: process.env.REACT_APP_ENV,
    release: `exxonmobil-website@${process.env.REACT_APP_VERSION}`,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', '.png', '.jpeg', '.jpg', '.svg'], // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      }),
      new CaptureConsole({
        levels: ['error']
      })
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // the fix for - Non-Error promise rejection captured with value: Timeout
    beforeSend(event, hint) {
      if (hint.originalException === "Timeout") return null;
      return event;
    },
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Unexpected identifier',
      'Unexpected token',
      'AbortError',
      'Network request failed',
      'undefined is not an object',
      'null is not an object'
    ],
  });
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);
