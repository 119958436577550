import styled, { css } from 'styled-components';

export const StepBox = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 8px;
    background: ${color.white};
    border-radius: 16px;
    transition: box-shadow 0.3s;
    text-decoration: none;

    ${mediaQueries.minWidthSM} {
      margin: 0;
      padding: 12px;

      width: 100%;
      border: 0;

      &:active,
      &:focus,
      &:focus-within,
      &:target,
      &:visited,
      &:focus-visible {
        border: none;
        outline: none;
      }
    }

    ${mediaQueries.sm} {
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  `;
});

export const StepImg = styled.div(({ theme }) => {
  const { mediaQueries } = theme;
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

    overflow: hidden;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      vertical-align: bottom;
      border-radius: 12px;
    }

    ${mediaQueries.minWidthSM} {
      max-height: 400px;
    }
  `;
});

export const StepContent = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    padding: 24px 0;

    ${mediaQueries.sm} {
    }
  `;
});

export const StepTitle = styled.h3(({ theme }) => {
  const { color, font } = theme;

  return css`
    color: ${color.darkCharcoal};
    font-family: ${font.family.EMPrintSemi};
    margin-bottom: 10px;
  `;
});

export const StepPreTitle = styled.h6(({ theme }) => {
  const { color, font, size } = theme;

  return css`
    color: ${color.graniteGray};
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px14};
    margin-bottom: 8px;
  `;
});

export const StepText = styled.div(({ theme }) => {
  const { size, color, font } = theme;

  return css`
    font-size: ${size.px14};
    color: ${color.graniteGray};
    line-height: 1.5;
    font-family: ${font.family.sansSerif};
    
    p, span {
      font-size: ${size.px14};
      color: ${color.graniteGray};
      line-height: 1.5;
      font-family: ${font.family.sansSerif};
      margin-top: ${size.px10};
    }

    a {
      color: ${color.blue};
      text-decoration: none;
    }
  `;
});

export const ButtonWrapper = styled.div(() => {
  return css`
    margin-top: 20px;
    
    button, a{
      display: inline-flex;
      min-width: auto;
    }
  `;
});