import React, { useState, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from 'uuid';
import SwiperCore, { Scrollbar } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import { getRewardsByCategory } from "../../../../../components/utils/_reward-helpers";
import Card from "./Card";
import { checkLoggedIn } from "../../../../../components/utils/_app-helpers";
import { useWindowSize } from "../../../../../redesign/hooks";
import { ProductCardProps } from "../../../../../redesign/components/shared/ProductCard/productCard.types";
import { ProductsWrapper } from "./Products.styles";
import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { ProductsImagesList } from "./productsImagesList";

SwiperCore.use([Scrollbar]);

type ProductsProps = {
  idCategory: string | null;
}

const Products = ({ idCategory }: ProductsProps) => {
  const onLogin = checkLoggedIn();
  const windowSize = useWindowSize();
  const [products, setProducts] = useState([]);
  const [env, setEnv] = useState<'stage' | 'prod' | null>(null);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();

  const swiperParams: Swiper = useMemo(
    () => ({
      loop: false,
      speed: 600,
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      spaceBetween: 20,
      initialSlide: 0,
      navigation: false,
      scrollbar:{
        draggable: true
      },
      onSwiper: setSwiperInstance
    }),
    []
  );

  useEffect(() => {
    process.env.REACT_APP_ENV === 'prod' ? setEnv('prod') : setEnv('stage');
  },[])

  useEffect(() => {
    if (idCategory && env) {
      getRewardsByCategory(99, idCategory).then((response) => {

        let updatedResponse = response?.data?.payload.rewards.map((reward: ProductCardProps) => {
          let customProduct = ProductsImagesList?.find((i) => {
            return i[`id_${env}`] === Number(reward?.id);
          })
          return Object.assign(reward, { customImageUrl: customProduct?.image });
        })

        setProducts(updatedResponse);
      });
    }
  }, [idCategory, env]);

  useEffect(() => {
    swiperInstance?.slideTo(0, 0)
  }, [products, swiperInstance]);

  return (
    <ProductsWrapper>
      { products?.length  > 0 ?
      (
        windowSize >= 768 ?
          (
            products?.map((item: ProductCardProps) => {
              return (
                <Card key={uuidv4()}
                      onLogin={onLogin}
                      name={item.name}
                      website={item.website}
                      imageUrl={item?.customImageUrl || item.imageUrl} />
              );
            })
          ) : (
            <Swiper {...swiperParams}>
              {
                products?.map((item: ProductCardProps) => {
                  return (
                    <SwiperSlide key={uuidv4()}>
                      <Card key={uuidv4()}
                            onLogin={onLogin}
                            name={item.name}
                            website={item.website}
                            imageUrl={item?.customImageUrl || item.imageUrl}  />
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          )
      ) : <></> }
    </ProductsWrapper>
  );
};

export default Products;
