import styled, { css } from 'styled-components';
export const LoyaltyProgramGrid = styled.div`
`;

export const LoyaltyProgramRow = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    width: calc(100% + 24px);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -12px;
    
    ${mediaQueries.md} {
      width: 100%;
      margin: 0;
    }
  `;
});

export const LoyaltyProgramWrap = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: calc(100% / 3 - 24px);
    margin: 0 12px 20px;

    ${mediaQueries.md} {
      width: 100%;
      max-width: 700px;
      margin: 0 0 20px;
    }

    ${mediaQueries.sm} {
      max-width: 100%;
    }
  `;
});