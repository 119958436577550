import React from 'react';
import GameWrap from '../GameWrap';
import { GameUnLoggedInUserProps } from './gameUnLoggedInUser.types';

const GameUnLoggedInUser: React.FC<GameUnLoggedInUserProps> = () => {
  return (
    <GameWrap/>
  )
};

export default GameUnLoggedInUser;