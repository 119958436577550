import React from 'react';

import { Headline, ServiceFeature } from '../../../redesign/components/shared';
import { ServiceFeatureProps } from '../../../redesign/components/shared/ServiceFeature/serviceFeature.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { FeaturesWrap } from './ContactComponent.styles';

interface ContactComponentProps {
  params?: {
    title: string;
    subtitle: string;
    features: ServiceFeatureProps[];

    _id: string;
    __typename: string;
  };
}

const ContactComponent: React.FC<ContactComponentProps> = ({ params }) => {
  return (
    <Wrapper>
      <Container maxWidth='1250px'>
        <Headline title={params?.title} subtitle={params?.subtitle}>
          <>
            <FeaturesWrap>
              {params?.features.map((feature, index) => {
                return (
                  <ServiceFeature
                    key={feature._id || index.toString()}
                    {...feature}
                  />
                );
              })}
            </FeaturesWrap>
          </>
        </Headline>
      </Container>
    </Wrapper>
  );
};

export default ContactComponent;
