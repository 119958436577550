import styled, { css } from 'styled-components';

export const accordionWidth = '486px';

export const AccordionWrapper = styled.ul(() => {
  return css`
    list-style: none;
    margin: 0 0 25px;
    padding: 0;
  `;
});

export const AccordionStepWrapper = styled.li(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    width: ${accordionWidth};
    margin: 10px 0 0;
    transition: background-color 0.2s ease-in-out;

    &:first-child {
      margin-top: 0;
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }
    
    ${AccordionStepButton},
    ${AccordionStepNum}{
      font-family: ${font.family.EMPrintRegular};
      font-weight: ${font.weight.semiBold};
      font-size: ${size.px20};
      line-height: 1.2;
      color: ${color['grey-400']};
      transition: 0.2s ease-in-out;
    }
    
    &.active{
      ${AccordionStepTitle},
      ${AccordionItemContentInner}{
        background-color: ${color.lightblue};
      }
      
      ${AccordionStepButton},
      ${AccordionStepNum}{
        color: ${color.blue};
      }
      
      ${AccordionItemImg}{
        opacity: 1;
      }
    }

    ${mediaQueries.sm} {
      width: 100%;
    }
  `;
});

export const AccordionStepTitle = styled.h3(() => {
  return css`
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `;
});

export const AccordionStepNum = styled.span(() => {
  return css`
    position: absolute;
    left: 25px;
    top: 25px;
  `;
});


export const AccordionStepButton = styled.button(() => {
  return css`
    width: 100%;
    padding: 25px 25px 15px 56px;
    border: 0;
    outline: none;
    cursor: pointer;
    text-align: left;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  `;
});

export const AccordionItemContainer = styled.div(() => {
  return css`
    transition: height 0.2s ease-in-out;
    overflow: hidden;
  `;
});

export const AccordionItemContent = styled.div(() => {
  return css`
  `;
});

export const AccordionItemContentInner = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    padding: 0 25px 25px 56px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    ${mediaQueries.sm} {
      margin-bottom: 10px;
    }
  `;
});

export const AccordionItemText = styled.p(({ theme }) => {
  const { size, font, color } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px14};
    line-height: 1.57;
    color: ${color.graniteGray};
    
    a {
      color: ${color.blue};
      text-decoration: none;
    }
  `;
});

export const AccordionItemImg = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: calc(100% - ${accordionWidth} - 25px);
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    
    img{
      width: 100%;
      max-width: 492px;
      height: auto;
      vertical-align: bottom;
    }

    ${mediaQueries.sm} {
      position: static;
      width: 100%;
      transform: none;
      opacity: 1;
      padding: 0 25px;
    }
  `;
});

