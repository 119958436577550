import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import {Loader} from '../index';
import { LoaderWrapper, Spinner } from "./LoaderProgressBar.styles";

export type LoaderProgressBarProps = {
  loaded: number;
  total: number;
  isSpinner?: boolean,
  position?: 'fixed' | 'absolute';
};
function LoaderProgressBar({loaded, total, isSpinner = false, position='fixed'}: LoaderProgressBarProps){
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let percentCompleted = Math.round((loaded / total) * 100);
    setProgress(percentCompleted);
  },[loaded, total])

  return (
    <LoaderWrapper position={position}>
      <LoadingBar
        containerStyle={{position: position}}
        color='#003399'
        progress={progress}
        height={4}
        onLoaderFinished={() => setProgress(0)}
      />
      {isSpinner && progress > 0 && (
        <Spinner position={position}>
          <Loader/>
        </Spinner>
      )}
    </LoaderWrapper>
  );
}
export default LoaderProgressBar;
