import styled, { css } from 'styled-components';
import { HeaderBg1, HeaderBgMob1 } from '../../assets/phase1';
import { HeaderBg2, HeaderBgMob2 } from '../../assets/phase2';

type HeaderType = {
  phase?: number;
};

export const Wrapper = styled.div<HeaderType>(({ theme, phase = 1 }) => {
  const { color, mediaQueries } = theme;

  return css`
    position: relative;
    overflow: hidden;
    height: 760px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: calc(100% + 10px) 100%;
    background-image: ${() => phase === 1 ? `url(${HeaderBg1})` : `url(${HeaderBg2})`};
    
    ${Title},
    ${Text}{
      color: ${() => phase === 1 ? color.white : color.navy};
    }

    ${mediaQueries.sm} {
      height: 690px;
      background-size: ${() => phase === 1 ? '100% 100%' : 'auto'};
      background-image: ${() => phase === 1 ? `url(${HeaderBgMob1})` : `url(${HeaderBgMob2})`};
    }
    
    ${HeaderImage}{
      ${phase === 1 && `
        right: 0;
        width: 740px;
      `}

      ${phase === 2 && `
        right: -5%;
        width: 70%;
        max-width: min(1000px, 70vw);
        min-width: 740px;
      `}
      
      ${mediaQueries.md} {
        ${phase === 1 && `
          bottom: 45px;
          transform: none;
          top: inherit;
          width: 560px;
        `};

        ${phase === 2 && `
          top: 50%;
          min-width: 530px;
        `};
      }
      
      ${mediaQueries.sm} {
        ${phase === 1 && `
          bottom: inherit;
          left: 50%;
          top: 15px;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          
          img{
            max-height: 340px;
          }
       `}
        
        ${phase === 2 && `
          right: inherit;
          left: 50%;
          top: -100px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          
          img{
            max-height: 640px;
          }
       `}
      }
    }
  `;
});

export const Container = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    padding: 0 25px;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    ${mediaQueries.sm} {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 25px;
    }
  `;
});

export const HeaderImage = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);

    ${mediaQueries.sm} {
      img{
        max-width: initial;
      }
    }
  `;
});

export const Content = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    z-index: 1;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${mediaQueries.lg} {
      width: 50%;
    }

    ${mediaQueries.sm} {
      width: 100%;
      height: auto;
      text-align: center;
    }
  `;
});

export const Title = styled.h1(({ theme }) => {
  const { size, mediaQueries } = theme;

  return css`
    margin-bottom: 30px;
    font-size: ${size.px56};
    line-height: 1.1;

    ${mediaQueries.md} {
      font-size: ${size.px42};
    }

    ${mediaQueries.sm} {
      font-size: ${size.px32};
      margin-bottom: 20px;
    }
  `;
});

export const Text = styled.p(({ theme }) => {
  const { font, size, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-weight: ${font.weight.semiBold};
    margin-bottom: 30px;
    font-size: ${size.px24};
    line-height: 1.2;

    ${mediaQueries.md} {
      font-size: ${size.px20};
    }

    ${mediaQueries.sm} {
      font-size: ${size.px18};
      margin-bottom: 20px;
    }
  `;
});

export const ButtonWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;

    button, a{
      display: inline-flex;
    }

    ${mediaQueries.sm} {

      button, a{
        width: auto;
      }
    }
  `;
});

export const KeysWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    margin-bottom: 30px;
    display: inline-flex;
    align-items: center;
    padding: 5px 15px;
    min-width: 312px;
    height: 76px;
    background-color: rgba(255, 255, 255, 0.16);
    border-radius: 8px;

    img{
      width: 60px;
      margin-right: 15px;
    }
    
    ${mediaQueries.sm} {
      min-width: 270px;
      height: 52px;
      padding: 5px 10px;
      margin: 0 0 15px;

      img{
        width: 42px;
        margin-right: 10px;
      }
    }
  `;
});

export const KeysText = styled.span(({ theme }) => {
  const { size, color, font, mediaQueries } = theme;

  return css`
    color: ${color.cottonGrey};
    font-size: ${size.px16};
    line-height: 1;
    font-weight: ${font.weight.semiBold};
    font-family: ${font.family.EMPrintSemi};

    ${mediaQueries.s} {
      font-size: ${size.px14};
    }
  `;
});