import styled, { css } from 'styled-components';

export const PageHeaderHomeWrapper = styled.header(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    position: relative;
    padding-bottom: ${size.px40};

    .anchor-cta {
      position: absolute;
      z-index: 2;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }

    ${mediaQueries.md} {
      margin-bottom: 0;

      .anchor-cta {
        display: none;
      }
      
      .swiper-container{
        background-color: #0047ba;
      }
      .swiper-slide{
        overflow: hidden;
      }
    }
  `;
});
