import React from 'react';

import { Headline, Step } from '../../../redesign/components/shared';

import { StepsHorizontalProps } from './stepsHorizontal.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { StepsFlexRow, StepsWrapper } from './StepsHorizontal.styles';

const StepsHorizontal: React.FC<StepsHorizontalProps> = ({ params }) => {
  return (
    <Wrapper>
      <Container maxWidth={'1200px'}>
        <Headline title={params?.headline} subtitle={params?.label} />
        <StepsWrapper>
          <StepsFlexRow itemsPerRow={params?.step_items?.length || 3}>
            {params?.step_items?.map((step, index) => {
              return <Step key={step._id || index.toString()} {...step} />;
            })}
          </StepsFlexRow>
        </StepsWrapper>
      </Container>
    </Wrapper>
  );
};

export default StepsHorizontal;
