import React, { useCallback, useRef } from 'react';
import i18n from '../../../../../config/i18n';

import { Button } from '../../../../../redesign/components/shared';
import { eBikeHeaderProps } from './eBikeHeader.types';
import {
    EBikeHeaderWrapper,
    AnimatedBox,
    AnimatedCloud,
    AnimatedFlyingBike,
    EBikeHeaderContent,
    EBikeHeaderTitle,
    EBikeHeaderSubTitle,
    ButtonBox,
    CloudBox,
    RefWrapp,
} from "./EBikeHeader.styles";
import { useSelector } from "react-redux";

const EBikeHeader: React.FC<eBikeHeaderProps> = () => {
  const isFinished = useSelector((state: any) => state?.campaignInfo?.campaignGames[0]?.isFinished);
  const scrollToCustom = useRef<HTMLDivElement>(null);

  const executeScrollToCustom = useCallback(() => {
    scrollToCustom?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [scrollToCustom])

  return (
      <EBikeHeaderWrapper>
        <AnimatedBox>
          <AnimatedFlyingBike/>
          <AnimatedCloud/>
        </AnimatedBox>

        <EBikeHeaderContent>
          {
            isFinished ?
              <>
                <EBikeHeaderTitle>{i18n.t('eBike_completed_subtitle')}</EBikeHeaderTitle>
                <EBikeHeaderSubTitle>{i18n.t('eBike_completed_text')}</EBikeHeaderSubTitle>
              </> :
              <>
                <EBikeHeaderTitle dangerouslySetInnerHTML={{ __html: i18n.t('eBike_header_title')}}></EBikeHeaderTitle>
                <EBikeHeaderSubTitle>{i18n.t('eBike_header_subtitle')}</EBikeHeaderSubTitle>
                <ButtonBox>
                  <Button
                    onClick={executeScrollToCustom}
                    text={i18n.t('eBike_header_btn')}
                    buttontype={'primary'}
                  />
                </ButtonBox>
              </>
          }
        </EBikeHeaderContent>

        <CloudBox/>

        <RefWrapp ref={scrollToCustom} />
      </EBikeHeaderWrapper>
  )
};

export default EBikeHeader;
