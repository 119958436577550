import styled, { css } from 'styled-components';
import { GameBalloon } from '../../../../../../../assets';

type BalloonWrapperTypes = {
  bottom: string,
  right: string,
  bottomMob: string,
  rightMob: string,
  zIndex: number,
  isActive: boolean,
  isAnimated: boolean,
}
export const GameWrapStyled = styled.div(({theme}) => {
  const {mediaQueries} = theme;
  return css`
    position: relative;
    width: 65%;
    max-width: 450px;
    height: 100%;
    margin-right: 55px;

    ${mediaQueries.sm} {
      width: 100%;
      max-width: 340px;
      margin: 0 auto;
      height: 400px;
    }
  `;
});

export const BalloonBox = styled.div(({theme}) => {
  const {mediaQueries} = theme;
  return css`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: url('${GameBalloon}') no-repeat top center transparent;
    background-size: 100%;
    transition: transform ease 0.5s;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
      outline: none !important;
    }
    
    ${mediaQueries.sm} {

    }
  `;
});

export const ButtonBox = styled.div(({theme}) => {
  const {color, mediaQueries} = theme;
  return css`
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity ease 0.5s;
    opacity: 0;
    
    button{
      border-radius: 25px;
      min-width: 175px;
      
      &:hover,
      &:disabled{
        background-color: ${color.olympic};
      }
    } 
    

    ${mediaQueries.sm} {
      top: 18%;
    }
  `;
});

export const PrizeBoxWon = styled.div(({theme}) => {
  const {mediaQueries} = theme;
  return css`
    display: none;
    position: absolute;
    width: 450px;
    bottom: -280px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity ease 0.5s;
    opacity: 1;
  }
    

    ${mediaQueries.sm} {
      width: 300px;
      bottom: -200px;
    }
  `;
});

export const PrizeBoxLost = styled.div(({theme}) => {
  const {mediaQueries} = theme;
  return css`
    display: none;
    position: absolute;
    width: 240px;
    bottom: -240px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity ease 0.5s;
    opacity: 1;
    margin-left: 20px;
    

    ${mediaQueries.sm} {
      width: 180px;
      bottom: -225px;
      margin-left: 15px;
    }
  `;
});

const ACTIVE_BALLOON = css`
  cursor: pointer;
  z-index: 20 !important;

  ${BalloonBox}{
    //z-index: 20 !important;
    transform: scale(1.2);
  }

  ${ButtonBox}{
    opacity: 1;
  }
`;

const ANIMATED_BALLOON = css`
  bottom: 300px !important;
  transition: bottom 6s ease;
  
  ${ButtonBox}{
    display: none;
  }
  
  ${PrizeBoxLost},
  ${PrizeBoxWon}{
    display: block;
  }
`;

export const BalloonWrapper = styled.div<BalloonWrapperTypes>(({
                                                                 theme,
                                                                 bottom,
                                                                 right,
                                                                 bottomMob,
                                                                 rightMob,
                                                                 zIndex,
                                                                 isActive,
                                                                 isAnimated}) => {
  const {mediaQueries} = theme;
  return css`
    position: absolute;
    width: 182px;
    height: 1064px;
    
    bottom: ${bottom};
    right: ${right};
    z-index: ${zIndex};

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
      outline: none !important;
    }

    ${isActive && ACTIVE_BALLOON}

    ${isAnimated && ANIMATED_BALLOON}
    
    ${mediaQueries.sm} {
      width: 130px;
      height: 760px;
      
      bottom: ${bottomMob};
      right: ${rightMob};
    }
  `;
});