import styled, { css } from 'styled-components';
import {
  Container,
  ContainerProps
} from '../../../redesign/theme/GlobalStyles';

type BannerWrapperProps = {
  imagePosition?: boolean;
};

type BannerImageProps = {
  image: {
    imageUrl?: string;
    imageAlignment?: string;
  };
  topPosition?: string;
}


export const imageWidth = '810px';
export const imageWidthTab = '55%';
export const imageHeightMob = '63vmin';

export const BannerContainer = styled(Container)<ContainerProps>(
  ({ theme }) => {
    const { mediaQueries } = theme;

    return css`
      display: flex;
      position: relative;
      min-height: 545px;
      
      ${mediaQueries.sm} {
        padding-top: 0;
      }
    `;
  }
);

export const BannerImage = styled.div<BannerImageProps>(({ theme, image, topPosition = '0' }) => {
  const { mediaQueries } = theme;
  const { imageUrl, imageAlignment = 'right' } = image;

  return css`
    display: block;
    position: absolute;
    top: ${topPosition}px;
    height: 100%;
    width: ${imageWidth};
    background-image: url('${imageUrl}');
    background-size: auto 100%;
    background-repeat: no-repeat;

    ${mediaQueries.lg} {
      width: ${imageWidthTab};
      background-size: contain;
    }

    ${mediaQueries.sm} {
      height: ${imageHeightMob};
      width: 85%;
      top: 0;
      right: ${() =>
        imageAlignment === "left"
          ? "inherit"
          : imageAlignment === "center"
            ? "50%"
            : "20px"} !important;
      left: ${() => (imageAlignment === "left" ? "20px" : "inherit")} !important;
      transform: ${() =>
        imageAlignment === "center" ? "translateX(50%)" : "none"} !important;

      @media (orientation: landscape) {
        max-width: 450px;
      }
    }
  `;
});

export const BannerWrapper = styled.div<BannerWrapperProps>(
  ({ theme, imagePosition = false }) => {
    const { mediaQueries } = theme;

    return css`
      position: relative;
      width: 100%;
      min-height: 545px;

      ${mediaQueries.lg} {
        min-height: auto;
      }
      
      ${BannerContainer}{
        flex-direction: ${() => imagePosition ? 'row-reverse' : 'row'};
      }

      ${BannerImage}{
        right: ${() => imagePosition ? 'inherit' : '-150px'};
        left: ${() => imagePosition ? '-150px' : 'inherit'};
        background-position: ${() => imagePosition ? 'top right' : 'top left'};

        ${mediaQueries.xxl} {
          right: ${() => imagePosition ? 'inherit' : '-5vw'};
          left: ${() => imagePosition ? '-5vw' : 'inherit'};
        }

        ${mediaQueries.xl} {
          right: ${() => imagePosition ? 'inherit' : '20px'};
          left: ${() => imagePosition ? '20px' : 'inherit'};
        }

        ${mediaQueries.lg} {
          background-position: ${() => imagePosition ? 'top right' : 'top left'};
        }

        ${mediaQueries.sm} {

        }
      }
    `;
  }
);

export const BannerContent = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    padding-top: ${size.px40};
    max-width: 548px;
    width: calc(100% - ${imageWidth} + 15px);

    ${mediaQueries.xl} {
      width: calc(100% - ${imageWidth} + 15px - 20px);
    }

    ${mediaQueries.lg} {
      width: calc(100% - ${imageWidthTab} - 25px - 20px);
    }

    ${mediaQueries.sm} {
      width: 100%;
      max-width: 100%;
      padding-top: calc(${imageHeightMob} + 15px);
    }
  `;
});

export const BannerSubTitle = styled.h6(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px18};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    color: ${color.graniteGray};
    margin-bottom: 15px;

    ${mediaQueries.sm} {
      font-size: ${size.px16};
      font-weight: ${font.weight.regular};
    }
  `;
});

export const BannerTitle = styled.h2(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px40};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    color: ${color.darkCharcoal};
    margin-bottom: 15px;

    ${mediaQueries.sm} {
      font-size: ${size.px28};
    }
  `;
});

export const BannerText = styled.div(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px14};
    font-weight: ${font.weight.regular};
    line-height: 1.5;
    color: ${color.graniteGray};
    margin: 0 0 30px;

    h1, h2, h3, h4, h5, h6{
      color: ${color.graniteGray};
      font-weight: ${font.weight.regular};
      line-height: 1.2;
      word-wrap: break-word;
    }

    h1, h2, h3, h4, h5, h6, p, ol, ul{
      margin-bottom: 15px;
    }

    p, a, span, ol, ul, li{
      font-family: ${font.family.sansSerif};
      font-size: ${size.px14};
      font-weight: ${font.weight.regular};
      line-height: 1.5;
      color: ${color.graniteGray};
    }

    ol, ul{
      padding-left: 16px;
      margin-top: 15px;
      
      li {
        list-style-type: disc;
      }

      p{
        margin-bottom: 0;
      }

      ul, ol{
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    
    h1{
      font-size: ${size.px36};

      ${mediaQueries.sm} {
        font-size: ${size.px28};
      }
    }
    
    h2, h3{
      font-size: ${size.px28};

      ${mediaQueries.sm} {
        font-size: ${size.px20};
      }
    }

    h4, h5{
      font-size: ${size.px14};
    }

    a{
      &:visited {
        text-decoration: underline;
      }
      &:hover,
      &:active {
        cursor: pointer;
        color: rgba(0, 33, 100, 0.7);
      }
    }

    ${mediaQueries.sm} {
      margin: 0 0 25px;
    }
  `;
});

export const ButtonBox = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: inline-block;

    a {
      text-decoration: none;
    }

    ${mediaQueries.sm} {
      display: block;
    }
  `;
});