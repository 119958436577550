import styled, { css } from 'styled-components';

export const HamburgerButtonWrapper = styled.button(({ theme }) => {
  const {color, mediaQueries} = theme;

  return css`
    ${mediaQueries.md} {
      display: flex;
    }
    
    display: none;
    align-items: center;
    background: transparent;
    width: 48px;
    height: 100%;
    padding: 0 10px;
    margin-right: 10px;
    transition: background-color 0.3s;
    
    i{
      border-radius: 3px;
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background-color: ${color.blue};
      
      &:nth-child(1) {
        animation: outT 0.3s backwards;
        animation-direction: reverse;
      }
      
      &:nth-child(2) {
        margin: 5px 0;
        animation: outM 0.3s backwards;
        animation-direction: reverse;
      }
      
      &:nth-child(3) {
        animation: outBtm 0.3s backwards;
        animation-direction: reverse;
      }
    }
    
    &.active {
      background-color: ${color.lightBlue};
      i{
        &:nth-child(1) {
          animation: inT 0.3s forwards;
        }

        &:nth-child(2) {
          animation: inM 0.3s forwards;
        }
        
        &:nth-child(3) {
          animation: inBtm 0.3s forwards;
        }
      }
    }

    @keyframes inM {
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(45deg);
      }
    }

    @keyframes outM {
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(45deg);
      }
    }

    @keyframes inT {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(8px) rotate(0deg);
      }
      100% {
        transform: translateY(8px) rotate(135deg);
        opacity: 0;
      }
    }

    @keyframes outT {
      0% {
        transform: translateY(0px) rotate(0deg);
        opacity: 1;
      }
      50% {
        transform: translateY(8px) rotate(0deg);
      }
      100% {
        transform: translateY(8px) rotate(135deg);
      }
    }

    @keyframes inBtm {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(-8px) rotate(0deg);
      }
      100% {
        transform: translateY(-8px) rotate(135deg);
      }
    }

    @keyframes outBtm {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(-8px) rotate(0deg);
      }
      100% {
        transform: translateY(-8px) rotate(135deg);
      }
    }

  `;
});
export const LinesBox = styled.div(() => {
  return css`
    cursor: pointer;
    width: 25px;
    height: 20px;
  `;
});



