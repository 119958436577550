import React from 'react';

import { VideoProps } from './video.types';
import { ItemContent, ItemDescription, ItemVideo, TitleVideo, WrappedItemVideo, BoxItemVideo } from "./Video.styles";

const Video: React.FC<VideoProps> = (props) => {
  const { url, title, is_video_vertical, description, description_position } = props;

  return (
    <WrappedItemVideo descriptionPosition={description_position}
                      isVideoVertical={is_video_vertical}>
      <BoxItemVideo>
        <ItemVideo>
          <iframe
            title={title}
            src={url}
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            className="responsive-iframe"
          />
        </ItemVideo>
      </BoxItemVideo>

      <ItemContent>
        {title && <TitleVideo>{title}</TitleVideo>}
        {description && <ItemDescription dangerouslySetInnerHTML={{ __html: `${description}` }} />}
      </ItemContent>
    </WrappedItemVideo>
  );
};

export default Video;
