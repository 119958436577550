import styled, { css } from 'styled-components';
import {
  headerWrapperHeight,
  headerWrapperHeightMob,
  customWrapperMarginBottom,
  customWrapperMarginBottomMob,
  gameContainerHeight,
  gameContainerHeightMob,
} from '../../EBikeCampaignCustom.styles';
import { Oval } from '../../../../assets';

export const EBikeGameWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    margin-top: -${headerWrapperHeight};
    padding-top:  ${headerWrapperHeight};
    background: url('${Oval}') no-repeat center #DBECC1;
    background-size: cover;
    overflow: hidden;

    ${mediaQueries.sm} {
      margin-top: -${headerWrapperHeightMob};
      padding-top:  ${headerWrapperHeightMob};
      background-image: none;
    }

    .anim-enter,
    .fade-enter {
      opacity: 0;
    }
    .anim-enter-active,
    .fade-enter-active{
      opacity: 1;
      transition: opacity 2000ms;
    }
    .anim-exit,
    .fade-exit{
      opacity: 1;
    }
    .anim-exit-active {
      display: none;
      opacity: 0;
      transition: opacity 0ms;
    }

    .fade-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }
  `;
});

export const EBikeGameContainer = styled.div(({ theme }) => {
  const {mediaQueries } = theme;

  return css`
    max-width: 1200px;
    height: ${gameContainerHeight};
    margin: 0 auto;

    ${mediaQueries.sm} {
      // height: ${gameContainerHeightMob};
      height: auto;
      min-height: 665px;
    }
  `;
});

export const EBikeGameBox = styled.div(({ theme }) => {
  const {mediaQueries } = theme;

  return css`
    height: calc(${gameContainerHeight} - ${customWrapperMarginBottom});
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    ${mediaQueries.sm} {
      align-items: flex-start;
      flex-direction: column-reverse;
      height: auto;
      padding-bottom: 0;
      position: relative;
      // height:  calc(${gameContainerHeightMob} - ${customWrapperMarginBottomMob});
    }
  `;
});

export const Subtitle = styled.h6(({ theme }) => {
  const {font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px18};
    line-height: 1.2;
    color: ${color.darkCharcoal};
    margin-bottom: 5px;

    ${mediaQueries.sm} {
    }
  `;
});