import React, { Component, Suspense, lazy } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import * as actions from '../../redux/actions';

import { GlobalStyles, Theme } from '../../redesign/theme';
import { ThemeProvider } from 'styled-components';

import { translate } from 'react-i18next';
import { createBrowserHistory } from 'history';
import i18n from '../../config/i18n';

import {
  getCurrentUser,
  getMyOrders,
} from '../utils/_home-helpers';
import {
  getAllPointsHistory,
  getPointTransactionTypes,
  getAllPointsTransfer,
  getCustomerDetails,
  getAllCards
} from '../utils/_profile-helpers';
// import Header from './Header/Header';
// import Footer from './Footer/Footer';
import Header from '../../redesign/components/Header';
import ChestGame from '../../redesign/campaigns/EYC2024';


import ScrollToTop from '../ScrollToTop';
// import uuid from 'uuid';

import { isWebView, checkLoggedIn } from '../utils/_app-helpers';
import { addGTM } from '../utils/_gtm-helpers';

import SetOneTrustCookies from './OneTrustCookies';
import { shopLang } from '../../config/config';

import '../../components/auth/Auth.scss';
import '../../components/home/Home.scss';
import { PreprPage } from '../../prepr/PreprPage';
import { PageStub, PageCycloo, Footer } from '../../redesign/components/shared';
import { clearLocaleStorage, PAGE_PATH } from '../../redesign/utils';
import { Login, Registration, EmailSent, EmailConfirm } from '../../redesign/components/auth';
import TestAnimation from '../../redesign/components/testAnimation';

// Homepage

const Notification = lazy(() => import('../common/Forms'));
const WhyModal = lazy(() => import('../common/modals/WhyModal'));

// Authorization

const Auth = lazy(() => import('../auth/Auth'));
const AuthTabs = lazy(() => import('../auth/AuthTabs'));
const ForgotEmail = lazy(() => import('../auth/ForgotEmail'));
const ForgotPass = lazy(() => import('../auth/ForgotPass'));
const Register = lazy(() => import('../auth/Register'));
const StartRegistration = lazy(() => import('../auth/StartRegistration'));
// const EmailConfirm = lazy(() => import('../auth/EmailConfirm'));
// const EmailSent = lazy(() => import('../auth/EmailSent'));
const TemporaryPassword = lazy(() => import('../auth/TemporaryPassword'));
const ChangeWeakPassword = lazy(() => import('../auth/ChangeWeakPassword'));

//Common

const Handover = lazy(() => import('../common/Handover'));
const MobileBridge = lazy(() => import('../common/MobileBridge'));

//Rewards pages

const RewardsAll = lazy(() => import('../rewards/RewardsAll'));
// const RewardInternal = lazy(() => import('../rewards/RewardInternal'));
// const Save = lazy(() => import('../../routes/Save'));
// const Unattended = lazy(() => import('../../routes/Unattended'));
// const Error404 = lazy(() => import('./Error404'));

//Profile pages

const Overview = lazy(() => import('../profile/Overview'));
const Transactions = lazy(() => import('../profile/Transactions'));
const UserInfo = lazy(() => import('../profile/UserInfo'));
const UserCards = lazy(() => import('../profile/UserCards'));
const UserRewards = lazy(() => import('../profile/UserRewards'));
const ChangePhone = lazy(() => import('../profile/ChangePhone'));
const ChangePassword = lazy(() => import('../profile/ChangePassword'));
const MergeAccounts = lazy(() => import('../profile/MergeAccounts'));

// Other pages

// const Terms = lazy(() => import('../terms/Terms'));
const TermsBoost = lazy(() => import('../terms/TermsBoost'));
// const Faq = lazy(() => import('../faq/Faq'));
// const Contact = lazy(() => import('../contact/Contact'));
const ContactForm = lazy(() => import('../contact/ContactForm'));
// const Privacy = lazy(() => import('../privacy/Privacy'));
const Cookies = lazy(() => import('../cookies/Cookies'));
// const Payment = lazy(() => import('../../routes/Payment/Payment'));

//Cookies Scripts

const history = createBrowserHistory();

history.listen((location) => {
  const pagepath = location.pathname + location.hash + location.search;

  // send page path to GA4
  ReactGA.send({ hitType: 'pageview', page: pagepath});

  //Google Tag Manager
  const isLoggedIn = checkLoggedIn() ? 'Logged In' : 'Logged out';

  addGTM('DC-3743070/count0/master+standard', {
    u1: pagepath,
    u2: location.hash.replace('#/', ''),
    u3: isLoggedIn
  });
});

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: {
        status: false,
        type: 'error',
        message: 'No information.'
      },
      types: [],
      webView: false,
    };
    this.showNotice = this.showNotice.bind(this);
    this.dismissNotice = this.dismissNotice.bind(this);
  }

  componentDidMount = () => {
    let webView = isWebView();
    if (!webView) {
      SetOneTrustCookies();
    }
    // shopLang === 'be_fr'
    //   ? addGTMLocalisationBE('2020_0')
    //   : addGTMLocalisationBE();
    //error message in case if user entered registered email
    if (localStorage.getItem('noEmailUpdate')) {
      this.showNotice(i18n.t('05_authorization_update_email_error'), 'error');
      localStorage.removeItem('noEmailUpdate');
    }
  };

  dismissNotice() {
    let that = this;
    let selector = document.querySelector('.notification-wrapper');
    if (typeof selector === 'undefined' || !selector) {
      return;
    }
    selector.classList.remove('active');
    setTimeout(function () {
      that.setState({
        notification: {
          status: false,
          message: 'No information.',
          type: 'error'
        }
      });
    }, 200);
  }

  showNotice(text, type) {
    this.dismissNotice();
    let that = this;

    function fireModal() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          that.setState({
            notification: {
              status: true,
              message: text,
              type: type
            }
          });
          resolve();
        }, 1000);
      });
    }

    function closeModal() {
      setTimeout(() => {
        that.dismissNotice();
      }, 5000);
    }

    fireModal().then(closeModal());
  }

  componentWillMount() {
    let that = this;
    let authorized = localStorage.getItem('authorized');
    let getToken = JSON.parse(localStorage.getItem('authToken'));
    let token = getToken && getToken.accessToken;

    //fix for Summer Campaign
    if (shopLang === 'be_nl' && window.location.hash === '#/cycling-fr') {
      window.location.replace(
        window.location.hash.replace('#/cycling-fr', '#/cycling-fr?lang=fr')
      );
      window.location.reload();
    }
    //end fix for Summer Campaign

    // Parse userAgent to find that is webView
    let webView = isWebView();

    if (webView) {
      this.props.dispatch(actions.addWebView(true));
    }

    if (authorized) {
      getCurrentUser(token)
        .then((response) => {
          if (response.data.code === 4000) {
            let accountID = response.data.payload.accountID;
            localStorage.setItem('accountId', accountID);
            that.props.dispatch(actions.addUserData(response.data.payload));
            that.props.dispatch(actions.setIsCurrentUserDataLoaded(true));
            transactionTypes(token);
            pointHistory(accountID, token);
            orderHistory(accountID, token);
            customerDetails(accountID, token);
            allCards(accountID, token);
          }
        })
        .catch((error) => {
          // fix the bug with white empty screen
          if(!webView){
            clearLocaleStorage();
            window.location.reload();
          }
          // end fix the bug with white empty screen
          console.log('error', error);
        });

      const setIsCurrentUserActive = (records) => {
        let fuelTransaction = records?.find((record) => {
          let last30Days = Date.now() - 30 * 24 * 60 * 60 * 1000;
          return (
            record.primaryType === 7 &&
            new Date(record.timeStampy).getTime() > last30Days
          );
        });

        fuelTransaction &&
          that.props.dispatch(actions.setIsCurrentUserActive(true));
      };

      const pointHistory = (accountId, token) => {
        let settings = {
          accountId,
          pageSize: 40,
          pageNumber: 1,
          sortOrder: 0
        };
        getAllPointsHistory(settings, token).then((response) => {
          if (response.data.payload.records.length > 0) {
            that.props.dispatch(
              actions.addPointsHistory(response.data.payload)
            );

            setIsCurrentUserActive(response.data.payload.records);
          }
          that.props.dispatch(actions.historyLoadedState(true));
        });

        getAllPointsTransfer(accountId, token).then((response) => {
          const { transfers } = response.data.payload;
          this.props.dispatch(actions.setAllPointsTransfer(transfers));
        });
      };

      const orderHistory = (accountId, token) => {
        getMyOrders(accountId, token).then((response) => {
          if (response.data.payload.myOrders.length > 0) {
            that.props.dispatch(actions.addOrderHitory(response.data.payload));
          }
        });
      };

      const transactionTypes = (token) => {
        getPointTransactionTypes(token).then((response) => {
          if (response.data.payload.types.length > 0) {
            that.props.dispatch(
              actions.addTransactionTypes(response.data.payload)
            );
          }
        });
      };

      const customerDetails = (accountId, token) => {
        getCustomerDetails(accountId, token).then((response) => {
          this.props.dispatch(
            actions.addCustomerDetails(response.data.payload)
          );
          this.props.dispatch(actions.phoneNumberLoaded(true));
        });
      };

      const allCards = (accountId, token) => {
        getAllCards(accountId, token).then((response) => {
          that.props.dispatch(actions.addCardsData(response.data.payload));
        });
      };
    }
  }

  render() {
    const { webView } = this.props;
    webView && document.querySelector('html').classList.add('webview');

    return (
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <Router>
          <Suspense fallback={null}>
            <ScrollToTop dismissNotice={this.dismissNotice} webView={webView}>
              {!webView && (
                <Header
                  isLoggedIn={this.props.isLoggedIn}
                  currentUser={this.props.currentUser}
                />
              )}
              <main>
                {this.state.notification.status && (
                  <Notification
                    text={this.state.notification.message}
                    onClick={this.dismissNotice}
                    type={this.state.notification.type}
                  />
                )}

                <Switch>
                  <Route
                    path='/'
                    exact
                    render={() => (<PageStub />)}
                  />
                  <Route
                    path='/test-animation'
                    render={() => (
                      <TestAnimation/>
                    )}
                  />
                  <Route
                    path='/auth/login-old'
                    render={(props) => (
                      <Auth>
                        <AuthTabs showNotice={this.showNotice} {...props} />
                      </Auth>
                    )}
                  />

                  <Route
                    path="/auth/login"
                    render={() => (<Login showNotice={this.showNotice}/>)}
                  />

                  <Route
                    path='/auth/forgotten-email'
                    render={() => (
                      <Auth>
                        <ForgotEmail showNotice={this.showNotice} />
                      </Auth>
                    )}
                  />
                  <Route
                    path='/auth/forgotten-password'
                    render={(props) => (
                      <Auth>
                        <ForgotPass showNotice={this.showNotice} />
                      </Auth>
                    )}
                  />
                  <Route
                    path='/auth/temporary-password'
                    render={(props) => (
                      <Auth>
                        <TemporaryPassword showNotice={this.showNotice} />
                      </Auth>
                    )}
                  />
                  <Route
                    path="/auth/registration/regOption=4"
                    render={() => <Registration showNotice={this.showNotice} />}
                  />
                  <Route
                    path='/auth/registration'
                    render={() => <Register showNotice={this.showNotice} />}
                  />
                  <Route
                    path='/auth/start-registration'
                    render={() => (
                      <StartRegistration showNotice={this.showNotice} />
                    )}
                  />
                  <Route
                    path='/auth/change-password'
                    render={(props) => (
                      <Auth>
                        <ChangeWeakPassword
                          showNotice={this.showNotice}
                          {...props}
                        />
                      </Auth>
                    )}
                  />

                  <Route path='/email-sent' render={(props) => <EmailSent />} />
                  <Route
                    path='/email-confirm'
                    render={(props) => (
                      <EmailConfirm {...props} showNotice={this.showNotice} />
                    )}
                  />
                  <Route
                    path='/confirm-email'
                    render={(props) => (
                      <EmailConfirm {...props} showNotice={this.showNotice} />
                    )}
                  />

                  {/*<Route path='/terms' render={() => <Terms />} />*/}
                  <Route path='/terms-boost' render={() => <TermsBoost />} />
                  <Route
                    path='/contact-us'
                    exact
                    render={() => (
                      <PageStub hardReload redirectURL='info-contact' />
                    )}
                  />
                  <Route
                    path='/contact-form-old'
                    render={() => (
                      <ContactForm
                        showNotice={this.showNotice}
                        currentUser={this.props.currentUser}
                      />
                    )}
                  />
                  <Route path='/cookies' render={() => <Cookies />} />
                  <Route
                    path='/why-phone'
                    render={() => <WhyModal webView />}
                  />

                  <Route path='/rewards' exact render={() => <RewardsAll />} />

                  <Route
                    path='/profile/overview'
                    render={() => (
                      <Overview currentUser={this.props.currentUser} />
                    )}
                  />
                  <Route
                    path='/profile/transactions'
                    render={() => (
                      <Transactions
                        currentUser={this.props.currentUser}
                        showNotice={this.showNotice}
                      />
                    )}
                  />
                  <Route
                    path='/profile/rewards'
                    render={() => (
                      <UserRewards currentUser={this.props.currentUser} />
                    )}
                  />

                  <Route
                    path='/profile/cards'
                    render={() => (
                      <UserCards
                        currentUser={this.props.currentUser}
                        showNotice={this.showNotice}
                      />
                    )}
                  />
                  <Route
                    path='/profile/info'
                    render={() => (
                      <UserInfo
                        currentUser={this.props.currentUser}
                        showNotice={this.showNotice}
                      />
                    )}
                  />
                  <Route
                    path='/profile/change-phone'
                    render={() => (
                      <ChangePhone
                        currentUser={this.props.currentUser}
                        showNotice={this.showNotice}
                      />
                    )}
                  />
                  <Route
                    path='/profile/change-password'
                    render={() => (
                      <ChangePassword
                        currentUser={this.props.currentUser}
                        showNotice={this.showNotice}
                      />
                    )}
                  />
                  <Route
                    path='/profile/merge-accounts'
                    render={() => (
                      <MergeAccounts
                        currentUser={this.props.currentUser}
                        showNotice={this.showNotice}
                      />
                    )}
                  />

                  <Route path='/handover-test' render={() => <Handover />} />
                  <Route
                    path='/mobile-bridge'
                    render={() => <MobileBridge />}
                  />
                  {shopLang === 'be_nl' &&
                    <Route
                      path='/cycloo'
                      exact
                      render={() => <PageCycloo showNotice={this.showNotice}/>}
                    />
                  }

                  <Route
                    path={`/${PAGE_PATH.EYC_2024_GAME}`}
                    render={() => <ChestGame />}
                  />

                  <Route
                    render={() => <PreprPage onLogin={this.props.isLoggedIn} />}
                  />
                </Switch>
              </main>
              {!webView && <Footer />}
            </ScrollToTop>
          </Suspense>
        </Router>
      </ThemeProvider>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    webView: state.webView,
  };
};

const ConnectedMain = connect(MapStateToProps)(Main);

export default translate('common')(ConnectedMain);
