import styled, { css } from "styled-components";

type BannerWrapperBaseProps = {
  darkMode: boolean | undefined;
};

type BannerContentBaseProps = {
  widthContent?: number;
};

export const BannerWrapperBase = styled.div<BannerWrapperBaseProps>(({theme, darkMode}) => {
  const {color, mediaQueries} = theme;

  return css`
    position: relative;
    width: 100%;
    border-radius: 16px;
    background-color: ${() => darkMode ? color.sea : color.lightblue};
    overflow: hidden;
    
    ${BannerTitle},
    ${BannerText}{
      color: ${() => darkMode ? color.white : color.blue};
    }

    ${mediaQueries.sm} {
      border-radius: 8px;
    }
	`;
});

export const BannerContainerBase = styled.div(({theme}) => {
  const {mediaQueries} = theme;

  return css`
    padding: 40px;
    
    ${mediaQueries.sm} {
      padding: 15px;
    }
	`;
});

export const BannerContentBase = styled.div<BannerContentBaseProps>(({theme, widthContent = 50}) => {
  const {mediaQueries} = theme;

  return css`
    width: ${() => widthContent ? `${widthContent}%` : '50%'};
    z-index: 1;

    ${mediaQueries.sm} {
      width: 100%;
    }
	`;
});

export const BannerTitle = styled.h3(({theme}) => {
  const {font, size, mediaQueries} = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-size: ${size.px32};
    font-weight: ${font.weight.semiBold};
    line-height: 1.2;
    margin-bottom: 10px;
    
    ${mediaQueries.sm} {
      font-size: ${size.px28};
      margin-bottom: 5px;
    }
	`;
});

export const BannerText = styled.p(({theme}) => {
  const {font, size, mediaQueries} = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px16};
    font-weight: ${font.weight.regular};
    line-height: 1.5;
    margin: 0;
    
    ${mediaQueries.sm} {
      font-size: ${size.px14};
    }
	`;
});
