import React, { useCallback, useEffect, useState } from 'react';

import getArticles from '../../queries/getArticles';

import { Article, Headline, Loader } from '../../../redesign/components/shared';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { ArticleProps } from '../../../redesign/components/shared/Article/article.types';
import {
  ArticleOverviewGrid,
  ArticleOverviewRow,
  ArticleOverviewRowCol
} from './ArticleOverview.styles';
import { sortArticles } from "../../../redesign/utils/sortArticles";

interface ArticleOverviewProps {
  params?: {
    title: string;
  };
}

const ArticleOverview: React.FC<ArticleOverviewProps> = ({ params }) => {
  const [articleLinks, setArticleLinks] = useState<ArticleProps[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>();

  const fetchArticleLinks = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await getArticles;

      if (data.length > 0) {
        const filteredArticles = data.filter(
          (item: { cta_url: any; internal_link: any }) =>
            item.cta_url !== null || item.internal_link !== null
        );

        setArticleLinks(filteredArticles);
      }
    } catch (e) {
      setErrors(['There is an error']);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchArticleLinks();
  }, [fetchArticleLinks]);

  return (
    <Wrapper>
      <Container maxWidth="1250px">
        <Headline title={params?.title}></Headline>
        {errors?.map((i) => i)}

        {isLoading ? (
          <Loader />
        ) : (
          <ArticleOverviewGrid>
            <ArticleOverviewRow>
              {articleLinks?.length &&
                sortArticles(articleLinks)?.map(
                  (
                    link: JSX.IntrinsicAttributes & ArticleProps,
                    index: { toString: () => any }
                  ) => {
                    return (
                      <ArticleOverviewRowCol key={link._id || index.toString()}>
                        <Article {...link} />
                      </ArticleOverviewRowCol>
                    );
                  }
                )}
            </ArticleOverviewRow>
          </ArticleOverviewGrid>
        )}
      </Container>
    </Wrapper>
  );
};

export default ArticleOverview;
