import React from 'react';
import { HamburgerButtonWrapper, LinesBox } from "./HamburgerButton.styles";
import { HamburgerButtonTypes } from "./hamburgerButton.types";

function HamburgerButton({...props} : HamburgerButtonTypes){
  const {isMobMenuActive, setIsMobMenuActive} = props
  return (
    <HamburgerButtonWrapper onClick={setIsMobMenuActive}
                            className={isMobMenuActive ? 'active' : undefined}>
      <LinesBox>
        <i></i>
        <i></i>
        <i></i>
      </LinesBox>
    </HamburgerButtonWrapper>
  )
}
export default HamburgerButton;
