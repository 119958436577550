import axios from 'axios';
import { apiURL, shopLang } from '../../config/config';
import { getShopLocalePrepr } from './_app-helpers';

const preprShopLocal = getShopLocalePrepr();

export const getRewardsByCategory = (amount, id) => {
  return axios({
    url:
      apiURL +
      `/api/Product/${
        preprShopLocal ? preprShopLocal : shopLang
      }/getRewardsByCategory`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      pageSize: amount,
      categoryId: id
    }
  });
};

export const getTestRewardsByCategory = () => {
  return axios({
    url: 'https://esso-backend-api-prod.m-point.eu/api/Product/be_nl/getRewardsByCategory?pageSize=30&categoryId=27',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const getAllCategories = () => {
  return axios({
    url: apiURL + `/api/Product/${shopLang}/getAllCategories`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
};
