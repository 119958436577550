import axios from 'axios';
import { store } from 'redux/store';
import * as Sentry from '@sentry/react';
import { shopLang } from '../../../../config/config';
import { getAccessToken, getRefreshToken, getTokens, getTransactions } from "./selectors";
import {environment, reCAPTCHA, baseURLStage, baseURLProd} from '../2024/api/const';
import {
  getCampaignInfo,
  addAuthData,
  addUserData,
  setTransactions,
  setParticipant,
  setCurrentPrize,
  setIsUserWon,
  setIsShowModal,
  setIsModalAnswerCorrect,
  setIsReplayGame,
  setActiveElement,
  setIsElementAnimated,
  setIsGameResultAnimated,
  setIsDefineWinnerCompleted,
  handleSetWinPoints,
} from "../../../../redux/actions_eyc";

class APIService {
  _getApiUrl(){
    if (environment === 'prod') {
      return baseURLProd;
    }else{
      return baseURLStage;
    }
  }

  _apiUrl = this._getApiUrl();

  _axios = axios.create({
    baseURL: `${this._apiUrl}/api`,
    withCredentials: true
  })

  _requests = new Map();

  _anonymousUrls = [
    '/auth/token/refresh',
    '/auth/token/revoke',
    '/auth/signin'
  ];

  constructor() {
    this.createInterceptors();
  }

  _updateRequests(url = '', add = false) {
    const current = this._requests.get(url) ?? 0;
    this._requests.set(url, current + +add * 2 - 1);
  }

  createInterceptors() {
    this._axios.interceptors.request.use(async (config) => {
      if (config?.url && !this._anonymousUrls.includes(config.url)) {
        const state = store.getState();
        const accessToken = getAccessToken(state);

        if (accessToken && config?.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      this._updateRequests(config.url, true);
      return config;
    });

    this._axios.interceptors.response.use(
      async (response) => {
        this._updateRequests(response.config.url);
        return response;
      },

      async (error) => {
        const originalRequest = error.config;
        this._updateRequests(originalRequest.url);
        const { message } = error.response?.data ?? {};

        const unauthorized = error.response?.status === 401;
        const invalid = message === 'Access token is invalid or expired';

        if (invalid && unauthorized) {
          if (originalRequest._retry) return this._revokeToken();

          const state = store.getState();
          const accessToken = getAccessToken(state);
          const refreshToken = getRefreshToken(state);

          await this._refreshToken(refreshToken);
          originalRequest._retry = true;

          if (accessToken) {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return this._axios(originalRequest);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  async _getReCaptchaConfig() {
    if(reCAPTCHA){
      if (!window.grecaptcha) {
        if (reCAPTCHA)
          throw new Error('ReCaptcha not initialized.');
        return;
      }

      try {
        const { enterprise } = window.grecaptcha;
        const token = await enterprise.execute(reCAPTCHA, { action: 'post' });
        return { headers: { 'x-recaptcha-token': token } };
      } catch {
        throw new Error('ReCaptcha not fetched.');
      }
    }
  }

  async _refreshToken(token){
    let resp = await this._axios.post(`/auth/token/refresh`, {refreshToken: token});


    const { accessToken, refreshToken, accessExpiresIn, refreshExpiresIn } =
      resp.data;

    const refreshExpiresAt = this._getAccessExpiration(refreshExpiresIn);
    const accessExpiresAt = this._getAccessExpiration(accessExpiresIn);

    store.dispatch(
      addAuthData({
        refreshExpiresAt,
        accessExpiresAt,
        refreshToken,
        accessToken
      })
    );

    return resp.data;
  }

  async _revokeToken(){
    store.dispatch(
      addAuthData({
        refreshExpiresAt: null,
        accessExpiresAt: null,
        refreshToken: null,
        accessToken: null
      })
    );

    return (await this._axios.post(`/auth/token/revoke`)).data;
  }

  _getAccessExpiration(delay = 0) {
    return Date.now() + delay * 1000;
  }

  async getCampaignBySlug (slug){
    let resp = await this._axios.get(`/campaigns/${slug}`);

    let locale = shopLang.slice(0, 2).toLowerCase();
    let activeCampaign = resp.data?.campaignGames?.find((game) =>
      (game?.isStarted && !game?.isFinished) ||
      (game?.isStarted && game?.isFinished && game?.name === `ey-2024-${locale}-gold`)
    )

    activeCampaign ? store.dispatch(getCampaignInfo(activeCampaign)) : store.dispatch(getCampaignInfo(null));

    return resp.data;
  }

  async signIn (data){
    // const config = await this._getReCaptchaConfig();
    let resp = await this._axios.post(`/auth/signin`, {...data});
    const { accessToken, refreshToken, accessExpiresIn, refreshExpiresIn } =
      resp.data.token;

    const refreshExpiresAt = this._getAccessExpiration(refreshExpiresIn);
    const accessExpiresAt = this._getAccessExpiration(accessExpiresIn);

    store.dispatch(
      addAuthData({
        refreshExpiresAt,
        accessExpiresAt,
        refreshToken,
        accessToken
      })
    );

    return resp.data;
  }

  async lotterySync () {
    try{
      let getTokenCommon = JSON.parse(localStorage.getItem('authToken'));
      let accessTokenCommon = getTokenCommon && getTokenCommon.accessToken;
      // const config = await this._getReCaptchaConfig();

      let resp = await this._axios.post(`/esso/lottery-tickets-sync`, {token: accessTokenCommon});
      return resp.data;
    } catch(e){
      Sentry.captureMessage(`Error in lotterySync: ${e}`, `info`);
    }
  }

  async updateUser (){
    let resp = await this._axios.get(`/users/me`);

    store.dispatch(addUserData({...resp.data}));
    return resp.data;
  }

  // createParticipant and runLottery in 1 call
  async createParticipantWithLottery(data) {
    // const config = await this._getReCaptchaConfig();
    let resp = await this._axios.post(`/participants/with-lottery`, {...data});
    let {lotteryPrize} = resp.data;

    store.dispatch(setParticipant(resp.data));
    store.dispatch(setCurrentPrize(lotteryPrize));

    return resp.data;
  }

  setTransactionsPrev (){
    const state = store.getState();
    const transactions = getTransactions(state);

    store.dispatch(setTransactions(transactions));
  }

  handleSetTransactions (transactions){
    store.dispatch(setTransactions(transactions));
  }

  setTransactions (){
    const state = store.getState();
    const transactions = getTokens(state);

    store.dispatch(setTransactions(transactions));
  }

  setIsUserWon(data){
    store.dispatch(setIsUserWon(data));
  }

  setIsShowModal(data){
    store.dispatch(setIsShowModal(data));
  }

  setIsModalAnswerCorrect(data){
    store.dispatch(setIsModalAnswerCorrect(data))
  }

  setIsReplayGame(data){
    store.dispatch(setIsReplayGame(data))
  }

  setActiveElement(data){
    store.dispatch(setActiveElement(data))
  }

  setIsElementAnimated(data){
    store.dispatch(setIsElementAnimated(data))
  }

  setIsGameResultAnimated(data){
    store.dispatch(setIsGameResultAnimated(data))
  }

  setIsDefineWinnerCompleted(data){
    store.dispatch(setIsDefineWinnerCompleted(data))
  }

  handleSetWinPoints (points){
    store.dispatch(handleSetWinPoints(points));
  }
}

export default new APIService();
