import React from 'react';
import { InfoUnLoggedInUser } from '../parts';
import { GameUnLoggedInUser } from '../parts/Game';
import { unLoggedInUserProps } from './unLoggedInUser.types';
import { EBikeGameBox, EBikeGameContainer } from '../EBikeGame.styles';

const UnLoggedInUser: React.FC<unLoggedInUserProps> = () => {
  return (
      <EBikeGameContainer>
        <EBikeGameBox>
          <InfoUnLoggedInUser/>
          <GameUnLoggedInUser/>
        </EBikeGameBox>
      </EBikeGameContainer>
  )
};

export default UnLoggedInUser;